<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h2>
          Vergleich der leistungsstärksten Implantat-Zahnzusatzversicherungen
        </h2>
        <h3>
          Welche Zahnzusatzversicherungen bieten die höchsten
          Implantat-Erstattungen?
        </h3>

        <br />Eine Implantat-Behandlung zieht of sehr hohe Zahnarztrechnungen
        nach sich. In der täglichen Leistungspraxis sehen wir regelmäßig
        Rechnungen die 20.000,- € überschreiten. Um im Leistungsfall nicht auf
        den Großteil der Kosten alleine sitzen zu bleiben, raten wir unseren
        Kunden eine Zahnzusatzversicherung abzuschließen, die im Ernstfall einen
        großen Teil der Kosten übernimmt.
        <br />
        <br />
        <h3>
          Die 10 besten Zahnzusatzversicherungen für Implantate im Vergleich
        </h3>
        Diese Zahnzusatzversicherungen begrenzen die Leistung für Implantate
        weder auf einen Höchstbetrag noch auf eine maximale Anzahl.
        <v-divider :thickness="2" class="my-6"></v-divider>
        <LayoutTable class="my-4" tableTyp="implantatVergleich" />

        <v-divider :thickness="2" class="my-6"></v-divider>
        <h3 id="checkliste_implantat">
          Checkliste Implantat-Zahnzusatzversicherung
        </h3>
        Um die richtige Zahnzusatzversicherung für Implantate zu finden, sollten
        Sie auf die folgenden Leistungsmerkmale achten.
        <ol>
          <li>
            Leistet die Versicherung für Implantate, wenn ja zu wieviel Prozent?
          </li>
          <li>
            Ist die Anzahl der Implantate für die geleistet wird begrenzt, wenn
            ja auf wieviele?
          </li>
          <li>
            Ist die Höhe der Erstattung pro Implantat begrenzt, wenn ja auf
            wieviel Prozent?
          </li>
          <li>
            Leistet die Versicherung für Knochenaufbau, wenn ja zu wieviel
            Prozent?
          </li>
        </ol>
        <v-divider :thickness="2" class="my-6"></v-divider>
        <h3 id="was_kostet_ein_implantat">
          Wieviel kostet das Einsetzen eines Implantates?
        </h3>
        Die Kosten für ein Zahnimplantat können sich auf 2000 € bis 3000 €
        belaufen. Je nach Ausgangssituation und Schwierigkeitsgrad (z.B. Ist ein
        Knochenaufbau nötig oder gibt es Komplikationen beim Setzen eines
        Implantats) können diese erheblich variieren. Diese Kosten muss der
        Patient nicht immer selbst tragen, sondern bekommt hierfür Unterstützung
        sowohl von der Gesetzlichen Krankenkasse (kleiner Anteil) als auch von
        einer eventuell abgeschlossenen Zahnzusatzversicherung (bis zu 100 % der
        Behandlungskosten). Das sind die größten Kostentreiber für
        Zahnimplantate:
        <ol>
          <li>
            Zahnarzthonorar (stark abhängig vom Schwierigkeitsgrad der
            Implantation)
          </li>
          <li>Zahnlabor</li>
          <li>Verwendete Materialien</li>
        </ol>

        <v-divider :thickness="2" class="my-6"></v-divider>

        <h3 id="alternative_behandlungsmethoden">
          Welche alternative Behandlungsmethoden gibt es für Zahnimplantate?
        </h3>
        <v-table>
          <tr style="vertical-align: top">
            <th style="width: 40px">
              <b></b>
            </th>
            <th style="width: 160px">
              <b>Zahnimplantat</b>
            </th>
            <th style="width: 160px">
              <b>Zahnbrücke</b>
            </th>
            <th style="width: 160px">
              <b>Zahnprothese</b>
            </th>
          </tr>
          <tr style="vertical-align: top">
            <td style="width: 60px">
              <b>Vorteil</b>
            </td>
            <td style="width: 160px">
              <ul>
                <li>Keine Beeinträchtigung benachbarter Zähne</li>
                <li>
                  Optisch nahezu nicht von natürlichen Zähnen zu unterscheiden
                </li>

                <li>Belastbar und langlebig wie ein natürlicher Zahn</li>

                <li>Keinen Rückbildung des Kieferknochens</li>
              </ul>
            </td>

            <td style="width: 160px">
              <ul>
                <li>bewährte Funktion</li>
                <li>Optisch gut integriert</li>
                <li>Fester Sitz</li>
              </ul>
            </td>
            <td style="width: 160px">
              <ul>
                <li>Kostengünstig</li>
                <li>Einfache Pflege</li>
              </ul>
            </td>
          </tr>

          <tr style="vertical-align: top">
            <td>
              <b>Nachteil</b>
            </td>
            <td>
              <ul>
                <li>Eventuell ist Knochenaufbau notwendig</li>
                <li>Aufwendige und zeitintensive Behandlung</li>

                <li>Mundhygiene und Pflege ist sehr wichtig</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Beeinträchtigung benachbarter Zähne</li>

                <li>Es kann zu Fehlbelastungen kommen</li>

                <li>Kieferknochen-Rückbildung möglich</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Eingeschränkter Trage- und Kaukomfort</li>

                <li>Gewöhnungsbedürftiger im Gebrauch</li>
              </ul>
            </td>
          </tr>

          <tr style="vertical-align: top">
            <td>
              <b>Kosten</b>
            </td>
            <td>ca. 2.000 € - 3.000 €</td>
            <td>ca. 1.000 € - 2.000 €</td>
            <td>ca. 500 - 1.000 €</td>
          </tr>
          <tr style="vertical-align: top">
            <td>
              <b>Leistung Gesetzliche Krankenkasse</b>
            </td>
            <td>
              Implantate sind keine Kassenleistung - lediglich für die
              Suprakonstruktion gibt es einen befundbezogenen Festzuschuss
            </td>
            <td>
              Die Gesetzlichen Krankenkasse zahlt einen Festzuschuss in Höhe von
              50 % - 65 % der tatsächlichen Kosten der einfachen Regelversorgung
            </td>
            <td>
              Die Gesetzlichen Krankenkasse zahlt einen Festzuschuss in Höhe von
              50 % - 65 % der tatsächlichen Kosten der einfachen Regelversorgung
            </td>
          </tr>
        </v-table>

        <v-divider :thickness="2" class="my-6"></v-divider>
        <h3>Lassen sich Implantate für bereits fehlende Zähne versichern?</h3>
        In einem Großteil der Fälle wird bei Verlust eines Zahnes die Versorgung
        der Lücke durch den Zahnarzt angeraten. Da diese Versorgung damit, vor
        Abschluss der Versicherung, angeraten wurde, werden die damit
        verbundenen Kosten nicht von der Versicherung getragen. Sollte eine
        Versorgung der Lücke nicht angeraten sein, aber nach
        Versicherungsabschluss medizinische notwendig werden, würde die
        Zahnzusatzversicherung für die Kosten dieser Behandlung leisten.
        Allerdings besteht die Schwierigkeit darin nachzuweisen, dass die
        Versorgung der Lücke erst nach Abschluss der Versicherung medizinisch
        notwendig wurde. Man kann hier also schon sagen, dass sich im Vergleich
        zum Abschlusszeitpunkt nachträglich etwas am Zahnstatus verändert haben
        muss, z. B. dass ein Nachbarzahn der Lücke ebenfalls gezogen werden
        muss, damit diese nachher eingetretene medizinische Notwendigkeit
        nachgewiesen werden kann.
        <v-divider :thickness="2" class="my-6"></v-divider>
        <h3>
          Wann wird eine Implantat-Maßnahme als medizinisch notwendig
          bezeichnet?
        </h3>
        Es gibt bestimmte Vorgaben, wann eine Zahnimplantat medizinisch
        notwendig ist. Ob ein Implantat medizinisch notwendig ist wurde in der
        sogenannten "Konsensuskonferenz für Implantologie" festgelegt. Zum
        Beispiel ist ein Implantat für einen fehlenden Zahn nur dann medizinisch
        notwendig ist, wenn die beiden Nachbarzähne der Lücke noch vollkommen
        intakt sind. Grund dafür ist, dass die beiden Zähne bei einer
        Brückenversorgung durch die Kronen beschädigt werden müssten, was beim
        Implantat nicht der Fall wäre. Auch wenn ein Implantat nach diesen
        Maßstäben als nicht medizinisch notwendig eingestuft wird, ist eine
        Erstattung möglich. Allerdings muss der behandelnde Arzt hier für eine
        medizinische Stellungnahme abgeben. Deshalb ist es wichtig vor der
        Behandlungen einen Heilkostenplan bei der Versicherung einzureichen und
        auf die schriftliche Kostenzusage warten. Weiterführende Informationen
        zu diesem Thema können Sie unter dem folgenden Link:
        <a href="https://www.konsensuskonferenz-implantologie.eu"
          >www.konsensuskonferenz-implantologie.eu</a
        >
        abrufen.
        <v-divider :thickness="2" class="my-6"></v-divider>
        <h3>
          Was tun wenn ein Implantat vom Zahnarzt angeraten wurde, der Patient
          aber bisher keine Zahnzusatzversicherung abgeschlossen hat?
        </h3>
        Sobald der Zahnarzt die Versorgung einer Lücke mit Zahnersatz angeraten
        hat, kann dieser Zahnersatz im Rahmen einer Zahnzusatzversicherung nicht
        mehr mitversichert werden, da der Versicherungsfall (mit Anraten der
        Maßnahme und Eintragung in die Patientenakte) bereits eingetreten ist.
        Es gibt nur einen Tarif der für bereits angeratene Maßnahmen leistet und
        das ist der ERGO Direkt Zahnersatz-Sofort. Allerdings verdoppelt dieser
        nur den Festzuschuss der Gesetzlichen Krankenkasse. Da Implantate keine
        Kassenleistung sind und es lediglich für die Suprakonstruktion einen
        befundbezogenen Festzuschuss gibt, muss man den größten Teil der Kosten
        für ein Implantat selbst tragen. Um zukünftigen Zahnersatz zu versichern
        sollte man dennoch eine Zahnzusatzversicherung abschließen. Es stellt
        sich allerdings die Frage, ob man die Lücke erst auf eigene Kosten
        versorgt und sich damit anschließend einen geringeren Beitrag sichert,
        oder ob man sich sofort versichert und einen etwaigen Aufschlag wegen
        der existierenden Lücke in Kauf nimmt.
        <v-divider :thickness="2" class="my-6"></v-divider>
        <h3>
          Sind Zahnimplantationen wirklich so schmerzhaft, wie viele behaupten?
        </h3>
        Bei einer Zahnimplantation handelt es sich um einen körperlichen
        Eingriff, der im Rahmen einer kleinen Operation erfolgt. Viele Patienten
        haben Angst vor einer Zahnimplantation weil sie gehört haben, dass diese
        besonders schmerzhaft ist. Hier können wir grundsätzlich Entwarnung
        geben. Natürlich ist das Schmerzempfinden vor, während und nach einem
        derartigen Eingriff sehr stark von der subjektiven Wahrnehmung des
        einzelnen Patienten abhängig. Während der Implantation findet zumeist
        eine örtliche Betäubung des betroffenen Bereiches statt. Aber auch
        Lachgas, Beruhigungstabletten und eine Narkose sind denkbar. Für
        Schmerzen die vereinzelt nach einer Zahnimplantation auftreten können,
        werden vom Zahnarzt Schmerzmittel, wie z.B. Ibuprofen verschrieben.
        Zusammenfassend lässt sich sagen, dass es mittlerweile Mittel und Wege
        gibt, eine Zahnimplantation so schmerzfrei wie möglich für den Patienten
        zu gestalten.
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  import LayoutTable from "@/views/site/helper/LayoutTable";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);

  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Zahnzusatzversicherung für Implantat |Tarif-Vergleich Begrenzungen & Leistungen",
      "Welche Zahnzusatzversicherungen leisten uneingeschränkt  für Implantat. Ohne Summenbegrenzungen und Begrenzungen für die Anzahl der Implantate"
    );
  });
</script>
