<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2>Heilpraktiker-Zusatzversicherung ohne Wartezeit</h2>
        Gibt es eine Heilpraktiker-Zusatzversicherung ohne Wartezeit? <b>Ja</b>.

        <br />
        Eine Zusatzversicherung Heilpraktiker kann mit oder ohne Wartezeiten
        abgeschlossen werden. Dabei obliegt es der Versicherungsgesellschaft, ob
        im jeweiligen Tarif eine Wartezeit vorgesehen ist oder nicht.
        <ul>
          <li>
            <a href="/heilpraktikerversicherung_vergleich"
              >alle Heilpraktikerversicherungen im Vergleich</a
            >
          </li>
          <li>
            <a href="/kinder_heilpraktikerversicherung_vergleich"
              >Kinder-Heilpraktikerversicherungen im Vergleich</a
            >
          </li>
        </ul>
        <br /><br />

        <h3>Was bedeutet Wartezeit?</h3>
        Unter einer Wartezeit versteht man eine tariflich festgelegte Frist,
        während die versicherte Person trotz bestehendem Versicherungsschutz
        keine Ansprüche geltend machen kann.
        <br /><br />

        <h3>Wann beginnt die Wartezeit?</h3>
        Die Wartezeit beginnt mit dem im Versicherungsschein dokumentierten
        Versicherungsbeginn. Meist wird der Versicherungsvertrag zum Ersten
        eines Monats policiert.
        <br /><br />
        <v-btn
          outlined
          color="#088a85"
          style="color: #fff !important"
          href="/vergleich/starten/heilpraktiker"
          >jetzt Heilpraktiker Zusatzversicherung vergleichen</v-btn
        >
        <br /><br />
        <h3>
          Stichwort sofortige Leistung - Heilpraktiker-Zusatzversicherung ohne
          Wartezeiten<br />
          Kann ich angeratene / geplante / laufende Behandlungen noch
          versichern?
        </h3>
        Nein. Auch Heilpraktikerversicherungen ohne Wartezeit leisten nicht für
        bereits vor Versicherungsbeginn angeratene / geplante oder laufende
        Behandlungen.
        <br /><br />
        Ein sofortiger Leistungsanspruch kann bei einer
        Heilpraktikerversicherung ohne Wartezeit zwar bestehen, jedoch muss der
        Versicherungsfall erst geprüft werden. Hier geht es darum, dass quasi
        der Versicherungsfall nicht schon vor Abschluss der Versicherung bereits
        eingetreten ist. Dazu erfolgt eine sog. nachgelagerte Gesundheitsprüfung
        in Form einer Arzt-Rückfrage.
        <br /><br />

        <h3>Während die Wartezeit läuft</h3>
        Solange die Wartezeit läuft, besteht Versicherungsschutz, jedoch kein
        Leistungsanspruch. Es können also nach Beginn des Versicherungsschutzes
        zwar Diagnosen gestellt werden, sprich Maßnahmen angeraten werden,
        welche erstattungsfähig sind. Jedoch können diese Maßnahmen erst nach
        Ablauf der entsprechenden Wartezeit erstattet werden. Entscheidend für
        die Leistung / Erstattung ist dabei das Behandlungsdatum, nicht das
        Rechnungsdatum.
        <br /><br />
        <v-btn
          outlined
          color="#088a85"
          style="color: #fff !important"
          href="/vergleich/starten/heilpraktiker"
          >jetzt Heilpraktiker-Zusatzversicherung vergleichen</v-btn
        >
        <br /><br />

        <h3>Allgemeines zu den Wartezeiten</h3>
        Weit verbreitet ist der Irrglaube, dass gerade Heilpraktiker-Tarife ohne
        Wartezeit sofort leisten müssen. Dem ist nicht zwangsläufig so. Zu den
        Pflichten, den sog. Obliegenheiten, des künftigen Versicherungsnehmers
        gehört es, bereits vor Abschluss der Versicherung wahrheitsgemäß alle
        Fragen zu beantworten, welche im Antrag gestellt werden. Dort werden
        meist auch entsprechende Fragen zum Gesundheitszustand des
        Antragstellers ausführlich oder offen formuliert gestellt. Diese Fragen
        können bis zu 10 Jahren rückwirkend zu beantworten sein.
        <br /><br />
        In den Allgemeinen Versicherungsbedingungen (AVBs) sowie den Allgemeinen
        Muster- und Tarifbedingungen wird darauf hingewiesen, dass es eine sog.
        vorvertragliche Anzeigepflicht (VVA) gibt und vor Vertragsbeginn bereits
        eingetretene Versicherungsfälle nicht gedeckt, sprich nicht
        erstattungsfähig sind. Einige Anbieter lehnen sogar Antragsteller ab,
        welche bereits Beschwerden haben und sich für spätere Behandlungen
        versichern möchten. Hier lohnt sich die Rücksprache mit einem Experten
        für Heilpraktikerversicherungen, wie der Fall einzuschätzen ist.

        <v-card class="my-8">
          <v-card-title>Wichtig:</v-card-title>
          <v-card-text
            >Alle bereits vor Abschluss der Heilpraktiker-Zusatzversicherung
            angeratenen, begonnenen / laufenden oder beabsichtigten Behandlungen
            sind vom Versicherungsschutz ausgeschlossen. Daher sollte die
            Wartezeit kein Kriterium bei der Wahl der passenden
            Heilpraktikerversicherung sein.</v-card-text
          >
        </v-card>

        <h3>
          Diese Wartezeiten gibt es bei der Heilpraktiker-Zusatzversicherung
        </h3>
        Die <strong>allgemeine Wartezeit</strong> für eine
        Heilpraktikerversicherung beträgt 3 Monate. Die sog.
        <strong>besondere Wartezeit</strong> beträgt 8 Monate und gilt für
        Entbindung und Psychotherapie. Bei Behandlungen, welche auf einen Unfall
        zurückzuführen sind, verzichten die Versicherer auf Wartezeiten.
        <br /><br />

        <h3>
          Besteht bei Heilpraktiker-Tarifen die Möglichkeit eines
          Wartezeitverzichts?
        </h3>
        Bei Heilpraktikerversicherungen kann es einen Wartezeiterlass geben.
        Dieser lohnt jedoch nicht, da nur diese Behandlungen versichert werden
        können, deren Diagnose nach Abschluss der Versicherung gestellt wurde.
        Darüber hinaus obliegt es der Versicherung zu entscheiden, ob der
        eingereichte Untersuchungsbericht des Hausarztes anerkannt wird oder
        nicht.
        <br /><br />

        <h3>
          Unsere Tarifempfehlungen: Heilpraktiker-Zusatzversicherungen ohne
          Wartezeiten
        </h3>
        <ul>
          <li>
            <a href="/heilpraktikerversicherung/id/270"
              >Heilpraktiker-Zusatzversicherung Barmenia Mehr Gesundheit
              2.000</a
            >
          </li>

          <li>
            <a href="/heilpraktikerversicherung/id/269"
              >Heilpraktiker-Zusatzversicherung Barmenia Mehr Gesundheit
              1.000</a
            >
          </li>

          <li>
            <a href="/heilpraktikerversicherung/id/248"
              >Heilpraktiker-Zusatzversicherung Versicherungskammer Bayern
              NaturPRIVAT</a
            >
          </li>

          <li>
            <a href="/heilpraktikerversicherung/id/262"
              >Heilpraktiker-Zusatzversicherung Versicherungskammer Bayern
              NaturPRIVAT + VorsorgePRIVAT</a
            >
          </li>

          <li>
            <a href="/heilpraktikerversicherung/id/261"
              >Heilpraktiker-Zusatzversicherung Signal Iduna AmbulantPLUS</a
            >
          </li>

          <li>
            <a href="/heilpraktikerversicherung/id/260"
              >Heilpraktiker-Zusatzversicherung Signal Iduna AmbulantPLUSpur</a
            >
          </li>

          <li>
            <a href="/heilpraktikerversicherung/id/249"
              >Heilpraktiker-Zusatzversicherung Württembergische Natur Med
              Plus</a
            >
          </li>
        </ul>
        <br /><br />
        <v-btn outlined color="#088a85" href="/vergleich/starten/heilpraktiker"
          >jetzt eigene Berechnung starten</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script setup></script>
