<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h2>
          WaizmannTabelle |
          <span style="color: #00bcd4"
            >Krankenhauszusatzversicherungen mit Unterbringung im
            Einbettzimmer</span
          >
        </h2>

        <br /><br />

        Die Art der gesonderten Unterbringung im Fall eines stationären
        Krankenhausaufenthalts ist mit die wichtigste Leistung einer
        Krankenhauszusatzversicherung.

        <br /><br />

        Im Folgenden eine Übersicht der von uns empfohlenen
        Einbettzimmer-Tarife:
        <LayoutTable class="my-4" tableTyp="krankBett1" />

        Ein Einbettzimmer im Fall eines stationären Krankenhausaufenthalts steht
        zunächst nur privat Versicherten offen. Gesetzlich Versicherte kommen
        nur dann in den Genuss einer Unterbringung im Einbettzimmer, wenn eine
        medizinische Notwendigkeit dafür vorliegt oder wenn der Patient bereit
        ist den Zuschlagspreis für die bestmögliche Unterbringung selbst zu
        zahlen.

        <br /><br />

        Der beste Weg für gesetzlich Krankenversicherte ohne Aufpreis an die
        bestmögliche Unterbringung zu kommen, ist somit der Abschluss einer
        entsprechenden Krankenhauszusatzversicherung mit
        Einbettzimmer-Leistungen. Die Vorteile einer Unterbringung im
        Einbettzimmer sind viel Privatsphäre und ausreichend Ruhe, um schnell
        gesund zu werden, weniger Kontakt mit anderen Patienten und somit eine
        geringer Infektionsgefahr und letztendlich mehr Komfort, z. B. durch ein
        eigenes Bad.

        <br /><br />

        <v-card class="my-4 pa-4">
          <v-card-title>TIPP vom KV-Spezialisten: </v-card-title>
          <v-card-text>
            Wenn man die gesonderte Unterbringung im Ein- oder Zweibettzimmer
            nicht nutzt, hat man in den von uns empfohlenen Krankenhaus-Tarifen
            Anspruch auf ein Ersatzkrankenhaustagegeld. Man erhält dann für
            jeden Tag eines Krankenhausaufenthalts einen tariflich vorher
            festgelegten Satz. Gleiches gilt bei der Nichtinanspruchnahme der
            Chefarztbehandlung.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts typeInfo="krank"
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  import LayoutTable from "@/views/site/helper/LayoutTable";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Krankenhauszusatzversicherungen mit Unterbringung im Zweibettzimmer",
      "Krankenhauszusatzversicherungen für das Zweibettzimmer | gehobene Unterbringung | kostenlosen und anonymen Annahmecheck nutzen"
    );
  });
</script>
