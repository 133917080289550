<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2>Heilpraktiker-Zusatzversicherung ohne Begrenzung</h2>
        Gibt es eine Heilpraktiker-Zusatzversicherung die ohne Begrenzung
        leistet?
        <b>Nein</b>. Eine Heilpraktiker Zusatzversicherung welche zu 100 %
        leistet gibt es nicht.
        <br />

        <ul>
          <li>
            <a href="/heilpraktikerversicherung_vergleich"
              >alle Heilpraktikerversicherungen im Vergleich</a
            >
          </li>
          <li>
            <a href="/kinder_heilpraktikerversicherung_vergleich"
              >Kinder-Heilpraktikerversicherungen im Vergleich</a
            >
          </li>
        </ul>
        <br /><br />

        <h3>Heilpraktiker-Zusatzversicherung 100 % Erstattung</h3>
        Eine Heilpraktikerversicherung mit 100 % Erstattung (ohne jegliche
        Leistungsbegrenzung in Euro / in Prozent) gibt es nicht. Die
        Leistungshöhen sind immer begrenzt. Zwar gibt es Heilpraktiker-Tarife
        mit einer 100 % Erstattung für Naturheilverfahren, welche dann aber z.B.
        bei 1.000,- € oder 2.000,- € pro Jahr summenmäßig begrenzt sind.
        <br /><br />
        Jeder Tarif kommt entweder nach anfänglicher Summenbegrenzung an seine
        tariflich festgelegte Obergrenze oder beginnt gleich mit der maximalen
        Leistungsobergrenze für Naturheilkunde-Leistungen sowie ggf. weiterer
        Erstattungshöhen für z.B. Sehhilfen (Brille, LASIK) sowie
        Vorsorgeleistungen und Schutzimpfungen.
        <br /><br />

        <h3>Das gilt es bei Leistungs-Begrenzungen zu unterscheiden</h3>
        Sowohl das Gebührenverzeichnis für Heilpraktiker (GebüH) als auch die
        Gebührenordnung für Ärzte (GOÄ) sehen eine Obergrenze / einen Höchstsatz
        für Behandlungen vor. Dieser Betrag / Satz der Gebührenordnung kann je
        nach Aufwand, Umfang und Schwere der Behandlung dementsprechend
        angesetzt werden.<br />
        Dabei wird bis zu diesen Höchstgrenzen maximal erstattet, wobei die
        meisten Heilpraktiker-Tarife eine Erstattung bis zum Höchstsatz der
        Gebührenordnung vorsehen.
        <br /><br />
        Zudem gibt es eine Höchstgrenze, welche in Euro definiert wurde und
        zudem mit z.B. 80 % Erstattung für die vorliegende Behandlungsrechnung
        leistet. Es wird also solange geleistet, bis die gesamten Rechnungen
        eben die Obergrenze erreicht haben. Wenn der Tarif zu 80 % leistet sind
        die verbleibenden 20 % der Eigenanteil der privat zu tragen ist.
        <br /><br />

        <v-card>
          <v-card-title
            >Experten-Tipp:<br />Empfohlene Summenbegrenzungen mit Fokus
            Naturheilkunde Leistungen</v-card-title
          >
          <v-card-text
            >Wir raten zu Naturheilkunde Tarifen, welche bis zum Höchstbetrag
            des Gebührenverzeichnis für Heilpraktiker (GebüH) sowie zum
            Höchstsatz der Gebührenordnung für Ärzte (GOÄ) leisten. Zudem sollte
            sich die maximale Erstattung in Euro bei Leistungen für
            Naturheilkundeverfahren in Höhe von ca. 800,- bis 1.000,- € pro Jahr
            erstrecken. Diese Begrenzung sollte spätestens nach der meist
            zweijährigen anfänglichen Begrenzung erreicht sein. Jedoch hat jede
            Person eigene Anforderungen an die Höhe der Versicherungsleistungen,
            sodass diese Entscheidung sehr individuell ist. Aktuell sind meist
            1.000 € pro Jahr Erstattung für Naturheilkunde der Standard, wobei
            es auch Tarife mit einem Budget von bis zu 2.000 € pro Jahr gibt. So
            oder so gibt es jedoch keine Tarife, welche gesamt gesehen
            unbegrenzt leisten.</v-card-text
          >
        </v-card>

        <h3>
          Empfehlenswerte Leistungsgrenzen für Erwachsenen Heilpraktiker-Tarife
        </h3>
        Als Budget für Naturheilkunde-Leistungen sollten mind. 800 € / 1.000 €
        pro Jahr zur Verfügung stehen. Idealerweise bereits gleich zu Beginn,
        quasi innerhalb der meist zweijährigen Begrenzung.
        <br /><br />

        <h3>
          Empfehlenswerte Leistungsgrenzen für Kinder Heilpraktiker-Tarife
        </h3>
        Als Budget für Naturheilkunde-Leistungen sollten mind. 600 € / 800 € pro
        Jahr zur Verfügung stehen. Idealerweise bereits gleich zu Beginn, quasi
        innerhalb der meist zweijährigen Begrenzung.
        <br /><br />
        Diese bereits zu beginn hohen Erstattungen für Naturheilkunde-Leistungen
        bieten sog. Misch-Tarife. Reine Heilpraktikerversicherungen sind dagegen
        anfangs meist stark begrenzt.
        <br /><br />

        <h3>
          Unsere Tarifempfehlungen: Heilpraktiker-Zusatzversicherungen mit hoher
          Leistung
        </h3>
        <ul>
          <li>
            <a href="/heilpraktikerversicherung/id/270"
              >Heilpraktiker-Zusatzversicherung Barmenia Mehr Gesundheit
              2.000</a
            >
          </li>
        </ul>
        100 % Erstattung für Naturheilverfahren durch Ärzte und Behandlungen
        durch Heilpraktiker bis 2.000,- € pro Jahr. Begrenzungen für
        Naturheilkunde Leistungen: 2.000 € pro Jahr.
        <br /><br />
        <ul>
          <li>
            <a href="/heilpraktikerversicherung/id/252"
              >Heilpraktiker-Zusatzversicherung ARAG 483</a
            >
          </li>
        </ul>
        Begrenzungen für Naturheilkunde Leistungen: 2.250 € alle zwei Jahre.
        <br /><br />
        <h3>
          Unsere Tarifempfehlungen: Heilpraktiker-Zusatzversicherung mit sehr
          guter Leistung
        </h3>
        <ul>
          <li>
            <a href="/heilpraktikerversicherung/id/387">AXA MED Komfort U</a>
          </li>
        </ul>
        Begrenzungen für Naturheilkunde Leistungen: 2.000 € innerhalb von zwei
        aufeinanderfolgenden Versicherungsjahren.
        <br /><br />
        <ul>
          <li>
            <a href="/heilpraktikerversicherung/id/269"
              >Heilpraktiker-Zusatzversicherung Barmenia Mehr Gesundheit
              1.000</a
            >
          </li>
        </ul>
        100 % Erstattung für Naturheilverfahren durch Ärzte und Behandlungen
        durch Heilpraktiker bis 1.000,- € pro Jahr. Begrenzungen für
        Naturheilkunde Leistungen: 1.000 € pro Jahr.
        <br /><br />
        <ul>
          <li>
            <a href="/heilpraktikerversicherung/id/257"
              >Heilpraktiker-Zusatzversicherung Gothaer Medi Ambulant</a
            >
          </li>
        </ul>
        Begrenzungen für Naturheilkunde Leistungen: 1.000 € pro Jahr.
        <br /><br />

        <v-btn outlined color="#088a85" href="/vergleich/starten/heilpraktiker"
          >jetzt eigene Berechnung starten</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script setup></script>
