<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h2>
          WaizmannTabelle |
          <span style="color: #00bcd4"
            >Krankenhauszusatzversicherung Testsieger 2024</span
          >
        </h2>

        Die Leistung der gesetzlichen Krankenversicherung stellt unter anderem
        auch im stationären Bereich lediglich eine Grundversorgung dar. Doch
        gerade hier kann es von großer Bedeutung sein von einem Spezialisten
        behandelt zu werden, eine passende Fachklinik selbst wählen zu können
        oder mit der Unterbringung im Ein- oder Zweibettzimmer gerade bei
        längeren Krankenhausaufenthalten einen gewissen Komfort zu erhalten, auf
        den man nicht gern verzichten möchte. Die wichtigsten Leistungen einer
        Krankenhaus-Zusatzversicherung sind:

        <ul>
          <li>Chefarztbehandlung</li>
          <li>Unterbringung im Ein- oder Zweibettzimmer</li>
          <li>freie Krankenhauswahl</li>
        </ul>

        Zusätzlich gibt es, abhängig vom Tarif, Leistungen für z. B.
        Komfortleistungen (Internet/Telefon/Fernsehen), Rooming-In,
        Krankentransport oder Übernahme der gesetzlichen Zuzahlung im
        Krankenhaus.

        <br /><br />

        Anhand dieser großen Auswahl an Leistungen sowie weiterer möglicher
        Faktoren, wie z. B. dem Beitrag, können Tarife für
        Krankenhauszusatzversicherungen getestet werden. Einer der bekanntesten
        Tester für Versicherungen ist Stiftung Warentest / Finanztest, dessen
        Test wir im Folgenden mit dem Testverfahren der WaizmannTabelle, dem
        KH-WaizmannWert, vergleichen.

        <br /><br />

        <ul>
          <li>
            <a href="/krankenhauszusatzversicherung_vergleich"
              >Alle Krankenhaus-Zusatzversicherungen im Vergleich</a
            >
          </li>
        </ul>

        <br /><br />

        <h3>
          Krankenhaus-Zusatzversicherungen im Test - Vergleich der Testverfahren
          und Testsieger
        </h3>

        <br />

        Im letzten Test von Krankenhauszusatzversicherungen durch Stiftung
        Warentest / Finanztest (Heft 07/2020) wurden insgesamt 37
        Einbettzimmertarife und 24 Zweibettzimmertarife getestet. Getestet
        wurden die Krankenhaus-Zusatzversicherungen aller privaten
        Krankenversicherer, die jedem gesetzlich Krankenversicherten offenstehen
        und nicht an andere Versicherungen gebunden sind. In jeder Kategorie gab
        es - anders als bei den vergangenen Tests zu Zahnzusatzversicherungen -
        nur einen Testsieger mit der Note “sehr gut”. Einige Tarife erhielten
        jeweils die Note “gut”, aber auffällig viele lediglich die Note
        “befriedigend” bzw. “ausreichend” und ein Tarif sogar die Note
        “mangelhaft”.

        <br /><br />

        <b>Testverfahren Stiftung Warentest / Finanztest</b>

        <br />

        Das wichtigste Kriterium bei der Bewertung war das sog.
        “Preis-Leistungs-Verhältnis”. Zudem wurde die Beitragsstabilität über
        die letzten sieben Jahre bewertet. Alle Tarife mussten vorgegebene
        Mindestanforderungen erfüllen, sodass günstige Tarife mit weniger guten
        Leistungen im Test erst überhaupt nicht auftauchen. Des Weiteren ist bei
        den Tarifen im Test keine Selbstbeteiligung vorgesehen und die Beiträge
        sind mit Altersrückstellungen (Kalkulation nach Art der
        Lebensversicherung) kalkuliert. Als Mindestleistung mussten folgende
        Kriterien erfüllt werden:

        <br /><br />

        <ul>
          <li>
            Chefarztbehandlung mit Übernahme der Arzthonorare mindestens bis zum
            Höchstsatz der Gebührenordnung für Ärzte (3,5fach).
          </li>
          <li>
            Wahlleistung Unterkunft: Einbettzimmer im Vergleich der
            Einbettzimmertarife und Zweibettzimmer im Vergleich der
            Zweibettzimmertarife.
          </li>
          <li>
            Freie Wahl unter allen zugelassenen Kliniken für gesetzlich
            Versicherte.
          </li>
          <li>
            Der Versicherer verzichtet auf sein ordentliches Kündigungsrecht in
            den ersten drei Jahren.
          </li>
        </ul>

        <br /><br />

        Nicht untersucht hingegen wurden:

        <ul>
          <li>
            Tarifkombinationen, die neben der Kostenübernahme für
            Klinikleistungen auch solche für Leistungen niedergelassener Ärzte
            oder Zahnärzte enthalten.
          </li>
          <li>
            Zusatztarife, die nur bei Krankenhausaufenthalten infolge von
            Unfällen oder bestimmten Erkrankungen leisten.
          </li>
          <li>
            Einsteigertarife für junge Versicherte, die nach einigen Jahren oder
            mit dem Erreichen eines festgelegten Höchstalters entweder ein Ende
            des Versicherungsschutzes oder ein Umsteigen auf einen anderen Tarif
            vorsehen.
          </li>
          <li>Tarife für spezielle Berufsgruppen, wie z. B. Ärzte.</li>
        </ul>

        <br /><br />

        Für beide Vergleiche wurden die Beiträge für einen gesunden Modellkunden
        (keine Risikozuschläge) mit einem Eintrittsalter von 43 Jahren zugrunde
        gelegt.

        <br /><br />

        Bewertet wurde mit 80% das Preis-Leistungs-Verhältnis - also der
        Quotient aus Beitrag und den erreichten Leistungspunkten für Mindest-
        und Zusatzleistungen. Dabei erhielt jeder Tarif anhand der
        Mindestleistungen schon 90 Prozent der Punkte, durch Zusatzleistungen,
        wie z. B. Übernahme von Arzthonoraren über dem Höchstsatz der GOÄ
        (3,5fach) oder die Höhe des Ersatzkrankenhaustagegeldes, falls ein
        Versicherter die bessere Unterbringung nicht in Anspruch nimmt, konnten
        zusätzlich nochmals 10 Prozent der Punkte erreicht werden. Mit den
        restlichen 20% wurde die Beitragsentwicklung bewertet. Dazu wurden die
        Neukundenbeiträge der vergangene sieben Jahre bewertet. Die
        Beitragsentwicklung ergab sich dabei als Quotient aus dem Beitrag zum
        01.07.2020 und dem Beitrag zum 01.07.2013.

        <br /><br />

        <b
          >TOP 10 Krankenhauszusatzversicherungen Stiftung Warentest /
          Finanztest Heft 7/2020</b
        >

        <ul>
          <li>
            Debeka | WKplus | Note 0,9 - “sehr gut” (nur über den Versicherer
            direkt abschließbar)
          </li>
          <li>Alte Oldenburger | K50+K/S | Note 1,9 - “gut”</li>
          <li>
            Provinzial Hannover | KHUu+KKHPu | Note 1,9 - “gut” (außerhalb von
            Bremen und Niedersachsen nur über den Versicherer direkt
            abschließbar)
          </li>
          <li>
            HUK-Coburg | SZ | Note 2,0 - “gut” (nur über den Versicherer direkt
            abschließbar)
          </li>
          <li>AXA | SW | Note 2,1 - “gut”</li>
          <li>VRK | SW | Note 2,2 - “gut”</li>
          <li>HanseMerkur | PSG | Note 2,3 - “gut”</li>
          <li>DEVK | ST-G1 | Note 2,6 - “befriedigend”</li>
          <li>
            Barmenia | S+ | Note 2,7 - “befriedigend” (Tarif wurde zum
            01.07.2020 geschlossen)
          </li>
          <li>LKH | S400E | Note 2,8 - “befriedigend”</li>
        </ul>

        <br /><br />

        <b
          >Fazit des Testverfahrens im Test von Stiftung Warentest /
          Finanztest</b
        >

        <br /><br />

        Jedes Testverfahren im Bereich der Krankenhauszusatzversicherungen ist
        anders, hat Stärken aber unter Umständen auch Schwächen. Es gibt andere
        Bewertungskriterien oder Testergebnisse werden anders gewichtet und
        bewertet. Nicht für jeden Versicherten macht auch jedes Testverfahren
        gleichermaßen Sinn um die für ihn bestmögliche Absicherung im
        stationären Bereich zu finden.

        <br /><br />

        Das Testverfahren im Test von Stiftung Warentest / Finanztest legt das
        Hauptaugenmerk auf die Beiträge der Tarife bzw. auf das
        Preis-Leistungs-Verhältnis und die Beitragsentwicklung. Gänzlich nicht
        bewertet werden Tarife, die ohne Altersrückstellungen (nach Art der
        Schadenversicherung) kalkuliert werden. Diese Tarife sind zwar
        altersbedingt nicht beitragsstabil, steigen also im Alter an. Dafür sind
        sie aber in jungen Jahren sehr günstig, sodass sich auch Nicht- oder
        Schlechtverdiener eine Krankenhauszusatzversicherung durchaus leisten
        können. Später, wenn die Tarife dann teurer werden, können sich die
        Versicherten die Umstellung in den Tarif mit Altersrückstellungen oder
        die Beitragssprünge dann leisten. Viele Versicherer bieten Tarife mit
        und ohne Altersrückstellungen mit gleichen Leistungen an, sodass ein
        Wechsel vollkommen unproblematisch und ohne Beantwortung der
        Gesundheitsfragen jederzeit möglich ist.

        <br /><br />

        Ein weiteres Problem im Testverfahren von Stiftung Warentest /
        Finanztest liegt in der Bewertung der Beitragsentwicklung. Nicht die
        Bewertung der Beitragsentwicklung an sich ist das Problem, sondern dass
        Tarife, die nicht schon seit mindestens sieben Jahren am Markt
        erhältlich sind, gar nicht abschließend bewertet wurden. Gerade in den
        letzten Jahren sind sehr viele wirklich gute Tarife im stationären
        Bereich auf den Markt gekommen, die aber nur ohne Benotung im unteren
        Teil des Tests aufgeführt wurden.

        <br /><br />

        Die wichtigsten Merkmale einer Krankenhaus-Zusatzversicherung - die
        Leistung an sich - spielt nur im Rahmen der Berechnung des
        Preis-Leistungs-Verhältnis in Form von Mindestanforderungen und ggf.
        durch Extra-Punkte für Zusatzleistungen eine untergeordnete Rolle.

        <br /><br />

        <b>Testverfahren der WaizmannTabelle / KH-WaizmannWert</b>

        <br /><br />

        Das Testverfahren der WaizmannTabelle legt bei der Bewertung von
        Krankenhauszusatzversicherungen ausschließlich Wert auf die
        Leistungsstärke eines Tarifs sowie außerdem auf die Vertragsqualität und
        die Serviceleistungen des Versicherers. Dazu wurde als Benchmark der
        KH-WaizmannWert entwickelt, der immer aktuell die abschließbaren Tarife
        für den stationären Bereich vergleicht. Die Beiträge eines jeden Tarifs
        oder die Art der Beitragskalkulation spielen für die Bewertung an sich
        keinerlei Rolle, fließen später dann aber in die Ergebnisübersicht bzw.
        den Berechnungsalgorithmus mit ein.

        <br /><br />

        Die bewerteten Leistungen bzw. Kriterien sind im Folgenden aufgeführt.

        <br /><br />

        Leistung:

        <br /><br />

        <ul>
          <li>Unterbringung im Ein- oder Zweibettzimmer</li>
          <li>Chefarztbehandlung</li>
          <li>
            Höhe des Ersatz-Krankenhaustagegeldes bei Verzicht auf
            1-/2-Bettzimmer und/oder die Chefarztbehandlung
          </li>
          <li>Erstattung der gesetzlichen Zuzahlung im Krankenhaus</li>
          <li>
            Leistung bei ambulanten OPs bzw. Einschränkungen oder zeitliche
            Vorgaben
          </li>
          <li>
            Leistung bei Kur- oder Reha-Aufenthalten bzw. Einschränkungen oder
            zeitliche Vorgaben
          </li>
          <li>Leistung in Privatkliniken bzw. Einschränkungen</li>
          <li>
            Leistung bis/über einen bestimmten Höchstsatz bzw. Einschränkungen
          </li>
          <li>Mehrkosten für Krankentransport</li>
          <li>Mehrkosten für Krankenrücktransport aus dem Ausland</li>
        </ul>

        <br /><br />

        Vertragsqualität:

        <br /><br />

        <ul>
          <li>
            Geltungsbereich weltweit / außerhalb Europas bzw. Einschränkungen
            oder zeitliche Vorgaben dazu
          </li>
          <li>Wartezeiten / besondere Wartezeiten</li>
          <li>Mitteilungsfristen</li>
        </ul>

        <br /><br />

        Serviceleistungen:

        <br /><br />

        <ul>
          <li>Krankenkarte zur direkten Abrechnung mit dem Versicherer</li>
        </ul>

        <br /><br />

        Auch im KH-WaizmannWert, unserem Testverfahren für die Bewertung von
        Krankenhaus-Zusatzversicherungen wurde mit einem Punktesystem
        gearbeitet. Jeder Tarif konnte dabei maximal 100 Punkte erreichen,
        soweit alle bewerteten Leistungskriterien, die Vorgaben zur
        Vertragsqualität sowie zu den Serviceleistungen des Versicherers erfüllt
        wurden. Die Unterbringung im Einbettzimmer wurde aufgrund der besseren
        Leistung im Vergleich zur Unterbringung im Zweibettzimmer mit 10 Punkten
        mehr bewertet.

        <br /><br />

        Nicht in die Bewertung mit eingeflossen sind vordefinierte Leistungen
        bzw. Kriterien wie die freie Krankenhauswahl, Leistungen bei
        Vor-/Nachbehandlungen oder der Geltungsbereich innerhalb Europas sowie
        der Verzicht auf das ordentliche Kündigungsrecht des Versicherers, da
        sämtliche von uns getesteten Tarife diese Vorgabe erfüllten und somit
        keine Unterschiede kenntlich gemacht werden konnten.

        <br /><br />

        <b
          >TOP 10 Krankenhauszusatzversicherungen WaizmannTabelle /
          KH-WaizmannWert 2024</b
        >

        <br /><br />

        <ul>
          <li>
            Gothaer MediClinic S Premium | KH-WaizmannWert 100% | Note Stiftung
            Warentest ---
          </li>
          <li>
            INTER Versicherungen Premium S1 | KH-WaizmannWert 96 % | Note
            Stiftung Warentest ---
          </li>
          <li>
            Barmenia Mehr Komfort 1-Bett | KH-WaizmannWert 95% | Note Stiftung
            Warentest ---
          </li>
          <li>
            Hallesche Krankenversicherung a.G. GIGA.Clinic RI | KH-WaizmannWert
            92% | Note Stiftung Warentest ---
          </li>
          <li>
            Gothaer MediClinic S Plus | KH-WaizmannWert 90% | Note Stiftung
            Warentest ---
          </li>
          <li>
            R+V Versicherung Klinik premium (K1U) | KH-WaizmannWert 87% | Note
            Stiftung Warentest 3,5
          </li>
          <li>
            Continentale SG1 | KH-WaizmannWert 80% | Note Stiftung Warentest 5,3
          </li>
          <li>
            Hallesche Krankenversicherung a.G. MEGA.Clinic RI | KH-WaizmannWert
            80% | Note Stiftung Warentest ---
          </li>
          <li>
            INTER Versicherungen Exklusiv S2 | KH-WaizmannWert 80% | Note
            Stiftung Warentest ---
          </li>
          <li>
            Nürnberger SG1 | KH-WaizmannWert 80% | Note Stiftung Warentest 4,0
          </li>
        </ul>

        <br /><br />

        <b>Vergleich der Testverfahren und Testsieger</b>

        <br /><br />

        Am Tarif Continentale SG1 sieht man ganz deutlich die Unterschiede der
        beiden Testverfahren. Der Tarif hat sehr gute und umfassende Leistungen,
        deswegen aber natürlich auch einen höheren Beitrag. Zudem hat sich
        rückblickend zum Jahr 2013 der Beitrag kostenbedingt erhöht, was sich
        negativ auf die Beitragsentwicklung auswirkt. Das lässt sich aber auch
        nur rückwirkend betrachten, seriöserweise niemals in die Zukunft. Daher
        taugt die Beitragsstabilität in zurückliegenden Jahren auch nicht als
        Bewertungskriterium, weil es nicht gesagt ist, dass auch zukünftig der
        Beitrag angepasst werden muss. Ausschließlich auf die Leistungen bezogen
        erreichte der Tarif bei uns einen respektablen 7. Platz.

        <br /><br />

        Der Tarif Continentale SG2 erreichte im gleichen Test aber für die
        Zweibettzimmertarife einen sehr guten 2. Platz. Die Leistungen sind
        absolut identisch, der einzige Unterschied ist die Leistung für das
        Zweibett- anstatt für das Einbettzimmer im Tarif SG1. Der Beitrag ist
        daher natürlich wesentlich günstiger und auch die Beitragsentwicklung
        war rückblickend besser - entweder weil die Kostenstruktur für den
        Versicherer nicht negativ ausfiel oder weil den Tarif einfach weniger
        Versicherte abgeschlossen haben. Bei uns werden beide
        Krankenhauszusatzversicherungen der Continentalen nahezu gleich bewertet
        - es gibt also nur ganz marginale Leistungsunterschiede.

        <br /><br />

        Ein anderes Beispiel sind die beiden Tarife SG1 und SG2 der Nürnberger
        Krankenversicherung. Im Test von Stiftung Warentest / Finanztest wurden
        beide nahezu gleich mit einem “ausreichend” (SG1 Note 4,0 und SG2 Note
        3,6) bezüglich Preis-Leistungs-Verhältnis und Beitragsentwicklung
        bewertet. Auch hier sind die Leistungen bis auf die Art der
        Unterbringung annähernd deckungsgleich.

        <br /><br />

        Das verdeutlicht, dass im Testverfahren von Stiftung Warentest /
        Finanztest ein viel zu großes Augenmerk auf die Beiträge und die
        Beitragsentwicklung gelegt wird und weniger auf die wirklich wichtigen
        Kriterien - die Leistungen. Daher bildet ein rein leistungsbezogenes
        Testverfahren, wie von der WaizmannTabelle / KH-WaizmannWert den
        erheblich besseren Informationsgehalt über die Leistungsstärke von
        stationären Zusatzversicherungen ab. Das für sich beste
        Preis-Leistungs-Verhältnis sollte jeder Versicherte für sich und mit
        sich selbst abklären. Letztendlich ist jeder für einen anderen Beitrag
        bereit entsprechende Leistungen abzusichern.

        <br /><br /><br />

        <v-card class="my-4 pa-4">
          <v-card-title>TIPP vom KV-Spezialisten: </v-card-title>
          <v-card-text>
            Nicht immer sind sehr gute oder schlechte Platzierungen in
            Testverfahren aussagekräftig genug um eine Entscheidung für den
            bestmöglichen Tarif zu treffen. Die wichtigste Entscheidungshilfe
            sollte immer die Leistungstärke eines Tarifs sein und nicht in
            erster Linie der Beitrag.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts typeInfo="krank"
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Testsieger Krankenhauszusatzversicherungebn  Stiftung Warentest 2024 im Vergleich",
      "Krankenhauszusatzversicherung Testsieger Stiftung Warentest 2024"
    );
  });
</script>
