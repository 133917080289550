<template>
  <div>
    <span v-if="sharedDataStore.isMobile()">
      <v-row>
        <v-col cols="12">
          <router-link :to="sharedDataStore.getStartLink(tarif.tarif_type)">
            <v-btn
              :color="sharedDataStore.showBtnColor(tarif.tarif_type)"
              style="height: 36px"
              elevated
              tile
              rounded="0"
              type="submit"
              class="button_caps"
              tonal
              value="jetzt Berechnung starten &nbsp; Ergebnis anzeigen"
            >
              <span style="font-size: 18px; color: #ffffff !important"
                >Jetzt Preise berechnen
              </span>
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
    </span>
    <span v-if="!sharedDataStore.isMobile()">
      <v-row>
        <v-col cols="12"
          ><h3>Anonymer AntragAnnahmecheck | anonym & kostenlos</h3>
          Können Sie die {{ tarif.un_name }}
          {{ tarif.tarif_title }} abschließen? Was kostet der Tarif. Hier können
          Sie einen anonymem AntragAnnahmecheck durchführen. Wir fragen für Sie
          anonym die Annahmebedingungen der Gesellschaften ab.</v-col
        >
      </v-row>
      <v-row>
        <v-col cols="12"
          ><router-link :to="sharedDataStore.getStartLink(tarif.tarif_type)">
            <v-btn
              :color="sharedDataStore.showBtnColor(tarif.tarif_type)"
              style="height: 36px"
              elevated
              tile
              rounded="0"
              type="submit"
              class="button_caps"
              tonal
              value="jetzt Berechnung starten &nbsp; Ergebnis anzeigen"
            >
              <span style="font-size: 18px; color: #ffffff !important"
                >Jetzt AnnahmeCheck starten | 100 % anonym
              </span>
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
    </span>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
  });

  console.log(props.tarif);
</script>
