<template>
  <div>
    <v-table>
      <br />
      <thead>
        <tr>
          <th style="max-width: 10px">#</th>
          <th>Tarif</th>

          <th>Ø-Erstattung</th>
          <th>Zimmer</th>
          <th>Preis | {{ preisAlter }} Jahre</th>
        </tr>
      </thead>
      <tbody>
        <tr style="vertical-align: top" v-for="tarif in tarife" :key="tarif.id">
          <td>{{ tarif.rank }}.</td>
          <td>
            <strong
              ><a
                @click="
                  berechnungStore.setShowPopAppTarif(tarif, 'showTarifOptions')
                "
                >{{ tarif.versicherung }}</a
              >
            </strong>

            <br />
            {{ tarif.tarif_title }}
          </td>
          <td>
            <strong>{{ tarif.waizmannwert }} </strong>
          </td>
          <td>
            <b v-if="tarif.kh_bett == 0">1-Bettzimmer</b>
            <b v-if="tarif.kh_bett == 1">2-Bettzimmer</b>
          </td>
          <td>
            {{ tarif.preis_value }}
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  const berechnungStore = useBerechnungStore();
  const props = defineProps({
    elementLine: {
      type: String,
    },
    preisAlter: {
      type: String,
    },
    tarife: {
      type: Array,
    },
  });
  console.log(props.tarif);
</script>
<style scoped>
  td {
    padding-top: 8px !important;
  }
</style>
