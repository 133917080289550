<template>
  <div>
    <v-row>
      <v-col cols="12" md="9"
        ><h1 class="mb-2">
          Ihr <span class="text-green">kostenloses</span> Infopaket enthält:
        </h1>
        <div>
          Fordern Sie jetzt Ihr kostenloses Antrags & Infopaket an. Dieser
          Service ist für Sie <b class="text-green">100 % kostenlos</b> und
          <b class="text-green">unverbindlich</b>.
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="9">
        <h2>Umfangreiche Tarifinformationen & Servicehinweise</h2>
        <ul class="mt-2">
          <li>
            Eine ausführliche Beschreibung & Analyse der wichtigsten
            Leistungsdetails für {{ tarif.un_name }} {{ tarif.tarif_title }}
          </li>
          <li>
            Wichtige Hinweise zur "sicheren" Beantragung Ihrer
            Zahnzusatzversicherung
          </li>
        </ul>

        <h2>Einen professionell vorbereiteten Versicherungsantrag</h2>
        <ul class="mt-2">
          <li>
            Sie erhalten einen von unserem Antragsteam vorbereiteten
            Versicherungsantrag. Den Sie einfach unterschreiben und an uns
            zurücksenden können.
          </li>
          <li>
            <b>Wichtig:</b> wir prüfen jeden Antrag ob die Versicherung ihn
            aufgrund Ihrer Angaben auch annehmen wird. Dadurch vermeiden Sie
            lästige Antragsablehnungen.
          </li>
        </ul>
        <span v-if="berechnung.ber_segment < 2">
          <br />
          <h2>Zugangsdaten für Ihren Clearment Vertragsschutz</h2>
          <ul class="mt-2">
            <li>
              Sie können damit sofort in Ihren Clearment Account einloggen.
            </li>
            <li>
              Sie können den vollständigen
              <a @click="showCheck = !showCheck">Clearment VertragsCheck</a>
              abrufen
            </li>
          </ul>
        </span>
      </v-col>
    </v-row>
    <v-dialog width="888" v-model="showCheck">
      <v-card class="pa-4">
        <ClearmentCheck v-if="showCheck" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
  import { ref, defineProps } from "vue";
  import ClearmentCheck from "@/components/berechnung/info/ClearmentCheck";
  //   import { useBerechnungStore } from "@/stores/berechnung";
  //   import { useSharedDataStore } from "@/stores/sharedData";
  //   const sharedDataStore = useSharedDataStore();
  //   const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  const showCheck = ref(false);
  console.log(props.tarif);
</script>
