<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2>Heilpraktiker-Zusatzversicherung ohne Gesundheitsfragen</h2>
        Gibt es eine Heilpraktiker-Zusatzversicherung ohne Gesundheitsfragen?
        <b>Nein</b>.
        <br />
        Das bedeutet, dass bei jedem Heilpraktiker-Tarif mind. eine (meist
        ausführliche) Gesundheitsfrage im Antrag gestellt wird. Ganz ohne
        Gesundheitsprüfung geht es bei Heilpraktiker Tarifen also leider nicht.
        <ul>
          <li>
            <a href="/heilpraktikerversicherung_vergleich"
              >alle Heilpraktikerversicherungen im Vergleich</a
            >
          </li>
          <li>
            <a href="/kinder_heilpraktikerversicherung_vergleich"
              >Kinder-Heilpraktikerversicherungen im Vergleich</a
            >
          </li>
        </ul>
        <br /><br />

        <h3>
          Warum sind bei Heilpraktiker-Zusatzversicherungen immer
          Gesundheitsfragen zu beantworten?
        </h3>
        Jede Versicherungsgesellschaft, welche eine Heilpraktikerversicherung
        anbietet, führt bei Antragstellung eine Gesundheitsprüfung durch.
        Hintergrund ist, dass Versicherungen eben nicht bereit sind, jedes
        Risiko zu versichern, jede Person anzunehmen. Und genau aus diesem Grund
        werden meist umfangreiche Gesundheitsfragen im Antrag einer
        Heilpraktiker-Zusatzversicherung gestellt.
        <br /><br />
        <v-btn outlined color="#088a85" href="/vergleich/starten/heilpraktiker"
          >jetzt Heilpraktiker Zusatzversicherung vergleichen</v-btn
        >
        <br /><br />

        <h3>
          Was versteht man unter einer Gesundheitsprüfung bzw. wann erfolgt
          diese?
        </h3>
        Zunächst muss der Antrag auf eine Heilpraktiker-Zusatzversicherung
        ausgefüllt werden. Bei der Antragstellung vertraut die Versicherung
        zunächst auf die Korrektheit der beantworteten Gesundheitsfragen im
        Antrag. Dies ist quasi die <strong>erste Gesundheitsprüfung</strong>, ob
        der Antragsteller angenommen werden kann oder nicht. Und eben genau
        diese explizit im Antrag gestellten Gesundheitsfragen muss der
        Antragsteller wahrheitsgemäß beantworten. Wenn der Antrag zustande
        kommt, wird der Antragsteller dann zur versicherten Person. <br /><br />
        Im Falle eines ersten / späteren Leistungsfalles wird die
        Versicherungsgesellschaft dann eine sog.
        <strong>nachgelagerte Gesundheitsprüfung</strong> durchführen. Dabei
        wird meist der Hausarzt (per Fragebogen) im Rahmen der Arzt Rückfrage
        kontaktiert. Der Hausarzt muss dabei der Versicherung die gestellten
        Fragen beantworten. Vorbehandler / Behandler können über einen Zeitraum
        von bis zu 10 Jahren rückwirkend befragt werden. Die Versicherung möchte
        nämlich den Leistungsfall prüfen, ob dieser Anspruch berechtigt ist, und
        die Erstattung erfolgen kann oder der Leistungsfall schon bereits VOR
        Versicherungsbeginn eingetreten ist und der eingereichte
        Versicherungsfall nicht erstattet wird. <br /><br />
        <strong>Vorsicht:</strong> Die Versicherungsgesellschaft prüft im Rahmen
        der nachgelagerten Gesundheitsprüfung ganz automatisch, ob der Vertrag
        auch zustande kommen durfte oder nicht. Hätte der Antragsteller nämlich
        die Gesundheitsfragen wissentlich oder unwissentlich falsch beantwortet,
        hätte die Heilpraktikerversicherung (also der Versicherungsschutz) im
        schlimmsten Fall nicht zustande kommen dürfen. Es kann im Zuge einer
        nachgelagerten Gesundheitsprüfung somit nachträglich ein Ausschluss für
        gewisse Behandlungen vereinbart oder ein Risikozuschlag erhoben
        werden.<br />
        <strong
          >Rücktrittsrecht des Versicherers bei vorvertraglicher
          Anzeigepflichtverletzung</strong
        ><br />
        Die Versicherungsgesellschaft hat u.a. das Recht vom Vertrag
        zurückzutreten und z.B. kündigt den Vertrag rückwirkend. Das ist dann
        der Fall, wenn der Vertrag unter Kenntnis der wahren Gegebenheiten nicht
        zustande gekommen wäre. Bereits gezahlte Beiträge werden genauso wenig
        zurückerstattet wie auch der Versicherungsfall nicht erstattet wird.
        <br /><br />
        <v-btn outlined color="#088a85" href="/vergleich/starten/heilpraktiker"
          >jetzt Heilpraktiker Zusatzversicherung vergleichen</v-btn
        >
        <br /><br />

        <v-card>
          <v-card-title
            >Unser Rat: Gesundheitsfragen bei der
            Heilpraktikerversicherung</v-card-title
          >
          <v-card-text
            >Falls Sie nicht alle Gesundheitsfragen im Antragsformular des
            Versicherungsantrages zweifelsfrei beantworten können, raten wir zu
            einer ausschließlich telefonischen Rückfrage bei Ihrem Hausarzt /
            weiterer Ärzte. Würden Sie nämlich noch VOR Abschluss der
            Heilpraktikerversicherung eine Diagnose gestellt bekommen, wäre
            ersten Ihre Annahme u.U. gefährdet oder ein Leistungsausschluss bzw.
            ein Risikozuschlag fällig. Demnach bitte VOR Beantragung der
            Heilpraktiker-Zusatzversicherung keinen Termin mehr beim Arzt
            persönlich wahrnehmen, soweit möglich.</v-card-text
          >
        </v-card>
        <br /><br />

        <h3>
          Tarif-Empfehlungen: Heilpraktikerversicherungen mit vereinfachten
          Gesundheitsfragen
        </h3>
        Folgende Heilpraktiker-Zusatzversicherungen können mit nur einer oder
        mit vereinfachten Gesundheitsfragen beantragt werden.
        <br /><br />

        <h3>
          Heilpraktiker-Zusatzversicherungen mit nur einer Gesundheitsfrage
        </h3>
        <ul>
          <li>
            <a href="/heilpraktikerversicherung/id/264"
              >Heilpraktiker-Zusatzversicherung Universa uni-med A Exklusiv</a
            >
          </li>
        </ul>
        <ul>
          <li>
            <a href="/heilpraktikerversicherung/id/263"
              >Heilpraktiker-Zusatzversicherung Universa uni-med A Premium</a
            >
          </li>
        </ul>
        <ul>
          <li>
            <a href="/heilpraktikerversicherung/id/260"
              >Heilpraktiker-Zusatzversicherung Signal Iduna AmbulantPLUSpur</a
            >
          </li>
        </ul>
        <ul>
          <li>
            <a href="/heilpraktikerversicherung/id/261"
              >Heilpraktiker-Zusatzversicherung Signal Iduna AmbulantPLUS</a
            >
          </li>
        </ul>
        <br /><br />
        <h3>Heilpraktikerversicherungen mit vereinfachten Gesundheitsfragen</h3>
        <ul>
          <li>
            <a href="/heilpraktikerversicherung/id/387">AXA MED Komfort U</a>
          </li>
        </ul>
        <ul>
          <li>
            <a href="/heilpraktikerversicherung/id/248"
              >Heilpraktiker-Zusatzversicherung Versicherungskammer Bayern
              NaturPRIVAT</a
            >
          </li>
        </ul>
        <ul>
          <li>
            <a href="/heilpraktikerversicherung/id/262"
              >Heilpraktiker-Zusatzversicherung Versicherungskammer Bayern
              NaturPRIVAT + VorsorgePRIVAT</a
            >
          </li>
        </ul>
        <br /><br />

        <h3>Wie umfangreich werden Gesundheitsfragen gestellt?</h3>
        Manche Heilpraktiker-Tarife fragen bei der Gesundheitsprüfung im Antrag
        größere Zeiträume ab.
        <br /><br />
        <strong>Der Antragsteller muss bis zu:</strong>
        <ul>
          <li>
            drei Jahre rückwirkend Fragen zu diversen Krankheiten beantworten.
          </li>
        </ul>
        <ul>
          <li>
            fünf Jahre rückwirkend Fragen zu stationären Krankenhausaufenthalten
            beantworten.
          </li>
        </ul>
        <ul>
          <li>
            zehn Jahre rückwirkend Fragen zu psychotherapeutischen Behandlungen
            beantworten.
          </li>
        </ul>
        Zudem können auch Fragen zu Medikamenten, ambulanten / stationären
        Operationen, möglichen Behinderungen / Einschränkungen zu beantworten
        sein.
        <br /><br />

        <h3>
          K.O. Kriterien für den Abschluss einer Heilpraktikerversicherung
        </h3>
        Ganz generell wird der Antrag bei schweren (Vor-) Erkrankungen sowie
        chronischen Erkrankungen generell abgelehnt werden. Hier gäbe es auch
        keine Möglichkeit einer Annahme mit Beitragszuschlag bzw. Ausschluss für
        diese oder jene Behandlung.
        <br /><br />
        <v-btn outlined color="#088a85" href="/vergleich/starten/heilpraktiker"
          >Jetzt Berechnung starten</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script setup></script>
