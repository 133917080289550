<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h2>
          WaizmannTabelle |
          <span style="color: #00bcd4"
            >Krankenhauszusatzversicherung ohne Altersrückstellungen</span
          >
        </h2>

        Die Art der Beitragskalkulation einer Krankenhausversicherung hat neben
        dem Eintrittsalter der zu versichernden Person den größten Einfluss auf
        den Beitrag eines solchen Tarifs. Deshalb sind entsprechend kalkulierte
        Krankenhaus-Tarife mit oder ohne Altersrückstellung für Personen
        jüngeren, mittleren oder gehobenen Alters unterschiedlich gut geeignet.

        <br /><br />

        Im Folgenden eine Übersicht der von uns empfohlenen Krankenhaus-Tarife
        ohne Altersrückstellungen:
        <LayoutTable class="my-4" tableTyp="krankOhneAlter" />

        Bei Krankenhaus-Tarifen, die nach Art der Schadenversicherung kalkuliert
        sind, wurden keine Altersrückstellungen mit einberechnet. Das bedeutet,
        dass sich der Beitrag eines solchen Vertrags in regelmäßigen, vorher
        festgelegten Abständen oder bei Erreichen einer nächsthöheren
        Altersgruppe ändert.

        <br /><br />

        Da bei dieser Art der Beitragskalkulation ausschließlich der reine
        Risikobeitrag abhängig vom tatsächlichen Alter zu zahlen ist, sind die
        Beiträge gerade in jungen Jahren sehr viel günstiger, als die Beiträge
        von Krankenhausversicherungen mit Altersrückstellungen.

        <br /><br />

        Deshalb sind die stationären Tarife ohne Altersrückstellungen gerade für
        Einsteiger in jungen Jahren, die erst zu arbeiten begonnen haben oder
        Schlechtverdiener geeignet. Später ist ein Wechsel in eine
        Krankenhauszusatzversicherung mit Altersrückstellungen jederzeit mit
        nochmaliger Beantwortung der Gesundheitsfragen möglich.

        <br /><br />

        <v-card class="my-4 pa-4">
          <v-card-title>TIPP vom KV-Spezialisten: </v-card-title>
          <v-card-text>
            Viele der größeren Versicherer im stationären Bereich bieten die
            Krankenhaus-Versicherungen sowohl mit als auch ohne
            Altersrückstellungen an. So kann man in jungen Jahren mit einem
            Tarif ohne Rückstellungen beginnen und später in den Tarif mit
            Rückstellungen wechseln und sich so dann den Beitrag dauerhaft
            sichern. Vorteilhaft ist zudem, dass bei einem Wechsel von einem
            Tarif ohne zu einem Tarif mit Altersrückstellungen keine angesparten
            Beiträge verloren gehen.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts typeInfo="krank"
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  import LayoutTable from "@/views/site/helper/LayoutTable";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Krankenhauszusatzversicherungen ohne Altersrückstellungen",
      "Krankenhauszusatzversicherungen nach Art der Schadenversicherung | altersbedingte Beitragsanpassungen | günstige Beiträge für junge Versicherte"
    );
  });
</script>
