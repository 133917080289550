<template>
  <div>
    <h1>
      {{ tarif.un_name }}
      <span class="text-blue">{{ tarif.tarif_title }}</span>
    </h1>
    <h3>
      Ausführliche Leistungsbeschreibung der {{ tarif.un_name }}
      {{ tarif.tarif_title }} Zahnzusatzversicherung.
    </h3>
    <v-divider :thickness="2" class="my-4"></v-divider>

    <v-row>
      <v-col cols="2" v-if="!sharedDataStore.isMobile()"
        ><div class="blueground px-4 py-8" style="height: 120px">
          <h1 class="text-white">{{ tarif.tarif_waizmannwert }} %</h1>
          <span class="text-white"> Ø-Erstattung</span>
        </div>
        <div class="pt-2">
          Tarif-Nr.: <b>{{ tarif.tarif_id }}</b>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <h3>Wichtige Eckdaten</h3>
        <v-row class="my-1 pb-1" style="border-bottom: 2px solid #f2f2f2">
          <v-col cols="5" class="py-0">Ø-Erstattung</v-col>
          <v-col cols="7" class="py-0"
            ><b>{{ tarif.tarif_waizmannwert }} %</b> |
            <a @click="sharedDataStore.scrollToElement('waizmannwert')"
              >Rechenweg</a
            >
          </v-col>
        </v-row>

        <v-row class="my-1 pb-1" style="border-bottom: 2px solid #f2f2f2">
          <v-col cols="5" class="py-0">Bereiche</v-col>
          <v-col cols="7" class="py-0">
            Zahnersatz:
            <strong>{{ tarif.tarif_leistung_ze }}%</strong>
            <br />Zahnerhalt:
            <strong>{{ tarif.tarif_leistung_zb }}%</strong>
            <br />Prophylaxe:
            <strong>{{ tarif.tarif_leistung_pzr }}%</strong>
            <br />
          </v-col>
        </v-row>
        <v-row class="my-1 pb-1" style="border-bottom: 2px solid #f2f2f2">
          <v-col cols="5" class="py-0">Max. Leistung <br />1.-4. Jahr</v-col>
          <v-col cols="7" class="py-0"
            >1.-2. Jahr:
            <strong>{{ tarif.tarif_int_begrenzung_2 }}</strong>
            <br />3.-4 .Jahr:
            <strong>{{ tarif.tarif_int_begrenzung_4 }} </strong>
          </v-col>
        </v-row>
        <v-row
          class="my-1 pb-1"
          style="border-bottom: 2px solid #f2f2f2"
          v-if="tarif.tarif_kwert_gesamt"
        >
          <v-col cols="5" class="py-0"
            >KFO Ø-Erstattung<br />
            Begrenzungen</v-col
          >
          <v-col cols="7" class="py-0"
            ><b>{{ tarif.tarif_kwert_gesamt }} %</b> | für Kinder geeignet
            <br />KIG 1-2:
            <strong>{{ tarif.tarif_leistung_kfo_beg_ogkv }}</strong>
            <br />KIG 3-5:
            <strong>{{ tarif.tarif_leistung_kfo_beg_mgkv }}</strong>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <ClearmentLsk
              v-if="tarif.tarif_type == 0 && tarif.tarif_clearment_lsk > 0"
              :tarif="tarif"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <img width="60" :src="require('@/assets/clearment/small.png')"
          /></v-col>
          <v-col cols="10"
            ><h3>Clearment Vertragsschutz</h3>
            Bei Abschluss der {{ tarif.un_name }}
            {{ tarif.tarif_title }} erhalten Sie den Clearment Vertragsschutz
            kostenlos.
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider :thickness="2" class="my-4"></v-divider>
    <v-dialog
      v-model="berechnungStore.tarif.showPopYes"
      :width="berechnungStore.tarif.dialogWidth"
    >
      <v-card class="pa-4">
        <v-row
          ><v-col cols="11"></v-col
          ><v-col cols="1"
            ><v-btn
              icon
              @click="berechnungStore.closePop(berechnungStore.tarif)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn></v-col
          ></v-row
        >
        <ClearmentLskBig
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'clearmentlsk'"
          :tarif="berechnungStore.tarif"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
  import ClearmentLskBig from "@/components/tarif/info/ClearmentLsk";
  import ClearmentLsk from "@/components/tarif/mini/ClearmentLsk";
  import { useBerechnungStore } from "@/stores/berechnung";
  import { defineProps } from "vue";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const berechnungStore = useBerechnungStore();
  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    preise: {
      type: Array,
    },
  });

  console.log(props.tarif.tarif_id);
</script>
