<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h2 class="subtitle-3">
          WaizmannTabelle |
          <span style="color: #00bcd4"
            >FAQ - Häufig gestellte Fragen zur
            Krankenhauszusatzversicherung</span
          >
        </h2>

        <br /><br />

        Die WaizmannTabelle erreichen immer wieder die gleichen Fragen zum Thema
        “Krankenhauszusatzversicherung” bzw. “stationäre Zusatzversicherung”.
        Aus diesem Grund haben wir die am Häufigsten gestellten Fragen in der
        folgenden Übersicht zusammengefasst und beantwortet. Damit sind schon
        viele Fragen sofort beantwortet:

        <br /><br />

        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Welche Leistungen sind in einer Krankenhauszusatzversicherung
                  versichert?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Die wichtigsten und hauptsächlichen Leistungen einer
              Krankenzusatzversicherung für den stationären Bereich sind die
              gesonderte Unterbringung im Ein- oder Zweibettzimmer, die
              Behandlung durch den Chefarzt bzw. durch einen Spezialisten, sowie
              die freie Krankenhauswahl.

              <br /><br />

              Die erweiterten Leistungen von Krankenhaus-Tarifen sind unter
              anderem die Übernahme von Kosten in Kur oder Reha, die Bezahlung
              eines Aufenthalts in einer Privatklinik, die Erstattung für
              Krankentransporte im In- oder Ausland, sowie Komfort-Leistungen in
              den Krankenhäusern und viele mehr.

              <br /><br />

              Für was welche Versicherung genau leistet, kann in der
              detaillierten Leistungsbeschreibung zu jedem Tarif unter
              <a href="/krankenhauszusatzversicherung_vergleich"
                >Vergleich Krankenhauszusatzversicherungen</a
              >
              nachgelesen werden.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Leistet eine Krankenhauszusatzversicherung für
                  Psychotherapie?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Ja. Die meisten Krankenhaustarife leisten auch für Psychotherapie,
              soweit diese stationär durchgeführt wird. Da aber nicht alle
              Tarife gleichermaßen dafür leisten, sollte das vorher unbedingt
              durch den jeweiligen Versicherer genehmigt werden.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Leistet eine Krankenhauszusatzversicherung auch bei
                  Aufenthalt in einer Privatklinik?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Ja. Viele Krankenhaus-Versicherungen leisten ganz oder zumindest
              teilweise auch für einen Aufenthalt in einer Privatklinik. Aber
              auch hier ist es so, dass die Leistungen von Tarif zu Tarif sehr
              unterschiedlich sind, weshalb es auf jeden Fall sinnvoll ist, eine
              etwaige Leistung seitens des Versicherers vorab prüfen und
              genehmigen zu lassen.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Gibt es Krankenhauszusatzversicherungen ohne
                  Gesundheitsfragen?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Ja. Die normalen stationären Zusatztarife haben allerdings immer
              Gesundheitsfragen, manche mit einfachen, andere mit schweren bzw.
              ausführlichen Antragsfragen. Ganz ohne Gesundheitsfragen geht es
              bei Tarifen mit umfassenden Leistungen für den Fall eines
              Krankenhausaufenthalts nicht.

              <br /><br />

              Die DKV bietet mit den Tarifen UZ1 und UZ2 allerdings zwei
              Krankenhaustarife an, die ganz ohne Gesundheitsfragen abschließbar
              sind. Die Leistungen beschränken sich hier zwar auf die
              Unterbringung im Ein- oder Zweibettzimmer, so hat zumindest aber
              jeder die Möglichkeit sich für eine gesonderten Unterbringung im
              Fall eines Krankenhausaufenthalts zu versichern.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Gibt es Krankenhauszusatzversicherungen, die trotz
                  Vorerkrankung abschließbar sind?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Ja. Ausschlaggebend ist hier immer die entsprechende Diagnose bzw.
              Vorerkrankung. Es gibt Versicherer, die im Antrag nur eine
              einfache Gesundheitsfrage stellen. Oftmals ist dann schon ohne
              Probleme in diesen Tarifen ein Abschluss möglich.

              <br /><br />

              Bei schweren Vorerkrankungen ist ein einfacher Abschluss meist
              nicht mehr möglich. Da gibt es dann die Möglichkeit einer sog.
              “Risikovoranfrage”. So kann schon im Voraus abgeklärt werden, ob
              eine Annahme zu normalen Bedingungen oder zumindest gegen
              Leistungsausschluss oder Risikozuschlag erfolgen kann.

              <br /><br />

              Ist der Abschluss aufgrund einer Diagnose oder Vorerkrankung nicht
              mehr möglich, gibt es immer noch die Möglichkeit einen Tarif ohne
              Gesundheitsfragen zu wählen. Die DKV bietet hier zwei Tarife (UZ1
              und UZ2) an, die zwar lediglich für die Unterbringung im Ein- oder
              Zweibettzimmer leisten, aber eben für jeden abschließbar sind.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Ab welchem Zeitpunkt besteht Versicherungsschutz bzw. können
                  Ansprüche geltend gemacht werden?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Versicherungsschutz besteht frühestens ab Erhalt der
              Versicherungspolice. Erst mit Zusendung der
              Versicherungsunterlagen nimmt der Versicherer den Antrag an und es
              kommt ein rechtsgültiger Vertrag zustande.

              <br /><br />

              Zusätzlich zum Erhalt des Versicherungsscheins (formeller
              Versicherungsbeginn) muss, damit Versicherungsschutz besteht, auch
              das in der Police genannte Beginndatum (technischer
              Versicherungsbeginn) abgelaufen sein und der Erstbeitrag
              (materieller Versicherungsbeginn) gezahlt worden sein. Wurde
              allerdings ein SEPA-Lastschriftmandat erteilt, gilt der materielle
              Versicherungsbeginn nicht, da der Versicherer dann für das
              pünktliche Einziehen der Beiträge selbst verantwortlich ist,
              soweit das Konto des Beitragszahlers gedeckt ist. Aus der
              Bringschuld des Versicherten wird in diesem Fall dann die
              Holschuld des Versicherers.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Gibt es Krankenhauszusatzversicherungen ohne Wartezeit bzw.
                  mit sofortiger Leistung oder kann ein Wartezeiterlass
                  stattfinden?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Ja.Die modernen stationären Zusatzversicherungen haben für normale
              Krankenhausaufenthalte normalerweise keine Wartezeit. Lediglich
              für die Sonderbereiche “Entbindung” und “Psychotherapie” gilt
              zumeist eine besondere Wartezeit von 3 bzw. sogar 8 Monaten.

              <br /><br />

              Die Wartezeit ist ein Zeitraum, während dem zwar grundsätzlich
              bereits Versicherungsschutz besteht, aber noch keine Ansprüche
              gegenüber dem Versicherer geltend gemacht werden können. Wird die
              Behandlung allerdings erst nach Ablauf der Wartezeit durchgeführt,
              ist auch eine Leistung seitens des Versicherers möglich.

              <br /><br />

              Ein Wartezeiterlass ist bei den meisten Tarifen mit Wartezeit
              möglich. Allerdings ist dazu ein ausführlicher Arztbericht
              notwendig, der dem Versicherer mehr an Informationen über den
              Gesundheitszustand eines Patienten liefert, als dieser über die
              Gesundheitsfragen im Antrag erhalten hätte. In diesem Fall geht es
              dann nicht nur um den Erlass der Wartezeit, sondern auch um die
              Annahme des Antrags im Allgemeinen. Der Versicherer kann im
              Einzelfall einen Antrag aufgrund der ausführlichen Informationen
              ablehnen, obwohl eine Annahme anhand der Gesundheitsfragen ganz
              normal möglich gewesen wäre.

              <br /><br />

              Zudem macht ein Wartezeiterlass ohnehin meist keinen Sinn, weil
              Versicherungsfälle, die vor Abschluss einer solchen Versicherung
              bereits eingetreten waren, grundsätzlich nicht mehr versichert
              werden können - und zwar unabhängig davon, ob es eine Wartezeit
              gibt oder nicht.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Können bereits angeratene oder laufende Behandlungen noch
                  versichert werden?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Nein. Versicherungsfälle, die bereits vor Abschluss einer solchen
              Versicherung eingetreten sind, also Behandlungen, die bereits
              angeraten wurden oder gar schon am Laufen sind, können
              grundsätzlich nicht mehr versichert werden. Das gilt für sämtliche
              Krankenhauszusatz-Tarife unabhängig davon, ob der gewünschte Tarif
              eine Wartezeit hat oder nicht.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Gibt es Krankenhauszusatzversicherungen ohne anfängliche
                  Begrenzungen?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Ja. In den meisten Bereichen der privaten
              Krankenzusatzversicherung gibt es sog. “anfängliche
              Summenbegrenzungen”. Das bedeutet, dass die Leistung in den ersten
              Jahren nach dem Versicherungsbeginn auf einen bestimmten Betrag
              pro Versicherungs- oder Kalenderjahr begrenzt ist.

              <br /><br />

              Das trifft allerdings nicht auf die Krankenhauszusatzversicherung
              zu. Hier gibt es für die Leistungen von Beginn an keine
              anfänglichen Begrenzungen. Lediglich in manchen
              Leistungsbereichen, wie z. B. beim Maximalbetrag eines
              Rücktransports aus dem Ausland gibt es - dann aber auch dauerhaft
              - Begrenzungen.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Gibt es Krankenhauszusatzversicherungen, welche die
                  Möglichkeit einer Familienversicherung anbieten?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Nein. In der privaten Krankenzusatzversicherung gibt es keine
              Familienversicherung, wie diese aus der gesetzlichen
              Krankenversicherung bekannt ist, so auch nicht bei der
              Krankenhauszusatzversicherung. Jede zu versichernde Person
              generiert anhand des Eintrittsalters ein eigenes Risiko, welches
              durch einen eigenen Vertrag mit separaten Beitrag abgesichert
              werden muss.

              <br /><br />

              Aus diesem Grund gibt es auch keine Rabatte, wenn mehrere
              Versicherte beim gleichen Versicherer einen Vertrag abgeschlossen
              haben.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Gibt es Krankenhauszusatzversicherungen, welche die
                  Möglichkeit einer “Doppelversicherung” bieten? Kann ich also
                  mehrere Krankenhaus-Tarife gleichzeitig haben?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Ja. Eine sog. “Doppelversicherung” ist zumeist problemlos möglich.
              Allerdings macht eine Doppelversicherung im Bereich der
              Krankenhauszusatzversicherung keinen Sinn.

              <br /><br />

              Sämtliche von uns empfohlenen Tarife leisten für die wichtigsten
              Bereiche gleichermaßen gut und die Leistungen können nur von einem
              Versicherer erbracht werden. Im Rahmen des sog.
              “Bereicherungsverbotes” ist es nicht erlaubt, dass Rechnungen oder
              Leistungen mehrfach mit verschiedenen Versicherern abgerechnet
              werden und somit die versicherte Person die Kosten mehr als ein
              Mal erstattet bekommt.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Lohnt sich der Abschluss einer
                  Krankenhauszusatzversicherung?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Ja. Der Abschluss einer privaten Krankenhauszusatzversicherung ist
              ausschließlich für Versicherte in der gesetzlichen
              Krankenversicherung möglich. Durch Wahlleistungen für die
              Unterbringung im Ein- oder Zweibettzimmer, die Behandlung durch
              Spezialisten über die Chefarztbehandlung oder die freie
              Krankenhauswahl bis hin zur Privatklinik kann jeder gesetzlich
              Krankenversicherte den Komfort im Fall eines
              Krankenhausaufenthalts absichern.

              <br /><br />

              Auch Gutverdiener, die sich gegen den Abschluss einer privaten
              Krankenvollversicherung entschieden haben, sind als weiterhin
              gesetzlich Krankenversicherte durch den Abschluss einer
              Krankenhauszusatzversicherung bestens für den stationären Bereich
              versichert.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Wie hoch sind die Kosten für eine
                  Krankenhauszusatzversicherung?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Was die Absicherung für den stationären Bereich genau kostet,
              kommt in erster Linie auf das Eintrittsalter, die Art der
              Beitragskalkulation (mit oder ohne Altersrückstellungen), sowie
              letztendlich auf den jeweiligen Versicherer und Tarif an.

              <br /><br />

              Während sich bei Kindern die Beiträge zumeist noch unter 10 Euro
              monatlich bewegen, sind für einen jungen Erwachsenen im Alter von
              21 Jahren für einen Tarif mit umfassenden Leistungen schon
              zwischen 20 und 40 Euro monatlich fällig.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Wie wurden die Krankenhauszusatzversicherungen getestet?
                  Welche Unterschiede gibt es im Test?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Stiftung Warentest / Finanztest hat
              Krankenhauszusatzversicherungen letztmalig im Heft 07/2020
              getestet. Dabei wurden insgesamt 37 Einbettzimmer- und 24
              Zweibettzimmertarife überprüft. Alles zu diesem Test sowie den
              Unterschieden in den Testverfahren von Finanztest und der
              WaizmannTabelle finden Sie unter:
              <a href="/krankenhauszusatzversicherung_test"
                >Krankenhauszusatzversicherungen im Test</a
              >.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Ist eine Krankenhauszusatzversicherung für Kinder bereits
                  sinnvoll?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Auch für Kinder ist es schon sinnvoll eine
              Krankenhauszusatzversicherung abzuschließen. So sichert man sich
              frühzeitig die Leistungen im stationären Bereich zu einem sehr
              günstigen Beitrag - meist unter 10 Euro monatlich. Beachtet muss
              allerdings werden, dass der Beitrag auch bei Tarifen mit
              Altersrückstellungen bei Kindern nicht gleich bleibt. Spätestens
              bei Erreichen des Erwachsenenalters kommt es zu einer einmaligen
              Beitragsanpassung.

              <br /><br />

              Wichtig ist, dass der Tarif für Kinder unbedingt die
              “Rooming-In”-Leistungen beinhalten sollte.

              <br /><br />

              Alles zu Krankenhaus-Tarifen für Kinder finden Sie unter:
              <a href="/kinder_krankenhauszusatzversicherung_vergleich"
                >Krankenhauszusatzversicherungen für Kinder</a
              >.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Warum sollte eine Krankenhauszusatzversicherung so früh wie
                  möglich abgeschlossen werden?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Ein Grund die Krankenhauszusatzversicherung so früh wie möglich
              abzuschließen liegt darin, dass die meisten Versicherer
              Gesundheitsfragen im Antrag stellen. Je jünger eine zu
              versichernde Person ist, desto eher ist es der Fall, dass diese
              Antragsfragen unproblematisch beantwortet werden können und somit
              der Abschluss ohne Einschränkungen möglich ist. Später können
              Vorerkrankungen oder Diagnosen eintreten, die einen Abschluss
              einschränken oder ganz unmöglich machen.

              <br /><br />

              Ein anderer Grund die Zusatzversicherung für den stationären
              Bereich so früh wie möglich abzuschließen, ist der günstigere
              Beitrag im Vergleich zu einem späteren Zeitpunkt. Zumindest bei
              Krankenhaus-Tarifen, die mit Altersrückstellungen kalkuliert sind,
              kann man sich durch einen frühzeitigen Abschluss (im jungen
              Erwachsenenalter) einen dauerhaft günstigen Beitrags sichern. Bei
              Kindern ist das, unabhängig von der Art der Beitragskalkulation
              noch nicht möglich, da eine altersbedingte Beitragsanpassung zum
              ersten Erwachsenenalter auf jeden Fall kommen wird.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Wo sind die Unterschiede zwischen
                  Krankenhauszusatzversicherungen mit Altersrückstellungen und
                  Krankenhaus-Tarifen ohne Altersrückstellungen?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Die Art der Beitragskalkulation bei einem Tarif sollte allenfalls
              zweitrangig sein. In erster Linie muss immer die Leistung im
              Mittelpunkt für die Auswahl eines Tarifs im stationären Bereich
              stehen.

              <br /><br />

              Ist ein Tarif mit Altersrückstellungen kalkuliert, wird in jungen
              Jahren schon für das Alter angespart. Somit ist der Beitrag im
              jungen Alter höher, als eigentlich notwendig, später dafür dann
              günstiger. Der Beitrag bleibt ab dem Abschlusszeitpunkt,
              vorausgesetzt es findet keine allgemeine Beitragsanpassung
              zwischendurch statt, gleich und ändert sich altersbedingt nicht
              mehr. Nachteil bei der Beitragskalkulation nach Art der
              Lebensversicherung (mit Altersrückstellung) ist, dass bei einem
              etwaigen Wechsel der Versicherers diese angesparten
              Altersrückstellungen verloren gehen. Man hat dann möglicherweise
              jahrelang zu viel Beitrag bezahlt.

              <br /><br />

              Bei Tarifen ohne Altersrückstellungen kommt es in regelmäßigen
              Abständen oder bei Erreichen der nächsthöheren Altersgruppe zu
              einem altersbedingten Beitragssprung. Das bedeutet, dass sich der
              Beitrag unabhängig vom Abschlusszeitpunkt immer wieder
              altersbedingt ändert. Dabei kann es sich um Erhöhungen als auch um
              Senkungen handeln. Wechsel sind hier nicht aber nachteilig, weil
              es sich bei den zu zahlenden Beiträgen bei der Beitragskalkulation
              nach Art der Schadenversicherung (ohne Altersrückstellungen) um
              reine Risikobeiträge handelt. Es gibt somit keine zuviel gezahlten
              Beiträge, die verloren gehen können.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Welche Vorteile bietet der Abschluss einer
                  Krankenhauszusatzversicherung über die
                  WaizmannTabelle?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Zum einen hat die WaizmannTabelle ein anderes Prüfungsverfahren
              für die Bewertung von Krankenhauszusatzversicherungen (siehe auch
              <a href="/krankenhauszusatzversicherung_test"
                >Krankenhauszusatzversicherungen im Test</a
              >) als Stiftung Warentest / Finanztest, welches unserer Ansicht
              nach auf jeden Fall transparenter und vor allem
              leistungsorientierter ist.

              <br /><br />

              Zum anderen bietet die WaizmannTabelle den Kunden eine Vielzahl an
              Services an, die dauerhaft kostenlos bleiben und das bei gleichem
              Beitrag im Vergleich zum Direktabschluss über den Versicherer.
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>

        Sollte Ihre Frage hier nicht beantwortet worden sein, schicken Sie uns
        Ihre Frage bitte einfach per Mail an info@waizmanntabelle.de. Unser
        Service-Team wird diese dann schnell und vor allem schriftlich
        beantworten.
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts typeInfo="krank"
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "FAQ - Häufig gestellte Fragen zur Krankenhauszusatzversicherung",
      "Fragen und Antworten zu Krankenhauszusatzversicherungen | Expertenwissen nutzen | FAQ zur Krankenhausversicherung"
    );
  });
</script>
