<template>
  <div class="mb-8 pb-4" style="max-width: 999px">
    <v-row
      class="mb-0 py-2"
      style="
        border-left: 4px solid #f2f2f2;
        border-bottom: 4px solid #f2f2f2;
        background-color: #f5f5f5;
      "
    >
      <v-col cols="12" md="8">
        <h1>
          Ihr Ergebnis:
          <span :class="berechnung.fontClass"
            >{{ berechnung.show_ber_geschlecht }},
            {{ berechnung.show_ber_geburtstag }}</span
          >
        </h1>
        <h3>
          {{ berechnung.showBerechnungName }}en | sortiert nach höchster
          Leistung | Preis-Leistung
        </h3></v-col
      >
      <v-col cols="12" md="4">
        <BerechnungTeilen :berechnung="berechnung" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="8">
        <v-row>
          <v-col cols="12" md="6">
            <h3>Ihre Berechnungsvorgaben</h3>

            <ul>
              <li
                v-for="(item, index) in berechnung.berlog"
                v-bind:key="index"
                v-html="nl2br(item.value)"
              ></li>
              <li>
                <router-link :to="`/vergleich/${berechnung.showLinkStarten}`"
                  >Berechnungsvorgaben anpassen</router-link
                >
              </li>
            </ul></v-col
          >
          <v-col cols="12" md="6">
            <h3>Ergebnis Antrag-AnnahmeCheck</h3>
            <v-row>
              <v-col cols="12" md="12"
                ><b class="text-green"
                  >{{ vergleichCount.tarifeCount }} Tarife</b
                >
                sind beantragbar |
                <a
                  @click="berechnungStore.setShowPop(false, 'annahmekriterien')"
                  >diese Kriterien werden berücksichtigt</a
                ></v-col
              >
            </v-row>
            <v-row v-if="vergleichCount.notarifeCount == 0" class="mt-2">
              <v-col cols="12" md="12" class="py-0"
                >Alle getesteten Tarife können aufgrund Ihrer Angaben beantragt
                werden
              </v-col>
            </v-row>
            <v-divider :thickness="2" class="my-4"></v-divider>
            <v-row
              v-if="vergleichCount.notarifeCount > 0"
              class="pa-0 mt-1"
              style="max-width: 660px"
            >
              <v-col cols="12" md="12" class="py-0"
                >Bei

                <span v-if="vergleichCount.notarifeCount > 1">
                  <b class="text-red">
                    {{ vergleichCount.notarifeCount }} Tarifen
                  </b>
                </span>
                <span v-if="vergleichCount.notarifeCount == 1">
                  <b class="text-red"> diesem Tarif </b>
                </span>
                würde Ihr Antrag abgelehnt werden
                <span v-if="vergleichCount.notarifeCount > 0"
                  ><br />
                  <a @click="berechnungStore.setShowPop(false, 'annahmecheck')"
                    >Lesen Sie hier wieso manche Tarife nicht abschließbar
                    sind...</a
                  >
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <ClearmentHeader v-if="berechnung.ber_segment <= 1" />
      </v-col>
    </v-row>
    <v-row
      class="mt-6 pa-1"
      style="border-top: 4px solid #f2f2f2; border-bottom: 4px solid #f2f2f2"
    >
      <v-col cols="12" md="12">
        <v-btn
          style="color: white !important"
          :color="berechnung.fontColorVue"
          class="mr-2 button_caps"
          rounded="0"
          @click="berechnungStore.setShowPop(false, 'schnellvergleich')"
          >Schnellübersicht</v-btn
        >
        <v-btn
          v-if="berechnung.ber_segment <= 1 && berechnung.ber_algo_view != 2"
          :color="berechnung.fontColorVue"
          style="color: white !important"
          class="mr-2 button_caps"
          rounded="0"
          @click="berechnungStore.setShowPop(false, 'vergleichhightech')"
          >Hightech-Leistungen
        </v-btn>
        <v-btn
          v-if="berechnung.ber_segment <= 1 && berechnung.ber_algo_view == 2"
          :color="berechnung.fontColorVue"
          style="color: white !important"
          class="mr-2 button_caps"
          rounded="0"
          @click="berechnungStore.setShowPop(false, 'vergleichkinder')"
          >Kinder-Leistungen</v-btn
        >
        <v-btn
          class="button_caps mr-2"
          style="color: white !important"
          :color="berechnung.fontColorVue"
          rounded="0"
          @click="berechnungStore.setShowPop(false, 'beitragsentwicklung')"
          >Beitragsentwicklung</v-btn
        >
        <v-btn
          v-for="sv in berechnung.spezialVergleiche"
          :key="sv.text"
          class="button_caps mr-2"
          style="color: white !important"
          :color="berechnung.fontColorVue"
          rounded="0"
          @click="berechnungStore.setShowPop(false, sv.pop)"
        >
          {{ sv.text }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row
      class="pa-1"
      style="border-bottom: 4px solid #f2f2f2"
      v-if="berechnung.berechnungHinweise"
    >
      <v-col cols="12">
        <h3 class="text-red mr-4">
          Wichtige Hinweise - bitte lesen Sie diese:
        </h3>
        <span
          style="font-size: 17px"
          class="mr-6"
          v-for="h in berechnung.berechnungHinweise"
          :key="h.id"
        >
          <a @click="berechnungStore.setShowPop(false, h.pop)">{{ h.title }}</a>
        </span>
        <span v-if="berechnung.ber_zb_3 == 1">
          <h3 class="mt-2">
            Wichtig bei Parodontose:
            <span class="text-green">positive Erstattungspraxis</span>
          </h3>
          Falls Ihre Parodontose-Behandlung abgeschlossen wurde, wählen Sie
          unbedingt einen Tarif mit einer "positiven"
          Parodontose-Erstattungspraxis.
          <a @click="berechnungStore.setShowPop(false, 'paroempfehlung')"
            >Mehr dazu.....</a
          >
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  import BerechnungTeilen from "@/components/tarif/vergleich/BerechnungTeilen";
  import ClearmentHeader from "@/components/tarif/vergleich/ClearmentHeader";
  import { useBerechnungStore } from "@/stores/berechnung";
  import { useNl2Br } from "@/composable/useNl2Br";
  const { nl2br } = useNl2Br();
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  const berechnungStore = useBerechnungStore();

  const props = defineProps({
    berechnung: {
      type: Object,
      default: () => ({}),
    },
    vergleichCount: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.berechnung);
</script>
