<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h2>Alle von uns getesteten Zusatzversicherungen</h2>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Tarif suchen"
          single-line
          hide-details
        ></v-text-field>
        <br />
        <v-data-table
          v-if="tarife && tarife.length > 0 && headers"
          :headers="headers"
          :items="tarife"
          :items-per-page="300"
          :search="search"
        >
          <template v-slot:item="{ item }">
            <tr style="vertical-align: top">
              <td>
                <b>
                  <span v-if="item.tarif_type == 0">
                    <router-link
                      :class="
                        sharedDataStore.showFontColorTarif(item.tarif_type)
                      "
                      :to="
                        sharedDataStore.getBeschreibungLink(
                          item.tarif_type,
                          item.tarif_id
                        )
                      "
                      >Zahnzusatz</router-link
                    ></span
                  >
                  <span v-if="item.tarif_type == 1">
                    <router-link
                      :class="
                        sharedDataStore.showFontColorTarif(item.tarif_type)
                      "
                      :to="
                        sharedDataStore.getBeschreibungLink(
                          item.tarif_type,
                          item.tarif_id
                        )
                      "
                      >Brille</router-link
                    ></span
                  >
                  <span v-if="item.tarif_type == 2">
                    <router-link
                      :class="
                        sharedDataStore.showFontColorTarif(item.tarif_type)
                      "
                      :to="
                        sharedDataStore.getBeschreibungLink(
                          item.tarif_type,
                          item.tarif_id
                        )
                      "
                      >Heilpraktiker</router-link
                    ></span
                  >
                  <span v-if="item.tarif_type == 3">
                    <router-link
                      :class="
                        sharedDataStore.showFontColorTarif(item.tarif_type)
                      "
                      :to="
                        sharedDataStore.getBeschreibungLink(
                          item.tarif_type,
                          item.tarif_id
                        )
                      "
                      >Krankenhaus</router-link
                    ></span
                  >
                </b>
              </td>

              <td>
                <strong>{{ item.un_name }}</strong>
                {{ item.tarif_title }}
              </td>
              <td>Nr. {{ item.tarif_id }}</td>
              <td>
                <router-link
                  :to="
                    sharedDataStore.getBeschreibungLink(
                      item.tarif_type,
                      item.tarif_id
                    )
                  "
                >
                  Beschreibung
                </router-link>
              </td>
              <td>
                <span v-if="item.tarif_int_closed == 1" class="text-red">
                  geschlossen
                </span>
              </td>
            </tr>
          </template>
        </v-data-table></v-col
      >
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts
      /></v-col>
    </v-row>
    +
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  import BaseCalls from "@/services/BaseCalls";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(false);
  const tarife = ref(false);
  const search = ref("");
  const headers = ref([
    {
      title: "Art",
      align: "left",
      sortable: false,
      value: "tarif_title",
    },
    {
      title: "Versicherung",
      align: "left",
      sortable: true,
      value: "un_name",
    },
    {
      title: "Nr",
      align: "left",
      sortable: true,
      value: "tarif_id",
    },
    {
      title: "Beschreibung",
      align: "left",
      sortable: false,
      value: "tarif_id",
    },
    {
      title: "abschließbar",
      align: "left",
      sortable: false,
      value: "tarif_int_closed",
    },
  ]);
  async function getAlleZusatzTarife() {
    let select = {};
    select.action = "getAlleZusatzTarife";
    try {
      const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.tarife) {
        tarife.value = resp.data.tarife;
        appLoaded.value = true;
      }
    } catch (err) {
      console.log("error tarife");
    }
  }
  onMounted(() => {
    getAlleZusatzTarife();
    proxy.$updateDocumentHead(
      "Alle 'waizmann-getestete' Zusatzversicherungen",
      "Hier finden Sie alle auf der WaizmannTabelle getesteten Zusatzversicherungen"
    );
  });
</script>
