<template>
  <div>
    <v-table>
      <thead>
        <tr style="vertical-align: top">
          <th colspan="2" style="width: 80px" class="purpleground pa-2">
            <h2 class="text-white">Top {{ tarife.length }} | 60Plus</h2>
          </th>
          <th class="pa-2 greyground">
            <TooltipHelper toolkey="tarif_kwert_gesamt">
              <template v-slot:toolTipText>
                <a>Zahnersatz</a>
              </template>
            </TooltipHelper>
          </th>
          <th class="pa-2 greyground">
            <TooltipHelper toolkey="tarif_kwert_gesamt">
              <template v-slot:toolTipText>
                <a>Zahnerhalt</a>
              </template>
            </TooltipHelper>
          </th>
          <th class="pa-2 greyground">
            <TooltipHelper toolkey="tarif_kwert_gesamt">
              <template v-slot:toolTipText>
                <a>Prophylaxe</a>
              </template>
            </TooltipHelper>
          </th>

          <th class="pa-2 greyground">
            <TooltipHelper toolkey="tarif_lsk">
              <template v-slot:toolTipText>
                <a>Clearment<br /><span>zertifiziert</span></a>
              </template>
            </TooltipHelper>
          </th>

          <th class="pa-2 greyground">
            <TooltipHelper toolkey="tarif_vs">
              <template v-slot:toolTipText>
                <a>Clearment<br /><span>Schutz</span></a>
              </template>
            </TooltipHelper>
          </th>

          <th class="pa-2 greyground">
            <TooltipHelper toolkey="tarif_aaa">
              <template v-slot:toolTipText>
                <a
                  ><strong>Antrag<br /><span>An.-Check</span></strong></a
                >
              </template>
            </TooltipHelper>
          </th>

          <th class="pa-2 greyground">
            Preis<br />
            60 Jahre
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          style="vertical-align: top"
          v-for="tarif in tarife"
          :key="tarif.tarif_id"
        >
          <td>#{{ tarif.rank }}</td>
          <td>
            <strong
              ><a
                @click="
                  berechnungStore.setShowPopAppTarif(tarif, 'showTarifOptions')
                "
                >{{ tarif.versicherung }}</a
              >
            </strong>

            <br />
            {{ tarif.tarif_title }}
          </td>
          <td>
            <strong>{{ tarif.tarif_leistung_ze }} %</strong>
          </td>
          <td>
            <strong>{{ tarif.tarif_leistung_zb }} %</strong>
          </td>
          <td>
            <strong>{{ tarif.tarif_leistung_pzr }} %</strong>
          </td>

          <td>
            <b :class="tarif.tarif_show_lsk_style">{{
              tarif.tarif_show_lsk_word
            }}</b>
          </td>
          <td>
            <span v-if="tarif.tarif_clearment_vs == 1"
              ><b class="text-green">ja</b></span
            >
            <span v-if="tarif.tarif_clearment_vs == 0">nein</span>
          </td>
          <td>
            <span v-if="tarif.tarif_int_antrag == 1"
              ><b class="text-green">ja</b></span
            >
            <span v-if="tarif.tarif_int_antrag == 0">nein</span>
          </td>
          <td>{{ tarif.preis_value }}€</td>
        </tr>
        <tr v-if="elementLine == 'startPlus'">
          <td></td>
          <td colspan="7">
            <h3>
              <router-link to="/60plus"
                >vollständige WaizmannTabelle | 60Plus</router-link
              >
            </h3>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script setup>
  import TooltipHelper from "@/components/base/TooltipHelper.vue";
  import { defineProps } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  const berechnungStore = useBerechnungStore();
  const props = defineProps({
    elementLine: {
      type: String,
    },
    tarife: {
      type: Array,
    },
  });
  console.log(props.tarif);
</script>
<style scoped>
  td {
    padding-top: 8px !important;
  }
</style>
