<template>
  <div>
    <!-- Bereichsanzeige -->
    <v-row>
      <v-col cols="12" md="3">Bereiche:</v-col>
      <v-col cols="12" md="9">
        Zahnersatz:
        <strong>{{ tarif.tarif_leistung_ze }} %</strong>
        | Zahnerhalt:
        <strong>{{ tarif.tarif_leistung_zb }} %</strong>
        <br />Prophylaxe:
        <strong>{{ tarif.show_pzr_grenze }}</strong>
      </v-col>
    </v-row>

    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Hightech-Leistungen und dynamischer Inhalt -->
    <v-row>
      <v-col cols="12" md="3"></v-col>
      <v-col cols="12" md="9">
        <a @click="showHigh = !showHigh" style="font-weight: bold"
          >Leistet für {{ tarif.tarif_count_high }}</a
        >
        von 12 Hightech-Leistungen
        <ul v-if="showHigh" class="body-2">
          <li v-for="item in tarif.highspezial" :key="item.id">
            <span v-if="item.ja">{{ item.value }}</span>
            <span
              v-if="!item.ja"
              class="text-red"
              style="text-decoration: line-through"
              >{{ item.value }}</span
            >
          </li>
          <li>
            <a @click="berechnungStore.setShowPop(tarif, 'vergleichhightech')"
              >Vergleich zu anderen Tarifen</a
            >
          </li>
        </ul>
      </v-col>
    </v-row>

    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Begrenzungen -->
    <v-row>
      <v-col cols="12" md="3">
        Begrenzungen:
        <strong
          v-if="tarif.LueckeVersicherung == 4"
          @click="showBegrenzung = !showBegrenzung"
          class="red--text"
        >
          <br />Hinweis lesen
        </strong>
      </v-col>
      <v-col cols="12" md="4">
        <strong
          ><a @click="showBegrenzung = !showBegrenzung">{{
            tarif.tarif_int_begrenzung_2
          }}</a></strong
        >
        | 1.-2. Jahr
      </v-col>
      <v-col cols="12" md="4">
        <strong
          ><a @click="showBegrenzung = !showBegrenzung">{{
            tarif.tarif_int_begrenzung_4
          }}</a></strong
        >
        | 3.-4. Jahr
      </v-col>
    </v-row>

    <v-divider :thickness="1" class="my-2"></v-divider>

    <!-- Wartezeiten -->
    <v-row class="mb-1">
      <v-col cols="12" md="3">Wartezeiten:</v-col>
      <v-col cols="12" md="4">
        <strong>{{ tarif.tarif_beschreibung_wz_ze }}</strong>
        <br />
        <span class="caption">Zahnersatz</span>
      </v-col>
      <v-col cols="12" md="4">
        <strong>{{ tarif.tarif_beschreibung_wz_zb }}</strong>
        <br />
        <span class="caption">Zahnerhalt</span>
      </v-col>
    </v-row>
    <v-dialog v-model="showBegrenzung" width="888">
      <v-card>
        <v-card-text>
          <v-row
            ><v-col cols="11"></v-col
            ><v-col cols="1"
              ><v-btn icon @click="showBegrenzung = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-col
            ></v-row
          >
          <span v-if="tarif.show_begrenzung_vorteil">
            <h2>
              <span class="text-green">Experten-Tipp:</span> Jetzt schneller
              {{ tarif.tarif_int_begrenzung_1 }}€ höhere Erstattung sichern!
            </h2>

            Wenn Sie den Vertrag noch mit Versicherungsbeginn in diesem
            Kalenderjahr abschließen, hat das den Vorteil, daß Sie am 1. Januar
            des Folgejahres bereits im 2. Versicherungsjahr sind.
            <br />
            Dadurch sparen Sie ein Jahr der anfänglichen Summenbegrenzung. Sie
            können dadurch bereits nächstes Jahr
            {{ tarif.tarif_int_begrenzung_2 }} (statt
            {{ tarif.tarif_int_begrenzung_1 }} €) in Anspruch nehmen. Sie
            gewinnen dadurch
            <strong class="text-green"
              >{{ tarif.tarif_int_begrenzung_1 }} € zusätzliche
              Erstattung</strong
            >. <br /><br />
          </span>

          <h2>Anfängliche Leistungsbegrenzungen im Detail</h2>
          <div
            class="body-1"
            v-html="nl2br(tarif.tarif_beschreibung_beg)"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
  import { ref, defineProps } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  //   import { useSharedDataStore } from "@/stores/sharedData";
  //   const sharedDataStore = useSharedDataStore();
  const berechnungStore = useBerechnungStore();
  import { useNl2Br } from "@/composable/useNl2Br";
  const { nl2br } = useNl2Br();
  const showBegrenzung = ref(false);
  const showHigh = ref(false);
  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
