<template>
  <div>
    <v-table>
      <thead>
        <tr style="vertical-align: top">
          <th colspan="2" style="width: 80px" class="yellowground pa-2">
            <h2 class="text-white">Top {{ tarife.length }} | Kinder/KFO</h2>
          </th>
          <th class="pa-2 greyground">
            <TooltipHelper toolkey="tarif_kwert_gesamt">
              <template v-slot:toolTipText>
                <a>KFO-Wert</a>
                <br />
                <span>gesamt</span>
              </template>
            </TooltipHelper>
          </th>
          <th class="pa-2 greyground">
            <TooltipHelper toolkey="tarif_kwert_gesamt">
              <template v-slot:toolTipText>
                <a>KFO-Wert</a>
                <br />
                <span>
                  KIG 1-2
                  <br />Begrenzung
                </span>
              </template>
            </TooltipHelper>
          </th>
          <th class="pa-2 greyground">
            <TooltipHelper toolkey="tarif_kwert_gesamt">
              <template v-slot:toolTipText>
                <a>KFO-Wert</a>
                <br />
                <span>
                  KIG 3-5
                  <br />Begrenzung
                </span>
              </template>
            </TooltipHelper>
          </th>

          <th class="pa-2 greyground">
            <TooltipHelper toolkey="tarif_kinderleistungen">
              <template v-slot:toolTipText>
                <a style="cursor: pointer">
                  <strong>Kinder-Leistungen</strong>
                </a>
              </template>
            </TooltipHelper>
          </th>

          <th class="pa-2 greyground">
            <TooltipHelper toolkey="tarif_lsk">
              <template v-slot:toolTipText>
                <a>Clearment<br /><span>zertifiziert</span></a>
              </template>
            </TooltipHelper>
          </th>

          <th class="pa-2 greyground">
            <TooltipHelper toolkey="tarif_vs">
              <template v-slot:toolTipText>
                <a>Clearment<br /><span>Schutz</span></a>
              </template>
            </TooltipHelper>
          </th>

          <th class="pa-2 greyground">
            <TooltipHelper toolkey="tarif_aaa">
              <template v-slot:toolTipText>
                <a
                  ><strong>Antrag<br /><span>An.-Check</span></strong></a
                >
              </template>
            </TooltipHelper>
          </th>

          <th class="pa-2 greyground">
            Preis<br />
            Kind 10 Jahre
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          style="vertical-align: top; cursor: pointer"
          v-for="tarif in tarife"
          :key="tarif.tarif_id"
          @click="berechnungStore.setShowPopAppTarif(tarif, 'showTarifOptions')"
        >
          <td>#{{ tarif.rank }}</td>
          <td>
            <strong
              ><router-link
                :to="
                  sharedDataStore.getBeschreibungLink(
                    tarif.tarif_type,
                    tarif.tarif_id
                  )
                "
                >{{ tarif.versicherung }}</router-link
              >
            </strong>

            <br />
            {{ tarif.tarif_title }}
          </td>
          <td>
            <strong>{{ tarif.kfowert }}</strong>
          </td>
          <td>
            <strong>{{ tarif.kfo1 }}</strong>
            <br />
            <span>{{ tarif.kfo1beg }}</span>
          </td>
          <td>
            <strong>{{ tarif.kfo3 }}</strong>
            <br />
            <span>{{ tarif.kfo3beg }}</span>
          </td>
          <td>
            <b>{{ tarif.tarif_count_kind }}</b>
          </td>
          <td>
            <b :class="tarif.tarif_show_lsk_style">{{
              tarif.tarif_show_lsk_word
            }}</b>
          </td>
          <td>
            <span v-if="tarif.tarif_clearment_vs == 1"
              ><b class="text-green">ja</b></span
            >
            <span v-if="tarif.tarif_clearment_vs == 0">nein</span>
          </td>
          <td>
            <span v-if="tarif.tarif_int_antrag == 1"
              ><b class="text-green">ja</b></span
            >
            <span v-if="tarif.tarif_int_antrag == 0">nein</span>
          </td>
          <td>{{ tarif.preis_value }}€</td>
        </tr>
        <tr v-if="elementLine == 'startKind'">
          <td></td>
          <td colspan="9">
            <h3>
              <router-link to="/kinder"
                >vollständige WaizmannTabelle | Kinder</router-link
              >
            </h3>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script setup>
  import TooltipHelper from "@/components/base/TooltipHelper.vue";
  import { defineProps } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const berechnungStore = useBerechnungStore();

  const props = defineProps({
    elementLine: {
      type: String,
    },
    tarife: {
      type: Array,
    },
  });
  console.log(props.tarif);
</script>
<style scoped>
  td {
    padding-top: 8px !important;
  }
</style>
