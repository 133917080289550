<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="9"
        ><h2>{{ faq.site_title }}</h2>
        <span v-html="nl2br(faq.site_text)"></span>
        <v-divider :thickness="2" class="my-4"></v-divider>

        <router-link to="/vergleich/starten/kind">
          <a>Kinder-Zahnzusatzversicherung Vergleich starten</a>
        </router-link>
        |
        <router-link to="/kinder">
          <a>alle von uns getesteten Kinder-Zahnzusatzversicherung</a>
        </router-link>
        |
        <router-link to="/kinder/faq">
          <a>Übersicht aller Eltern-Fragen</a>
        </router-link>

        <v-divider :thickness="2" class="my-4"></v-divider>
        <span v-if="yous && yous.length > 0">
          <v-divider :thickness="2" class="my-4"></v-divider>
          <h3>Interessante Videos</h3>

          <span class="pa-4" v-for="you in yous" :key="you.you_id">
            <br />
            <b>{{ you.you_url_text }}</b>
            <br />
            <span v-html="you.you_url"></span>
            <span v-if="you.you_desc != ''">
              {{ you.you_desc }}
              <br />
            </span>
          </span>
        </span>

        <span v-if="links && links.length > 0">
          <v-divider :thickness="2" class="my-4"></v-divider>
          <h3>Noch mehr zu diesem Thema</h3>

          <span v-for="link in links" :key="link.link_id">
            <span v-if="link.link_extern == 1">
              <a :href="link.link_url" target="_blank">{{
                link.link_url_text
              }}</a
              ><br />
              <br />
            </span>
            <span v-if="link.link_extern != 1">
              <a :href="link.link_url">{{ link.link_url_text }}</a
              ><br />
            </span>
            <span v-if="link.link_desc != ''">
              {{ link.link_desc }}
              <br />
            </span>
          </span>
        </span>
      </v-col>
      <v-col cols="12" md="3" v-if="sharedDataStore.isDesktop()"
        ><MenueRechts typeInfo="kinder"
      /></v-col>
    </v-row>
    <div id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted, watch } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  import BaseCalls from "@/services/BaseCalls";
  import { useRoute } from "vue-router";
  import { useNl2Br } from "@/composable/useNl2Br";
  const { proxy } = getCurrentInstance();
  const { nl2br } = useNl2Br();
  const sharedDataStore = useSharedDataStore();
  const route = useRoute();
  const faq = ref(false);
  const yous = ref(false);
  const links = ref(false);
  const appLoaded = ref(false);

  async function getLexikon() {
    let select = {};
    select.action = "getFaq";
    select.thema = route.params.thema;

    try {
      const resp = await BaseCalls.postMixed(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.faq) {
        faq.value = resp.data.faq;
        yous.value = resp.data.yous;
        links.value = resp.data.links;
        proxy.$updateDocumentHead(
          faq.value.site_title,
          faq.value.site_vorschau
        );
        appLoaded.value = true;
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }
  watch(
    () => route.params,
    () => {
      // Die Funktion checkRouteChange wird aufgerufen, wenn sich die Route-Parameter ändern
      getLexikon();
    },
    { deep: true, immediate: true }
  ); // `deep` und `immediate` stellen sicher, dass bei initialer und tieferer Änderung reagiert wird

  onMounted(() => {});
</script>
