<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h2>
          FAQs - Häufig gestellte Fragen zur Heilpraktiker-Zusatzversicherung
        </h2>
        Nachfolgend beantworten wir für Sie die häufigsten Fragen zu
        Heilpraktiker-Zusatzversicherungen.<br />

        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong>Was kostet eine Heilpraktikerversicherung?</strong>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Für eine dreißigjährige Person kostet eine
              Heilpraktikerversicherung zwischen 20,- € bis 50,- € Beitrag pro
              Monat. Bei Kinder-Tarifen liegen die Monatsbeiträge zwischen ca.
              8,- € bis 12,- €. Je nach Versicherungsumfang, Erstattungshöhen
              und Anbieter. Jedoch ist diese Frage pauschal nicht zu
              beantworten.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Welche Leistungen bietet eine
                  Heilpraktiker-Zusatzversicherung?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Klassische Naturheilkunde-Leistungen umfassen u.a. Bereiche der
              Akupunktur, Chiropraktik, Homöopathie, Osteopathie sowie der
              traditionell chinesischen Medizin (TCM). Darüber hinaus leisten
              viele Tarife auch für Vorsorgeuntersuchungen, Impfungen, Sehhilfen
              sowie LASIK. Dabei hängt der gesamte Leistungsumfang vom
              jeweiligen Anbieter und Tarif ab. Kosten für Naturheilverfahren
              werden i.d.R. nach Hufelandverzeichnis oder nach Gebührenordnung
              für Heilpraktiker (GebüH) abgerechnet.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Für wen lohnt sich eine
                  Heilpraktiker-Zusatzversicherung?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Eine Heilpraktiker-Zusatzversicherung lohnt sich für Versicherte
              der gesetzlichen Krankenversicherung (GKV). Patienten können dann
              auch, neben schulmedizinischen Behandlungen, sämtliche
              Naturheilverfahren in Anspruch nehmen. Jedoch sollten
              naturheilkundliche Behandlungen nicht nur kurzfristig angestrebt
              werden, da Zusatzversicherungen für ein oder zwei Jahre
              abgeschlossen werden müssen.<br />

              <strong>Tipp:</strong> Besonders Babies, Kleinkinder, Kinder und
              Jugendliche profitieren doppelt. Erstens ist die
              Gesundheitsprüfung bei Antragstellung in jungen Jahren meist
              einfach zu durchlaufen. Zweitens sind die monatlichen Beiträge mit
              ca. 8,- € bis 12,- € besonders günstig.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Gibt es eine Heilpraktiker Zusatzversicherung ohne
                  Gesundheitsfragen?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Nein. Bei der Antragstellung müssen immer Gesundheitsfragen
              beantwortet werden. Dabei kann die Annahme auch mit einem sog.
              Risikozuschlag oder einem Ausschluss für manche
              Versicherungsleistungen erfolgen. Dennoch müssen immer
              Gesundheitsfragen im Rahmen der Gesundheits- bzw. Risikoprüfung
              beantwortet werden.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Ist der Abschluss einer Heilpraktikerversicherung mit
                  Vorerkrankung möglich?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Ja. Hier kommt es jedoch immer auf die zugrunde liegende Diagnose
              / das Krankheitsbild an. Da die Gesundheitsfragen jedoch meist
              weitreichend sind, führen viele Vorerkrankungen leider zur
              Ablehnung des Versicherungsantrages. Jedoch kann es auch trotz
              Vorerkrankung zur Antragsannahme kommen, jedoch meist in
              Verbindung mit einem sog. Risikozuschlag oder einem Ausschluss für
              manche Versicherungsleistungen.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Gibt es eine Heilpraktiker-Zusatzversicherung ohne
                  Begrenzung?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Nein. Leistungsbegrenzungen sind immer gegeben. Einige Tarife
              sind in den ersten ein bis zwei, manchmal auch drei Jahren
              gedrosselt. Manche Tarife bieten jedoch bereits zu Beginn die
              stets gleichen Erstattungshöhen, wie in den Folgejahren.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Gibt es eine Heilpraktikerversicherung ohne
                  Wartezeit?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Ja. Einige Versicherungen verzichten auf die Wartezeit. Die
              Wartezeit beschreibt eine Frist, bei der zwar bereits
              Versicherungsschutz besteht, der Versicherungsnehmer aber noch
              keine Leistungen in Anspruch nehmen kann. Mit anderen Worten,
              können Versicherte in dieser Zeit noch keine Rechnungen erstattet
              bekommen. Die übliche Wartezeit für Naturheilkunde Leistungen
              beträgt 3 Monate. Die sog. besondere Wartezeit für Entbindung und
              Psychotherapie beträgt 8 Monate.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong>Kann es einen Wartezeiterlass geben?</strong>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Ja. Ein Wartezeiterlass lohnt jedoch nicht, da ohnehin nur jene
              Behandlungen versichert werden können, deren Diagnose NACH
              Abschluss der Versicherung gestellt wurde. Zudem obliegt es der
              Versicherung, zu entscheiden, ob der eingereichte
              Untersuchungsbericht des Hausarztes anerkannt wird oder nicht.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong>Zusatzversicherung Heilpraktiker im Test</strong>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <strong>Test von Stiftung Warentest</strong>
              <p>
                Stiftung Warentest / Finanztest hat Heilpraktikerversicherungen
                zuletzt im April 2017 getestet. Dabei wurden 59
                Zusatzversicherungen analysiert, wobei mehr als die Hälfte der
                Tarife ein “Gut” bekamen. Es wurden Ergän­zungs­tarife geprüft,
                die mindestens Heilpraktiker­leistungen oder Naturheil­verfahren
                durch Ärzte bezu­schussen. Bewertet wurden diverse Tarife für
                Naturheil­verfahren die zudem auch Leistungen für Brillen,
                Zahn­ersatz, Impfungen und Vorsorgeunter­suchungen bieten. In
                die Bewertung sind Leistungen für Heilpraktiker, Brillen und
                Zahn­ersatz eingeflossen.
              </p>

              <strong
                >Test von WaizmannTabelle nach dem WaizmannWert
                Heilpraktiker</strong
              >

              <p>
                WaizmannTabelle analysiert laufend neue
                Heilpraktikerversicherungen. Der Bewertungsansatz von
                WaizmannTabelle Heilpraktiker liegt in der erstmals
                mathematischen Analyse von Heilpraktikerversicherungen. Dabei
                wird die maximale Leistungsstärke ausschließlich für
                Naturheilkunde-Leistungen konkret in Zahlen (in Prozent)
                ausgedrückt, um einen transparenten Tarifvergleich zu
                ermöglichen.
                <br />

                Für die Tarifanalyse wurde eine Leistungs-Benchmark “Warenkorb
                für Naturheilkunde Leistungen”, WaizmannWert Heilpraktiker
                genannt, entwickelt. Dieser Warenkorb zeigt in einer Zeit von
                vier Jahren die maximal durchschnittliche Leistungsstärke für
                Naturheilkunde Leistungen auf. Demzufolge werden auf der
                WaizmannTabelle Heilpraktiker auch nur die stärksten Tarife
                gelistet. <br />

                Es wurden darüber hinaus weitere Kriterien der gelisteten Tarife
                definiert, wie z.B. das Behandlungen für Naturheilverfahren
                sowohl beim Heilpraktiker als auch beim Arzt für
                Naturheilverfahren erstattet werden. Zudem umfassen
                Tarifleistungen die Positionen des Gebührenverzeichnisses für
                Heilpraktiker (GebüH). Getestete Tarife leisten für sämtliche
                Naturheilverfahren des Hufelandverzeichnis bzw. einer ähnlich
                umfangreichen Aufstellung des Versicherers an
                Naturheilverfahren.
              </p>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong>Heilpraktikerversicherung für Kinder</strong>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Ob Baby, Kleinkind bis hin zu Jugendlichen Patienten,
              Heilpraktiker-Zusatzversicherungen lohnen sich gleich zweimal.
              Erstens ist der monatliche Beitrag mit 8,- € bis 12,- €. ziemlich
              günstig. Zweitens ist der Abschluss einer Versicherung meist
              besonders einfach, da Gesundheitsfragen i.d.R. kein Hindernis
              darstellen. In jungen Jahren ist man meist gesund, hat keine
              Vorerkrankungen. Dabei erhalten Kinder, neben Leistungen der
              gesetzlichen Krankenkassen, Zugang zu einem ganzheitlichen
              Behandlungsansatz.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Welche Vorteile bietet ein Online-Versicherungsvergleich mit
                  WaizmannTabelle?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Anhand des sog. WaizmannWertes für Heilpraktikerversicherungen
              haben wir die leistungsstärksten Heilpraktiker-Tarife (Schwerpunkt
              Naturheilkunde-Leistungen) analysiert. Das ermöglicht eine
              prozentgenaue Vergleichbarkeit, da
              Heilpraktiker-Zusatzversicherungen unterschiedliche
              Leistungspunkte und auch unterschiedliche Erstattungshöhen
              bieten.<br />

              <strong>Der WaizmannWert Heilpraktiker bietet:</strong><br />
              <ul>
                <li>
                  einen prozentgenauen Vergleich der leistungsstärksten
                  Heilpraktiker-Tarife
                </li>
                <li>
                  einen guten Marktüberblick der besten Tarife in nur 30
                  Sekunden
                </li>
                <li>alle Leistungseckdaten und Bedingungen auf einen Blick</li>
                <li>eine kostenlose Expertenberatung</li>
              </ul>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Warum so früh wie möglich eine Heilpraktiker-Versicherung
                  abschließen?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Jede Heilpraktiker-Zusatzversicherung stellt Gesundheitsfragen.
              Mitunter sind diese Fragen umfassend und reichen über mehrere
              Jahre zurück. Vorerkrankungen bzw. Erkrankungen führen nicht
              selten zu einer Ablehnung, zu Ausschlüssen mancher
              Versicherungsleistungen oder zu Beitragszuschlägen. Daher gilt,
              wenn möglich, eine Heilpraktikerversicherung so früh wie möglich
              abzuschließen.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Sind bereits angeratene / laufende Behandlungen
                  mitversichert?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Nein. Wie bei Krankenzusatzversicherungen üblich gilt, dass
              bereits angeratene Behandlungen sowie laufende Behandlungen nicht
              erstattungsfähig sind. <br />
              <strong>Wichtig:</strong> Vom Versicherer im Antrag gestellte
              Gesundheitsfragen müssen wahrheitsgemäß beantwortet werden. Im
              Idealfall sind vor Antragstellung alle Behandlungen erfolgreich
              abgeschlossen worden. Es bestehen keine Vorerkrankungen. Die
              Antragsstellung führt weder zu Ausschlüssen noch
              Beitragszuschlägen. Kommen Zweifel bei Gesundheitsfragen auf, kann
              jedoch auch jederzeit eine anonyme Einzelfallprüfung bei der
              gewünschten Versicherungsgesellschaft kostenlos und unverbindlich
              durchgeführt werden. Das schafft Klarheit.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Heilpraktikerversicherungen mit Sehhilfe-Leistungen /
                  Leistungen für LASIK</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Diverse Heilpraktiker-Tarife bieten gute Leistungen für Sehhilfen
              wie Brille, LASIK sowie Kontaktlinsen an. Und da über die Hälfte
              der Menschen in Deutschland eine Sehhilfe benötigen, kann diese
              Kombination lohnenswert sein, zumal die gesetzliche Krankenkasse
              im Erwachsenenalter nur sehr begrenzt leistet. Übliche Leistungen
              für die Brille sind circa 300,- € bis 400,- € alle zwei oder drei
              Jahre. Leistungen für LASIK liegen bei circa 1.000,- € bis 1.500,-
              € nach den ersten drei bis fünf Jahren.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Leistet eine Heilpraktikerversicherung für
                  Psychotherapie?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Bei Leistungen für Psychotherapie kommt es maßgeblich auf die
              Bedingungen bzw. das Tarifwerk der Versicherung an. Einige Tarife
              bieten Leistungen für die dafür vorgesehenen Position im
              Gebührenverzeichnis für Heilpraktiker (GebüH) “19 Psychotherapie”.
              Andere Tarife bieten wiederum erst gar keine
              Psychotherapie-Leistungen an. <br />
              Ganz allgemein bietet eine Heilpraktikerversicherung inkl.
              Psychotherapie-Leistungen eine gute Option für Behandlungen wie
              z.B. Stress, Burnout sowie depressiven Verstimmungen. Speziell
              ausgebildete Heilpraktiker Psychotherapie ermöglichen hierfür eine
              gute Anlaufstelle.<br />

              <strong>Wichtig:</strong> Bereits eingetretene Leistungsfälle
              können nicht mehr versichert werden. In den Gesundheitsfragen,
              welche der Antragsteller zu beantworten hat, können bis zu 10
              Jahren rückwirkend psychotherapeutische Behandlungen abgefragt
              werden. Für Psychotherapie gilt meist eine besondere Wartezeit von
              8 Monaten.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong>Heilpraktikerversicherung trotz Psychotherapie</strong>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Befindet sich der Antragsteller aktuell in einer
              psychotherapeutischen Behandlung bzw. wurde eine angeraten, gibt
              es keine Leistung mehr. Es werden auch diese Tarife wohl nicht
              mehr abschließbar sein, welche für Psychotherapie leisten würden.
              Oft beinhalten Gesundheitsfragen auch explizite Fragen nach
              Psychotherapie in den vergangenen, teils bis zu 10 Jahren. Bei
              einigen Tarifen ist Psychotherapie als Leistung auch
              ausgeschlossen.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong>Heilpraktikerversicherung für Hypnose</strong>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Hypnose stellt eine wirkungsvolle Art der Behandlung beim
              Heilpraktiker dar. Klassische Anwendungsgebiete für Hypnose sind
              z.B. Blockaden und Angststörungen. Auch eine gewünschte Entwöhnung
              des Rauchens kann mit Hypnose therapiert werden.<br />

              Das Gebührenverzeichnis für Heilpraktiker (GebüH) sieht zwar unter
              19.8 die Behandlung einer Einzelperson durch Hypnose vor, jedoch
              leistet aktuell nur die Allianz Heilpraktikerversicherung im Tarif
              AmbulantBest02 für eine Kostenübernahme bei einer
              Hypnose-Behandlung. Als Voranfrage an andere Versicherer kann
              Hypnose jedoch auch bei vorliegender medizinischer Notwendigkeit
              erstattet werden. Andere Versicherungen bieten hingegen erst gar
              keine Hypnose-Behandlungen in ihrem Bedingungswerk.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong>Ab wann besteht Versicherungsschutz?</strong>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Wie bei anderen Krankenzusatzversicherungen gilt auch hier, erst
              wenn Sie die Police in Händen halten, der Erstbeitrag abgebucht
              und der Versicherungsbeginn erreicht wurde, besteht
              Versicherungsschutz.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong>Wird sofort geleistet? Stichwort Wartezeit!</strong>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Mittlerweile gibt es verschiedene Heilpraktiker-Tarife ohne
              Wartezeit. Dabei können ab Versicherungsschutz, Leistungen in
              Anspruch genommen werden. Jedoch übernehmen auch diese Tarife nur
              solche Ansprüche an die Versicherung, weiche NACH Abschluss
              eintreten.<br />

              <strong> Wichtig:</strong> Diagnosen die vor Beginn des
              Versicherungsschutzes gestellt wurden, Behandlungen die vor Beginn
              des Versicherungsschutzes bereits begonnen haben oder angeraten
              wurden, sind NICHT erstattungsfähig.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong>Mit und ohne Altersrückstellungen</strong>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Ob ein Tarif mit oder ohne Altersrückstellung gewählt wird, darf
              die Tarifwahl nicht zu sehr beeinflussen. An erster Stelle sollte
              die gesamte Leistungsstärke bzw. die Leistungen des angestrebten
              Versicherungsschutzes bewertet werden. Bei Tarifen mit
              Alterungsrückstellungen werden bereits in jungen Jahres
              Rückstellungen für das Alter gebildet. Dabei sind diese Tarife
              eben auch in jungen Jahres etwas “teurer” kalkuliert.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Besteht die Möglichkeit einer Familienversicherung?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Nein. Im Gegensatz zur gesetzlichen Krankenversicherung gibt es
              bei der privaten Kranken-Zusatzversicherung keine
              Familienmitversicherung. Jedes Familienmitglied muss separat
              versichert werden. Jede zu versichernde Person birgt für den
              Versicherer ein eigenes Risiko, das separat versichert werden muss
              und daher auch einen separaten Beitrag generiert.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Darf man zwei Heilpraktikerversicherungen haben?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Eine sog. Doppelversicherung ist nicht üblich. In anderen
              Krankenzusatz-Versicherungen ist dies vereinzelt von der
              jeweiligen Versicherung erlaubt, z.B. bei Zahnzusatzversicherungen
              wo Zahnerhalt und Zahnersatz getrennt abgesichert werden können.
              Nur ist das sinnvoll? Zum einen gilt das sog. Bereicherungsverbot.
              Deshalb fragen auch diverse Anbieter ab, ob eine entsprechende
              Zusatzversicherung parallel besteht. Zum anderen ist dies im
              Leistungsfall ein erheblicher Mehraufwand für Versicherungen, zu
              prüfen, wer was erstattet.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Was ist das Gebührenverzeichnis für Heilpraktiker
                  (GebüH)?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Das GebüH ist ein Verzeichnis der durchschnittlich üblichen
              Vergütungen beim Heilpraktiker. Diese Erstattungstabelle für
              Heilpraktikerleistungen, wird für die Abrechnung auch von
              Heilpraktiker-Zusatzversicherungen zu Rate gezogen bzw. genutzt.
              Dennoch können Heilpraktiker Ihre Vergütung frei vereinbaren, da
              das GebüH einen Richtwert, jedoch keine verbindliche
              Gebührenordnung darstellt.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong
                  >Was bedeutet Abrechnung nach Hufelandverzeichnis?</strong
                >
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Das Hufeland-Verzeichnis listet diverse Naturheilverfahren,
              welches von der Hufelandgesellschaft in Berlin herausgegeben wird.
              Es führt Leistungen, welche beim Arzt für Naturheilkunde
              übernommen werden. Führt ein Arzt Naturheilkunde-Behandlungen
              durch, rechnet dieser seine Leistungen i.d.R. nach dem
              Hufeland-Verzeichnis ab. Es wird also nach den GOÄ Ziffern
              (Gebührenordnung für Ärzte) via Empfehlungen des
              Hufelandverzeichnisses abgerechnet. Für die Abrechnung mit
              Heilpraktiker-Zusatzversicherungen durch Ärzte wird das
              Hufelandverzeichnis als Abrechnungshilfe genutzt.
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title class="titleExp">
              <div>
                <strong>Was ist die Gebührenordnung für Ärzte (GOÄ)?</strong>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              >Die GOÄ ist die Gebührenordnung für Ärzte. Sie bildet die
              Grundlage für die Berechnung / Abrechnung der Vergütung für
              privatärztliche Leistungen beim Arzt. Da in der GOÄ jedoch nicht
              alle naturheilkundlichen Behandlungsmethoden aufgeführt sind, wird
              das sog. Hufeland-Verzeichnis als Orientierungshilfe und
              Abrechnungsgrundlage genutzt.
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels></v-col
      >
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts typeInfo="heilpraktiker"
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Häufig gestellte Fragen zur Heilpraktiker-Zusatzversicherung",
      "Finden Sie aussagekräftige Antworten zu häufig gestellten Fragen rund um Heilpraktiker-Zusatzversicherungen."
    );
  });
</script>
