<template>
  <div style="height: 1000px">
    <h1 class="titleHome blackText">
      WaizmannTabelle |
      <span class="text-blue">Zahnzusatzversicherung</span>
    </h1>
    <v-row>
      <v-col cols="12" md="10">
        <v-row>
          <v-col cols="12" md="6">
            <h3>203 Zahnzusatzversicherungen im Vergleich</h3>
            Die von uns entwickelten Zahnzusatzversicherungs-Benchmarks
            ermitteln anhand eines standardierten Berechnungsverfahren
            (mathematisch, exakt) die Durchschnittserstattung einer
            Zahnzusatzversicherung in konkreten Prozentzahlen. Auf Basis von
            medizinischen Muster-Behandlungsverläufen berechnen wir die
            tatsächliche Erstattung einer Zahnzusatzversicherung. Dabei werden
            sowohl anfängliche Summenbegrenzungen als auch versteckte
            Einschränkungen berücksichtigt.</v-col
          >
          <v-col cols="12" md="6">
            <h3>WaizmannWert Realwert-Methode</h3>
            <p>
              Der Vergleich der besten Erwachsenen-Zahnzusatzversicherungen
              wurde nach dem WaizmannWert Realwert-Methode ermittelt. Dier
              Vergleichswert gibt die Durchschnittserstattung eines Zahntarifes
              an.
              <router-link to="/waizmannwert">
                <a>Lesen Sie mehr über die WaizmannWert Realwert-Methode ...</a>
              </router-link>
            </p>
          </v-col>
        </v-row>
        <LayoutTable tableTyp="startErwachsenAlle" />
        <v-row class="mt-4">
          <v-col cols="12">
            <h2>Wie vergleiche ich Zahnzusatzversicherungen richtig?</h2>
            Ein
            <b class="green--text">nicht ganz ernst zu nehmender Vergleich</b>
            der häufigsten Vergleichsmethoden. Vorweggenommen, der beste Weg die
            richtige Zahnzusatzversicherung zu wählen, ist sich auf die
            WaizmannTabelle zu verlassen.
            <b class="green--text">Das sagen zumindest wir ;-) .</b>
            <br />
            <br />Aber nüchtern betrachtet gibt es für unterschiedliche Menschen
            auch unterschiedliche Wege ans Ziel zu kommen:
            <br />
            <br />
            <b>1. Der “Ich möchte nicht groß Nachdenken”-Vergleich</b>
            <br />Wenn Sie das Thema Zahnzusatzversicherung so viel
            interessiert, wie die Anzahl der funktionsfähigen Fahrräder in Rom,
            und das Thema “Vergleichen” so schnell wie möglich beenden wollen
            (und dabei zumindest große Dummheiten vermeiden wollen), dann setzen
            Sie konsequent auf den Marktführer beim Vergleichen. Die
            Wahrscheinlichkeit, dass Sie hier eine gute, wenn auch nicht
            unbedingt die allerbeste, Zahnzusatzversicherung (oder sonstiges
            Produkt) finden werden ist hoch. Bei allgemeinen
            Versicherungsvergleichen ist wahrscheinlich Check24.de der
            Marktführer in Deutschland. Durch das hohe Werbebudget sind diese
            Vergleichsportale einer Vielzahl an Verbrauchern bekannt. Diese
            Bekanntheit schützt Sie als Verbraucher bis zu einem gewissen Grad
            davor, dass Sie keinen Schrott angedreht bekommen. Wahrscheinlich
            ist dies der einfachste Weg eine gute Zahnzusatzversicherung zu
            finden.
            <br />
            <br />
            <br />
            <b>2. Der “100 % perfekt”-Vergleich</b>
            <br />Wenn Sie ein Mensch sind, der immer versucht die absolut beste
            Option zu finden, dann vergleichen Sie am besten
            Zahnzusatzversicherungen auf mehreren Vergleichsportalen (möglichst
            vielen). Drucken Sie sich jede Tarifbeschreibung aus und vergleichen
            diese untereinander. Finden Sie einen kleinen Fehler, schließen Sie
            den Vergleich sofort aus Ihrer Liste aus und schreiben diesen
            Amateuren eine E-Mail, um diese auf Ihre Fehler hinzuweisen. Kaufen
            Sie die neueste Stiftung Warentest mit einem Vergleich der besten
            Zahnzusatzversicherungen. Lesen Sie alles was Sie im Internet finden
            können. Führen Sie für jeden Tarif eine möglichst ausführliche
            Pro-Contra Liste. Fragen Sie alle Ihre Freunde und interviewen Ihren
            Zahnarzt. Lassen Sie sich mehrere Monate Zeit und werten Sie alle
            Informationen ausführlich aus. Nach dieser Recherchearbeit wissen
            Sie wahrscheinlich mehr über das Thema Zahnzusatzversicherung, wie
            99 % aller Experten. Sie können Ihre Freunde und Bekannten jetzt
            optimal beraten.
            <br />
            <br />
            <br />
            <b
              >3. Der “Ich vertraue nur Experten die ich selbst
              kenne”-Vergleich</b
            >
            <br />Dann sind Sie in der glücklichen Lage, dass Sie überhaupt
            keinen Vergleich für Zahnzusatzversicherung machen müssen. Sie
            kennen ja bereits den richtigen Experten, den Sie nur Fragen müssen
            um den für Sie besten Tarif zu finden. Da Sie den Experten ja
            persönlich kennen, muss er ja schließlich der beste ”Mann” sein. Sie
            haben ihn ja selbst ausgewählt. Wahrscheinlich haben Sie mit dieser
            Methode schon etliche glückliche Entscheidung getroffen, z.B. einen
            Bausparer abgeschlossen oder eine Aktie gekauft. Der Experte Ihrer
            Wahl trifft für Sie die bestmögliche Entscheidung . Sicherlich ist
            dies die einfachste Art Zahnzusatzversicherungen zu vergleichen.
            <br />
            <br />
            <br />
            <b>4. Der “Eigentlich bin ich Selbst der Experte”- Vergleich</b> -
            Vergleichen ist etwas für Verlierer <br />Sie kennen Sich mit Ihren
            Finanzen besser aus wie jeder Andere. Sie sind ja schließlich auch
            in Ihrem Beruf ein angesehener Experte. Da ist sehr praktisch, weil
            Sie müssen nicht mehr groß Vergleichen. Ein kurzer Blick in die
            aktuellen Vergleichsergebnisse reicht um die besten
            Zahnzusatzversicherungen schnell ermittelt zu haben. Jetzt geht es
            nur darum Lücken im Kleingedruckten zu erkennen und knallhart
            auszunutzen. Sie finden schnell Schwächen der jeweiligen Anbieter
            und können dadurch später finanziell davon profitieren.
            <br />
            <br />Oder Sie haben bereits vor langer Zeit eine
            Zahnzusatzversicherungen abgeschlossen. Lange bevor es echte Online
            Vergleiche gab. Und Sie finden jetzt die entscheidenden Beweise,
            dass Sie bereits damals (ohne echte Vergleichsmöglichkeiten)
            instinktiv die beste Zahnzusatzversicherung ausfindig gemacht haben.
            Mit diesen Beweisen überzeugen Sie auch Ihre Freunde davon, dass Sie
            selbst ein echter Experte sind und es eigentlich auch schon immer
            waren.
            <br />
            <br />
          </v-col>
        </v-row>
        <!-- <h2 class="my-4">
          <a @click="showFull = !showFull"
            >Noch mehr getestete Zahnzusatzversicherungen</a
          >
        </h2>
        <LayoutTable v-if="showFull" tableTyp="startErwachsenAlleFull" /> -->
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  import LayoutTable from "@/views/site/helper/LayoutTable";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(false);
  // const showFull = ref(false);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Vollständige Übersicht aller 203 getesteten Zahnzusatzversicherung im Vergleich",
      "Hier finden Sie alle jemals mit dem Waizmannwert-Benchmark getesteten Zahnzusatzversicherung."
    );
  });
</script>
