<template>
  <div>
    <div v-if="frageErfolg" style="height: 1000px">
      <v-card align-start class="pa-4" style="width: 666px">
        <v-card-title class="text-green">
          Wir haben Ihren Rückruf soeben erhalten
        </v-card-title>
        <v-card-text>
          Wir werden uns so schnell wie möglich bei Ihnen melden.
        </v-card-text>
      </v-card>
    </div>

    <v-card style="width: 666px" class="pa-4" v-if="!frageErfolg">
      <h2>Telefonische Beratung vereinbaren</h2>
      <v-card-text>
        <v-form @submit.prevent="submitFrage">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="fragedata.EmailFrom"
                background-color="white"
                type="text"
                label="Ihre E-Mail"
                :rules="[validateEmail(fragedata.EmailFrom)]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="fragedata.name"
                background-color="white"
                type="text"
                label="Ihr Name"
                :rules="[checkTextFeld('name')]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                style="width: 260px"
                v-model="fragedata.aid"
                background-color="white"
                type="text"
                label="Ihre Kundennummer | optional"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="fragedata.Subject"
                background-color="white"
                type="text"
                label="Ihre Telefonnummer"
                :rules="[checkTextFeld('Subject')]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="fragedata.Message"
                background-color="white"
                type="text"
                label="Wann sollen wir Sie zurückrufen?"
                :rules="[checkTextFeld('Message')]"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn type="submit" class="button_caps" color="blue">
                jetzt einen Rückruf anfordern
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
  import { ref } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  import { useGuestStore } from "@/stores/guest";
  import { useErrorStore } from "@/stores/errorStore";
  const errorStore = useErrorStore();
  const guestStore = useGuestStore();
  const frageErfolg = ref(false);
  const showErrorAlert = ref(false);
  const showErrorText = ref(false);
  // const fragedata = ref({
  //   EmailFrom: "",
  //   name: "",
  //   aid: "",
  //   Subject: "",
  //   Message: "",
  //   todo_guest_id: guestStore.guest.guest_id,
  //   action: "insertRueckruf",
  // });
  const fragedata = ref({
    EmailFrom: "",
    name: "",
    aid: "",
    Subject: "",
    Message: "",
    todo_guest_id: 0, // Reaktiv verbunden mit dem Store
    action: "insertRueckruf",
  });
  //   function checkForm() {
  //     let checkPassed = true;
  //     if (!$refs.frage_form.validate()) {
  //       checkPassed = false;
  //       console.log("fehler");
  //     }
  //     return checkPassed;
  //   }

  function checkFormular() {
    let fields = ["Subject", "name", "Message", "EmailFrom"];

    if (!fragedata.value || typeof fragedata.value !== "object") {
      console.error("Fehler: antragdata.value ist nicht korrekt definiert.");
      return false;
    }

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      let errorField = checkTextFeld(field); // Direkter Aufruf von checkTextFeld

      if (errorField !== true) {
        console.log("Fehler im Feld: " + field + " - " + errorField);
        return false;
      }
    }

    return true;
  }

  function checkTextFeld(feld) {
    let showError = false;
    let TextError = "";

    if (feld === "name" && fragedata.value.name === "") {
      TextError = "Bitte geben Sie Ihren Namen an.";
      showError = true;
    } else if (feld === "Subject" && fragedata.value.Subject === "") {
      TextError = "Bitte geben Sie Ihre Telefonnummer an.";
      showError = true;
    } else if (feld === "Message" && fragedata.value.Message === "") {
      TextError = "Bitte sagen Sie uns, wann wir Sie erreichen können.";
      showError = true;
    } else if (feld === "EmailFrom") {
      if (fragedata.value.EmailFrom === "") {
        TextError = "Bitte geben Sie eine E-Mail-Adresse an.";
        showError = true;
      } else if (!validateEmail(fragedata.value.EmailFrom)) {
        TextError = "Bitte geben Sie eine gültige E-Mail-Adresse an.";
        showError = true;
      }
    }

    if (showError) {
      console.log("Fehler: " + feld);
      showErrorAlert.value = true;
      showErrorText.value = TextError;
      return false;
    }

    return true;
  }

  function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  async function submitFrage() {
    const allValid = checkFormular();
    fragedata.value.todo_guest_id = guestStore.guest.guest_id;
    console.log("submitFrage: " + allValid);
    if (!allValid) {
      // Verhindere das Absenden, wenn ein Fehler vorliegt
      errorStore.setError(true, showErrorText.value);
      return;
    }
    try {
      const resp = await BaseCalls.postBerechnung(fragedata.value); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.success) {
        frageErfolg.value = true;
        console.log("insert");
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }
</script>
