<template>
  <div style="max-width: 888px">
    <v-row id="appLoaded">
      <v-col cols="12" class="pa-2">
        <div class="pb-2">
          <strong style="font-size: 24px">
            <span class="text-blue">Zahnzusatzversicherungen</span> | jetzt
            Vergleich starten
          </strong>
        </div>
        <div class="mb-2">
          Wählen Sie jetzt den gewünschten Waizmann-Vergleich. Sie erhalten in
          nur 30 Sekunden Ihr maßgeschneidertes Vergleichsergebnis.
        </div>
      </v-col>
    </v-row>
    <StartZahn />
    <v-row class="mt-12">
      <v-col cols="12" class="pa-2">
        <strong style="font-size: 24px"
          >Weitere Zusatzversicherungen | vergleichen</strong
        >
      </v-col>
    </v-row>
    <StartZusatz />
  </div>
</template>

<script setup>
  import { getCurrentInstance, onMounted } from "vue";
  import StartZahn from "@/views/rechner/mini/StartZahn";
  import StartZusatz from "@/views/rechner/mini/StartZusatz";
  import { useBerechnungStore } from "@/stores/berechnung";
  const berechnungStore = useBerechnungStore();
  const { proxy } = getCurrentInstance();
  onMounted(() => {
    berechnungStore.setShowFooter(false);
    proxy.$updateDocumentHead(
      "Welche Zusatzversicherung wollen Sie vergleichen?",
      "Alle WaizmannTabelle Zusatzversicherungs-Rechner"
    );
  });
</script>
