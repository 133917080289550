<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h2>Wann macht der Wechsel meiner Zahnzusatzversicherung Sinn?</h2>

        <h3>1. Die Leistung Ihrer Zahnzusatzversicherung ist zu gering.</h3>

        Ihre Zahnzusatzversicherung leistet stark eingeschränkt, max. 70% für
        Zahnersatz und Zahnbehandlungen? Dann sollten Sie auf jeden Fall den
        Wechsel Ihrer Zahnzusatzversicherung in Erwägung ziehen.
        <br /><br />
        <b>Gut zu wissen:</b> Wie viel Ihr Tarif für Zahnersatz und
        Zahnbehandlungen leistet, finden Sie entweder in Ihrer
        Versicherungspolice, in den Tarifbedingungen oder Sie fragen direkt bei
        Ihrer Versicherung nach?

        <h3>2. Der Beitrag Ihrer Zahnzusatzversicherung ist zu hoch.</h3>

        Gehen Sie wie folgt vor um herauszufinden ob Ihr Beitrag zu hoch ist:
        <ol>
          <li>
            Checken Sie den Beitrag Ihrer aktuelle ZZV → Versicherungspolice
            oder Nachtrag zur Police
          </li>
          <li>
            Berechnen Sie den Beitrag einer aktuellen ZZV →
            www.waizmanntabelle.de
          </li>
          <li>
            <b>Vergleichen Sie die Beiträge</b><br />
            Ist der Beitrag ihrer bisherigen ZZV bei gleicher Leistung um mind.
            15 % höher als der Beitrag einer aktuellen ZZV, sollten Sie Ihre ZZV
            wechseln.
          </li>
        </ol>

        <v-btn class="my-4">
          <router-link class="button_caps" to="/vergleich/starten"
            >jetzt Wechsel-Rechner starten</router-link
          ></v-btn
        >
        <v-divider :thickness="2" class="my-4"></v-divider>
        <br />
        <h3>Wann dürfen Sie Ihre Zahnzusatzversicherung nicht wechseln?</h3>

        In diesen 3 Fällen dürfen Sie Ihre Zahnzusatzversicherung auf keinen
        Fall wechseln, da Sie dadurch Ihre Leistung verlieren.
        <ol>
          <li>
            Sie befinden sich in einer laufenden Zahnbehandlung Wichtig: Auch
            das Tragen einer Schiene oder eines Retainers wird von ZZVs als
            laufende Maßnahme betrachtet.
          </li>
          <li>
            Ihr Zahnarzt hat bereits eine Zahnbehandlung angeraten. Verlangen
            Sie Einsicht in Ihre Patientenakte, um zu klären, ob bereits eine
            Zahnbehandlung angeraten wurde oder nicht.
          </li>
          <li>
            Sie haben eine chronische Erkrankung, wie z.B. Parodontitis oder
            CMD.
          </li>
        </ol>

        <br />
        <h3>Was sollten Sie auf jeden Fall vor einen Wechsel beachten?</h3>
        Diese Dinge sollten Sie auf jeden Fall bei einem Versicherungswechsel
        beachten
        <ol>
          <li>
            <b>Wählen Sie einen Tarif mit Wechsel-Option</b><br />
            Ersparen Sie sich anfängliche Summenbegrenzungen und / oder die
            Wartezeit und wählen Sie einen Wechseltarif. Zu finden auf
            www.waizmanntabelle.de/wechsel
          </li>
          <li>
            Checken Sie die Kündigungsfrist Ihrer bisherigen Versicherung
            Erkundigen Sie sich zuerst, welche Kündigungsfrist Ihrer bisherige
            Versicherung hat, bevor Sie eine neue Versicherung abschließen. Im
            Zweifel müssen Sie sonst doppelten Beitrag bezahlen
          </li>
          <li>
            <b
              >Unterschreiben Sie Ihre Kündigung und lassen Sie sich den Eingang
              bestätigen.</b
            >
            Hinweis: Eine einfache E-Mail reicht in den meisten Fällen nicht
            aus.
          </li>
        </ol>
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Zahnzusatzversicherung wechseln - in welchen Fällen lohnt sich ein Wechsel ",
      "Zahnzusatzversicherung wechseln? Macht das Sinn. Hier können Sie testen ob sich ein Wechsel Ihrer Zahnzusatzversicherung lohnt."
    );
  });
</script>
