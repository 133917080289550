<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h2>
          WaizmannTabelle |
          <span style="color: #00bcd4"
            >Krankenhauszusatzversicherungen mit Unterbringung im
            Zweibettzimmer</span
          >
        </h2>

        <br /><br />

        Die Art der gesonderten Unterbringung im Fall eines stationären
        Krankenhausaufenthalts ist mit die wichtigste Leistung einer
        Krankenhauszusatzversicherung.

        <br /><br />

        Im Folgenden eine Übersicht der von uns empfohlenen
        Zweibettzimmer-Tarife:
        <LayoutTable class="my-4" tableTyp="krankBett2" />

        Es gibt zwar mittlerweile auch Krankenhäuser in Deutschland, wo die
        Unterbringung im Zweibettzimmer Standard geworden ist. Dort ist es
        relativ wahrscheinlich, dass man als Patient in ein Zweibettzimmer
        kommt. Allerdings besteht keine Garantie darauf und eine Verlegung in
        ein Mehrbettzimmer ist jederzeit möglich. Seitens der gesetzlichen
        Krankenversicherung ist eine gesonderte Unterbringung im Ein- oder
        Zweibettzimmer nämlich meist nicht vorgesehen. Eine gesonderte
        Unterbringung ist nur bei bestimmten Diagnosen oder nach schweren
        Operationen im Leistungskatalog der Krankenkassen enthalten.

        <br /><br />

        Daher sollte man sich zumindest die gehobene Unterbringung im
        Zweibettzimmer mittels einer Krankenhauszusatzversicherung sichern. Mit
        einem solchen Krankenhaus-Tarif können Sie auf eine Unterbringung im
        Zweibettzimmer bestehen, die Mehrkosten trägt der Zusatzversicherer.
        Sollte ein gesonderte Unterbringung mangels Platz nicht möglich sein,
        erhält man ein Ersatzkrankenhaustagegeld in Höhe eines vorher
        festgelegten Satzes für jeden Tag eines stationären Aufenthalts im
        Krankenhaus.

        <br /><br />

        <v-card class="my-4 pa-4">
          <v-card-title>TIPP vom KV-Spezialisten: </v-card-title>
          <v-card-text>
            Wenn man die gesonderte Unterbringung im Ein- oder Zweibettzimmer
            nicht nutzt, hat man in den von uns empfohlenen Krankenhaus-Tarifen
            Anspruch auf ein Ersatzkrankenhaustagegeld. Man erhält dann für
            jeden Tag eines Krankenhausaufenthalts einen tariflich vorher
            festgelegten Satz. Gleiches gilt bei der Nichtinanspruchnahme der
            Chefarztbehandlung.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts typeInfo="krank"
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  import LayoutTable from "@/views/site/helper/LayoutTable";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Krankenhauszusatzversicherungen mit Unterbringung im Zweibettzimmer",
      "Krankenhauszusatzversicherungen für das Zweibettzimmer | gehobene Unterbringung | kostenlosen und anonymen Annahmecheck nutzen"
    );
  });
</script>
