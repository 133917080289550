<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <RatgeberStart v-if="$route.params.name == 'ratgeber'" />
        <FamilienVersicherung
          v-if="$route.params.name == 'familienversicherung_partnertatif'" />
        <GuenstigIndex v-if="$route.params.name == 'guenstig'" />
        <KuendigungIndex v-if="$route.params.name == 'kuendigung'" />
        <SinnvollIndex v-if="$route.params.name == 'sinnvoll'" />

        <PsychotherapieIndex v-if="$route.params.name == 'psychotherapie'" />
        <MitVorerkrankung v-if="$route.params.name == 'mit_vorerkrankung'" />
        <OhneBegrenzung v-if="$route.params.name == 'ohne_begrenzung'" />
        <OhneGesundheitsfragen
          v-if="$route.params.name == 'ohne_gesundheitsfragen'" />
        <OhneWartezeit v-if="$route.params.name == 'ohne_wartezeit'"
      /></v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts typeInfo="heilpraktiker"
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import { useRoute } from "vue-router";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";

  import RatgeberStart from "@/views/site/heil/ratgeber/RatgeberStart";
  import FamilienVersicherung from "@/views/site/heil/ratgeber/FamilienVersicherung";
  import SinnvollIndex from "@/views/site/heil/ratgeber/SinnvollIndex";
  import KuendigungIndex from "@/views/site/heil/ratgeber/KuendigungIndex";
  import GuenstigIndex from "@/views/site/heil/ratgeber/GuenstigIndex";
  import OhneWartezeit from "@/views/site/heil/ratgeber/OhneWartezeit";
  import OhneBegrenzung from "@/views/site/heil/ratgeber/OhneBegrenzung";
  import OhneGesundheitsfragen from "@/views/site/heil/ratgeber/OhneGesundheitsfragen";
  import MitVorerkrankung from "@/views/site/heil/ratgeber/MitVorerkrankung.vue";
  import PsychotherapieIndex from "@/views/site/heil/ratgeber/PsychotherapieIndex.vue";
  const route = useRoute();
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);

  function updatePageInfo(title, description) {
    proxy.$updateDocumentHead(title, description);
  }

  onMounted(() => {
    switch (route.params.name) {
      case "ratgeber":
        updatePageInfo(
          "Ratgeber Heilpraktiker-Zusatzversicherung",
          "Ausführliche Antworten zu den wichtigsten Themen der Heilpraktikerversicherung, einfach und verständlich erklärt in unserem Ratgeber."
        );
        break;
      case "psychotherapie":
        updatePageInfo(
          "Heilpraktiker-Zusatzversicherung Psychotherapie",
          "Wissenswertes und Tarif-Empfehlungen zum Thema Psychotherapie bei Heilpraktiker-Zusatzversicherungen."
        );
        break;
      case "ohne_begrenzung":
        updatePageInfo(
          "Heilpraktikerversicherung ohne Begrenzung | 100 % Erstattung",
          "Aktuelle Tarif-Empfehlungen von Heilpraktiker-Zusatzversicherungen mit 100 % Erstattung bzw. hoher Leistung."
        );
        break;
      case "ohne_wartezeit":
        updatePageInfo(
          "Heilpraktiker-Zusatzversicherung ohne Wartezeit",
          "Konkrete Infos und aktuelle Tarif-Empfehlungen zu Heilpraktiker-Zusatzversicherungen ohne Wartezeiten."
        );
        break;
      case "ohne_gesundheitsfragen":
        updatePageInfo(
          "Heilpraktiker-Zusatzversicherung ohne Gesundheitsfragen",
          "Alle wichtigen Infos sowie aktuelle Tarif-Empfehlungen zum Thema Gesundheitsfragen bei Heilpraktiker-Zusatzversicherungen."
        );
        break;
      case "guenstig":
        updatePageInfo(
          "Günstige Heilpraktikerversicherung",
          "Jetzt gut & günstige Heilpraktiker-Zusatzversicherungen finden. Aktuelle Tarif-Empfehlungen zu getesteten, leistungsstarken Heilpraktiker-Tarifen."
        );
        break;
      case "sinnvoll":
        updatePageInfo(
          "Ist eine Heilpraktikerversicherung sinnvoll?",
          "Für wen lohnt sich eine Heilpraktiker-Zusatzversicherung? Wann ist eine Naturheilkunde Versicherung sinnvoll? Unser Experten-Team klärt auf."
        );
        break;
      case "mit_vorerkrankung":
        updatePageInfo(
          "Heilpraktiker-Zusatzversicherung mit Vorerkrankung",
          "Kompakte Informationen sowie Tarif-Empfehlungen zum Abschluss einer Heilpraktiker-Zusatzversicherung mit Vorerkrankung."
        );
        break;
      case "kuendigung":
        updatePageInfo(
          "Heilpraktikerversicherung Kündigung & Wechsel",
          "Was Sie zum Thema Kündigung & Wechsel von Heilpraktiker-Zusatzversicherungen wissen müssen."
        );
        break;
      case "familienversicherung_partnertatif":
        updatePageInfo(
          "Heilpraktikerversicherung Familienversicherung | Partnertarif",
          "Gibt es bei der Heilpraktikerversicherung eine Familienversicherung oder einen Partnertarif? Unser Experten-Team klärt auf."
        );
        break;
    }
  });
</script>
