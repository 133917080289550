<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10"
        ><h2>
          Häufig gestellte Fragen zur
          <span style="color: #ff8000">Brillenversicherung</span>
        </h2>
        <br />
        <v-btn
          href="/vergleich/starten/brille"
          class="button_caps mb-4"
          style="background-color: #ff8000; color: #fff !important"
          >jetzt Brillenversicherungen online vergleichen</v-btn
        >
        <br />
        <a href="/brillenversicherung_vergleich">
          Alle Brillen-Versicherungen im Vergleich</a
        >
        <br />
        <br />
        <h3>Lohnt sich der Abschluss einer Brillenversicherung?</h3>
        Ja. Der Zentralverband der Augenoptiker und Optometristen (ZVA) geht
        davon aus, dass insgesamt 41,2 Millionen der Bundesbürger fehlsichtig
        sind. Aber nur ca. 3% davon können mit einem GKV-Zuschuss zu Ihrer
        Sehhilfe rechnen. Der GKV Zuschuss wird befundbezogen nur auf Basis der
        Brillengläser (bzw. Kontaktlinsen) gewährt, für die Fassung bzw. das
        Brillengestell ist kein Zuschuss vorgesehen. Zudem wird nur für eine
        ausreichende, zweckmäßige und wirtschaftliche Versorgung der
        Versicherten mit Hilfsmitteln geleistet. Ein Leistungsanspruch der GKV
        besteht bei Personen über 18 Jahren nur, wenn entweder mehr als 6
        Dioptrien bei Kurz- oder Weitsichtigkeit, oder mehr als 4 Dioptrien bei
        einer Hornhautverkrümmung vom Augenarzt diagnostiziert wird. (Hinweis:
        Auch bei schweren Sehbehinderungen beteiligt sich die GKV)
        Augenoperation mittels Laser (LASIK) werden als Schönheitsoperation
        eingeordnet und werden deshalb nicht bezuschusst. Bei Personen unter 18
        Jahren beteiligt sich die gesetzliche Krankenkasse lediglich in Höhe der
        Festbeträge. Für ein Brillenglas zwischen 10 € bis 112 €. Das heißt man
        sollte sich im Sehhilfe-Bereich nicht auf die Leistung der GKV
        verlassen. Um nicht komplett auf den teilweise hohen Kosten für eine
        Brille sitzen zu bleiben, empfiehlt sich der Abschluss einer
        Brillenversicherung.
        <br />
        <br />
        <h3>Wann sollte man eine Brillenversicherung abschließen?</h3>
        Die Sehfähigkeit eines Menschen ist sehr individuell. Dementsprechend
        lassen sich nur sehr schwer Ableitungen treffen, wann genau der richtige
        Zeitpunkt für den Abschluss einer Sehhilfe-Versicherungs ist.
        <br />Grundsätzlich lässt sich aber mit zunehmendem Lebensalter eine
        Verschlechterung der Sehleistung feststellen. Gerade ab dem 35ten
        Lebensjahr erhöht sich die Wahrscheinlichkeit für Altersweitsichtigkeit
        dramatisch, dementsprechend lohnt sich hier der Abschluss einer
        Zusatzversicherung für Brillen besonders. Kommt zu dieser
        altersbedingten Weitsichtigkeit noch eine Kurzsichtigkeit hinzu,
        benötigt man eine Gleitsichtbrille, die schnell mehrere hundert Euro
        kostet. Auch die Herausforderungen unserer modernen Gesellschaft wirken
        sich zunehmend auf unsere Sehfähigkeit aus.
        <br />
        <br />
        <div id="app">
          <div class="text--primary">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card :elevation="hover ? 24 : 6" class="mx-auto pa-2">
                  <span style="color: #ff8000">Experten-Tipp:</span> Um nicht
                  auf den kompletten Kosten für eine Brille sitzen zu bleiben,
                  empfehlen wir den fühzeitigen Abschluss eine
                  Brillenversicherung. Denn ist die Sehschwäche erst einmal
                  diagnostiziert und/oder wurde ein Brille bereits verordnet,
                  ist der Versicherungsfall bereits eingetreten und kann damit
                  auch nicht mehr versichert werden.
                </v-card>
              </template>
            </v-hover>
          </div>
        </div>
        <br />
        <br />
        <h3>
          Muss mein Arbeitgeber die Kosten für eine Arbeitsplatzbrille
          übernehmen?
        </h3>
        Grundsätzlich müsste der Arbeitgeber, auf Basis des Arbeitsschutzes,
        dafür sorgen, dass der Mitarbeiter wenn nötig eine geeignete Sehhilfe am
        Arbeitsplatz zur Verfügung hat.
        <br />
        Die Realität sieht aber oft anders aus. Zurückhaltende Arbeitnehmer und
        fehlende Informationen auf Seiten der Arbeitgeber führen dazu, dass
        immer noch viele Angestellte entweder keine Brille tragen, obwohl diese
        nötig wäre, oder die Kosten zur Anschaffung einer Arbeitsplatzbrille
        selbst tragen.
        <br />
        Eine Brillenversicherung unterstützt dabei, sich eine Arbeitsplatzbrille
        unabhängig vom Arbeitgeber zu finanzieren. Zudem werden von einer
        Brillenversicherung auch zusätzliche Annehmlichkeiten, wie z.B.
        ästhetische Brillengestelle oder Entspiegelung der Gläser, übernommen.
        <br />
        <a href="/vergleich/starten/brille"
          >Jetzt Brillenversicherung vergleichen</a
        >
        <br />
        <br />
        <h3>Zahlen Brillen-Versicherungen auch für Arbeitsplatzbrillen?</h3>
        Eine Arbeitsplatzbrille optimiert im Gegensatz zu “gewöhnlichen” Brillen
        die Sicht in einem Distanzbereich zwischen 50 cm und 70 cm. Also genau
        die Entfernung, die für einen Monitor am Arbeitsplatz vorgesehen ist.
        Eine Arbeitsplatzbrille ist also eine Sehhilfe, wie andere Brillen (z.B.
        Gleitsichtbrillen) auch. Damit leisten auch private
        Brillenversicherungen für Arbeitsplatzbrillen. Unter gewissen Umständen
        werden die Kosten für eine Arbeitsplatzbrille von Ihrem Arbeitgeber
        übernommen.Jedoch muss der Arbeitgeber nur die Kosten einer medizinisch
        notwendige Basis-Ausführung einer Arbeitsplatzbrille übernehmen. Das
        heißt zusätzliche Kosten für spezielle Gläser oder für Designer Gestelle
        müssen Sie selbst tragen.
        <br />
        <a href="/brillenversicherung_arbeitsplatzbrille"
          >Arbeitsplatzbrille - Was sollte man noch beachten?</a
        >
        <br />
        <br />
        <h3>
          Welche Brillenversicherungen leisten für Augenlaserbehandlungen?
        </h3>
        Neben der klassischen Versorgung mit einer Sehhilfe, lässt sich eine
        Fehlsichtigkeit in vielen Fällen auch mit einer Augenlaserbehandlung
        (z.B. LASIK oder LASEK) verbessern oder sogar beheben. Allerdings gelten
        Augenlaserbehandlungen (auch refraktive Chirurgie genannt) auch
        heutzutage immer noch als Schönheitsoperationen und werden auch deshalb
        nur im absoluten Ausnahmefall, nämlich wenn eine medizinische
        Notwendigkeit vorliegt, von der Gesetzlichen Krankenkasse bezuschusst.
        Die Kosten für eine Augenlaser-OP können sich je nach Verfahren auf ca.
        800 € bis 3000 € je Auge belaufen. Umso wichtiger ist es eine
        Brillenversicherung zu haben, die sich an diesen hohen Kosten beteiligt.
        <br />
        <ul>
          <li>
            <a href="/lasik_brillenversicherung_vergleich"
              >Die TOP LASIK-Versicherungen im Vergleich</a
            >
            <br />
          </li>
        </ul>
        <br />
        <h3>
          Welche Unterlagen Sind im Leistungsfall bei der Versicherung
          einzureichen?
        </h3>
        Im Leistungsfall muss eine spezifizierte Rechnung im Original bei der
        Brillenversicherungen eingereicht werden. Diese sollte insbesondere
        Folgendes enthalten:
        <ol>
          <li>Angabe des Rechnungsdatums</li>
          <li>Name der behandelten Person</li>
          <li>
            Angaben der Refraktionswerte (Sehwerte) wie Sphäre, Zylinder, Achse,
            Addition, Pupillendistanz, Prisma und Basislage des Prismas für
            beide Augen
          </li>
          <li>
            Aufschlüsselung der jeweiligen Kosten für die Fassung, Gläser,
            Kontaktlinsen, eventuelle Kosten wie z.B. Pflegemittel oder
            Brillenversicherung
          </li>
          <li>
            Gegebenenfalls mit Erstattungsvermerk der gesetzlichen oder privaten
            Krankenversicherung
          </li>
        </ol>
        <br />
        <h3>
          Führt eine Änderung der Sehfähigkeit automatisch zu einem neuen
          Leistungsanspruch?
        </h3>
        Die meisten Brillenversicherungen begrenzen ihre Leistungen auf einen
        gewissen Maximalbetrag innerhalb eines bestimmten Zeitraums. Unabhängig
        von einer Änderung der Fehlsichtigkeit wird in diesem Zeitraum nur in
        Höhe des maximalen Erstattungsbetrags geleistet.
        <br />D.h. sollte sich die Sehfähigkeit so verändern, dass eine neue
        Brille (z.B. Gleitsichtbrille) benötigt wird, wird grundsätzlich für
        diese neue Brille nur geleistet, wenn der maximale Erstattungsbetrag für
        diesen Zeitraum noch nicht ausgeschöpft ist.
        <br />
        <br />
        <b>Beispiel:</b>
        <br />Der Tarif leistet max. 400 € für Sehhilfen in 24 Monaten. Heute
        wurde eine neue Brille für 300 € angeschafft und die Versicherung
        leistet dafür. Damit hat die versicherte Person für die nächsten 24
        Monate nur noch 100 € des Erstattungsbetrags übrig. Sollte sich nun in
        12 Monaten die Sehfähigkeit so verändern, dass eine neue Brille
        notwendig wird, leistet der Tarif dafür maximal 100 €.
        <br />
        <br />Eine Ausnahme machen hier die Tarife der DVK. Bei den Tarifen
        <a href="/brillenversicherung/id/231">KombiMed Sehhilfen Tarif KSHR</a>
        und
        <a href="/brillenversicherung/id/232"
          >KombiMed Hilfsmittel Tarif KHMR</a
        >
        führt eine Änderung der Sehfähigkeit um mind. 0,5 Dioptrien auf einem
        Auge zu einem neuen Leistungsanspruch.
        <br />
        <br />
        <h3>
          Brille verloren? - Leistet eine Brillenversicherung auch für verlorene
          oder gestohlene Brillen?
        </h3>
        Der Großteil der Brillen Versicherungen leistet auch für Diebstahl oder
        Verlust einer Brille. Allerdings nur im Rahmen der festgelegten
        Begrenzungen für Sehhilfen. D.h. begrenzt eine Brillen-Versicherung Ihre
        Leistungen für Sehhilfen beispielsweise auf max. 300 € innerhalb von 2
        Kalenderjahren, wird in diesen zwei Kalenderjahren für einen Neubezug
        aufgrund von Diebstahl oder Verlust nur für maximal 300 € geleistet.
        <br />Beispiel: Leistungsbegrenzung für Sehhilfen max. 300 € innerhalb
        von 2 Kalenderjahren Sie beziehen in 2022 eine neue Brille und die
        Versicherung leistet dafür 250 €. Diese Brille geht Ihnen in 2024
        verloren und die Kosten für die Ersatz-Brille belaufen sich auf 250 €.
        D.h. die Versicherung leistet für diese Ersatzbrille nur maximal 50 €.
        <br />
        <br />
        <h3>
          Brille kaputt? - Leistet eine Brillen-Versicherung auch für zu Bruch
          gegangene Brillen?
        </h3>
        Der Großteil der Brillenversicherungen leistet auch für den Ersatz
        kaputter Brillen beziehungsweise für deren Reparatur. Allerdings nur im
        Rahmen der festgelegten Begrenzungen für Sehhilfen. D.h. begrenzt eine
        Brillen-Versicherung Ihre Leistungen für Sehhilfen beispielsweise auf
        max. 400 € innerhalb von 2 Kalenderjahren, wird in diesen zwei
        Kalenderjahren für Neubezug und/oder Reparatur nur für maximal 400 €
        geleistet. <br />Beispiel: Leistungsbegrenzung für Sehhilfen max. 400 €
        innerhalb von 2 Kalenderjahren Sie beziehen in 2022 eine neue Brille und
        die Versicherung leistet dafür 350 €. Diese Brille geht Ihnen in 2024
        kaputt und die Kosten der Reparatur belaufen sich auf 150 €. D.h. die
        Versicherung leistet für diese Reparatur maximal 50 €.
        <br />
        <br />
        <h3>Stundung von Versicherungsbeiträgen in der Corona Krise</h3>
        Vielen Deutsche sind auf die eine oder andere Art finanziell stark von
        der Coronakrise betroffen. Ob Jobverlust, Kurzarbeit oder zusätzliche
        Kinderbetreuungskosten, Covid 19 bringt viele Deutsche an ihre
        finanzielle Leistungsgrenze. Daraus resultieren finanzielle
        Schwierigkeiten, verspätete Zahlungen und etliche Mahnverfahren. Zudem
        sind da noch die monatlich auflaufenden Versicherungsbeiträge. Da stellt
        sich die Frage: “Bieten Versicherung kurzfristige Programme an um diese
        finanziellen Engpässe zu überwinden?” <br />Zunächst ist es wichtig zu
        wissen dass, sobald Sie mit Ihren Versicherungsbeitrag in Verzug geraten
        riskieren Sie Ihren Versicherungsschutz. <br />Grundsätzlich ist eine
        Stundung der Versicherungsprämie und/oder eine Beitragsfreistellung im
        Krankenzusatz Bereich nicht üblich. <br />Nichtsdestotrotz bieten
        einzelne Versicherung im Rahmen der Corona-Krise Hilfestellungen an um
        Zahlungsschwierigkeiten kurzfristig zu überbrücken.
        <br />
        <br
      /></v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.isDesktop()"
        ><MenueRechts typeInfo="brillen"
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Häufig gestellte Fragen zur Brillenversicherung - FAQs",
      "Fragen und Antworten rund um das Thema Brillenversicherung. Hier finden Sie die Antworten auf die wichtigsten Fragen zur Brillenversicherung"
    );
  });
</script>
