<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10"
        ><h2>WaizmannTabelle "Zahnzusatz-Lexikon"</h2>
        Fast alle Fragen rund um die Zahnzusatzversicherung von unseren Experten
        beantwortet.
        <br />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Thema suchen"
          single-line
          hide-details
        ></v-text-field>
        <br />
        <v-data-table
          v-if="faq.length > 0"
          :headers="headers"
          :items="faq"
          :items-per-page="300"
          :search="search"
        >
          <template v-slot:item="{ item }">
            <tr style="vertical-align: top">
              <td class="pa-2">
                <strong style="font-size: 16px">{{ item.site_title }}</strong>
                <br />
                {{ item.site_vorschau }}
                <router-link :to="item.site_weiterlesen">
                  <a>...ganze Antwort lesen...</a>
                </router-link>
                <br />
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  import BaseCalls from "@/services/BaseCalls";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(false);
  const faq = ref(false);
  const search = ref("");
  const headers = ref([
    {
      title: "Lexikon Thema",
      align: "left",
      sortable: true,
      value: "site_title",
    },
  ]);
  async function getFaqAlle() {
    let select = {};
    select.action = "getFaqAlle";
    try {
      const resp = await BaseCalls.postMixed(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.faq) {
        faq.value = resp.data.faq;
        appLoaded.value = true;
      }
    } catch (err) {
      faq;
    }
  }

  onMounted(() => {
    getFaqAlle();
    proxy.$updateDocumentHead(
      "WaizmannTabelle Lexikon Zahnzusatzversicherung",
      "WaizmannTabelle Lexikon Zahnzusatzversicherung"
    );
  });
</script>
