<template>
  <div>
    <v-card-text>
      <h2>WaizmannTabelle | Erstinformationen für Kunden</h2>
      <br />
      <p>
        Die Waizmanntabelle wird von der WaizmannTabelle GmbH (unabhängiger
        Versicherungsmakler) betrieben. Die WaizmannTabelle GmbH ist ein
        Familienbetrieb mit Sitz in München. Sie wurde 1995 von Dipl.-Kfm. Hans
        Waizmann gegründet.<br />
        Wir beschäftigen uns ausschließlich mit der Analyse und der Bewertung
        von Zahnzusatzversicherungen. Mittlerweile sind wir die Nr. 1 in
        Deutschland im Bereich der Zahnzusatzversicherungen und werden von mehr
        als 10.000 Zahnärzten regelmäßig empfohlen.<br />
        <br />
        <br />
        <b>Kontakt - so erreichen Sie uns:</b><br />
        www.waizmanntabelle.de<br />
        <br />
        <b>Geschäftsführung WaizmannTabelle</b><br />
        Florian Meier <br />
        <br />
        <b>Geschäftsführung Versicherungsvermittlung</b><br />
        WaizmannTabelle GmbH<br />
        Dipl.-Kfm. Hans Waizmann<br />
        Art der Versicherungstätigkeit<br />
        Dipl.Kfm. Hans Waizmann ist gemäß §93 HGB als Makler tätig<br />
        <br />
        <b>Vollhaftende Gesellschafter der KG</b><br />
        Florian Meier<br />
        Dipl.-Kfm. Hans Waizmann<br />
        <br />
        <b>Postanschrift</b><br />
        Lohstrasse 56<br />
        81543 München<br />
        <br />
        Telefon: 089-31868310<br />
        Fax: 089-3162218 <br />
        <br />
        Schreiben Sie uns eine E-Mail:<br />
        info@waizmanntabelle.de<br />
        <br />
        <br />
        <b>Finanzierung der Waizmann Webservices</b><br />
        Die WaizmannTabelle Service-Leistungen sind für unsere Kunden kostenlos.
        Wir erhalten von den meisten angeschlossen Versicherungen eine
        Abschluss-Vergütung. Diese Vergütung hat keinerlei Einfluss auf das
        Ranking und den WaizmannWert.<br />
        <br />
        <br />
        <b>Beteiligung an/von Versicherungsunternehmen</b><br />
        Das Unternehmen besitzt keine direkten oder indirekten Beteiligungen
        oder Stimmrechte am Kapital eines Versicherungsunternehmens. Es besitzt
        auch kein Versicherungsunternehmen direkte oder indirekte Stimmrechte
        oder Beteiligungen am Kapital der WaizmannTabelle GmbH.<br />
        <br />
        <br />
        <b>Vermittlerregister</b><br />
        Hans Waizmann ist Versicherungsmakler mit Erlaubnis gemäß §34 d Abs. 1
        GewO registriert unter IHK-Nr. D-CCSE-XZX0U-94.<br />
        <br />
        <br />
        <b>Vermögensschadenhaftpflichtversicherung</b><br />
        Eine den Bestimmungen der EU-Vermittlerrichtlinie entsprechende
        Berufshaftpflichtversicherung/Vermögensschadenhaftpflichtversicherung
        liegt vor (über Nassau Versicherungen).<br />
        <br />
        <br />
        <b>Zuständige Aufsichtsbehörde</b><br />
        Industrie- und Handelskammer für München und Oberbayern<br />
        Max-Joseph-Str. 2<br />
        80333 München<br />
        Telefonnummer: 089-5116-0<br />
        Telefax: 089-5116-306<br />
        E-mail: iszihkmail@muenchen.ihk.de<br />
        web: www.ihk-muenchen.de<br />
        <br />
        <br />
        <b>Anschrift der Schlichtungsstellen</b><br />
        Bundesanstalt für Finanzdienstleistungsaufsicht (BAFin)<br />
        Graurheindorfer Straße 108, 53117 Bonn<br />
        Telefon: 0228-4108-0<br />
        Telefax: 0228-4108-1550<br />
        web: www.bafin.de<br />
        <br />
        <br />
        <b>Versicherungsombudsmann e.V.</b><br />
        Postfach 080622, 10006 Berlin<br />
        Telefon: 01804-224424<br />
        Telefax: 01804-224425<br />
        web: www.versicherungsombudsmann.de<br />
        <br />
        <br />
        <b>Ombudsmann für die private Kranken- und Pflegeversicherung</b><br />
        Leipziger Str. 104, 10117 Berlin<br />
        Telefon: 01802-550444<br />
        Telefax: 030-20458931<br />
        web: www.pkv-ombudsmann.de
      </p>
    </v-card-text>
  </div>
</template>

<script>
  export default {
    name: "erst-info-pop",
    components: {},
  };
</script>

<style scoped></style>
