<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h2>
          WaizmannTabelle |
          <span style="color: #088a85">Kinder Heilpraktikerversicherungen</span>
        </h2>
        <h3>
          Auf einen Blick die stärksten Heilpraktikertarife für Kinder finden
          und vergleichen
        </h3>

        <br />Heilpraktikerversicherungen sind nicht nur etwas für Erwachsene.
        Besonders Kinder können von einem ganzheitlichen Behandlungsansatz
        profitieren und so natürlich gesund werden. <br /><br />
        Das Expertenteam der WaizmannTabelle hat die aktuell sieben
        leistungsstärksten Kinder Heilpraktiker-Zusatzversicherungen
        zusammengestellt sowie Extraleistungen für Kinder und Jugendliche
        explizit herausgearbeitet. So erhalten Eltern mit unserem
        Online-Vergleich als Ergebnis einen passenden Tarif empfohlen sowie eine
        Alternative dazu aufgezeigt. Dabei orientiert sich das Ergebnis stets
        nach maximaler Erstattung für Naturheilverfahren.

        <ul>
          <li>
            <a href="/heilpraktikerversicherung_vergleich"
              >alle Heilpraktikerversicherungen im Vergleich</a
            >
          </li>
        </ul>

        <v-divider :thickness="2" class="my-4"></v-divider>
        <v-btn
          href="/vergleich/starten/heilpraktiker"
          class="button_caps mb-4 text-white"
          style="background-color: #088a85"
          >jetzt Heilpraktikerversicherungen online vergleichen</v-btn
        >

        <LayoutTable class="my-4" tableTyp="heilKinder" />

        <v-divider :thickness="2" class="my-4"></v-divider>
        <h3>Die TOP 7 Kinder Heilpraktiker-Zusatzversicherungen im Detail</h3>

        <v-card class="pa-4 my-4">
          <v-card-title style="color: #088a85"
            >#1 Barmenia Mehr Gesundheit 1.000</v-card-title
          >
          <v-card-text>
            <ul>
              <li>Erstattung für Naturheilkunde Leistungen pro Jahr: 1000 €</li>
              <li>
                Begrenzungen für Naturheilkunde Leistungen: 1.000 € pro Jahr
              </li>
              <li>Erstattung nach: Hufelandverzeichnis und GOÄ / GebüH</li>
              <li>Wartezeit: ohne Wartezeit</li>
            </ul>
            Die Heilpraktikerversicherung Barmenia Mehr Gesund 1.000 bietet eine
            absolut flexible Nutzung der tariflichen Leistungen. Der Tarif
            leistet mit seinem Jahresbudget von Anfang an für 1.000 €
            übergreifend für alle Leistungsarten (Naturheilkunde, Vorsorge,
            Schutzimpfungen), je nachdem wie die Leistungen in Anspruch genommen
            werden möchten. Bestens geeignet für Kinder und Jugendliche, da
            dieselben Leistungsansprüche wie für Erwachsene gelten.
            <br />
            <br />
            <b>Extra Vorsorgeleistungen für Kinder:</b>
            <br />Nicht alle GKVs übernehmen die zusätzlichen U-Untersuchungen
            U10, U11 und J2. Sollte die eigene GKV hierfür keine Kostenübernahme
            vorsehen, wäre eine Erstattung aus dem Tarif Mehr Gesundheit
            möglich. Darüber hinaus können für Kinder weitere
            Vorsorgeuntersuchungen in Anspruch genommen werden, für die die GKV
            meist nicht leistet und für die man auf Wunsch auch einen versierten
            Facharzt aufsuchen kann. Beispiele sind zusätzliche
            Vorsorgeuntersuchungen außerhalb der GKV-Fristen,
            Ultraschalluntersuchungen als Vorsorge, ein Hör-Check (auch beim
            HNO), eine Schiel-Vorsorge, ein Augenscreening oder ein Augen-Check
            (auch beim Augenarzt).
            <br />
            <br />
            <b>Erweiterte Leistungen</b>
            <br />Vorsorgeleistungen, Schutzimpfungen, Heilmittel
          </v-card-text>
        </v-card>

        <v-card class="pa-4 my-4">
          <v-card-title style="color: #088a85"
            >#2 Gothaer Medi Ambulant</v-card-title
          >
          <v-card-text>
            <ul>
              <li>
                Erstattung für Naturheilkunde Leistungen pro Jahr: 1.000 €
              </li>
              <li>
                Begrenzungen für Naturheilkunde Leistungen: 1.000 € pro Jahr
              </li>
              <li>Erstattung nach: Hufelandverzeichnis und GOÄ / GebüH</li>
              <li>Wartezeit: 3 Monate</li>
              <li>Note Stiftung Warentest: 2,4</li>
            </ul>
            Die Heilpraktikerversicherung der Gothaer, MediAmbulant bietet
            bereits eine anfänglich hohe Erstattung für Naturheilkunde
            Leistungen und erbringt für Kinder dieselben Leistungsansprüche wie
            für Erwachsene. Neben Leistungen für Naturheilkunde sind hier auch
            Leistungen für Sehhilfen mit inbegriffen. Als Besonderheit bietet
            dieser Tarif die Möglichkeit einer Beitragsrückerstattung. Werden
            keine Leistungen in Anspruch genommen, gibt es Geld zurück (bis zu
            fünf Monatsbeiträge).
            <br />
            <br />
            <b>Beitragsrückerstattung bei Leistungsfreiheit</b>
            <br />
            <ul>
              <li>Drei Monatsbeiträge für ein Kalenderjahr</li>
              <li>
                Vier Monatsbeiträge für zwei aufeinander folgende Kalenderjahre
              </li>
              <li>
                Fünf Monatsbeiträge für drei aufeinander folgende Kalenderjahre
              </li>
            </ul>
            <br />
            <br />
            <b>Erweiterte Leistungen</b>
            <br />Leistungen für Sehhilfen, Heilmittel
          </v-card-text>
        </v-card>

        <v-card class="pa-4 my-4">
          <v-card-title style="color: #088a85"
            >#3 AXA MED Komfort U</v-card-title
          >
          <v-card-text>
            <ul>
              <li>Erstattung für Naturheilkunde Leistungen pro Jahr: 1000 €</li>
              <li>
                Begrenzungen für Naturheilkunde Leistungen: 2.000 € innerhalb
                von zwei aufeinanderfolgenden Versicherungsjahren
              </li>
              <li>Erstattung nach: Hufelandverzeichnis und GOÄ / GebüH</li>
              <li>Wartezeit: 3 Monate</li>
              <li>Note Stiftung Warentest: 2,1</li>
            </ul>
            Die Heilpraktikerversicherung der AXA, Tarif MED Komfort U kann mit
            einer vereinfachten Gesundheitsprüfung abgeschlossen werden. Zudem
            bietet der Tarif eine hohe anfängliche Erstattung für Naturheilkunde
            Leistungen. Bestens geeignet für Kinder und Jugendliche, da
            dieselben Leistungsansprüche wie für Erwachsene gelten. Neben
            Leistungen für Naturheilkunde sind u.a. auch Leistungen für
            Sehhilfen (300 € innerhalb von zwei aufeinanderfolgenden
            Versicherungsjahren) mit inbegriffen.
            <br />
            <br />
            <b>Extra Vorsorgeleistungen für Kinder:</b>
            <br />Vorsorge- und Früherkennungsuntersuchungen für Kinder/
            Jugendliche, wie z.B. Audiocheck, Schielvorsorge,
            Kinder-Intervall-Check, erweiterte Kinder- und Jugenduntersuchungen.
            Nähere Infos unter AXA MED Komfort U in der Liste
            Vorsorgeuntersuchungen (siehe AVB`s & Downloads -
            Infos_zu_Vorsorgeuntersuchungen).
            <br />
            <br />
            <b>Erweiterte Leistungen</b>
            Leistungen für Sehhilfen, Vorsorgeleistungen, Schutzimpfungen,
            Heilmittel
          </v-card-text>
        </v-card>

        <v-card class="pa-4 my-4">
          <v-card-title style="color: #088a85"
            >#4 Universa uni-med A Exklusiv</v-card-title
          >
          <v-card-text>
            <ul>
              <li>Erstattung für Naturheilkunde Leistungen pro Jahr: 900 €</li>
              <li>
                Begrenzungen für Naturheilkunde Leistungen: 1.800 € innerhalb
                von zwei Kalenderjahren
              </li>
              <li>Erstattung nach: Hufelandverzeichnis und GOÄ / GebüH</li>
              <li>Wartezeit: 3 Monate</li>
              <li>Note Stiftung Warentest: 1,8</li>
            </ul>
            Die Heilpraktikerversicherung Universa uni-med A Exklusiv kann mit
            nur einer Gesundheitsfrage abgeschlossen werden. Für Kinder gelten
            dieselben Leistungsansprüche wie für Erwachsene. Neben Leistungen
            für Naturheilkunde sind u.a. auch Leistungen für Sehhilfen (300 €
            alle zwei Kalenderjahre) mit inbegriffen.
            <br />
            <br />
            <b>Extra Vorsorgeleistungen für Kinder:</b>
            <br />Es wird für ambulante Vorsorgeuntersuchungen durch Ärzte, ohne
            Altersbegrenzung und ohne festgelegte Zeitabstände wie z.B.
            Individuelle Gesundheitsleistungen (IGel), Kinder- und
            Jugendlichenvorsorge geleistet.
            <br />
            <br />
            <b>Erweiterte Leistungen</b>
            <br />Leistungen für Sehhilfen, Vorsorgeleistungen, Schutzimpfungen,
            Heilmittel
          </v-card-text>
        </v-card>

        <v-card class="pa-4 my-4">
          <v-card-title style="color: #088a85"
            >#5 Versicherungskammer Bayern NaturPRIVAT</v-card-title
          >
          <v-card-text>
            <ul>
              <li>Erstattung für Naturheilkunde Leistungen pro Jahr: 1000 €</li>
              <li>
                Begrenzungen für Naturheilkunde Leistungen: 500 € im ersten
                Kalenderjahr, 1.000 € in den ersten beiden Kalenderjahren. Im
                ersten Jahr erhalten Sie höchstens 500 Euro (80% aus 625 €
                Rechnungsbetrag) und in den ersten beiden Jahren maximal 1.000 €
                (80% aus 1.250 € Rechnungsbetrag).
              </li>
              <li>Erstattung nach: Hufelandverzeichnis und GOÄ / GebüH</li>
              <li>Wartezeit: Keine Wartezeit</li>
              <li>Note Stiftung Warentest: 2,2</li>
            </ul>
            Die Heilpraktikerversicherung der Versicherungskammer Bayern,
            NaturPRIVAT kann mit einer vereinfachten Gesundheitsprüfung
            abgeschlossen werden. Der Tarif ist ein reiner Heilpraktiker-Tarif
            mit Leistungen ausschließlich im Bereich Naturheilkunde. Für Kinder
            gelten dieselben Leistungsansprüche wie für Erwachsene.
            <br />
            <br />

            <b>Erweiterte Leistungen</b>
            <br />Heilmittel
          </v-card-text>
        </v-card>

        <v-card class="pa-4 my-4">
          <v-card-title style="color: #088a85"
            >#6 Signal Iduna AmbulantPLUSpur</v-card-title
          >
          <v-card-text>
            <ul>
              <li>Erstattung für Naturheilkunde Leistungen pro Jahr: 750 €</li>
              <li>
                Begrenzungen für Naturheilkunde Leistungen: 750 € pro
                Kalenderjahr
              </li>
              <li>Erstattung nach: Hufelandverzeichnis und GOÄ / GebüH</li>
              <li>Wartezeit: Keine Wartezeit</li>
            </ul>
            Die Heilpraktikerversicherung Signal Iduna AmbulantPLUSpur kann mit
            nur einer Gesundheitsfrage abgeschlossen werden. Für Kinder gelten
            dieselben Leistungsansprüche wie für Erwachsene. Neben erweiterten
            Leistungen bietet der Tarif sogar eine
            Auslandsreisekrankenversicherung inkl. einem Auslandsrücktransport.
            Neben Leistungen für Naturheilkunde sind hier zudem auch Leistungen
            für Sehhilfen (375 € alle zwei Kalenderjahre) mit inbegriffen.
            <br />
            <br />
            <b>Extra Vorsorgeleistungen für Kinder:</b>
            <br />Es sind Vorsorgeuntersuchungen (inklusive individuelle
            Gesundheitsleistungen) und Reiseschutzimpfungen zu 100% tarifgemäß
            wie z.B. Kinder- und Jugendvorsorge tarifgemäß versichert.
            <br />
            <br />

            <b>Erweiterte Leistungen</b>
            <br />Leistungen für Sehhilfen, Vorsorgeleistungen, Schutzimpfungen,
            Heilmittel
            <br />
            <br />

            <b>Sonstige Leistungen</b>
            <br />Auslandsreisekrankenversicherung inklusive medizinisch
            sinnvoller und vertretbarer Auslandsrücktransporte (Reisen bis zu 8
            Wochen Dauer) tarifgemäß
          </v-card-text>
        </v-card>

        <v-card class="pa-4 my-4">
          <v-card-title style="color: #088a85"
            >#7 Universa uni-med A Premium</v-card-title
          >
          <v-card-text>
            <ul>
              <li>Erstattung für Naturheilkunde Leistungen pro Jahr: 600 €</li>
              <li>
                Begrenzungen für Naturheilkunde Leistungen: 1.200 € innerhalb
                von zwei Kalenderjahren
              </li>
              <li>Erstattung nach: Hufelandverzeichnis und GOÄ / GebüH</li>
              <li>Wartezeit: 3 Monate</li>
              <li>Note Stiftung Warentest: 2,5</li>
            </ul>
            Die Heilpraktikerversicherung Universa uni-med A Premium kann mit
            nur einer Gesundheitsfrage abgeschlossen werden. Für Kinder gelten
            dieselben Leistungsansprüche wie für Erwachsene. Neben Leistungen
            für Naturheilkunde sind hier u.a. auch Leistungen für Sehhilfen (250
            € alle zwei Kalenderjahre) mit inbegriffen.
            <br />
            <br />
            <b>Extra Vorsorgeleistungen für Kinder:</b>
            <br />Es wird für ambulante Vorsorgeuntersuchungen durch Ärzte, ohne
            Altersbegrenzung und ohne festgelegte Zeitabstände wie z.B.
            Individuelle Gesundheitsleistungen (IGel), Kinder- und
            Jugendlichenvorsorge geleistet.
            <br />
            <br />

            <b>Erweiterte Leistungen</b>
            <br />Leistungen für Sehhilfen, Vorsorgeleistungen, Schutzimpfungen,
            Heilmittel
          </v-card-text>
        </v-card>

        <h3>
          Das Zusammenspiel von GKV und der Kinder
          Heilpraktiker-Zusatzversicherung
        </h3>
        Die meisten gesetzlichen Krankenversicherungen (GKV) bieten keine
        Leistungen für Naturheilverfahren. Nur wenige GKVs bieten stark
        begrenzte Leistungen, welche für eine dauerhaft schonende bzw.
        ganzheitliche Behandlung nicht ausgelegt sind. Oft bietet aber gerade
        die Kombination aus beiden Bereichen, quasi mit unterschiedlichen
        Ansätzen, die gewünschten Ergebnisse. Wenn das Kind dann z.B. mit einer
        typischen Kinderkrankheit “kämpft”, kann mit schonenden Behandlungen wie
        mit Akupunktur, Homöopathie, Osteopathie etc. eine natürliche Genesung
        erreicht werden. Demnach ist es für das (Klein)Kind äußerst wichtig, so
        früh wie möglich eine passende Kinder Heilpraktiker-Zusatzversicherung
        zu haben.
        <br />
        <br />
        <h3>
          Was soll eine Kinder Heilpraktiker-Zusatzversicherung für
          Naturheilkunde leisten?
        </h3>
        Eine Heilpraktiker-Zusatzversicherung für Kinder ermöglicht dauerhaften
        Zugang zu diversen Naturheilverfahren zu günstigen Beiträgen. Wichtig
        ist, dass für Behandlungen beim Heilpraktiker sowie beim Arzt für
        Naturheilverfahren geleistet wird. Als Umfang für
        Naturheilkunde-Leistungen sollte ein Budget von mind. 600 € / 800 € pro
        Jahr zur Verfügung stehen. Idealerweise bereits gleich zu Beginn, quasi
        innerhalb der meist zweijährigen Begrenzung. Erstattung sollen bis zum
        Höchstbetrag für das Hufelandverzeichnis sowie für die GOÄ / GebüH
        gegeben sein.
        <br />
        <br />
        <h3>Wieviel kostet eine Heilpraktikerversicherung für Kinder?</h3>
        Die Monatsbeiträge für Heilpraktiker-Kindertarife liegen zwischen ca.
        8,- bis 12,- €. Kindern wird somit der Zugang zu vielfältigen
        Naturheilkunde Leistungen schon für kleines Geld dauerhaft ermöglicht.
        <br />
        <br />
        <h3>
          Warum ist eine Heilpraktiker-Zusatzversicherung für Kinder sinnvoll?
        </h3>
        Ob Kleinkind, Kind oder Jugendlicher, junge Menschen profitieren enorm
        von einem ganzheitlichen Behandlungsansatz. Schonende Behandlungen durch
        Naturheilkunde beim Heilpraktiker bzw. Arzt für Naturheilverfahren
        können als Ergänzung zu Leistungen der GKV oder statt herkömmlichen
        Ansätzen der Schulmedizin genutzt werden, um natürlich gesund zu werden.
        <br />
        <br />
        <h3>
          Warum so früh wie möglich eine Heilpraktiker-Zusatzversicherung
          abzuschließen ist?
        </h3>
        Jede Heilpraktikerversicherung stellt Gesundheitsfragen. Meist sind
        diese Fragen umfassend, wodurch so manche Annahme in späteren Jahren
        erst gar nicht mehr möglich ist oder wenn, mit Zuschlägen bzw.
        Ausschlüssen zu rechnen ist. Zudem sind Kindertarife vollwertige Tarife,
        welche im Erwachsenenalter weitergeführt werden können. Und das ohne
        Leistungseinschränkungen. Unser Tipp: Je früher eine
        Heilpraktiker-Zusatzversicherung abgeschlossen wird, umso besser. Die
        Gefahr einer Vorerkrankung bzw. Erkrankung ist in jungen Jahren deutlich
        geringer als im Erwachsenenalter. Nicht alle z.B. chronischen
        Erkrankungen, Diagnosen bestehen von Geburt an. Je länger also gewartet
        wird, desto schwieriger kann die Annahme bei einer
        Heilpraktikerversicherung werden.</v-col
      >
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts typeInfo="heilpraktiker"
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  import LayoutTable from "@/views/site/helper/LayoutTable";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Heilpraktikerversicherungen für Kinder - Die TOP 7 Tarife 2024",
      "Jetzt die beste Heilpraktikerversicherung für Ihr Kind finden. Bis zu 100 % Erstattung für Naturheilverfahren. Online vergleichen und kostenlose Experten-Beratung nutzen. "
    );
  });
</script>
