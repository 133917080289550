<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <v-row>
          <v-col cols="12">
            <h1>
              WaizmannTabelle 2024 |
              <span class="text-blue">Nr. 1 bei Zahnärzten</span>
            </h1>

            Seit 1995 zu 100 % auf Zahnzusatzversicherungen spezialisiert.
            <span v-if="sharedDataStore.isDesktop()">
              Der 1. Online-Vergleich (seit 1995) für Zahnzusatzversicherungen
              in Deutschland. In nur 1-2 Minuten wertet der Waizmann-Rechner
              über 200 getestete Zahntarife aus.</span
            >
          </v-col>
        </v-row>
        <v-divider :thickness="2" class="my-8"></v-divider>
        <StartZahn />
        <v-divider :thickness="2" class="my-8"></v-divider>
        <LayoutTable tableTyp="startErwachsen" />
        <v-divider :thickness="2" class="my-8"></v-divider>
        <LayoutTable tableTyp="startKind" />
        <v-divider :thickness="2" class="my-8"></v-divider>
        <LayoutTable tableTyp="startPlus" />
        <v-divider :thickness="2" class="my-8"></v-divider>
        <StartZahn />
        <v-row class="mt-8">
          <!-- Spalte gross unten links -->
          <v-col cols="12">
            <h2>Zahnzusatzversicherung Stiftung Warentest 06/2022</h2>
            Alle 1-2 Jahre testet Stiftung Warentest / Finanztest
            Zahnzusatzversicherungen. In der neuesten Ausgabe 6/2022 wurden 267
            Zahn-Tarife getestet. Davon sind 111 Zahnzusatzversicherungen mit
            “sehr gut” bewertet, 26 Zahnzusatzversicherungen mit Best-Note 0,5
            ausgezeichnet. Unser ExpertenTeam hat den aktuellen
            Zahnzusatzversicherung Test analysiert und mit der WaizmannTabelle
            8-Jahres Realwert-Methode verglichen. Dies sind die wichtigsten
            Unterschiede im Vergleich:

            <br /><br />
            <h3>Fokus auf Zahnersatz</h3>

            Stiftung Warentest analysiert Zahnzusatzversicherungen
            ausschließlich nach der Leistung für Zahnersatz. Zahnbehandlungen
            fließen nicht in die Bewertung mit ein, sondern werden lediglich
            teilweise mit ausgewiesen. Da sich ein großer Teil der
            zahnärztlichen Praxis um Zahnbehandlungen und Prophylaxe dreht,
            greift das Testverfahren hier zu kurz.
            <br /><br />
            <h3>Bewertung von Leistungsbegrenzungen</h3>
            Sowohl Stiftung Warentest als auch WaizmannTabelle beziehen
            anfängliche Summenbegrenzungen in ihr Testverfahren mit ein. Während
            WaizmannTabelle die anfänglichen Summenbegrenzungen 1:1 anwendet,
            bringt Stiftung Warentest diese nur als Prozentwert (10 %) in die
            Bewertung mit ein. Weist ein Tarif eine anfängliche Wartezeit auf,
            wird das Qualitätsurteil von Stiftung Warentest um 0,1 in der Note
            abgewertet.
            <br /><br />
            <h3>Unterscheidung nach Altersgruppen</h3>
            Im Testverfahren von Stiftung Warentest wird nach drei Modell Kunden
            im Alter von jeweils 43 Jahren unterschieden. Damit kommt die
            Unterscheidung nach Altersgruppen wesentlich zu kurz. Vereinfacht
            gesagt haben verschiedene Altersgruppen unterschiedliche
            Leistungsschwerpunkte. Kinder und Jugendliche benötigen
            kieferorthopädische Leistungen, während 60Plus Patienten Tarife mit
            starken Zahnersatz-Leistungen benötigen.
            <br /><br />
            <h3>Unser Fazit</h3>
            Die Auswertungen von Stiftung Warentest sowie anderer Test-Magazine
            können dem Verbraucher nur einen groben Marktüberblick geben und als
            Richtschnur dienen. Die individuelle Vorgeschichte in punkto
            Zahngesundheit eines Verbraucher können diese Tests nicht abbilden
            und damit keine verbrauchergerechte Tarif-Empfehlung geben.

            <br />
            <ul class="mt-4">
              <li>
                <router-link to="/vergleich/starten">
                  <a>Onlinevergleich starten</a> [Tipp]
                </router-link>
              </li>
              <li>
                <router-link to="/waizmannwert">
                  <a>Waizmann Realwert-Methode</a>
                </router-link>
              </li>
              <li>
                Ein ausführliche Gegenüberstellung der Testverfahren -
                <router-link to="/zahnzusatzversicherung_testsieger">
                  <a
                    >8-Jahres Realwert-Methode vs. Stiftung Warentest finden Sie
                    hier</a
                  ></router-link
                >.
              </li>
            </ul>

            <br />
            <br />

            <h2>
              Ist der Wechsel Ihrer Zahnzusatzversicherung wirtschaftlich
              sinnvoll?
            </h2>
            Wenn Sie eine ältere Zahnzusatzversicherung haben, kann sich ein
            Wechsel unter bestimmten Vorraussetzungen für Sie lohnen.
            <br />
            <br />Fast in jedem Monat werden von den Versicherungsgesellschaften
            neue Tarife auf den Markt gebracht. Im Schnitt ist das
            Leistungsniveau der Top Zahnversicherungen in den letzten 10 Jahren
            fast um 70 % gestiegen. Wenn Sie 2007 noch 500,-€ Erstattung
            bekommen hätten, können Sie 2017 schon über 850,-€ von Ihrer
            Zahnzusatzversicherung erstattet bekommen. Un das bei gleichen
            monatlichen Beiträgen. Für eine 30-Jährige Person kostet ein
            moderner Top-Tarif wie der Giga.Dent der Halleschen mit 98 %
            Durchschnittserstattung 23.90 € pro Monat. Im Gegensatz dazu
            bezahlen Sie für den schon länger am Markt erhältlichen Tarif ARAG
            Z100 Dental Pro mit nur 84% Durchschnittserstattung 35.87 €. Bei
            einem Wechsel sparen Sie ca. 12 € im Monat bei deutlich besseren
            Leistungen.
            <br />
            <br />Dabei ist allerdings wichtig zu beachten, dass gewisse Punkte
            im Vorfeld geklärt sein müssen, damit Sie reibungslos Ihren
            Zahnversicherungsschutz verbessern können.
            <br />
            <br />Befinden Sie sich in einer laufenden zahnärztlichen
            Behandlung? Oder hat Ihr Zahnarzt bereits eine Behandlung angeraten?
            Wie ist Ihr aktueller Zahnstatus? Wir helfen Ihnen zu klären, ob Sie
            Ihren Zahntarif ohne Probleme wechseln können. Fragen Sie uns
            einfach.
            <br />
            <ul class="mt-4">
              <li>
                <router-link to="/vergleich/starten">
                  <a>Onlinevergleich starten</a> [Tipp]
                </router-link>
              </li>

              <li>
                <router-link to="/wechsel">
                  <a>Informationen zum Wechsel eines Zahntarifes</a>
                </router-link>
              </li>
            </ul>
            <br />
            <br />
            <br />
            <h2>Die richtige Zahnzusatzversicherung für Ihr Kind</h2>
            Zahnarztbesuchen die Diagnose erhalten - “Ihr Kind braucht eine
            Spange!”. Schnell kommen hier neben einem geringen Kassenanteil auch
            noch privatzahnärztliche Kosten auf Sie zu. Das kann sich dann
            zwischen 1.000,- bis 5.000,- bewegen.
            <br />
            <br />Andererseits möchte man nur das beste an Versorgung für sein
            Kind. Und so weicht man von der Billigvariante der Regelversorgung
            ab und nutzt die zahnmedizinischen Fortschritte, um eine Zahn- oder
            Kieferregulierung durchführen zu lassen. Sie würden dann bei
            Behandlungen mit speziellen Brackets, farblosen Bögen, Retainer und
            anderen Spezialleistungen diese zu 100% selbst tragen, da die
            gesetzliche Krankenkasse hierfür keinerlei Notwendigkeit anerkennt.
            <br />
            <br />Unsere Kinder haben einen komplett anderen Anspruch an eine
            Zahnzusatzversicherungen, wie wir Erwachsenen. Wo wir uns über
            Zahnersatz in Form von Kronen und Implantaten Gedanken machen, steht
            bei den Kindern der Zahnerhalt mit Prophylaxe, Fissurenversiegelung,
            Füllungen und Kieferorthopädie im Vordergrund.
            <br />
            <br />Daher ist auch der Zeitpunkt wichtig, zu welchem Sie Ihr Kind
            versichern, damit Sie nicht zu spät dran sind, wenn Sie
            Unterstützung brauchen. Deswegen raten wir zu einem Abschluss ab dem
            2. oder 3. Lebensjahr, bevor die regelmäßigen Zahnarztbesuche mit
            den Kleinen beginnen. Mit dem richtigen Versicherungsschutz
            profitieren unsere Zwerge dann von umfangreichen Leistungen und
            Vorteilen und das schon zum kleinen monatlichen Preis. Sie können
            den Eigenanteil für die speziellen Kinderleistungen im Bereich der
            Kieferorthopädie dann bis zu 100% je nach Tarif abdecken.
            <br />Prophylaxe und Fissurenversiegelung für gesunde Zähne Füllung
            für den langen Erhalt der eigenen Zahnsubstanz Kieferorthopädie für
            Regulierungen von Zahn- oder Kieferfehlstellungen Bei Ihrer Suche
            müssen Sie auf einige Punkte achten, bevor Sie eine Entscheidung
            treffen. Denn nur mit der richtigen Wahl ist Ihnen, Ihrem Kind und
            dem Geldbeutel geholfen.
            <br />
            <ul class="mt-4">
              <li>
                Deckt der gewünschte Tarife alle
                <router-link to="/erklaert/mehrkosten_kig_1_2">
                  <a>KIG-Einstufungen</a> </router-link
                >ab?
              </li>
              <li>Wie sind die Leistungen für Kieferorthopädie begrenzt?</li>
              <li>
                Sind spezielle Leistungen für Kinder, wie
                <router-link to="/kinder">
                  <a>Kinder-PZR und Fissurenversiegelung</a> </router-link
                >enthalten?
              </li>
            </ul>
            <br />Sind spezielle Leistungen für Kinder, wie Kinder-PZR und
            Fissurenversiegelung enthalten? Mit der WaizmannTabelle für Kinder
            können Sie schnell und übersichtlich den richtigen Tarif angepasst
            an die Bedürfnisse Ihres Kindes finden und auswählen. Sie finden
            geeignete Tarife schon ab 10,- Euro bis zu den Top-Tarifen zwischen
            14,- bis 20,- Euro.
            <br />

            <ul class="mt-4">
              <li>
                <router-link to="/vergleich/starten">
                  <a>Onlinevergleich Kinder starten</a> </router-link
                >[Tipp]
              </li>
              <li>
                <router-link to="/kinder">
                  <a>die besten Kinder-Zahnzusatzversicherungen anzeigen</a>
                </router-link>
              </li>
              <li>
                <router-link to="/kinder/faq">
                  <a
                    >Häufig gestellte Fragen von Eltern zur
                    Zahnzusatzversicherung für Kinder</a
                  >
                </router-link>
              </li>
            </ul>
            <br />
            <br />

            <h2>
              Die besten Zahnzusatzversicherungen für die Generation 60Plus
            </h2>
            Die häufigste Frage ist, ob ab einem bestimmten Alter eine
            Zusatzversicherung für Zähne noch Sinn macht. Wir sind der Meinung,
            dass es immer sinnvoll ist, auch wenn sich im Alter die Suche etwas
            schwieriger gestaltet. Hat man evtl. sehr viel oder sehr alten
            Zahnersatz oder gar schon “Dritte” Zähne, ist die Auswahl der
            möglichen Tarife sehr reduziert. Aber es gibt definitiv Tarife,
            welche Zahnersatz, Prothesen und auch die nach wie vor wichtige
            Prophylaxe absichern. Auf der WaizmannTabelle Generation 60+ listen
            wir genau diese Tarife für Senioren auf und weisen auf die wichtigen
            Leistungen für diese Generation hin.
            <ul class="mt-4">
              <li>
                <router-link to="/vergleich/starten">
                  <a>Onlinevergleich starten</a> </router-link
                >[Tipp]
              </li>

              <li>
                <router-link to="/60plus">
                  <a>Die besten Generation 60Plus Tarife</a>
                </router-link>
              </li>
            </ul>
            <br />
            <br />
            <h2>Die besten Heilpraktikerversicherungen 2024 im Vergleich</h2>
            Natürlich gesund zu werden, ist ein Wunsch, den immer mehr
            gesetzlich Versicherte haben. Demnach suchen Kassenpatienten
            verstärkt nach alternativen Heilmethoden bei Heilpraktikern und
            Ärzten für Naturheilkunde, um schonender zu gesunden. Demgegenüber
            stehen jedoch restriktive Beschränkungen, als gesetzlich
            Versicherter, Erstattungen für alternative Naturheilverfahren zu
            erhalten, welche im starren Katalog der gesetzlichen Krankenkassen
            (GKV) nicht vorgesehen sind. Die Lösung ist meist, eine passende
            Heilpraktiker-Zusatzversicherung zu finden, welche individuelle
            Bedürfnisse alternativer Behandlungsmethoden von Osteopathie,
            Homöopathie, Akupunktur, Chiropraktik bis hin zur TCM sowie weiteren
            Zusatzleistungen abdeckt.
            <br />
            <br />
            <br />
            <h2>
              Eine Heilpraktiker-Zusatzversicherung als sinnvolle Ergänzung zur
              Schulmedizin
            </h2>
            Bei WaizmannTabelle erhalten Sie
            Heilpraktiker-Zusatzversicherungs-Testsieger, Kosten und Leistungen
            der jeweiligen Heilpraktikerversicherungen transparent dargestellt,
            Heilpraktiker-Tarife ohne Wartezeit sowie einen Vorab-Annahme-Check
            auf Basis der Gesundheitsfragen. Anhand des WaizmannWertes für
            Heilpraktiker haben wir für Sie die leistungsstärksten Heilpraktiker
            Zusatzversicherungen (Schwerpunkt Naturheilkunde-Leistungen)
            analysiert. Basis hierfür ist ein Warenkorb mit
            Muster-Behandlungsverläufen, welcher realitätsnah zusammengestellt
            wurde und aus jeweils 4 Jahren mit verschiedenen Erst- und
            Folgebehandlungen besteht.
            <ul class="mt-4">
              <li>
                <router-link to="/vergleich/starten/heilpraktiker">
                  <a
                    >Onlinevergleich Heilpraktiker-Zusatzversicherung starten</a
                  >
                </router-link>
              </li>
              <li>
                <router-link to="/heilpraktikerversicherung_vergleich">
                  <a>TOP 10 Heilpraktiker-Zusatzversicherungen im Vergleich</a>
                </router-link>
              </li>
              <li>
                <router-link to="/kinder_heilpraktikerversicherung_vergleich">
                  <a>TOP 7 Kinder Heilpraktikerversicherungen im Vergleich</a>
                </router-link>
              </li>
              <li>
                <router-link to="/heilpraktikerversicherung_test">
                  <a
                    >Heilpraktiker Zusatzversicherung Test - Stiftung Warentest
                  </a>
                </router-link>
              </li>
            </ul>
            <br />
            <br />
            <h2>Die besten Brillen-Versicherungen 2022 im Vergleich</h2>
            Ein großer Teil der Deutschen leidet unter einer Sehschwäche oder
            ist von einer altersbedingten Fehlsichtigkeit betroffen. In den
            meisten Fällen kann die eingeschränkte Sehfähigkeit durch Brillen
            mit Einstärken- oder Gleitsichtgläsern bzw. Kontaktlinsen
            ausgeglichen werden. Augenlaser-Behandlungen, wie z.B. LASIK, sind
            mittlerweile in vielen Fällen die Behandlungs-Methode der Wahl. Auch
            Trendleistungen wie Prismenbrillen für Schüler zum Ausgleich einer
            Winkelfehlsichtigkeit werden heutzutage immer wichtiger. Jedoch
            bleiben Kassenpatienten in den meisten Fällen selbst auf den Kosten
            ihrer Behandlung sitzen, da nur bestimmte Sehhilfen bis zur
            Vollendung des 18. Lebensjahres von der Gesetzlichen Krankenkasse
            (GKV) bezuschusst werden. Deshalb ist es wichtig eine
            Brillenversicherung zu finden, die in den entscheidenden
            Leistungsbereichen: Sehhilfe, Vorsorgeuntersuchung und
            Augenlaserbehandlung, Ihren Bedürfnissen entspricht und im
            Schadensfall leistet.
            <br />
            <br />
            <br />
            <h2>
              WaizmannWert Brille - Der Benchmark für Brillenversicherungen
            </h2>
            Auf Basis eines fest definierten Warenkorbes hat die WaizmannTabelle
            die besten Brillentarife 2024 für Sie analysiert und transparent
            dargestellt. Neben generellen Leistungen für Sehhilfen, wie Brillen
            (auch Sonnenbrillen) und Kontakt-Linsen fließen auch Leistungen für
            Augenlaser-Behandlungen (z.B. LASIK) als auch
            Vorsorgeuntersuchungen, zur Früherkennung von Augenerkrankungen
            (z.B. Glaukom-Vorsorge), in unsere Bewertung mit ein. Um Ihnen die
            Suche nach der richtigen Sehhilfeversicherung zu erleichtern, finden
            Sie bei WaizmannTabelle neben Kosten und Leistungen auch weitere
            Informationen zu anfänglichen Begrenzungen, Wartezeiten und
            Zusatzleistungen der einzelnen Brillen Tarife.
            <ul class="mt-4">
              <li>
                <router-link to="/vergleich/starten/brille">
                  <a>Onlinevergleich Brillenversicherung starten</a>
                </router-link>
              </li>
              <li>
                <router-link to="/brillenversicherung_vergleich">
                  <a>TOP 10 Brillenversicherungen | im Vergleich</a>
                </router-link>
              </li>
              <li>
                <router-link to="/kinder_brillenversicherung_vergleich">
                  <a>Die TOP Kinder Brillenversicherungen | im Vergleich</a>
                </router-link>
              </li>
              <li>
                <router-link to="/senioren_brillenversicherung_vergleich">
                  <a>Die TOP Senioren Brillenversicherungen | im Vergleich</a>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/brillenversicherung_ohne_Wartezeit_mit_Sofortleistung"
                >
                  <a>Brillenversicherung ohne Wartezeit</a>
                </router-link>
              </li>
              <li>
                <router-link to="/brillenversicherung_prismenbrille">
                  <a
                    >Die besten Brillenversicherungen für eine
                    Winkelfehlsichtigkeit im Vergleich</a
                  >
                </router-link>
              </li>
              <li>
                <router-link to="/brillenversicherung_test">
                  <a>Brillenversicherungen Testsieger / Stiftung Warentest</a>
                </router-link>
              </li>
            </ul>
            <br /><br />
            <h2>
              Die besten Krankenhauszusatzversicherungen im Vergleich 2022
            </h2>
            Die gesetzliche Krankenversicherung (GKV) bietet bei einem
            Krankenhausaufenthalt einen ausreichenden Standard auf Kassenniveau.
            Dabei erhält jeder Patient im Krankenhaus die medizinisch
            notwendigen Leistungen, welche für eine schnelle Genesung
            ausreichend sind.
            <br /><br />
            Eine private Krankenhaus-Zusatzversicherung eröffnet jedoch den
            Zugang zu Wahlleistungen. Wer mehr Komfort bei seinem stationären
            Aufenthalt wünscht, kann eine Unterbringung im Ein- oder
            Zweibettzimmer, Chefarztbehandlung sowie freie Krankenhauswahl, wie
            z. B. in einer Privatklinik, mit einer privaten
            Krankenhausversicherung absichern.
            <br /><br />
            <h2>
              Die Krankenhauszusatzversicherung als sinnvolle Ergänzung für
              gesetzlich Versicherte
            </h2>

            Die WaizmannTabelle Krankenhaus hat alle aktuell abschließbaren
            Tarife stationärer Zusatzversicherungen geprüft. Dabei wurden über
            70 Krankenhaustarife detailliert verglichen. Über den
            KH-WaizmannWert, einem unabhängigen Benchmark mit eigens
            entwickeltem Punktesystem, werden diese Tarife nach Leistungsstärke
            gelistet. So ist es möglich die Vielzahl an Tarifen mit
            unterschiedlichen Leistungen und Beiträgen erstmals direkt
            vergleichbar zu machen. Auf einen Blick sind
            Krankenhausversicherungen ohne Wartezeit, sowie Tarife, die trotz
            Vorerkrankungen abschließbar sind, erkennbar. Darüber hinaus finden
            Sie auch Krankenhauszusatzversicherungen ohne Gesundheitsfragen.
            <ul class="mt-4">
              <li>
                <router-link to="/vergleich/starten/krankenhaus">
                  <a>Onlinevergleich Krankenhauszusatzversicherungen starten</a>
                </router-link>
              </li>
              <li>
                <router-link to="/krankenhauszusatzversicherung_vergleich">
                  <a>Die besten Krankenhauszusatzversicherungen im Vergleich</a>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/kinder_krankenhauszusatzversicherung_vergleich"
                >
                  <a>Die TOP10 Krankenhauszusatzversicherungen für Kinder</a>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/krankenhauszusatzversicherung_ohne_gesundheitsfragen"
                >
                  <a>Krankenhauszusatzversicherungen ohne Gesundheitsfragen</a>
                </router-link>
              </li>
              <li>
                <router-link to="/krankenhauszusatzversicherung_test">
                  <a
                    >Vergleich Testverfahren Stiftung Warentest vs.
                    WaizmannTabelle</a
                  >
                </router-link>
              </li>
              <li>
                <router-link to="/krankenhauszusatzversicherung_faq">
                  <a>FAQs - Häufig gestellte Fragen und Antworten</a>
                </router-link>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.isDesktop()">
        <div>
          <h3>
            Das Original seit
            <span class="text-blue"> 1995</span>
          </h3>
          <v-divider :thickness="2" class="my-2"></v-divider>
          <h3>
            <span class="text-blue"> > 10.000</span> registrierte Zahnärzte
          </h3>
          <v-divider :thickness="2" class="my-2"></v-divider>
          <h3>Auflage 2023: <span class="text-blue"> 1.2 Mio</span></h3>
          <v-divider :thickness="2" class="my-2"></v-divider>
          <h3>
            <span class="text-blue"> > 400</span> getestete Zusatzversicherungen
          </h3>
          <v-divider :thickness="2"></v-divider>
        </div>
        <div class="mt-12">
          <h4 class="text-green">Für Zahnärzte</h4>
          <h3>WaizmannTabellen anfordern</h3>
          <a href="https://www.teamwaizmann.de" target="_blank"
            >Klicken Sie hier</a
          >, um WaizmannTabellen für Ihre Praxis anzufordern (<b>kostenlos</b>).
          <br /><br />

          <a href="https://www.teamwaizmann.de" target="_blank">
            <img
              :src="require('@/assets/site/togo.jpg')"
              style="width: 140px; float: left"
          /></a>
        </div>
        <v-divider :thickness="2" class="my-2"></v-divider>
        <div>
          <h3 style="margin-top: 160px">
            WaizmannTabelle | PZR Flatrate <sup class="text-green">Neu</sup>
          </h3>

          <a href="https://www.teamwaizmann.de" target="_blank"
            >Hier klicken um diese anzufordern</a
          >
          <br /><br />
          <a href="https://www.teamwaizmann.de" target="_blank">
            <img
              :src="require('@/assets/site/togoPzr.png')"
              style="width: 140px; float: left"
          /></a>
        </div>
      </v-col>
    </v-row>
    <div id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { getCurrentInstance, onMounted } from "vue";
  import StartZahn from "@/views/rechner/mini/StartZahn";
  import LayoutTable from "@/views/site/helper/LayoutTable";
  import { useSharedDataStore } from "@/stores/sharedData";
  import { useBerechnungStore } from "@/stores/berechnung";
  const berechnungStore = useBerechnungStore();
  const sharedDataStore = useSharedDataStore();
  //  import BaseCalls from  "@/services/BaseCalls";

  onMounted(() => {
    berechnungStore.setShowFooter(true);
    const { proxy } = getCurrentInstance();
    proxy.$updateDocumentHead(
      "WaizmannTabelle Zahnzusatzversicherung - 204 Zahntarife im Vergleich",
      "Zahnzusatzversicherung im Vergleich - 204 analysierte Zahnversicherungen. 1. mathematisch ermittelter Vergleichs-Benchmark. Von 10.000 Zahnärzten empfohlen."
    );
  });
</script>
