.
<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h1>
          WaizmannTabelle
          <strong style="color: #513b83"
            >Zahnzusatzversicherungen 60Plus</strong
          >
        </h1>
        <em>
          Die
          <b>WaizmannTabelle 60+</b> zeigt die leistungsstärksten Tarife aus
          über 200 Zahnzusatzversicherungen speziell für Versicherte “60plus”.
        </em>
        <br />
        <br />
        <p>
          Mit der immer höheren Lebenserwartung, steigt auch das Eintrittsalter
          in die private Zahnzusatzversicherung. Anders als bei Kindertarifen
          sowie Tarifen für Versicherte “mittleren Alters” sind
          Annahmerichtlinien und Leistungen anders zu bewerten.
        </p>

        <p>
          Gerade die Annahmebedingungen, das Höchsteintrittsalter sowie
          eventuelle Gesundheitsfragen im jeweiligen Antrag sind bei Senioren
          entscheidende Kriterien. Die WaizmannTabelle 50plus setzt den Fokus
          auf 100%ige Erstattung im Falle einer Regelversorgung und bestmögliche
          Leistung im Zahnersatz-Bereich, hier besonders für Implantate. Wichtig
          für die Bewertung sind außerdem spezielle Leistungen, die
          ausschließlich von der “älteren Generation” genutzt werden.
        </p>
        <br />
        <p>
          <b
            >Höchsteintrittsalter und Gesundheitsfragen bei
            Zahnzusatzversicherungen 60+</b
          >
          <br />Gerade bei “älteren” zu versichernden Personen spielt, abhängig
          vom Versicherer, das in den Annahmerichtlinien festgeschriebene
          Höchsteintrittsalter eine entscheidende Rolle. In der Praxis nutzt
          somit der leistungsstärkste Tarif nichts, wenn er nicht mehr
          abschließbar ist. Selbiges gilt für Tarife mit ausführlichen
          Gesundheitsfragen. Je mehr und ausführlicher die Gesundheitsfragen im
          Antrag sind, desto schwieriger ist es diesen Tarif abschließen zu
          können. Aus diesem Grund werden in der WaizmannTabelle 50plus
          bevorzugt jene Tarife empfohlen, die kein Höchsteintrittsalter und
          zusätzlich einfache bzw. keine Gesundheitsfragen haben.
        </p>
        <br />
        <p>
          <b
            >100%ige Erstattung für Zahnersatz (Implantate) im Falle einer
            Regelversorgung</b
          >
          <br />Viele Versicherte müssen im Rentenalter bei Zahnbehandlungen auf
          die reine Regelversorgung - also eine gesetzliche Kassenleistung (GKV)
          für sämtliche Maßnahmen - zurückgreifen, da die Rente für eine
          privatärztliche Versorgung nicht ausreicht. Dies ist bei über 70% der
          gesetzlich versicherten Rentnern ohne private Zahnzusatzversicherung
          der Fall. Deshalb ist es sehr wichtig, dass die private
          Zahnzusatzversicherung im Fall einer reinen Regelversorgung sämtliche
          Kosten der Eigenbeteiligung zu 100% übernimmt. Zusätzlich ist eine
          bestmögliche Leistung im Zahnersatz-Bereich, insbesondere eine nach
          Anzahl und Betrag unbegrenzte Leistungshöhe für Implantatversorgungen
          äußerst wichtig.
        </p>
        <LayoutTable class="my-4" tableTyp="startPlusAlle" />
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  import LayoutTable from "@/views/site/helper/LayoutTable";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(false);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Zahnzusatzversicherungen für die Generation 60+",
      "Zahnzusatzversicherungen für die Generation 60+"
    );
  });
</script>
