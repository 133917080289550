<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h1>Zahnzusatzversicherung Test - Stiftung Warentest 6/2022</h1>

        Alle 1-2 Jahre analysiert Stiftung Warentest / Finanztest
        Zahnzusatzversicherungen. In der aktuellen Ausgabe 6/2022 wurden dazu
        267 Zahn-Tarife unter die Lupe genommen. Davon wurden 111
        Zahnzusatzversicherungen mit “sehr gut” bewertet, 26 Zahnzusatz-Tarife
        als Testsieger mit Note 0,5 ausgezeichnet.
        <br /><br />
        Der folgende Artikel stellt anhand des Testsieger-Tarifes von Stiftung
        Warentest - Zahnzusatzversicherung Advigon, Tarif AZL (Dental Luxus) die
        Bewertungsunterschiede von Stiftung Warentest / Finanztest Test 2022 und
        der WaizmannTabelle Realwert-Methode gegenüber.
        <br /><br />
        <v-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Tarif</th>
                <th class="text-left">RealWert Methode</th>
                <th class="text-left">Stiftung Warentest Note</th>
                <!-- <th class="text-left">
                    Leistung Zahnersatz
                  </th>
                  <th class="text-left">
                    Leistung Zahnerhalt
                  </th> -->
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Advigon Dental Luxus (AZL)</td>
                <td>89 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Astra Zahn Sieger</td>
                <td>100 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Barmenia Mehr Zahn 100</td>
                <td>57 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Barmenia Mehr Zahn 100 + Mehr Zahnvorsorge Bonus</td>
                <td>100 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Berlin Direkt DZL</td>
                <td>82 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Continentale CEZE</td>
                <td>100 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>DA Direkt Zahnschutz Premium Plus</td>
                <td>100 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>
                  Deutsche Familienversicherung DFV-ZahnSchutz Exklusiv 100
                </td>
                <td>100 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Die Bayerische Zahn Prestige</td>
                <td>100 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Die Bayerische Zahn Prestige Plus</td>
                <td>100 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Europa E-ZP</td>
                <td>57 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Europa E-ZP + E-ZB</td>
                <td>100 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Gothaer MediZ Duo 100</td>
                <td>97 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Huk-Coburg ZZ Pro100</td>
                <td>100 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Münchener Verein ZahnGesund 100</td>
                <td>100 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Nürnberger Z100 (Komfort 100)</td>
                <td>96,5 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Ottonova Zahn 100</td>
                <td>100 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Ottonova Zahn 100+</td>
                <td>100 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>SDK Zahn 100</td>
                <td>96,5 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Signal Iduna Zahn Exklusiv</td>
                <td>100 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Signal Iduna Zahn Exklusiv pur</td>
                <td>100 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>VRK ZZ Exclusive</td>
                <td>100 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>WGV Plus</td>
                <td>88 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Württembergische Zahnersatz 100</td>
                <td>57 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Württembergische Zahnersatz 100 + Zahnbehandlung</td>
                <td>94 %</td>
                <td>0,5</td>
              </tr>
              <tr>
                <td>Württembergische ZahnSchutz Premium</td>
                <td>99 %</td>
                <td>0,5</td>
              </tr>
            </tbody>
          </template>
        </v-table>

        <br />
        <br />
        <h2>
          Exemplarische Tarifanalyse des Stiftung Warentest Testsieger-Tarif
          Zahnzusatzversicherung Advigon AZL (Dental Luxus)
        </h2>
        Die Zahnzusatzversicherung von Advigon, Tarif AZL (Dental Luxus) geht
        bei Stiftung Warentest als absoluter Testsieger - bester Tarif mit einer
        Note von 0,5 hervor. WaizmannTabelle kommt dabei zu einem etwas anderen
        Ergebnis. Der Tarif würde bei einer Analyse mittels WaizmannTabelle
        Realwert-Methode auf Platz 25 gelistet werden.
        <br />
        <br />

        <h3>Analyse nach durchschnittlicher Leistungsstärke</h3>
        Begonnen mit der durchschnittlichen Leistungsstärke ist der Advigon AZL
        (Dental Luxus) wie folgt zu verstehen. Die anfänglichen
        Summenbegrenzungen mit max. 1.000,- Euro je 12 Monate - also max. 4.000
        Euro Erstattung in den ersten 48 Monaten ab Vertragsbeginn sind für den
        Warenkorb der RealWert-Methode in Höhe von 4.970,- Euro während der
        ersten vier Jahre nicht ausreichend. Zudem begrenzt der Tarif die
        Leistung für Prophylaxe auf max. 140,- Euro je Versicherungsjahr sowie
        zusätzlich je Behandlung auf max. 70,- Euro. Damit ist die Leistung auf
        max. zwei Prophylaxe-Behandlungen je Jahr begrenzt. Somit kommt der
        Tarif AZL (Dental Luxus) der Advigon auf eine kurzfristigen RealWert von
        lediglich 80% und auf einen langfristigen RealWert von 98% - Damit
        beträgt der RealWert im Durchschnitt 89%.

        <br />
        <br />
        Zusätzlich hat der Tarif einige Haken und Ösen in den Tarifbedingungen
        verankert, die für Versicherte interessant bzw. nachteilig sind. Die
        Erstattung im Bereich Parodontose ist auf bestimmte GOZ-Ziffern
        begrenzt. Das bedeutet, dass die Erstattung für Parodontose zwar
        eigentlich 100% inkl. Kassenleistung beträgt, wenn die falschen
        GOZ-Ziffern abgerechnet werden, weicht die Erstattungshöhe vom
        zugesagten Prozentwert ab. Ähnliches gilt für den Bereich der
        funktionsanalytischen und -therapeutischen Leistungen. Diese sind auf
        den Bereich Zahnersatz begrenzt, geleistet wird aber nur, wenn mind. 5
        Zähne gleichzeitig behandelt worden sind. Ansonsten erbringt der
        Versicherer die Leistung aufgrund fehlender medizinischer Notwendigkeit
        nicht.
        <br /><br />
        <h3>
          Eignung als Kinder Zahnzusatzversicherung (Fokus Kieferorthopädie)
        </h3>
        Für Kinder ist der Advigon AZL (Dental Luxus) vollkommen ungeeignet, da
        kieferorthopädische Leistungen überhaupt nicht im Versicherungsschutz
        des Tarifs enthalten sind.
        <br /><br />
        <h2>
          Unterschiede der Bewertungsverfahren von Stiftung Warentest /
          Finanztest und WaizmannTabelle
        </h2>
        1. Stiftung Warentest bewertet Zahnzusatzversicherungen ausschließlich
        nach der Leistung für Zahnersatz. Nach Ansicht von Finanztest ist das
        die zentrale Leistung einer Zusatzpolice. Die “Zusatzleistungen” aus dem
        Bereich Zahnerhalt bzw. Zahnbehandlung werden nicht bewertet. Es findet
        lediglich eine Aufzählung von “Zusatzleistungen” je Tarif statt, um dem
        Leser die Auswahl von Tarifen bei gleicher Bewertungsnote zu
        erleichtern.
        <br /><br />
        WaizmannTabelle vertritt schon immer die Ansicht, dass die Leistungen
        aus dem Bereich Zahnerhalt bzw. Zahnbehandlung genauso wichtig ist, wie
        die Leistungen für Zahnersatz. Aus diesem Grund finden sich im Warenkorb
        der RealWert-Methode 50% Leistungen aus dem Bereich Zahnersatz und 50%
        Leistungen aus dem Bereich Zahnerhalt bzw. Zahnbehandlung. Denn auch die
        Leistungen für Zahnerhalt bzw. Zahnbehandlung, zu denen beispielsweise
        Wurzel- und Parodontose-Behandlungen gehören, können gerade bei
        Spezialisten oder bei der Verwendung von Spezialgeräten schnell mehrere
        hundert Euro kosten und sind bei den modernen Tarifen zumeist
        mitversichert. Der Versicherte genießt so einen umfassenden Schutz für
        alle Leistungsbereiche und muss nicht warten, bis er Zahnersatz
        benötigt, damit seine Zusatzpolice leistet. Gerade durch die regelmäßige
        Inanspruchnahme von Professionellen Zahnreinigungen amortisiert sich der
        unter Umständen geringfügig höhere Mehrbeitrag oft.
        <br /><br />
        2. Finanztest bewertet die anfänglichen Summenbegrenzungen eines Tarifs
        lediglich mit 10% Prozent extra zusätzlich den vorgegebenen Leistungen,
        für vorhandene Wartezeiten gibt es einen Abzug in Höhe von 0,1 bei der
        Note.
        <br /><br />
        WaizmannTabelle berechnet bei der RealWert-Methode einen kurzfristigen
        und einen langfristigen RealWert. Der kurzfristige RealWert berechnet
        die Erstattung aus dem Warenkorb für die ersten vier Versicherungsjahre,
        bezieht die anfänglichen Summenbegrenzungen eines Tarifs also
        vollständig mit ein. Beim langfristigen RealWert wird die Erstattung aus
        dem Warenkorb für die Leistungsjahre 5-8 berechnet. Die beiden Werte
        werden zusammengezählt und daraus ein Durchschnittswert errechnet.
        <br /><br />
        Anfängliche Summenbegrenzungen haben so einen wesentlich höheren
        Einfluss auf die Gesamtbewertung und zeigen dem Versicherten auf einen
        Blick, wie stark ein Tarif schon in den ersten Jahren der Zugehörigkeit
        leistet bzw. wie gut er auf lange Sicht leistet.
        <br /><br />
        Wartezeiten werden von der WaizmannTabelle nicht in die Bewertung mit
        einbezogen, da diese normalerweise nicht ausschlaggebend sind. Bei
        Abschluss einer Zahnzusatzversicherung sollte man nach Möglichkeit
        ohnehin “gesund” sein, sprich keine Zahndefekte haben, um später im
        Leistungsfall keine Probleme zu bekommen. Wenn dann eine Wartezeit von
        3-8 Monaten gilt, in der noch keine Ansprüche geltend gemacht werden
        können, ist das aus unserer Sicht in den wenigsten Fällen ein Problem.
        Versichert wären die Behandlungen, die während der Wartezeit medizinisch
        notwendig werden nämlich. Soweit die Maßnahme erst nach Ablauf der
        Wartezeit durchgeführt wird, könnte auch eine Erstattung problemlos
        erfolgen.
        <br /><br />
        3. Stiftung Warentest hat im Test 06/2022 drei Modellkunden definiert,
        die bei Abschluss jeweils 43 Jahre alt sind und sich lediglich
        hinsichtlich des Wunsches in der Höhe der Absicherung unterscheiden. Es
        gibt den Kunden “rundum sorglos” mit einer 100%igen Zahnersatz-Leistung
        - hier sind alle Testsieger, Note 0,5 aufgeführt, den Kunden “gut und
        günstig” mit einer 70-80%igen Zahnersatz-Leistung und entsprechend
        günstigem Beitrag sowie den Kunden “Kasse genügt”, der lediglich eine
        Basisabsicherung wünscht und dem die Verdoppelung des Festzuschusses zu
        einem ganz kleinen Beitrag genügt.
        <br /><br />
        WaizmannTabelle führt ausschließlich Tarife, die mindestens 50% RealWert
        haben. Das bedeutet, dass wir die Tarife, die zwar sehr günstig sind,
        aber dafür auch kaum etwas leisten, gar nicht anbieten. Wir treffen hier
        also bereits eine Vorauswahl und verhindern so, dass Kunden auf günstige
        aber leistungsschwache Tarife “hereinfallen”.
        <br /><br />
        <h2>Fazit:</h2>
        Finanztest arbeitet, wie oben schon erwähnt mit “Modellkunden”. Die
        Tarife werden mehr oder weniger gut nach Leistungsstärke bewertet. Die
        individuelle Situation eines Kunden bzw. dessen Vorgeschichte bezogen
        auf die Zahngesundheit werden in keinster Weise mit einbezogen. So sieht
        der Kunde zwar, welche Tarife es gibt und wie stark diese sein mögen. Er
        kann aber nicht sehen, ob der Tarif für ihn überhaupt geeignet oder gar
        abschließbar ist.
        <br /><br />
        Bei WaizmannTabelle werden schon im Vorfeld einer Empfehlung die Angaben
        zum Zahnstatus abgefragt und Tarife ausschließlich anhand der
        Abschließbarkeit überhaupt erst empfohlen. Somit weiß der Kunde sofort,
        dass er bei den empfohlenen Tarifen auch definitiv versichert werden
        kann.
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  const year = ref(new Date().getFullYear());
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Stiftung Warentest  Zahnzusatzversicherung " +
        year.value +
        "  vs. Waizmann-Test",
      "Der grosse Fight der Zahnzusatzversicherung-Tests. Siftung Warentest " +
        year.value +
        "  versus WaizmannWert Test. Wer testet besser? Welches Verfahren liefert die besseren Ergebnisse"
    );
  });
</script>
