<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h2>
          WaizmannTabelle |
          <span style="color: #00bcd4"
            >Krankenhauszusatzversicherung mit Altersrückstellungen</span
          >
        </h2>

        Die Art der Beitragskalkulation einer Krankenhausversicherung hat neben
        dem Eintrittsalter der zu versichernden Person den größten Einfluss auf
        den Beitrag eines solchen Tarifs. Deshalb sind entsprechend kalkulierte
        Krankenhaus-Tarife mit oder ohne Altersrückstellung für Personen
        jüngeren, mittleren oder gehobenen Alters unterschiedlich gut geeignet.

        <LayoutTable class="my-4" tableTyp="krankMitAlter" />

        Im Folgenden eine Übersicht der von uns empfohlenen Krankenhaus-Tarife
        mit Altersrückstellungen:

        <br /><br /><br />

        <br /><br /><br />

        <v-btn
          href="/vergleich/starten/krankenhaus"
          class="button_caps mb-4 text-white"
          style="background-color: #00bcd4"
          >Online-Vergleich für Krankenhaus-Zusatzversicherungen jetzt
          starten</v-btn
        >

        <br /><br />

        Bei stationären Tarifen, die nach Art der Lebensversicherung kalkuliert
        sind, wurden Altersrückstellungen mit einberechnet. Das bedeutet, dass
        sich der Beitrag eines solchen Vertrags zumindest altersbedingt nach dem
        Abschluss nicht mehr ändert.

        <br /><br />

        Da der reine Risikobeitrag bei jüngeren Personen kleiner ist, als bei
        älteren Personen und die Altersrückstellungen angespart werden müssen,
        zahlt man in jüngeren Jahren also mehr an Beitrag, als man müsste, damit
        dann im Alter weniger zu zahlen ist, als es vom Risiko für den
        Versicherer her notwendig wäre. Daher sind die Beiträge von Tarifen mit
        Altersrückstellungen immer höher, als die von Tarifen ohne
        Altersrückstellungen.

        <br /><br />

        <b>Wichtig: </b>Zu einer allgemeinen bzw. kostenbedingten
        Beitragsanpassung kann es auch in Tarifen mit Altersrückstellungen immer
        mal kommen. Eine solche Erhöhung hat dann nichts mit der Art der
        Beitragskalkulation zu tun, sondern mit der Kostenstruktur des
        Versicherers und der Differenz aus Beitragseinnahmen und Ausgaben durch
        berechtigte Ansprüche.

        <br /><br />

        Krankenhauszusatzversicherungen mit Altersrückstellungen sind gerade für
        junge Erwachsene sehr gut geeignet. Der Beitrag ist gerade in jungen
        Jahren noch sehr günstig und man sichert sich frühzeitig und vor allem
        dauerhaft einen geringstmöglichen Beitrag. Zu beachten ist allerdings,
        dass bei einem Abschluss im Kindes- oder Jugendlichenalter trotz
        Kalkulation mit Altersrückstellungen ein Beitragssprung auf den ersten
        Beitrag im Erwachsenenalter stattfinden wird - dabei bleibt es dann
        allerdings.

        <br /><br />

        <v-card class="my-4 pa-4">
          <v-card-title>TIPP vom KV-Spezialisten: </v-card-title>
          <v-card-text>
            Wechselt man den Versicherer aus einem Tarif heraus, der mit
            Altersrückstellungen kalkuliert ist, gehen die bereits angesparten
            Rückstellungen verloren. Man hat dann möglicherweise jahrelang
            unnötig zu viel an Beitrag bezahlt. So ein Wechsel wirkt sich dann
            nachteilig aus und sollte daher vor dem Abschluss eines Vertrags mit
            Altersrückstellungen bereits bedacht werden.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts typeInfo="krank"
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  import LayoutTable from "@/views/site/helper/LayoutTable";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Krankenhauszusatzversicherungen mit Altersrückstellungen",
      "Krankenhauszusatzversicherungen nach Art der Lebensversicherung | ohne altersbedingte Beitragsanpassungen | stabile Beiträge im Alter"
    );
  });
</script>
