<template>
  <div>
    <v-row>
      <v-col cols="12">
        <strong style="font-size: 18px" class="text-blue">
          Gesundheitsfragen im Antrag</strong
        ><br />
        Folgende Gesundheitsfragen werden im Antrag der {{ tarif.un_name }}
        {{ tarif.tarif_title }} Zusatzversicherung gestellt.
        <ul class="my-4">
          <li
            v-for="(frage, index) in fragen"
            :key="frage.antrag_id"
            :index="index"
          >
            <span v-html="frage.antrag_text"></span>
          </li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    fragen: {
      type: Array,
    },
  });
  console.log(props.tarif);
</script>
