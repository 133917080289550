<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h1>
          <span class="text-blue">WaizmannTabelle</span> 8-Jahres
          Realwert-Methode
        </h1>
        <h3>
          1. mathematisch nachvollziehbares Bewertungsverfahren, dass die
          durchschnittliche Erstattung prozentgenau ermittelt.
        </h3>

        Die von uns entwickelte Realwert-Methode ermittelt anhand eines
        standardierten Berechnungsverfahren die Durchschnittserstattung einer
        Zahnzusatzversicherung in konkreten Prozentzahlen. Auf Basis von
        medizinischen Muster-Behandlungsverläufen berechnen wir die tatsächliche
        Erstattung einer Zahnzusatzversicherung. Dabei werden sowohl anfängliche
        Summenbegrenzungen als auch versteckte Einschränkungen berücksichtigt.
      </v-col>
    </v-row>

    <v-row style="max-width: 940px" class="mt-6">
      <v-col cols="12" md="6">
        <v-card elevation="2">
          <v-row>
            <v-col cols="2" class="pl-6 py-2" style="background-color: #f2f2f2">
              <span
                class="text-blue pr-6"
                style="font-size: 40px; font-weight: bold"
                >%</span
              >
            </v-col>
            <v-col class="blueground text-white pa-4">
              <strong style="font-size: 32px">Erwachsene</strong>
              <br />
              Ø-Erstattung Zahnzusatzversicherung (Zahnersatz, Zahnerhalt) für
              <strong>Erwachsene</strong>.
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2"></v-col>
            <v-col cols="10" class="pa-4">
              Der Realwert gibt die durchschnittliche Erstattung für
              Erwachsenen-Zahnzusatzversicherungen in Prozent an. Anhand eines
              realistischen Behandlungsverlaufes über die ersten 8
              Versicherungsjahre, wird die tatsächliche Erstattung für jeden
              einzelnen Tarif ermittelt. Er enthält Leistungen, aus den
              Bereichen Zahnerhalt und Zahnersatz, zu jeweils gleichen Teilen.
              Diese Aufteilung der Leistungsbereich entspricht der täglichen,
              zahnärztlichen Abrechnungspraxis.
              <br />
              <br />
              Durch diesen Bewertungsansatz werden erstmalig nicht nur die
              tariflichen Leistungen sondern auch die Begrenzungen auf Anhieb
              sichtbar.
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card elevation="2">
          <v-row>
            <v-col cols="2" class="pl-6 py-2" style="background-color: #f2f2f2">
              <span
                class="pr-6"
                style="font-size: 40px; font-weight: bold; color: #fecf0c"
                >%</span
              >
            </v-col>
            <v-col style="background-color: #fecf0c" class="text-white pa-4">
              <strong style="font-size: 32px">KFO/Kinder</strong>
              <br />KFO Ø-Erstattung Zahnzusatzversicherung (Kieferorthopädie)
              für <strong>Kinder</strong>.
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2"></v-col>
            <v-col cols="10" class="pa-4">
              Im Gegensatz zur Realwert-Methode für Erwachsene gibt unser neuer
              KFO-Wert die durchschnittliche Erstattung für Kieferorthopädie an.
              Dieser Benchmark gibt erstmalig die kieferorthopädische
              Durchschnittserstattung in exakten Prozent an. Der KFO-Wert wird
              anhand zweier Muster-Behandlungsverläufen mit KIG Einstung 2 und 4
              berechnet. Für Zahnfehlstellungen die mit KIG 2 eingestuft sind
              erstattet die GKV, im Gegensatz zu einer KIG 4 Einstufung, nicht.
              KIG 1-2 Einstufungen sind in der Praxis zwar etwas seltener, dafür
              sind die Eigenanteile wesentlich höher. Stiftung Warentest hat in
              Ihrem letzten Test von 2018 immer noch keine
              Kinder-Zahnzusatzversicherungen bewertet.
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "WaizmannWert prozentgenaue Benchmark-Verfahren für Zahnzusatzversicherungen",
      "Durch die WaizmannWert Benchmark-Verfahren wird die Durchschnittserstattung einer Zahnzusatzversicherung ersichtlich."
    );
  });
</script>
