<template>
  <div>
    <h3>Zu welchem Testergebnis kam Stiftung Warentest?</h3>
    <br />
    Hier finden Sie die Testergebnisse von Stiftung Warentest der
    {{ tarif.un_name }}
    {{ tarif.tarif_title }} Zahnzusatzversicherung. Stiftung Wartentest vergibt
    Schulnoten von 1-6 bzw. von 0.5-6.
    <br />
    <br />
    <table style="max-width: 400px">
      <tr v-for="(test, index) in test" :key="`testberichte${index}`">
        <td style="width: 220px">Test aus dem Jahr: {{ test.test_year }}</td>
        <td style="width: 120px">
          <span class="text-blue">Note: {{ test.test_value }}</span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    test: {
      type: Array,
    },
  });
  console.log(props.tarif);
</script>
