<template>
  <div class="wtb-chat" v-if="guest">
    <v-card class="pa-4">
      <v-row
        ><v-col cols="11"></v-col
        ><v-col cols="1"
          ><v-btn icon @click="berechnungStore.closePopChat">
            <v-icon>mdi-close</v-icon>
          </v-btn></v-col
        ></v-row
      >
      <h2>Zusatzversicherung Expertenchat</h2>

      <div v-if="firstChatCount == 0 && showChatWindow">
        <div>
          Chatten Sie jetzt mit unserem Expertenteam. Wir helfen Ihnen gerne.
          <v-text-field label="Ihr Name" v-model="ChatName"></v-text-field>
          <v-text-field label="Ihre E-Mail" v-model="ChatEmail"></v-text-field>
          <v-alert type="error" v-if="noEmail"
            ><b>Bitte geben Sie Ihre E-Mail an.</b></v-alert
          >
          <v-textarea
            label="ihre Nachricht hier"
            v-model="firstChat"
          ></v-textarea>
          <v-btn outlined color="green" @click="checkEmailStartChat()"
            >hier klicken um Chat starten</v-btn
          >
        </div>
      </div>

      <div
        v-if="
          (guest.guest_chat_unreaded == 1 || showChatWindow) &&
          firstChatCount > 0
        "
        style="max-height: 550px"
      >
        <div>
          <v-textarea
            label="Ihre Nachricht hier"
            v-model="newChat"
            v-on:keyup.enter="addNewChat(newChat)"
          ></v-textarea>
        </div>
        <div
          v-if="!isAnswered && !noAnswered && guest.guest_chat_todo == 0"
          class="pa-4"
        >
          <v-row>
            <v-col cols="12" md="2">
              <v-progress-circular
                indeterminate
                color="green"
              ></v-progress-circular>
            </v-col>
            <v-col cols="12" md="10">
              <b>Verbindung wird hergestellt </b>
              <br />Bitte haben Sie etwas Geduld wir versuchen Sie mit einem
              unseren Zusatz-Experten zu verbinden.
              <br />
            </v-col>
          </v-row>
        </div>
        <div
          v-if="!isAnswered && noAnswered && guest.guest_chat_todo == 0"
          class="pa-4"
        >
          <v-row>
            <v-col cols="12" md="2">
              <v-icon class="green--text">mail</v-icon>
            </v-col>
            <v-col cols="12" md="10">
              <b>Leider keine Verbindung möglich </b>
              <br />Leider sind momentan alle Waizmann-Experten beschäftigt. Wir
              versuchen Ihre Frage später per E-Mail zu beantworten. Sie können
              uns <b class="green--text">jetzt</b> Ihr Anliegen
              <b class="green--text">noch genauer</b> beschreiben.
              <br />
              <br />Wir schicken unsere Antwort an Ihre E-Mail:
              <b class="green--text">{{ ChatEmail }}</b>
            </v-col>
          </v-row>
        </div>
        <div style="max-height: 300px; overflow: scroll">
          <div v-for="chat in chats" :key="chat['.key']" class="mb-4">
            <div v-if="chat.admin_id == 0">
              <span style="font-size: 12px"
                >meine Frage | {{ formatDate(chat.createdAt) }} Uhr</span
              >
              <br />
              <span v-html="linkify(chat.text)"></span>
            </div>

            <div
              v-if="chat.admin_id > 0"
              class="pl-6 py-2"
              style="background-color: #f2f2f2"
            >
              <b>
                <span class="green--text">{{ chat.admin_name }}</span>
                | WaizmannTeam | {{ formatDate(chat.createdAt) }} Uhr
              </b>
              <br />
              <span v-html="linkify(chat.text)"></span>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script setup>
  import { ref, onMounted, watch, watchEffect } from "vue";

  import { useGuestStore } from "@/stores/guest";
  import BaseCalls from "@/services/BaseCalls";
  import { chatsCollection } from "@/components/chat/firebase";
  import { useBerechnungStore } from "@/stores/berechnung";
  const berechnungStore = useBerechnungStore();
  import {
    query,
    where,
    orderBy,
    addDoc,
    doc,
    updateDoc,
    onSnapshot,
  } from "firebase/firestore";

  //   import { collection } from "firebase/firestore";
  const guestStore = useGuestStore();

  const isAnswered = ref(false);
  const noAnswered = ref(false);
  const noEmail = ref(false);
  const chatInsert = ref(false);
  const chats = ref([]);
  const showChatWindow = ref(true);
  const newChat = ref("");
  const firstChat = ref("");
  const firstChatCount = ref(0);
  const ChatEmail = ref("");
  const ChatName = ref("");
  const ChatStart = ref(false);
  const guest = ref({
    guest_id: 0,
    guest_first_chat: "",
    guest_chat_unreaded: 0,
    guest_name: "",
    guest_email: "",
  });

  const guest_id = ref(0);
  const guest_identity_token = ref("");

  // Firestore binding
  //   chats.value = db.collection("chatsCollection");
  const chatsDb = chatsCollection;
  console.log("chatsRef: " + chatsDb);
  //console.log(chatsRef);
  watchEffect(() => {
    if (guestStore.guest && Object.keys(guestStore.guest).length > 0) {
      guest.value = guestStore.guest;
      guest_id.value = guestStore.guest.guest_id;
      guest_identity_token.value = guestStore.guest.guest_identity_token;
      // Führen Sie weitere Aktionen durch, die von den guest-Daten abhängen
      console.log("Guest data is ready:", guest.value);
    }
  });
  onMounted(() => {
    // store.commit("set_chatmode", true);
    console.log("guest::: ");
    // guest.value = guestStore.guest;
    // guest_id.value = guestStore.guest.guest_id;
    // guest_identity_token.value = guestStore.guest.guest_identity_token;
    // getGuest();
  });
  const loadChatMessages = (newGuestId) => {
    console.log("loadChatMessages");
    console.log(
      "newGuestId:",
      newGuestId,
      "Token:",
      guest_identity_token.value
    );
    if (newGuestId && guest_identity_token.value) {
      let q = query(
        chatsDb,
        where("guest_id", "==", parseInt(newGuestId)),
        where("guest_token", "==", guest_identity_token.value),
        orderBy("createdAt", "desc")
      );

      onSnapshot(
        q,
        (snapshot) => {
          console.log("onSnapshot");
          chats.value = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          if (chats.value.length > 0) {
            firstChatCount.value = 10;
          }
        },
        (error) => {
          console.error("Fehler beim Abrufen der Chat-Nachrichten: ", error);
        }
      );
    }
  };
  watch(chats, (newChats) => {
    console.log("watch chats");
    isAnswered.value = false; // Zurücksetzen des Flags vor der Überprüfung
    newChats.forEach((item) => {
      if (item.admin_id > 0) {
        isAnswered.value = true;
        console.log("antwort admin");
      } else {
        console.log("keine  admin antwprt");
      }
    });
  });
  watch(
    () => guest_id.value, // Verwende eine Getter-Funktion, um reaktiv auf Änderungen von guestId.value zu achten
    (newGuestId) => {
      if (newGuestId) {
        // Überprüfe, ob newGuestId vorhanden ist
        loadChatMessages(newGuestId);
      } else {
        console.log("Keine gültige newGuestId vorhanden");
      }
    },
    { immediate: true } // Führe den Watcher sofort aus, um bei der Initialisierung auf bestehende Werte zu reagieren
  );
  function formatDate(date) {
    var d = new Date(date.toDate());
    if (d.getMinutes() < 10) {
      var m = "0" + d.getMinutes();
    } else {
      m = d.getMinutes();
    }
    return d.getHours() + ":" + m;
  }
  function linkify(text) {
    const urlRegex =
      /(\bhttps?:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
    return text.replace(
      urlRegex,
      (url) => `<a href="${url}" target="_blank">${url}</a>`
    );
  }

  function setTimer() {
    if (!isAnswered.value) {
      setTimeout(setAlert, 120000);
    }
  }

  function markTodoInsert() {
    let select = {};
    select.action = "updateGuest";
    select.guest_id = parseInt(guest.value.guest_id);
    select.guest_chat_todo = 1;
    select.guest_email = ChatEmail.value;
    select.guest_name = ChatName.value;
    BaseCalls.postChat(select);
  }

  function setAlert() {
    noAnswered.value = true;
    if (!chatInsert.value) {
      let select = {};
      select.action = "insertTodo";
      select.todo_email = ChatEmail.value;
      BaseCalls.postChat(select);
      markTodoInsert();
      chatInsert.value = true;
    }
  }

  async function resetReload(id) {
    const docRef = doc(chatsDb, "chats", id); // Erstellt eine Referenz zum Dokument mit der ID 'id' in der Kollektion 'chats'
    try {
      await updateDoc(docRef, {
        reloadguest: 0, // Aktualisiert das Feld 'reloadguest' auf 0
      });
      console.log("Chat updated successfully.");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  }

  //   function openWindow() {
  //     showChatWindow.value = true;
  //     guest.value.guest_chat_no = 0;
  //   }

  function getGuest() {
    let select = {};
    select.action = "getGuest";

    const resp = BaseCalls.postChat(select); // Achte darauf, 'await' zu verwenden
    if (resp.data && resp.data.guest) {
      guest.value = resp.data.guest;
      guest_identity_token.value = resp.data.guest.guest_identity_token;
      firstChatCount.value = resp.data.guest.firstChatCount;
      if (guest.value.guest_id > 0 && guest.value.guest_chat_unreaded == 1) {
        resetUnreaded();
        guestStore.setGuest(resp.data.guest);
      }
      if (!ChatName.value) {
        ChatName.value = guest.value.guest_name;
      }
      if (!ChatEmail.value) {
        ChatEmail.value = guest.value.guest_email; // Dies sollte ChatEmail.value zuweisen, nicht ChatName.value
      }
    }
  }

  function resetUnreaded() {
    let select = {};
    select.action = "updateGuest";
    select.guest_id = parseInt(guest.value.guest_id);
    select.guest_chat_unreaded = 0;
    BaseCalls.postChat(select);
  }

  function addNewChat(enterChat) {
    if (enterChat.length > 1) {
      let start = true;
      chats.value.forEach((item) => {
        if (item.reloadguest === 1) {
          start = false;
          getGuest();
          resetReload(item.id);
          addChat();
        }
      });
      if (start) addChat();
    }
  }
  async function addChat() {
    try {
      await addDoc(chatsDb, {
        text: newChat.value,
        guest_token: guest.value.guest_identity_token,
        guest_id: parseInt(guest.value.guest_id),
        guest_ma_id: parseInt(guest.value.guest_ma_id),
        ma_id: parseInt(guest.value.guest_ma_id),
        guest_email: ChatEmail.value,
        guest_name: ChatName.value,
        ma_readed: 0,
        admin_id: 0,
        admin_name: "",
        createdAt: new Date(),
      });
      newChat.value = "";
    } catch (error) {
      console.error("Error adding FIRST document: ", error);
    }
  }

  function checkEmailStartChat() {
    if (ChatEmail.value) {
      addFirstChat();
    } else {
      noEmail.value = true;
    }
  }

  async function addFirstChat() {
    ChatStart.value = false;
    firstChatCount.value = 10;
    let select = {};
    select.guest_id = parseInt(guest.value.guest_id);
    select.guest_first_chat = firstChat.value;
    select.guest_chat_unreaded = 1;
    select.guest_email = ChatEmail.value;
    select.guest_name = ChatName.value;
    select.action = "updateGuest";
    console.log("firstChat: ", select);
    BaseCalls.postChat(select);
    // this.$store.dispatch("chat/updateGuest", this.updateFirst);
    //this.getGuest();
    guest.value.guest_chat_unreaded = 1;
    showChatWindow.value = true;
    try {
      const docRef = await addDoc(chatsDb, {
        firstchat: 1,
        text: firstChat.value,
        guest_token: guest.value.guest_identity_token,
        guest_id: parseInt(guest.value.guest_id),
        guest_email: ChatEmail.value,
        guest_name: ChatName.value,
        admin_id: 0,
        admin_name: "",
        ma_id: 0,
        ma_readed: 0,
        createdAt: new Date(),
      });
      console.log("Document FIRST ID: ", docRef.id);
    } catch (error) {
      console.error("Error adding FIRST document: ", error);
    }

    setTimer();
    newChat.value = "";
  }

  onMounted(() => {
    guest.value = guestStore.guest;
  });
</script>
