<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h2>Impressum der WaizmannTabelle</h2>
        <v-row>
          <v-col cols="12">
            Die Waizmanntabelle wird von der WaizmannTabelle GmbH betrieben. Die
            WaizmannTabelle GmbH ist ein Familienbetrieb mit Sitz in München.
            Sie wurde 1995 von Dipl.-Kfm. Hans Waizmann gegründet.
            <br />Wir beschäftigen uns ausschließlich mit der Analyse und der
            Bewertung von Zahnzusatzversicherungen. Mittlerweile sind wir die
            Nr. 1 in Deutschland im Bereich der Zahnzusatzversicherungen und
            werden von mehr als 10.000 Zahnärzten regelmäßig empfohlen.
            <br />
            <br />
            <b>Kontakt - so erreichen Sie uns:</b>
            <br />www.waizmanntabelle.de
            <br />
            <br />
            <b>Geschäftsführung WaizmannTabelle</b>
            <br />Florian Meier
            <br />
            <br />
            <b>Geschäftsführung </b>
            <br />WaizmannTabelle GmbH <br />Art der Versicherungstätigkeit
            <br />WaizmannTabelle GmbH ist gemäß §93 HGB als Makler tätig
            <br />
            <br />
            <b>Gesellschafter der GmbH</b>
            <br />Florian Meier <br />
            <br />
            <br />
            <b>Postanschrift</b>
            <br />Lohstrasse 56 <br />81543 München
            <br />
            <br />Telefon: 089-31868310 <br />Fax: 089-3162218
            <br />
            <br />Schreiben Sie uns eine E-Mail: <br />info@waizmanntabelle.de
            <br />
            <br />
            <br />
            <b>Finanzierung der Waizmann Webservices</b>
            <br />Die WaizmannTabelle Service-Leistungen sind für unsere Kunden
            kostenlos. Wir erhalten von den meisten angeschlossen Versicherungen
            eine Abschluss-Vergütung. Diese Vergütung hat keinerlei Einfluss auf
            das Ranking und den WaizmannWert.
            <br />
            <br />
            <br />
            <b>Beteiligung an/von Versicherungsunternehmen</b>
            <br />Das Unternehmen besitzt keine direkten oder indirekten
            Beteiligungen oder Stimmrechte am Kapital eines
            Versicherungsunternehmens. Es besitzt auch kein
            Versicherungsunternehmen direkte oder indirekte Stimmrechte oder
            Beteiligungen am Kapital der WaizmannTabelle GmbH.
            <br />
            <br />
            <br />
            <b>Vermittlerregister</b>
            <br />Die WaizmanTabelle GmbH ist Versicherungsmakler mit Erlaubnis
            gemäß §34 d Abs. 1 GewO registriert unter IHK-Nr. D-AZHX-QKS5Z-54.
            <br />
            <br />
            <br />
            <b>Vermögensschadenhaftpflichtversicherung</b>
            <br />Eine den Bestimmungen der EU-Vermittlerrichtlinie
            entsprechende
            Berufshaftpflichtversicherung/Vermögensschadenhaftpflichtversicherung
            liegt vor (über Nassau Versicherungen).
            <br />
            <br />
            <br />
            <b>Zuständige Aufsichtsbehörde</b>
            <br />Industrie- und Handelskammer für München und Oberbayern
            <br />Max-Joseph-Str. 2 <br />80333 München <br />Telefonnummer:
            089-5116-0 <br />Telefax: 089-5116-306 <br />E-mail:
            iszihkmail@muenchen.ihk.de <br />web: www.ihk-muenchen.de
            <br />
            <br />
            <br />
            <b>Anschrift der Schlichtungsstellen</b>
            <br />Bundesanstalt für Finanzdienstleistungsaufsicht (BAFin)
            <br />Graurheindorfer Straße 108, 53117 Bonn <br />Telefon:
            0228-4108-0 <br />Telefax: 0228-4108-1550 <br />web: www.bafin.de
            <br />
            <br />
            <br />
            <b>Versicherungsombudsmann e.V.</b>
            <br />Postfach 080622, 10006 Berlin <br />Telefon: 01804-224424
            <br />Telefax: 01804-224425 <br />web:
            www.versicherungsombudsmann.de
            <br />
            <br />
            <br />
            <b>Ombudsmann für die private Kranken- und Pflegeversicherung</b>
            <br />Leipziger Str. 104, 10117 Berlin <br />Telefon: 01802-550444
            <br />Telefax: 030-20458931 <br />web: www.pkv-ombudsmann.de
          </v-col>
        </v-row></v-col
      >
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "WaizmannTabelle Impressum",
      "WaizmannTabelle Impressum"
    );
  });
</script>
