<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h2>
          WaizmannTabelle |
          <span style="color: #ff8000">Prismenbrille</span>
        </h2>
        <br />
        <h3>
          <v-icon style="color: #ff8000">check</v-icon> TOP
          Brillenversicherungen für Prismenbrillen im Vergleich<br />
          <v-icon style="color: #ff8000">check</v-icon> Prismenbrille Kinder -
          Diese Brillenversicherungen leisten dafür<br />
          <v-icon style="color: #ff8000">check</v-icon> Unsere Erfahrungen mit
          Prismenbrillen <br />
        </h3>
        <br />
        <h3>
          <span style="color: #ff8000">Experten-Rat:</span> Ihr Kind ist
          verhaltensauffällig? - Eine Prismenbrille kann helfen.
        </h3>
        <br />
        <a href="/brillenversicherung_vergleich">
          Alle Brillen-Versicherungen im Vergleich</a
        >
        <br />
        <br />

        <v-btn
          href="/vergleich/starten/brille"
          class="button_caps mb-4"
          style="background-color: #ff8000; color: #fff !important"
          >jetzt Brillenversicherungen online vergleichen</v-btn
        >
        <br />
        <br />

        <h3>Ihr Kind ist verhaltensauffällig?</h3>
        Diesen Satz mussten sich sicherlich schon viel Eltern anhören.
        Heutzutage werden bereits in jungen Jahren schon sehr hohe Anforderungen
        an unsere Kinder gestellt und das wirkliche “Kind-Sein” bleibt immer
        mehr auf der Strecke.
        <br />Unsere Kinder können die daraus resultierende Überforderung nicht
        mehr kompensieren und es kommt zu Problemen in der Schule und Zuhause.
        Wie ich aus eigener Erfahrung weiß, lohnt es sich in solchen Fällen
        nicht gleich die erste Diagnose zu akzeptieren, sondern genauer
        hinzusehen. <br />Winkelfehlsichtigkeit kann eine Ursache für viele
        unterschiedliche Beschwerden sein die Ihr Kind beeinflussen. Zeigt Ihr
        Kind also gewisse Anzeichen wie Auffälligkeiten der Fein- oder
        Grobmotorik, Probleme beim Lesen oder Konzentrationsschwierigkeiten
        macht es Sinn ihr Kind bei einem fachkundigen Augenoptiker auf eine
        Winkelfehlsichtigkeit testen zu lassen. <br />
        Eine Brillenversicherung kann Ihnen bei der Finanzierung der Behandlung
        helfen.
        <br />
        <br />
        <h3>Was ist Winkelfehlsichtigkeit?</h3>
        Winkelfehlsichtigkeit auch Heterophorie genannt ist eine Fehlstellung
        der Augen, die bei einem sehr großen Teil der Menschen vorhanden ist.
        Allerdings hat nur ein kleiner Teil dieser Gruppe wirkliche Beschwerden
        durch diese Winkelfehlsichtigkeit. Warum?<br />Die vorhandene
        Fehlstellung der Augen wird mit viel Kraft durch unsere Augenmuskeln
        (motorisch) und unser Gehirn (sensorisch) ausgeglichen. Vereinfacht
        gesagt, werden die Augen durch die Augenmuskeln in die “richtige
        Stellung” gebracht und das Gehirn lässt die Seheindrücke passend
        miteinander verschmelzen. Diese sogenannte Fusion benötigt jede Menge
        Kraftaufwand und Energie, je nachdem wie stark die Winkelfehlsichtigkeit
        ausgeprägt ist. Manche Menschen haben in diesem Bereich höhere
        Toleranzen als andere. Aus diesem Grund machen sich nur in ca. 15 % der
        Fälle auch Symptome wie zum Beispiel Kopfschmerzen, starke
        Ermüdungserscheinungen, verschwommenes Sehen und Schwindel bemerkbar.
        <br />
        <br />
        <br />
        <h3>Wie lässt sich eine Winkelfehlsichtigkeit behandeln?</h3>
        Es gibt zwei unterschiedliche Ansätze eine Winkelfehlsichtigkeit
        auszugleichen:<br />
        <strong>1. Prismenbrille</strong><br />
        Über ihre prismatische Wirkung kann eine Prismenbrille die Fehlstellung
        der Augen ausgleichen und damit die Kraftanstrengung, für den
        motorischen und sensorischen Ausgleich des Seheindrucks, verringern. Der
        Alltag mit einer Prismenbrille ist damit weniger anstrengend für unsere
        Augen und unser Gehirn.<br />
        <a href="/vergleich/starten/brille">
          Jetzt die geeignete Brillenversicherung für Ihre Prismenbrille
          finden</a
        >
        <br />
        <strong>2. Augentraining</strong><br />
        Im Zusammenhang mit Winkelfehlsichtigkeit wird immer wieder auf
        Augentrainings verwiesen. Durch diese Trainings sollen schwache
        Sehfunktionen gezielt gestärkt werden und damit die Beschwerden einer
        Winkelfehlsichtigkeit verringert werden. Mit diesen Trainings ist
        allerdings ein gewisser zeitlicher Aufwand verbunden.
        <br />
        <br />
        <br />
        <h3>Mögliche Hinweise auf eine Winkelfehlsichtigkeit ihres Kindes</h3>
        Laut der Internationalen Vereinigung für Binokulares Sehen (IVBS)
        könnten die folgenden Anzeichen auf eine Winkelfehlsichtigkeit ihres
        Kindes hinweisen:<br />
        <ul>
          <li>Probleme beim Lesen, Schreiben und Rechnen</li>
          <li>
            Auffälligkeiten der Feinmotorik (z.B. beim Malen, Ausmalen,
            Ausschneiden)
          </li>
          <li>
            Auffälligkeiten der Grobmotorik (z.B. beim Ballspielen,
            Fahrradfahren, Treppensteigen)
          </li>
          <li>Anstrengungsbeschwerden (z.B. Augenreiben, Augenschmerzen)</li>
          <li>Kopfschmerzen</li>
          <li>
            Konzentrationsschwierigkeiten (z.B. schnelles Ermüden beim Lesen)
          </li>
          <li>
            Verhaltensauffälligkeiten (AD(H)S, Zappelphilipp, Klassenclown,
            Träumer)
          </li>
        </ul>
        <br />
        <br />
        <h3>Winkelfehlsichtigkeit bei Kindern</h3>
        Wie ich aus eigener Erfahrung berichten kann, werden besonders Kinder
        mit einer Winkelfehlsichtigkeit vor besondere Herausforderungen
        gestellt. Zum einen brauchen Kinder einen großen Teil ihrer Energie für
        ihr körperliche und geistige Entwicklung. Zum anderen verbringen Kinder
        im Gegensatz zu Erwachsenen einen Großteil ihres Tages in der Schule und
        können damit ihren Tagesablauf und den Anspruch, der an sie gelegt wird
        nur wenig beeinflussen. Das heißt sie müssen längere Zeit ruhig dasitzen
        und sich darauf konzentrieren, was der Lehrer sagt oder an die Tafel
        schreibt. Sie können damit ihren Alltag nicht an ihre individuellen
        Bedürfnisse (physische und intellektuelle Leistungsfähigkeit) anpassen.
        Das führt grundsätzlich dazu, dass einige Kinder überfordert bzw.
        unterfordert sind. Kommt dann noch eine Winkelfehlsichtigkeit hinzu, die
        ihr Kind motorisch und sensorisch ausgleichen muss, geraten einige
        Kinder an ihre Grenzen und werden damit auffällig. Oft steht dann auch
        der Verdacht auf ADS (Aufmerksamkeits Defizit Syndrom) oder ADHS
        (Aufmerksamkeitsdefizit-Hyperaktivitätsstörung) im Raum.
        <br />
        <br />
        <br />
        <div id="app">
          <div class="text--primary">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card :elevation="hover ? 24 : 6" class="mx-auto pa-2">
                  <h3>
                    Winkelfehlsichtigkeit meines Sohnes |
                    <span style="color: #ff8000"
                      >Erfahrungsbericht eines Vaters</span
                    >
                  </h3>
                  <br />
                  Auch unser Sohn wurde verhaltensauffällig. Je mehr Struktur
                  und Leistungsdruck in der jeweiligen Klassenstufe Einzug
                  hielt, desto auffälliger wurde er. Er hatte
                  Konzentrationsschwierigkeiten und störte immer öfter den
                  Unterricht. So wurde uns in der 2ten Klasse ein Besuch beim
                  Schulpsychologen vorgeschlagen. Das führte dazu, dass unser
                  Sohn noch weitere Aufgaben bekam. Neben den täglichen
                  Hausaufgaben hatte mein Sohn zusätzliche Übungsaufgaben um
                  seine “Schulischen Defizite”, die vermeintlich zu der
                  Überforderung geführt hatten, auszugleichen. Das verschärft
                  die Situation noch weiter.<br />
                  Unser Heilpraktiker empfahl uns unseren Sohn auf eine
                  Winkelfehlsichtigkeit testen zu lassen. Ein Besuch beim
                  Optiker unseres Vertrauens brachte dann die
                  <strong>Lösung: Eine Prismenbrille,</strong> die seine
                  Winkelfehlsichtigkeit ausgleichte.<br />
                  Nach einer Eingewöhnungszeit von ca. 3 Monaten, in der wir ihn
                  immer wieder an das Tragen der Prismenbrille erinnern mussten,
                  wurde mein Sohn im Unterricht ruhiger und sein Gemütszustand
                  viel ausgeglichener.<br />
                  Nach weiteren 3 Monaten wurden seine Leistungen besser und
                  mein Sohn begann von sich aus zu Lesen. Seitdem ist sein
                  Verhalten und seine schulischen Leistungen stabil auf einem
                  guten Niveau. Damit war die Quelle für die Auffälligkeit
                  meines Sohnes gefunden.
                  <br />
                </v-card>
              </template>
            </v-hover>
          </div>
        </div>
        <br />
        <br />
        <h3>Wieviel kostet eine Prismenbrille?</h3>
        Eine Prismenbrille ist eine Spezialanfertigung und deshalb teurer als
        eine normale Brille. Je nach Optiker kann der Aufpreis für eine
        Prismenbrille zwischen ca. 70 € und 210 € liegen. Das heißt für eine
        Mehrstärken-Brille, die man am Arbeitsplatz oder in der Schule benötigt,
        können sich die Kosten auf 250 € bis 600 € belaufen. Zudem empfehlen
        einige Optiker nach einer gewissen Zeit eine Anpassung der
        Prismenbrillen, was zu zusätzlichen Kosten führt.
        <br />
        <br />
        <br />
        <h3>
          Leistet die gesetzliche Krankenkasse für die Behandlungs-Kosten einer
          Winkelfehlsichtigkeit?
        </h3>
        Da sowohl die Winkelfehlsichtigkeit selbst, wie auch die Korrektion mit
        einer Prismenbrille - trotz der vielen Erfolge in der Praxis - weiterhin
        umstritten ist, sind für die Kosten eine Prismenbrille keine extra
        Leistungen im Leistungskatalog der Gesetzlichen Krankenkasse
        vorgesehen.<br />
        Die GKV leistet deshalb im üblichen Rahmen für Personen über 18 Jahren
        nur dann, wenn entweder eine Kurz- oder Weitsichtigkeit mit mehr als 6
        Dioptrien besteht, oder bei einer Hornhautverkrümmung von mehr als 4
        Dioptrien.<br />
        Bei Kindern leistet die gesetzliche Krankenkasse pro Brillenglas
        zwischen 10 € und 112 € für eine Fehlsichtigkeit. Für das Brillengestell
        gibt es keinen Zuschuss der GKV. <br />
        Um nicht einen großen Teil der Kosten für eine Prismenbrille selbst
        tragen zu müssen, macht es Sinn eine Brillenversicherung abzuschließen,
        die für Prismenbrillen leistet. Allerdings leisten nicht alle privaten
        Brillenzusatzversicherungen für die Kosten einer Prismenbrille. In
        vielen Fällen ist die Erstattung von einer ärztlichen Verordnung bzw.
        von einer neben der Winkelfehlsichtigkeit existierenden "klassischen
        Fehlsichtigkeit" abhängig.<br />
        <a href="/vergleich/starten/brille">
          Jetzt Prismenbrille über eine Brillenversicherung finanzieren</a
        >
        <br />
        <br />
        <br />
        <div id="app">
          <div class="text--primary">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card :elevation="hover ? 24 : 6" class="mx-auto pa-6">
                  <h2>
                    <span style="color: #ff8000">Prismenbrille - </span>Auf
                    einen Blick
                  </h2>
                  <br />
                  <strong>1.</strong> Eine Prismenbrille hilft dabei die
                  Kraftanstrengung, für den motorischen und sensorischen
                  Ausgleich des Seheindrucks, zu verringern.<br />
                  <strong>2.</strong> Eine Prismenbrille kostet zwischen 70 €
                  und 600 € <br />
                  <strong>3.</strong> Im Leistungskatalog der GKV sind keine
                  Extraleistungen für Prismenbrillen vorgesehen <br />
                  <strong>4.</strong> Die Beschwerden einer
                  Winkelfehlsichtigkeit können sich auf unterschiedlichste Weise
                  zeigen <br />
                </v-card>
              </template>
            </v-hover>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.isDesktop()"
        ><MenueRechts typeInfo="brillen"
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Die besten Brillenversicherungen für Prismenbrillen im Test",
      "Online-Vergleich der TOP Brillenversicherungen für Prismenbrillen. Diese Brillenversicherungen leisten für Winkelfehlsichtigkeit bei Kindern."
    );
  });
</script>
