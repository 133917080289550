<template>
  <div style="position: fixed; top: 160px; width: 160px">
    <v-row v-if="typeInfo == 'krank'">
      <v-col cols="12"
        ><h2 class="text-cyan">Krankenhaus</h2>
        <h3>Zahnzusatzversicherung</h3>
        <ul>
          <li>
            <router-link to="/krankenhauszusatzversicherung_vergleich"
              >WaizmannTabelle | Krankenhaus-Zahnzusatzversicherung</router-link
            >
          </li>
          <li>
            <router-link to="/vergleich/starten/krankenhaus">
              "Krankenhaus"-Vergleich starten</router-link
            >
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row v-if="!typeInfo">
      <v-col cols="12"
        ><h2 class="text-blue">WaizmannTabelle</h2>
        <h3>Zahnzusatzversicherung</h3>
        <ul>
          <li>
            <router-link to="/zahnzusatzversicherung_vergleich"
              >Alle getesteten Zahnzusatzversicherungen im
              Vergleichs</router-link
            >
          </li>
          <li>
            <router-link to="/vergleich/starten">
              Vergleich starten</router-link
            >
          </li>
        </ul>
        <br />
        <h3>Zahnzusatz-Infos</h3>
        <ul>
          <li>
            <router-link to="/zahnzusatzversicherung/faq">ZZV-FAQ</router-link>
          </li>
          <li>
            <router-link to="/erklaert">Zahnzusatz-Lexikon</router-link>
          </li>
          <li>
            <router-link to="/pzr_flatrate">Prophylaxe-Flatrate</router-link>
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row v-if="typeInfo == 'kinder'">
      <v-col cols="12"
        ><h2 class="text-yellow">Kinder</h2>
        <h3>Zahnzusatzversicherung</h3>
        <ul>
          <li>
            <router-link to="/kinder"
              >WaizmannTabelle Kinder | die besten
              Kinder-Zahnzusatzversicherung</router-link
            >
          </li>
          <li>
            <router-link to="/vergleich/starten/kind">
              KFO-Vergleich starten</router-link
            >
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row v-if="typeInfo == 'heilpraktiker'">
      <v-col cols="12"
        ><h2 class="text-turk">Heilpraktiker</h2>
        <h3>Zusatzversicherung</h3>
        <ul>
          <li>
            <router-link to="/heilpraktikerversicherung_vergleich"
              >WaizmannTabelle Vergleich
              Heilpraktiker-Zusatzversicherung</router-link
            >
          </li>
          <li>
            <router-link
              to="/heilpraktikerversicherung_faq_haeufig_gestellte_fragen"
              >FAQ`s Heilpraktikerversicherung</router-link
            >
          </li>
          <li>
            <router-link to="/vergleich/starten/heilpraktiker">
              Heilpraktiker-Vergleich starten</router-link
            >
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row v-if="typeInfo == 'brillen'">
      <v-col cols="12"
        ><h2 class="text-orange">Brillen</h2>
        <h3>Zusatzversicherung</h3>
        <ul>
          <li>
            <router-link to="/brillenversicherung_vergleich">
              Alle Brillenversicherungen im Vergleich</router-link
            >
          </li>
          <li>
            <router-link
              to="/brillenversicherung_ohne_Wartezeit_mit_Sofortleistung"
            >
              Brillenversicherungen ohne Wartezeit</router-link
            >
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-col cols="12"
        ><h3>WaizmannTabellen</h3>
        Alle Vergleiche der WaizmannTabelle:
        <ul>
          <li>
            <router-link to="/zahnzusatzversicherung_vergleich"
              >Zahnzusatzversicherungen</router-link
            >
          </li>
          <li>
            <router-link to="/kinder"
              >Kinder/KFO Zahnzusatzversicherungen</router-link
            >
          </li>
          <li>
            <router-link to="/heilpraktikerversicherung_vergleich"
              >Heilpraktiker-Versicherungen</router-link
            >
          </li>
          <li>
            <router-link to="/brillenversicherung_vergleich"
              >Brillenversicherungen</router-link
            >
          </li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  const props = defineProps({
    typeInfo: {
      type: String,
    },
  });
  console.log(props.typeInfo);
</script>
