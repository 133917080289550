<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10"
        ><h2>
          WaizmannTabelle |
          <span style="color: #ff8000"
            >Brillenversicherung ohne Wartezeit mit Sofortleistung</span
          >
        </h2>
        <br />
        <h3>
          <v-icon style="color: #ff8000">check</v-icon> Das sind die
          Brillenversicherung Testsieger ohne Wartezeit mit Sofortleistung<br />
          <v-icon style="color: #ff8000">check</v-icon> Diese Sehhilfe
          Versicherungen leisten ohne anfängliche Begrenzungen<br />
          <v-icon style="color: #ff8000">check</v-icon> Vorgezogene Erstattung
          für die zweite Brille - Brillenversicherungen mit Rumpfjahr<br />
        </h3>
        <br />
        <h3>
          <span style="color: #ff8000">Experten-Rat:</span> Achten Sie bei der
          Auswahl der richtigen Brillenversicherung nicht nur auf die Wartezeit
          sondern auch auf die anfänglichen Begrenzungen und profitieren Sie von
          Versicherungen mit Rumpfjahr
        </h3>
        <br />
        <a href="/brillenversicherung_vergleich">
          Alle Brillen-Versicherungen im Vergleich</a
        >
        <br />
        <br />
        <v-btn
          href="/vergleich/starten/brille"
          class="button_caps mb-4"
          style="background-color: #ff8000; color: #fff !important"
          >jetzt Brillenversicherungen online vergleichen</v-btn
        >
        <br />
        <br />
        <!-- Tabelle # / Tarifname / bri_ww / bri_wartezeit /
          bri_begrenzung_zusatz_erste_jahre / Preis 19 Jahre -->
        <h3 class="title">
          Brillenversicherung |
          <span style="color: #ff8000"
            >Wartezeit & Anfängliche Leistungsbegrenzugen im Vergleich</span
          >
        </h3>
        <LayoutTable class="my-4" tableTyp="brilleWarte" />
        <v-simple-table style="max-width: 666px" dense>
          <br />
          <thead>
            <tr>
              <th style="max-width: 10px">#</th>
              <th>Tarif</th>

              <th>Ø-Erstattung</th>
              <th>Wartezeit</th>
              <th>Anfängliche Begrenzungen</th>
            </tr>
          </thead>
          <tbody>
            <tr
              style="vertical-align: top"
              v-for="tarif in alleTarife"
              :key="tarif.id"
            >
              <td>{{ tarif.rank }}.</td>
              <td>
                <a :href="tarif.url">
                  <strong>{{ tarif.versicherung }}</strong>
                </a>
                <br />
                {{ tarif.tarif }}
              </td>
              <td>
                <strong>{{ tarif.waizmannwert }}</strong>
              </td>
              <td>{{ tarif.bri_wartezeit }}</td>
              <td>{{ tarif.bri_begrenzung_zusatz_erste_jahre }}</td>
              <td v-html="tarif.preis"></td>
            </tr>
          </tbody>
        </v-simple-table>
        <br />
        <br />
        <h3>
          Brillenversicherung ohne Wartezeit mit Sofortleistung - Darauf sollten
          Sie achten!
        </h3>
        Die meisten Brillenversicherungen sehen keine Wartezeiten vor, sondern
        leisten sofort für eine neue Markenbrille, Sonnenbrille oder
        Kontaktlinsen. Allerdings muss man darauf achten, dass das Datum für den
        Bezug der neuen Brille nach dem Versicherungsbeginn liegt.<br />
        Das heißt man sollte zuerst eine Brillen-Versicherung abschließen und
        warten bis man den Versicherungsschein erhalten hat. Erst dann sollte
        man zum Optiker oder Augenarzt gehen, um eine neue Brille zu
        bestellen.<br />
        <strong>Sie haben schon eine Brille? Kein Problem!</strong><br />Geben
        Sie in unseren
        <a href="/vergleich/starten/brille"> Online-Rechner</a> ein, dass Sie
        bereits eine Brille tragen. Sie erhalten innerhalb weniger Augenblicke
        die für Sie passende Empfehlung. Schließen Sie eine Brillenversicherung
        ab und warten Sie auch hier bis Sie die Police für Ihre neue
        Brillenversicherungen in Händen halten. Gehen Sie erst dann zum
        Augenarzt oder Optiker, um eine neue Brille zu bestellen.
        <br />
        <br />
        <div id="app">
          <div class="text--primary">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card :elevation="hover ? 24 : 6" class="mx-auto pa-2">
                  <span style="color: #ff8000">Info: </span>Innerhalb der
                  anfänglichen Wartezeit einer Brillenversicherung besteht schon
                  Versicherungsschutz, allerdings kann noch keine Leistung in
                  Anspruch genommen werden.
                </v-card>
              </template>
            </v-hover>
          </div>
        </div>
        <br />
        <br />
        <h3>
          Volle Leistungen von Anfang an - Brillenversicherungen ohne
          anfängliche Begrenzungen
        </h3>
        Auch wenn eine Brillenversicherung sofort und ohne Wartezeit leistet,
        können diese Leistungen in den ersten 24 Monaten begrenzt sein. Einige
        Brillenversicherungen begrenzen ihre Leistungen in den ersten 12 bis 24
        Monaten generell für alle tariflichen Leistungen oder für einen
        bestimmten Leistungsbereich (z.B. Sehhilfen oder LASIK).<br /><br />
        <strong>Beispiel:</strong><br />
        Die Ergo begrenzt die Erstattung für Sehhilfen im AugenPremium (AZB+AZE)
        in den ersten 12 Monaten auf 100 € und in den ersten 24 Monaten auf
        insgesamt 200 €.<br />
        Achtung: Auch wenn der Tarif alle 2 Versicherungsjahre eine max.
        Erstattung für Sehhilfen von 300 € vorsieht erhalten Sie in den ersten
        beiden Jahren nur maximal 200 €.
        <br />
        <br />
        <strong
          >Diese Brillenversicherungen leisten ohne anfängliche Begrenzungen für
          Sehhilfen:</strong
        >
        <ul>
          <li>
            <a href="/brillenversicherung/id/227">UKV VorsorgePrivat</a>
          </li>
          <li>
            <a href="/brillenversicherung/id/234"
              >Concordia Sehen + Hören AZSH</a
            >
          </li>
          <li>
            <a href="/brillenversicherung/id/232"
              >DKV KombiMed Hilfsmittel KHMR</a
            >
          </li>
          <li>
            <a href="/brillenversicherung/id/224">R+V Blick+Check premium</a>
          </li>
          <li>
            <a href="/brillenversicherung/id/274">Barmenia Mehr Sehen</a>
          </li>
        </ul>
        <br />
        <br />
        <h3>
          Brillenversicherungen mit Rumpfjahr - Vorgezogene Erstattung für die
          zweite Brille
        </h3>
        Brillenversicherungen mit einem sogenannten Rumpfjahr haben den Vorteil,
        dass Sie bereits vor Ablauf der ersten 24 Monate ein erneutes Brillen
        Budget erhalten.<br />
        <strong>Wie funktioniert das?</strong><br />
        Brillenversicherungen lassen sich auf Basis ihres Leistungszeitraums
        unterscheiden. Manche stellen alle 24 Monate ein neues Brillen Budget
        zur Verfügung, andere nach 2 Kalenderjahren.
        <br />
        Der Unterschied liegt darin, dass Sehhilfeversicherungen die auf Basis
        von Kalenderjahren leisten das Kalenderjahr in dem die Versicherung
        abgeschlossen wurde voll anrechnen, egal in welchem Monat der Vertrag
        geschlossen wurde.
        <br />
        <br />
        <div id="app">
          <div class="text--primary">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card :elevation="hover ? 24 : 6" class="mx-auto pa-2">
                  <span style="color: #ff8000">Erstattungs-Beispiel: </span
                  ><br />Sie schließen zum 01.10.2022 den Brillentarif
                  <a href="/brillenversicherung/id/227">
                    VorsorgePrivat der Union Krankenversicherung</a
                  >
                  ab.<br />
                  Nach Erhalt der Police besorgen Sie sich eine neue
                  Markenbrille und reichen die Rechnung (Rechnungsdatum 12.2022)
                  bei der Versicherung ein.<br />
                  Die Erstattung der Brille wird damit dem Kalenderjahr 2022
                  zugerechnet und Sie haben bereits ab 01.01.2024 erneuten
                  Anspruch auf eine maximale Erstattung für Sehhilfen von 400 €.
                  Das heißt Sie zahlen 201,75 € (15 Monatsbeiträge in Höhe von
                  13,45 €) und erhalten eine maximale Erstattung für Sehhilfen
                  (für 2022 und 2024) in Höhe von 800 €.
                </v-card>
              </template>
            </v-hover>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts typeInfo="brillen"
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  import LayoutTable from "@/views/site/helper/LayoutTable";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Brillenversicherungen ohne Wartezeit mit Sofortleistungs",
      "Vergleichen Sie jetzt die besten Brillenversicherungen ohne Wartezeit und anfängliche Leistungsbegrenzungen"
    );
  });
</script>
