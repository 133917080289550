<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h1>WaizmannTabelle | <span class="text-pink">PZR-Flatrate</span></h1>
        <h3>
          Zahnzusatzversicherungen mit PZR-Flatrate im großen Waizmann-Test
        </h3>
        <br />
        Zahnzusatzversicherungen gibt es mittlerweile auch mit PZR Flatrate.
        Unser Experten-Team hat die besten Zahnzusatzversicherungen mit
        PZR-Flatrate ausgewertet und in einer Liste zusammengestellt.

        <LayoutTable class="my-4" tableTyp="pzrFlat" />
        <h2>Was ist eine PZR-Flatrate?</h2>

        Mehr und mehr Zahnzusatzversicherungen bieten mittlerweile Tarife mit
        PZR-Flatrate. Aber was ist unter einer PZR-Flatrate zu verstehen?
        <br /><br />
        PZR-Flatrate bei einer Zahnzusatzversicherung bedeutet, dass für alle
        professionellen Zahnreinigungen die Erstattung in voller Höhe der PZR
        Rechnung von der Zahnzusatzversicherung übernommen wird. Leistungen für
        eine professionelle Zahnreinigung sind also weder auf einen jährlichen
        Betrag / noch auf eine Anzahl an PZR Behandlungen limitiert. Bei
        gegebener medizinischer Notwendigkeit, welche der Zahnarzt festlegt,
        können beliebig viele PZR-Behandlungen pro Jahr durchgeführt werden.
        Erstmalig ist so eine volle Kostenübernahme sämtlicher PZR-Behandlungen
        möglich.

        <v-divider :thickness="2" class="my-4"></v-divider>
        <h2>
          Brauche ich eine Zahnzusatzversicherung mit PZR-Flatrate überhaupt?
        </h2>

        Wir sagen ja. Warum also eine Zahnzusatzversicherung mit Begrenzung bzw.
        mit niedrigem PZR-Budget abschließen? Die meisten Zahnärzte empfehlen
        zwei Zahnreinigungen pro Jahr. Eine professionelle Zahnreinigung kostet
        mittlerweile durchschnittlich 80,- bis 140,- Euro. Und bei zwei PZR
        Behandlung von z.B. je 120,- € ist das Budget der meisten
        Zahnzusatzversicherungen oft auch schon erschöpft. Und wenn doch mal
        z.B. wegen einer Zahnfleischentzündung, eine dritte oder vierte PZR
        nötig ist? Demnach raten wir dazu, eine leistungsstarke
        Zahnzusatzversicherung mit PZR-Flatrate abzuschließen.
        <v-divider :thickness="2" class="my-4"></v-divider>
        <h2>Die professionelle Zahnreinigung damals und heute</h2>

        Im Vergleich zum Jahr 2010 fragen unsere Kunden heute gezielt nach
        starken bzw. bestmöglichen Leistungen für die zahnärztliche Prophylaxe.
        Ein hohes PZR-Budget wird unserer Meinung künftig sogar noch wichtiger
        werden, da es mittlerweile auch Zahnzusatzversicherungen mit
        PZR-Flatrate gibt.
        <br />
        Versicherungen haben die Entwicklung hin zu einem hohen PZR-Budget
        erkannt und tragen diesem Trend Rechnung. Neue, also moderne
        Zahnzusatzversicherungen sind daher auch meist mit einem PZR-Budget von
        durchschnittlich 200 bis 300 Euro pro Jahr oder mit einer PZR-Flatrate
        ausgestattet.
        <v-divider :thickness="2" class="my-4"></v-divider>
        <h2>
          AXA DENT Premium-U und Barmenia Mehr Zahn 100 + Mehr Zahnvorsorge
          Bonus im PZR-Budget Vergleich
        </h2>
        Hatte eine leistungsstarke Zahnzusatzversicherung, wie z. B. der Tarif
        AXA DENT Premium-U, im Jahr 2010 eine tarifliche PZR-Leistung in Höhe
        von 120,- Euro pro Kalenderjahr, bieten in 2024 die Barmenia Mehr Zahn
        100 + Mehr Zahnvorsorge Bonus eine unbegrenzte PZR Leistung je
        Kalenderjahr, sprich eine PZR-Flatrate. Der Tarif bietet ohne Wartezeit
        eine PZR-Leistung in Höhe von 100 % ohne Begrenzung hinsichtlich eines
        Höchstbetrags oder einer max. Anzahl an Behandlungen pro Jahr.
        <br /><br />

        <v-table style="max-width: 700px">
          <thead>
            <tr>
              <th>Tarif</th>
              <th>Alter Kunde</th>
              <th>mtl. Beitrag</th>
              <th>PZR-Leistung</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b>AXA DENT Premium-U</b><br />
                aus dem Jahr 2010
              </td>
              <td>25 Jahre</td>
              <td>28,35 €</td>
              <td>120,- € pro Kalenderjahr</td>
            </tr>
            <tr>
              <td>
                <b>Barmenia Mehr Zahn 100 + Mehr Zahnvorsorge Bonus</b><br />
                aus dem Jahr 2024
              </td>
              <td>25 Jahre</td>
              <td>18,50 €</td>
              <td>unbegrenzte PZR Leistung je Kalenderjahr PZR-Flatrate</td>
            </tr>
          </tbody>
        </v-table>
        <br /><br />

        <h2>Was versteht man unter Professioneller Zahnreinigung (PZR)?</h2>
        Die professionelle Zahnreinigung (PZR) zählt zur zahnmedizinischen
        Prophylaxe. Diese wird direkt beim Zahnarzt, meist durch eigens
        geschulte Prophylaxe-Assistentinnen / Dentalhygienikerinnen
        durchgeführt. Die PZR dauert je nach Umfang ca. 50 Minuten.
        <br />
        Während der umfassend mechanischen Zahnreinigung werden die Zähne und
        das Zahnfleisch professionell von Zahnbelag und Zahnstein gereinigt, was
        meist durch eigenes Zähneputzen in dieser Gründlichkeit nicht erreicht
        werden kann. Sinn der professionellen Zahnreinigung ist es die eigenen
        Bemühungen beim Zähneputzen zu unterstützen, um Karies und parodontalen
        Krankheiten vorzubeugen.
        <v-divider :thickness="2" class="my-4"></v-divider>
        <h2>Ist eine professionelle Zahnreinigung sinnvoll?</h2>
        Ja, eine professionelle Zahnreinigung ist sinnvoll. Beim Zahnarzt werden
        Zähne und Zahnfleisch, wie der Name sagt, professionell gereinigt. So
        wird Karies, Mundgeruch, einer Zahnfleischentzündung bis hin zu
        Parodontitis vorgebeugt. Die professionelle Zahnreinigung ist somit eine
        sinnvolle Ergänzung zur häuslichen Mundhygiene.
        <br />
        <v-card outline="1" class="my-4">
          <v-card-text>
            <b>Unser Tipp:</b> Sprechen Sie als Patient einfach mit Ihrem
            Zahnarzt über die professionelle Zahnreinigung. Manche Patienten
            haben bereits eine Parodontitis-Behandlung hinter sich und brauchen
            in der Folge oft mehrmals pro Jahr eine PZR. Andere Patienten
            wiederum sind Raucher oder neigen zu Karies. Daher am Besten einfach
            den eigenen Mundhygienestatus mit dem Zahnarzt besprechen. Der
            Zahnarzt teil Ihnen dann mit, wie oft pro Jahr eine PZR bei Ihnen
            sinnvoll ist.
          </v-card-text>
        </v-card>
        <v-divider :thickness="2" class="my-4"></v-divider>
        <h2>Was bedeutet “PZR-Effekt” bei der Zahnzusatzversicherung?</h2>
        Eine PZR kann sich nicht nur im zahnmedizinischen Sinn “lohnen”. Einige
        Patienten gehen ohnehin zwei bis drei Mal pro Jahr zur PZR. Diese ist
        jedoch ohne private Zahnzusatzversicherung in der Regel komplett selbst
        zu bezahlen. Lediglich manche gesetzlichen Krankenkassen zahlen bis zu
        eine PZR jährlich oder begrenzen diese Leistung auf z.B. 50,- Euro
        pauschal pro Jahr. Andere gesetzlichen Krankenkassen bieten eine
        begrenzte Zuzahlung zur PZR Behandlung nur über ein Bonusprogramm.
        <br /><br />
        Eine leistungsstarke Zahnzusatzversicherung (umfassender
        Versicherungsschutz für Zahnersatz, Zahnerhalt und Prophylaxe) kostet
        für eine Person mit 40 Jahren ca. 35,- Euro monatlich an Beitrag. Eine
        professionelle Zahnreinigung kostet durchschnittlich mittlerweile etwa
        120,- Euro pro Behandlung.
        <br /><br />
        Nutzt die versicherte Person die tarifliche Leistunge und geht
        beispielsweise dreimal pro Jahr zur PZR zu jeweils 120,- Euro, ist der
        Jahresbeitrag der privaten Zahnzusatzversicherung schon fast wieder
        ausgeglichen. Zudem sind die Zähne noch einwandfrei in Ordnung und
        ständig versorgt, sprich haben vollen Versicherungsschutz.
        <v-divider :thickness="2" class="my-4"></v-divider>
        <h2>Wie viel kostet eine professionelle Zahnreinigung?</h2>
        Eine professionelle Zahnreinigung kostet durchschnittlich 80,- bis 140,-
        Euro.
        <br /><br />
        Die Kassenzahnärztliche Bundesvereinigung (KZBV) nennt folgende
        Preisspanne der PZR in Deutschland:
        <b
          >“Die PZR kostet etwa 80 bis 120 Euro. Je kürzer die Abstände zwischen
          den jeweiligen Behandlungen sind, desto kürzer ist meist auch die
          Dauer der Behandlung – die Kosten pro Sitzung können sich dann
          ebenfalls verringern.”</b
        >
        Quelle: Kassenzahnärztliche Bundesvereinigung (KZBV) <br /><br />
        Wird die Gebührenordnung für Zahnärzte (GOZ) allerdings voll ausgenutzt,
        kann eine PZR auch noch teurer sein. Die GOZ-Ziffer 1040 für die
        professionelle Zahnreinigung zum Höchstsatz (3,5-facher Faktor)
        abgerechnet kostet dann bei 32 Zähnen (alle Zähne vorhanden inkl.
        Weisheitszähne bei einem Erwachsenen) schon 163,20 Euro.
        <v-divider :thickness="2" class="my-4"></v-divider>
        <h2>
          Zahlt die gesetzliche Krankenkasse für die professionelle
          Zahnreinigung?
        </h2>

        Nein. Die professionelle Zahnreinigung ist eine Privatleistung, welche
        meist komplett aus eigener Tasche zu bezahlen ist. Die professionelle
        Zahnreinigung ist in der Regelversorgung der gesetzlichen Krankenkassen
        (GKV) nicht vorgesehen. Manche gesetzliche Krankenkassen zahlen einen,
        recht überschaubaren Zuschuss für eine PZR dazu - wie oben bereits
        erwähnt entweder einen Pauschalbetrag in Höhe von ca. 50 Euro jährlich
        oder eine PZR pro Jahr.
        <v-divider :thickness="2" class="my-4"></v-divider>
        <h2>
          Wie oft sollte man eine professionelle Zahnreinigung machen lassen?
        </h2>
        Das kommt auf die sog. medizinische Notwendigkeit an. Erstattungsfähig
        sind nämlich grundsätzlich nur medizinisch notwendige
        Prophylaxe-Maßnahmen. Die medizinische Notwendigkeit legt immer der
        behandelnde Zahnarzt fest.
        <br />
        Bei den meisten Patienten ist die professionelle Zahnreinigung ein- bis
        zweimal jährlich medizinisch notwendig. Die PZR soll die häusliche
        Zahnpflege dabei nur unterstützen, nicht ersetzen. Je nach
        Mundhygienestatus des Patienten bzw. Krankheitsvorgeschichte oder
        Lebensweise (Raucher) können auch drei oder vier PZR-Behandlungen
        jährlich medizinisch notwendig sein.
        <v-divider :thickness="2" class="my-4"></v-divider>
        <h2>
          Was Versicherte in einer privaten Zahnzusatzversicherung mit
          Leistungen für die professionelle Zahnreinigung (PZR) wissen müssen:
        </h2>
        <b
          >Was heißt 100 % Erstattung der Kosten einer professionellen
          Zahnreinigung?</b
        >
        <br />
        Die tariflichen Leistungen bei Zahnzusatzversicherungen für die
        professionelle Zahnreinigung betragen meist 100 % Erstattung. Das ist
        zwar richtig, dennoch kann diese Leistung auf einen maximalen
        Höchstbetrag pro Jahr, z.B. 200,- Euro pro Jahr oder auf eine maximale
        Anzahl von Behandlungen pro Jahr, wie z. B. 2 x 100,- Euro pro
        Behandlung im Jahr begrenzt sein. Es gilt genau zu lesen, wie die
        tarifliche Leistung für die PZR aussieht.
        <br /><br />
        <b>Gibt es eine Wartezeit für die professionelle Zahnreinigung?</b>
        <br />

        Die meisten Zahnzusatzversicherungen verzichten für die professionelle
        Zahnreinigung auf eine Wartezeit. Wenn die Zahnzusatzversicherung
        abgeschlossen wurde (das Beginndatum erreicht wurde, der Erstbeitrag
        bezahlt wurde und der Versicherungsschein beim Kunden ist) kann eine PZR
        in Anspruch genommen werden. Eine Wartezeit für die PZR gibt es nur noch
        vereinzelt bei älteren Zahn-Tarifen, die schon länger am Markt sind.
        <br /><br />
        <b
          >Was bedeutet Gebührenposition 1040 GOZ bei der professionellen
          Zahnreinigung?</b
        >
        <br />
        Die professionelle Zahnreinigung ist Bestandteil der Gebührenordnung für
        Zahnärzte (GOZ). Als Privatleistung wird diese mit der Gebührenposition
        1040 GOZ abgerechnet. Die meisten Zahnzusatzversicherungen haben
        tariflich festgelegt, dass die Erstattung einer PZR-Rechnung
        ausschließlich nach dieser GOZ-Ziffer möglich ist.
        <br /><br />
        <b
          >Was bedeutet “pauschale Abrechnung” bei der professionellen
          Zahnreinigung?</b
        >
        <br />
        Neben der Abrechnung einer professionellen Zahnreinigung nach der
        Gebührenposition 1040 GOZ kann eine Zahnzusatzversicherung auch
        zusätzlich die Abrechnung über eine “pauschale Rechnung” anerkennen.
        Hier genügt dann eine einfache Rechnung vom Zahnarzt mit einem
        pauschalen Betrag, aus der lediglich hervorgeht, dass eine
        professionelle Zahnreinigung durchgeführt wurde.
        <br /><br />
        <b
          >Was bedeutet medizinische Notwendigkeit bei der professionellen
          Zahnreinigung?</b
        >
        <br />
        Für die Erstattung einer professionellen Zahnreinigung muss eine
        medizinische Notwendigkeit vorliegen. Rein ästhetische oder kosmetische
        Behandlungen sowie Wunsch- oder Verlangensleistungen können nicht
        erstattet werden.
        <br />
        Demnach kann man nicht einfach eine unbegrenzte Anzahl an
        Prophylaxe-Behandlungen pro Jahr erstattet bekommen. Es muss immer eine
        medizinische Notwendigkeit dafür gegeben sein, die im Fall einer
        Nachfrage auch von Behandler (Zahnarzt) begründet werden können muss.
        Möglicherweise gab es z.B. vor nicht allzu langer Zeit eine
        Parodontitis-Behandlung, welche der Patient hinter sich gebracht hat.
        Aufgrund des dann erhöhten Parodontose-Risikos müssen die Zähne extrem
        sauber gehalten werden, was durch 3-4 PZR-Behandlungen pro Jahr erreicht
        werden soll.
        <br /><br />
        <b
          >Was bedeutet GKV-Vorleistung bei der professionellen Zahnreinigung?
        </b>
        <br />
        Manche gesetzliche Krankenkassen (GKV) bieten einen jährlichen Zuschuss
        für die professionelle Zahnreinigung oder zahlen eine solche Behandlung
        pro Jahr gänzlich. Da die meisten Versicherer auch die Leistung für die
        professionelle Zahnreinigung inkl. einer möglichen Kassenleistung
        erstatten, muss der Versicherer wissen, ob eine Vorleistung seitens der
        gesetzlichen Krankenversicherung erbracht wurde. In diesen Fällen ist es
        notwendig eine eventuelle Vorleistung der privaten
        Zahnzusatzversicherung entsprechend nachzuweisen, bevor eine Erstattung
        erfolgen kann.
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()">
        <h2>Für Zahnärzte</h2>
        Fordern Sie jetzt kostenlos die WaizmannTabelle PZR-Flatrate für Ihre
        Praxis an. Kostenlos.
        <a href="https://www.teamwaizmann.de" target="_blank"
          >Jetzt anfordern</a
        >
        <br /><br />
        <a href="https://www.teamwaizmann.de" target="_blank">
          <img
            :src="require('@/assets/site/togoPzr.png')"
            style="width: 140px; float: left" /></a
      ></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";

  import LayoutTable from "@/views/site/helper/LayoutTable";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(false);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Gibt es eine Zahnzusatzversicherung mit PZR-Flatrate?",
      "Wir haben die besten Zahnzusatzversicherungen mit PZR-Flatrate zusammengestellt. Jetzt unbegrenzte PZR Leistung erhalten. 100 % Erstattung, ohne Limits."
    );
  });
</script>
