<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10"
        ><h2>
          {{ tarif.un_name }} {{ tarif.tarif_title }} {{ thread.comment_title }}
        </h2>
        <em>{{ thread.comment_text }}</em>
        <v-divider :thickness="2" class="my-4"></v-divider>
        <router-link :to="`/zahnzusatzversicherung/id/${tarif.tarif_id}`"
          >ausführliche Tarifbeschreibung {{ tarif.un_name }}
          {{ tarif.tarif_title }} Zahnzusatzversicherung anzeigen
        </router-link>
        <v-divider :thickness="2" class="my-4"></v-divider>
        <span v-html="nl2br(thread.comment_antwort)"></span>
        <div
          v-if="thread.comment_box != ''"
          class="my-4 pa-4"
          style="background-color: #f5f5f5"
        >
          {{ thread.comment_box }}
        </div>
        <v-divider :thickness="2" class="my-4"></v-divider>
        <h3>
          Weitere Fragen und Antworten zur {{ tarif.un_name }}
          {{ tarif.tarif_title }}
        </h3>
        <ul>
          <li v-for="frage in moreThreads" :key="frage.comment_id">
            <a
              :href="`/zahnzusatzversicherung_forum/${tarif.tarif_forum_name}/${frage.comment_speaking_link}`"
              >{{ frage.comment_title }}</a
            >
          </li>
        </ul>
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts
      /></v-col>
    </v-row>
    <div v-if="isLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, watch, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  import BaseCalls from "@/services/BaseCalls";
  import { useRoute } from "vue-router";
  import { useNl2Br } from "@/composable/useNl2Br";

  const { nl2br } = useNl2Br();
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const route = useRoute();
  const tarif = ref(false);
  const thread = ref(false);
  const moreThreads = ref(false);
  const isLoaded = ref(false);

  async function getForum() {
    let select = {};
    select.action = "getForum";
    select.tarif_forum_name = route.params.tarif;
    select.comment_speaking_link = route.params.thread;
    try {
      const resp = await BaseCalls.postMixed(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.tarif) {
        tarif.value = resp.data.tarif;
        thread.value = resp.data.thread;
        moreThreads.value = resp.data.moreThreads;
        isLoaded.value = true;
        proxy.$updateDocumentHead(
          `${tarif.value.un_name} ${tarif.value.tarif_title} ${thread.value.comment_title}`,
          thread.value.comment_meta
        );
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }
  watch(
    () => route.params,
    () => {
      // Die Funktion checkRouteChange wird aufgerufen, wenn sich die Route-Parameter ändern
      getForum();
    },
    { deep: true }
  ); // `deep` und `immediate` stellen sicher, dass bei initialer und tieferer Änderung reagiert wird

  onMounted(() => {
    getForum();
  });
</script>
