<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10"
        ><h2>
          Zahnzusatzversicherung mit Sofortleistung - geplante Behandlungen noch
          versichern
        </h2>
        <em
          >Seit 2021 gibt es Zahnzusatzversicherungen die auch für angeratene
          Implantat-Behandlungen erstatten.
        </em>
        <br />

        Unser Experten-Team hat Zahnzusatzversicherungen analysiert, die
        rückwirkend für eine bereits geplante Implantat-Behandlung leisten.
        Diese Zahnzusatzversicherungen mit Sofortleistung erstatten auch noch
        für eine bereits angeratene Implantat-Behandlung.

        <v-card class="my-8 greytext" max-width="777" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="mb-4" style="font-size: 13px">
                Florian Ferdinand,<br />
                Zahnzusatzversicherungs-Experte der WaizmannTabelle
              </div>
              <v-list-item-title class="mb-1">
                <h3 style="color: #555555">
                  Häufige Fragen aus unseren Kundengesprächen:
                </h3>
              </v-list-item-title>

              <em
                >“Mein Zahnarzt sagt ich brauche ein Implantat. Kann ich das
                Implantat jetzt noch versichern?”
                <b class="green--text">Ja.</b></em
              >

              <br />
              <em
                >“Gibt es Zahnzusatzversicherungen, die für bereits geplante
                Zahnimplantat-Behandlungen leisten?”
                <b class="green--text">Ja.</b></em
              >
              <br />
              <br />
              <b class="greytext"
                >Unsere Experten haben 3 passende Versicherungen mit
                Implantat-Sofortleistung gefunden, das Kleingedruckte geprüft
                und diese für gut befunden.</b
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <h2>Zahnersatz ist teuer, ganz besonders bei Implantaten</h2>
        Eine schon angeratene oder sogar bereits laufende Implantat-Behandlung
        wurde bislang von keiner Zahnzusatzversicherung mehr versichert. Da ein
        alter Versicherungsgrundsatz besagt, dass Leistungsfälle, welche vor
        Versicherungsabschluss eingetreten sind, nicht mehr versichert werden
        können. Dies hat sich zwischenzeitlich zumindest teilweise geändert.
        <br /><br />
        Mittlerweile bieten einige wenige Versicherer
        <b>Zahnzusatztarife mit sog. “Sofort-Option”</b> an. Damit können
        <b
          >bereits angeratene, also geplante Implantat-Behandlungen, rückwirkend
          versichert</b
        >
        werden. Es wird also für diese Zahnimplantat-Behandlung auch noch
        nachträglich geleistet. Der folgende Tarif bietet
        <b>sofortige Leistung, ohne Wartezeiten</b>. <br /><br />
        Wenn bereits ein <b>Heil- und Kostenplan</b> für die
        Implantat-Behandlung erstellt wurde, können Patienten noch eine
        erhebliche Leistung erhalten. Hier bietet die
        <b>Union Krankenversicherung im Tarif ZahnPRIVAT Premium</b> die wohl
        beste Möglichkeit ein Implantat noch im Nachhinein zu versichern. <br />
        <br />
        <h2>
          Beste Zahnzusatzversicherungen ein Implantat nachträglich zu
          versichern
        </h2>

        Folgende Zahnzusatzversicherungen bieten Konditionen, wo selbst noch
        geplante, also angeratene Implantate versichert werden können:
        <ul class="my-4">
          <li>Union Krankenversicherung ZahnPRIVAT Premium</li>
          <li>SDK Zahn 100 (ZP1) bzw. Zahn 90 (ZP9)</li>
          <li>Die Bayerische ZAHN Sofort</li>
        </ul>
        <div class="divider"></div>
        <h2>Union Krankenversicherung ZahnPRIVAT Premium</h2>
        <em
          >Die Union Krankenversicherung ZahnPRIVAT Premium
          Zahnzusatzversicherung ist der derzeit leistungsstärkste Tarif mit
          einer sog. Sofortoption.</em
        ><br />
        Der Tarif ZahnPRIVAT Premium leistet selbst dann noch, wenn bereits eine
        Implantat-Versorgung für fehlende, zu ziehende oder provisorisch
        versorgte Zähne angeraten wurde. Das geht mit bis zu 3 solcher Zähne
        gegen die Vereinbarung eines Risikozuschlags über die gesamte
        Vertragslaufzeit in Höhe von 8,60 Euro je Zahn zusätzlich pro Monat.
        Dabei ist es unerheblich, ob der jeweilige Zahn in der Vergangenheit
        vorbehandelt war oder nicht.<br /><br />
        <b>Wichtig:</b> Es darf zum Zeitpunkt des Abschlusses keine weitere
        Zahnmaßnahme laufen oder angeraten sein (wie z. B. eine
        Wurzelbehandlung). Das funktioniert auch, wenn an dem mitversicherten
        Zahn bereits die Versorgung begonnen hat, eine Erstattung für die vor
        Abschluss durchgeführten Behandlungen gibt es allerdings nicht! <br />
        <br />

        <h2>
          Tarif-Highlights der Union Krankenversicherung ZahnPRIVAT Premium
        </h2>
        <ul>
          <li>
            Leistet für eine bereits geplante / angeratene Implantat-Behandlung
          </li>
          <li>
            Leistet für bis zu 3 Zahnlücken (bereits fehlend oder zu ziehenden
            Zähnen)
          </li>
          <li>
            Leistet bis zu 2.700 € in den ersten beiden Versicherungsjahren (90
            % Zahnersatz-Leistung von max. 3.000,- Euro Rechnungsbetrag)
          </li>
          <li>
            Zusätzlich zum monatlichen Beitrag je nach Alter 8,60€ Aufschlag pro
            fehlendem Zahn
          </li>
          <li>
            <a href="/zahnzusatzversicherung/id/198"
              >ausführliche Tarifbeschreibung Union ZahnPRIVAT Premium</a
            >
          </li>
          <br />
        </ul>
        <v-card class="my-4 greytext pa-4" max-width="777" outlined>
          <h2>
            <span class="green--text"
              >Waizmann Expertentipp: Spät im Jahr abschliessen</span
            >
          </h2>
          Wenn es möglich ist, sollte die Zahnzusatzversicherung der Union
          Krankenversicherung, Tarif ZahnPRIVAT Premium für die
          Implantat-Behandlung möglichst Ende des Kalenderjahres aber spätestens
          zum 01.12. des Kalenderjahres abgeschlossen werden. Dadurch können Sie
          schon früher mehr Erstattung erhalten<br /><br />
          Die Maximalleistung der Union Krankenversicherung ZahnPRIVAT Premium
          umfasst zu Beginn:
          <ul>
            <li>im 1. Kalenderjahr max. 1.000,- Euro Rechnungsbetrag</li>
            <li>
              1.-2. Kalenderjahr max. 3.000,- Euro Rechnungsbetrag, also 2.700 €
              Leistung (90 % Zahnersatz-Leistung von 3.000,- Euro
              Rechnungsbetrag)
            </li>
          </ul>
        </v-card>

        <h2>
          Erstattungsbeispiel: Union Krankenversicherung ZahnPRIVAT Premium
        </h2>
        Wenn Sie die als 40 jährige Person die Union mit einem fehlenden Zahn
        abschließen zahlen Sie 42,08 € + 8,60 € (Zuschlag) pro Monat. Wenn Sie
        den Tarif bis zum 1.12. eines Jahres abgeschlossen haben, müssten Sie
        mind. 13 Monatsbeiträge zahlen. Das wären
        <b>658,84 €</b>. Im 2. Versicherungsjahr könnten Sie für Ihren Beitrag
        bereits eine Erstattung von
        <b class="green--text">2700,-</b> erhalten.<br /><br />
        <div class="divider"></div>
        <h2>Die Bayerische ZAHN Sofort Zahnzusatzversicherung</h2>
        <ul>
          <li>
            Leistet für Zahnersatz und Zahnerhalt Leistung bis zu 750€ pro
          </li>
          <li>Kalenderjahr, max. 1.500€ über die gesamte Vertragslaufzeit</li>
          <li>
            Nur in Verbindung mit einem Haupttarif (Smart, Komfort, Prestige,
            Prestige Plus) abschließbar
          </li>
          <li>
            Zusätzlich zum normalen altersbedingten Monatsbeitrag 29,90€
            monatlich (Der Sofort-Baustein endet automatisch nach 24 Monaten.)
          </li>
          <li>Keine Anrechnung auf die Zahnstaffel des Haupt-Tarifs</li>
          <li>
            Besonderheit: Der Heil- und Kostenplan darf älter als 6 Monate sein
          </li>
        </ul>
        <br />
        <div class="divider"></div>
        <h2>SDK Zahn 100 (ZP1) bzw. Zahn 90 (ZP9)</h2>
        <ul>
          <li>
            Leistet für bis zu 3 Zahnlücken (bereits fehlend oder zu ziehenden
            Zähnen)
          </li>
          <li>
            + 20% Aufschlag pro fehlendem Zahn (ZP1) zum regulären Monatsbeitrag
          </li>
          <li>
            + 20% Aufschlag pro fehlendem Zahn (ZP9) zum regulären Monatsbeitrag
          </li>
          <li>
            Leistungen sind auf 2.000€ (ZP1) bzw. 1.800€ (ZP9) in den ersten
            beiden Versicherungsjahren begrenzt.
          </li>
        </ul></v-col
      >
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Zahnzusatzversicherung für eine geplante Implantat-Behandlung abschließen",
      "Zahnimplantate nachträglich versichern. Jetzt passende Zahnzusatzversicherungen für eine Implantat Behandlung mit Sofortleistung | ohne Wartezeit finden."
    );
  });
</script>
