<template>
  <div>
    <h1>
      {{ tarif.un_name }}
      <span class="text-turk">{{ tarif.tarif_title }}</span>
    </h1>
    <h3>
      Ausführliche Leistungsbeschreibung der {{ tarif.un_name }}
      {{ tarif.tarif_title }} Heilpraktiker-Zusatzversicherung.
    </h3>

    <v-divider :thickness="2" class="my-4"></v-divider>
    <v-row>
      <v-col cols="2" v-if="!sharedDataStore.isMobile()"
        ><div class="turkground px-4 py-8" style="height: 120px">
          <h1 class="text-white">{{ tarif.hp_gesamterstattung_2_4 }} %</h1>
          <span class="text-white"> Ø-Erstattung</span>
        </div>
        <div class="pt-2">
          Tarif-Nr.: <b>{{ tarif.tarif_id }}</b>
        </div>
      </v-col>
      <v-col cols="12" md="7">
        <v-row class="mb-2">
          <v-col cols="12" md="4">
            <strong class="blue--text">Erstattung beim Heilpraktiker</strong>
          </v-col>
          <v-col cols="12" md="8">
            <strong>{{ tarif.hp_naturheilkunde_heilpraktiker }} %</strong>
          </v-col>
        </v-row>
        <v-divider :thickness="1" class="my-2"></v-divider>

        <v-row class="mb-2">
          <v-col cols="12" md="4">
            <div><b>Leistungen für Naturheilkunde</b></div>
          </v-col>
          <v-col cols="12" md="8">
            <ul>
              <li v-for="item in tarif.hp_leistung_array" :key="item">
                {{ item }}
              </li>
            </ul>
          </v-col>
        </v-row>
        <v-divider :thickness="1" class="my-2"></v-divider>

        <v-row class="mb-2">
          <v-col cols="12" md="4">
            <strong class="blue--text"
              >Erstattung beim Naturheilkunde-Arzt</strong
            >
          </v-col>
          <v-col cols="12" md="8">
            <strong>{{ tarif.hp_naturheilkunde_arzt }} %</strong>
          </v-col>
        </v-row>
        <v-divider :thickness="1" class="my-2"></v-divider>

        <v-row class="mb-2">
          <v-col cols="12" md="4">
            <strong class="blue--text">Begrenzungen</strong>
          </v-col>
          <v-col cols="12" md="8">
            <strong>{{ tarif.hp_begrenzungen_hp }}</strong>
          </v-col>
        </v-row>
        <v-divider :thickness="1" class="my-2"></v-divider>

        <v-row class="mb-2">
          <v-col cols="12" md="4">
            <strong class="blue--text">Wartezeit</strong>
          </v-col>
          <v-col cols="12" md="8">
            <strong>{{ tarif.hp_wartezeit }}</strong>
          </v-col>
        </v-row>
        <v-divider :thickness="1" class="my-2"></v-divider>
      </v-col>

      <v-col cols="12" md="3"> </v-col>
    </v-row>
    <v-divider :thickness="2" class="my-4"></v-divider>
  </div>
</template>

<script setup>
  //   import { useBerechnungStore } from "@/stores/berechnung";
  import { defineProps } from "vue";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  //   const berechnungStore = useBerechnungStore();
  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    preise: {
      type: Array,
    },
  });

  console.log(props.tarif.tarif_id);
</script>
