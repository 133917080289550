<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h1>WaizmannTabelle Kinder-Zahnzusatzversicherung</h1>
        <em
          >Die besten 37 Zahnzusatzversicherungen mit Kieferorthopädie für
          Kinder auf einen Blick (aus 204 getesteten Tarifen).</em
        >
        <v-row class="mt-4">
          <v-col cols="12" md="6">
            Die Kinder Zahnzusatzversicherung hat ihren Schwerpunkt bei den
            kieferorthopädischen Leistungen. Unser Tipp: Spätestens mit
            Vollständigkeit des Milchzahngebiss sollten auch die Zähne von
            Kindern optimal abgesichert werden. Dabei haben kleine Patienten
            natürlich andere Anforderungen als große. Hauptaugenmerk muss dabei
            neben Prophylaxe bzw. Zahnerhalt, also der Pflege der ersten Zähne,
            auf die Kieferorthopädie (Spange) gelegt werden.
          </v-col>
          <v-col cols="12" md="6" class="pl-4">
            Da heute fast jedes zweite Kind eine Behandlung beim
            Kieferorthopäden benötigt, ist der Schutz durch eine gute
            Zahnzusatzversicherung mit speziellen Kinder-Leistungen eine
            sinnvolle Investition. Zudem empfehlen Kieferorthopäden meist
            moderne Behandlungsmethoden, die im Leistungskatalog der
            Krankenkasse nicht enthalten sind. So werden die Kosten einer
            Zahnspange dank einer Zahnzusatzversicherung für Kinder bzw.
            Jugendliche deutlich verringert.
          </v-col>
        </v-row>
        <LayoutTable class="my-4" tableTyp="startKindAlle" />
        <h2>Ist eine Zahnzusatzversicherung für Kinder sinnvoll?</h2>
        Ja, wenn sie rechtzeitig abgeschlossen wird. Am besten
        <b class="green--text">vor Beginn des Zahnwechsels</b>.
        <router-link to="/kinder/faq/zahnzusatzversicherung_kinder_sinnvoll">
          <a
            >Kinder Zahnzusatzversicherung sinnvoll? Lesen Sie hier mehr
            dazu....</a
          >
        </router-link>
        <br />
        <br />
        <h2>Wieviel kostet eine Zahnzusatzversicherung für Kinder?</h2>
        Gute Zahnzusatzversicherungen für Kinder kosten bis zu 20,- € monatlich.
        Dabei sollten umfangreiche Leistungen für den KFO-Bereich, möglichst
        sowohl für KIG 1-2 als auch für KIG 3-5 abgedeckt sein. Außerdem sollten
        auch moderne Behandlungsmethoden wie z.B. Invisalign enthalten sein.
        <br />
        <br />
        <b>Soviel kosten die 3 besten Kinder-Zahnzusatzversicherungen</b>
        <br />(Preise für ein 7-jähriges Kind)
        <ul>
          <li>
            ARAG - Dent100 -
            <b>20.29 €</b>
          </li>
          <li>
            ARAG - DEnt90+ -
            <b>18.67 €</b>
          </li>
          <li>
            Union ZahnPRIVAT Premium -
            <b>19.79 €</b>
          </li>
          <li>
            <router-link to="/vergleich/starten/kind">
              <a>Jetzt Preise der besten Kinderversicherungen berechnen...</a>
            </router-link>
          </li>
        </ul>
        <br />
        <br />
        <h2>Gibt es Zahntarife für Kinder ohne Wartezeit</h2>
        <b>Ja</b>, aber das Kriterium Zahnzusatzversicherung ohne Wartezeit ist
        gerade bei Kinder unwichtig.
        <router-link to="/kinder/faq/zahnzusatzversicherung_ohne_wartezeit">
          <a>Lesen Sie hier wieso...</a>
        </router-link>
        <br />
        <br />
        <h2>Kinder-Zahnzusatversicherungen bei Stiftung Warentest</h2>
        Finanztest bietet bislang <b class="red--text">keine</b> Tests zu
        Zahnzusatzversicherungen für Kinder an. WaizmannTabelle ist das derzeit
        einzige Portal, das umfangreiche Tests von Kinder
        Zahnzusatzversicherungen durchführt.
        <br />
        <br />
        <h2>Kieferorthopädie - KIG 1-5 Einstufungen</h2>
        Die Erstattung einer Zahnzusatzversicherung für kieferorthopädische
        Behandlungen hängt von der KIG-Einstufung der Maßnahme ab.
        <router-link to="/kinder/faq/zahnzusatzversicherung_kinder_sinnvoll">
          <a
            >Lesen hier mehr über die kieferorthopädischen
            Indikationsgruppen...</a
          >
        </router-link>
        <br />
        <br />
        <h2>So finden Sie die geeignete Zahnzusatzversicherung für Ihr Kind</h2>
        Um den geeigneten Tarif für eine Kinder Zahnzusatzversicherung zu
        finden, muss man sich zuerst darüber im Klaren sein, was man genau
        absichern möchte. Grundsätzlich gibt es die zwei Möglichkeiten der
        Absicherung nach der Einstufung in die jeweilige kieferorthopädische
        Indikationsgruppe.
        <br />
        <br />Für KIG 1-2 leistet die gesetzliche Krankenversicherung nicht. Im
        Fall einer solchen Behandlung wären also die gesamten Kosten vom
        Versicherten selbst zu tragen. Mit einem Betrag von ca. 6.000 Euro für
        eine solche Behandlung ist das ein sehr hohes Kostenrisiko.
        <br />
        <br />Bei KIG 3-5, also den höheren Schweregraden von
        Zahn-/Kieferfehlstellungen, leistet die Krankenkasse für die
        Behandlungsbestandteile, welche im Leistungskatalog der GKV aufgeführt
        sind. In diesem Fall hat der Versicherte lediglich die Kosten der
        privatärztlichen Mehrkosten zu tragen, die von der gesetzlichen
        Krankenversicherung nicht übernommen werden. Diese Mehrkosten liegen in
        der Regel bei ca. 1.500,- Euro. Statistisch gesehen werden ca. 80 %
        aller ärztlich diagnostizierten Zahn-/Kieferfehlstellungen vom
        Kieferorthopäden in die KIG 3-5 eingestuft.
        <br />
        <br />Auf der Suche nach einer geeigneten Zahnzusatzversicherung für
        Kinder muss man also zuerst entscheiden, ob man das höhere Kosten und
        geringere Eintrittsrisiko oder das höhere Eintritts- und geringere
        Kostenrisiko absichern möchte. Einen Tarif, der beide Sparten
        bestmöglich abdeckt, gibt es seit einiger Zeit nicht mehr.
        <br />
        <br />Zusätzlich sollte man immer auch prüfen, ob der ausgesuchte Tarif
        auch für Fissurenversiegelung und Kinderprophylaxe sowie umfassend für
        die speziellen Behandlungsmethoden, wie z. B. Lingualtechnik oder
        Invisalign leistet. Die beste Leistung für Kieferorthopädie nutzt
        nichts, wenn diese Leistung später für die entsprechende
        Behandlungsmethode nicht erbracht wird.
        <br />
        <br />
        <h2>
          Kann mit einer Nichtanlage von bleibenden Zähnen noch eine
          Zahnzusatzversicherung für Kinder abgeschlossen werden?
        </h2>
        <b>Ja, aber:</b>Ist ein bleibender Zahn nicht angelegt und fehlt auch
        bereits der darüber liegende Milchzahn, besteht eine dauerhafte Lücke,
        die als fehlender Zahn bei den Gesundheitsfragen angegeben werden muss.
        <router-link to="/kinder/faq/nichtanlage_aplasie">
          <a>Sie hier mehr zu diesem Thema....</a>
        </router-link>
        <br />
        <br />
        <h2>Erstattungsbeispiel Fissurenversiegelung</h2>
        <p>
          Gute Zahnzusatzversicherungen für Kinder kosten ca. 100-180 €/Jahr.
          Eine Fissurenversiegelung der 8 Prämolaren wird von der gesetzlichen
          Krankenversicherung nicht bezahlt und kostet bis zu 320,- €. Moderne
          Zahnzusatzversicherungen erstatten für diese Behandlung bis zu 100 %.
          Diese Erstattungsmöglichkeit deckt in diesem Beispiel bereits ca. 1-2
          Jahresbeiträge für den Zahnzusatztarif.
        </p>

        <br />
        <br />
        <b>Achtung:</b> Wer sein Kind erst nach dem 6. Lebensjahr
        zusatzversichert, dem droht bei kurz danach anstehender KFO-Behandlung,
        falls diese vor Abschluss der Versicherung bereits absehbar war, weil
        bereits eine Zahn-/Kieferfehlstellung ärztlich diagnostiziert wurde,
        eine Leistungsablehnung. Dann wären Kosten von bis zu 5.000,- € selbst
        zu zahlen. Es ist daher sinnvoll, wenn Eltern eine
        Zahnzusatzversicherung für ihr Kind rechtzeitig, d.h. ab dem 3.
        Lebensjahr, abschließen.
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts typeInfo="kinder"
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  import LayoutTable from "@/views/site/helper/LayoutTable";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(false);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Die  besten 37 Zahnzusatzversicherungen für Kinder mit Leistungen für Kieferorthopädie  auf einen Blick.",
      "Zahnzusatzversicherung für Kinder | die 47  besten  KFO-Tarife im Waizmann-Vergleich"
    );
  });
</script>
