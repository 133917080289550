import { defineStore } from "pinia";

export const useGuestStore = defineStore("guest", {
  state: () => ({
    guest: {
      guest_id: 0,
    },
  }),
  actions: {
    setGuest(data) {
      this.guest = data;
    },
  },
});
