<template>
  <div>
    <v-table>
      <br />
      <thead>
        <tr>
          <th style="max-width: 10px">#</th>
          <th>Tarif</th>
          <th>Ø-Erstattung</th>
          <th>Begrenzung</th>
          <th>Preis 55 Jahre</th>
        </tr>
      </thead>
      <tbody>
        <tr
          @click="berechnungStore.setShowPopAppTarif(tarif, 'showTarifOptions')"
          style="vertical-align: top; cursor: pointer"
          v-for="tarif in tarife"
          :key="tarif.id"
        >
          <td>{{ tarif.rank }}.</td>
          <td>
            <strong
              ><router-link
                :to="
                  sharedDataStore.getBeschreibungLink(
                    tarif.tarif_type,
                    tarif.tarif_id
                  )
                "
                >{{ tarif.versicherung }}</router-link
              >
            </strong>

            <br />
            {{ tarif.tarif_title }}
          </td>
          <td>{{ tarif.waizmannwert }}</td>
          <td>{{ tarif.begrenzung }}</td>
          <td>{{ tarif.preis_value }} €</td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const berechnungStore = useBerechnungStore();
  const props = defineProps({
    elementLine: {
      type: String,
    },
    tarife: {
      type: Array,
    },
  });
  console.log(props.tarif);
</script>
<style scoped>
  td {
    padding-top: 8px !important;
  }
</style>
