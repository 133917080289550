<template>
  <div>
    <v-row>
      <v-col cols="12" xs="12">
        <h3>User Fragen an das Waizmann-Team</h3>

        <div class="pa-4 mb-4" v-for="frage in forum" :key="frage.comment_id">
          <strong>{{ frage.comment_title }}</strong>
          <br />
          <span v-html="frage.comment_text.replace(/\n/g, '<br />')"></span>
          <span v-if="frage.comment_antwort">
            <br />
            <br />
            <em
              class="pa-4"
              v-html="frage.comment_antwort.replace(/\n/g, '<br />')"
            ></em>
          </span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    forum: {
      type: Array,
    },
  });
  console.log(props.forum);
</script>
