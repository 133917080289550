<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h2>Was bedeutet Wartezeit bei einer Zahnzusatzversicherung?</h2>
        <h3>Welche Zahnzusatzversicherungen leisten sofort?</h3>
        <br />
        <br />Als Wartezeit wird der Zeitraum bezeichnet, in welchem zwar schon
        Versicherungsschutz besteht, aber noch keine Leistungen in Anspruch
        genommen werden können. Die Wartezeit kann, je nach Versicherer und
        Tarif, für die einzelnen Leistungsbereiche )Zahnersatz, Zahnerhalt,
        Kieferorthopädie und Prophylaxe) variieren. Für gewöhnlich besteht eine
        Wartezeit von 8 Monaten, die aber abhängig vom Tarif auch nur 6 oder 3
        Monate betragen kann bzw. in einigen Tarifen sogar ganz entfällt. Hier
        alle empfehlenswerten Tarife ohne Wartezeit auf einen Blick:
        <br />
        <v-divider :thickness="2" class="my-4"></v-divider>
        <LayoutTable class="my-4" tableTyp="wartezeitVergleich" />

        <v-divider :thickness="2" class="my-4"></v-divider>
        <h3>
          Falls eine Behandlung schon angeraten ist, kann ich dann noch eine
          Zahnzusatzversicherung abschließen und bekomme Leistungen?
        </h3>
        Die meisten Versicherer fragen im Antrag nach einer angeratenen oder
        laufenden Zahnbehandlung. Wenn diese Frage mit “JA” beantwortet werden
        muss, erfolgt in der Regel eine Ablehnung. Es gibt aber auch einige
        Tarife, die dann noch abgeschlossen werden können. Eines haben aber alle
        Tarife gemeinsam: Sie leisten nicht für Versicherungsfälle, die schon
        vor Abschluss der Versicherung eingetreten waren. Man kann den
        jeweiligen Tarif zwar abschließen, bekommt aber für die bereits
        angeratene Behandlung keine Leistung.
        <br />
        <br />Die einzige Ausnahme bildet hier der Tarif
        <router-link to="/zahnzusatzversicherung/id/149">
          <a>ZAHN-ERSATZ-SOFORT (ZEZ) der ERGO Direkt</a> </router-link
        >. Dieser Tarif leistet für bereits angeratene oder sogar laufende
        Behandlungen im Zahnersatz-Bereich - für Zahnerhalt oder Prophylaxe
        besteht keine Leistung. Der Tarif hat weder Wartezeit noch anfängliche
        Summenbegrenzungen. Allerdings wird auch nur die Vorleistung der
        gesetzlichen Krankenversicherung verdoppelt - Bonus für regelmäßige
        Vorsorge und Härtefallregelung wird berücksichtigt.
        <v-divider :thickness="2" class="my-4"></v-divider>
        <h3>
          Wartezeit und anfängliche Summenbegrenzungen sind zwei verschiedene
          Dinge - wir erklären die Unterschiede.
        </h3>
        Die
        <b>
          <router-link to="/erklaert/begriff_wartezeit">
            <a>Wartezeit</a>
          </router-link>
        </b>
        ist der Zeitraum, in welchem zwar grundsätzlich Versicherungsschutz
        besteht, aber noch keine Leistung vom Versicherten in Anspruch genommen
        werden können. Sollte während der Wartezeit also ein Versicherungsfall
        eintreten, ist das noch kein Problem, da der Schutz vorhanden ist.
        Problematisch wird es erst, wenn auch die Behandlung innerhalb der
        Wartezeit durchgeführt wird. In diesem Fall steht der Versicherer dann
        nicht in der Leistungspflicht. Wenn man allerdings mit der Behandlung
        warten kann, bis die Wartezeit abgelaufen ist, können die Leistungen
        anschließend auch in Anspruch genommen werden. Ausschlaggebend ist hier
        immer das jeweilige Behandlungsdatum, nicht etwa das Rechnungsdatum oder
        der Zeitpunkt der Inanspruchnahme.
        <br />
        <br />Die <b>anfänglichen Summenbegrenzungen</b> dienen dem Versicherer
        zwar auch als eine Art Schutzmechanismus sowie der Beitragsstabilität,
        haben aber nichts mit der Wartezeit zu tun. Während es bei der Wartezeit
        darum geht, ob der Versicherer überhaupt leisten muss oder nicht,
        deckeln die anfänglichen Summenbegrenzungen die mögliche Leistung auf
        einen bestimmten Höchstbetrag. Für gewöhnlich begrenzen die Versicherer
        die Leistung in den ersten vier Versicherungsjahren, meist ist die
        Leistung ab danach dann unbegrenzt möglich. Top-Tarife ohne anfängliche
        Summenbegrenzungen sucht man vergeblich. Nur bei Tarifen mit geringen
        Leistungen fehlen manchmal die anfänglichen Summenbegrenzungen - die
        Versicherer begrenzen hier über die Leistung an sich und müssen diese
        daher nicht deckeln.
        <v-divider :thickness="2" class="my-4"></v-divider>

        <h3>Warum haben Tarife eine Wartezeit?</h3>
        Die meisten Versicherer bauen die Wartezeit als eine Art Schutz ein,
        damit die Versicherten nicht sofort Leistungen in Anspruch nehmen
        können. Gerade Tarife ohne Wartezeit werden oft erst dann abgeschlossen,
        wenn schon absehbar ist, dass in naher Zukunft Behandlungen notwendig
        sein werden. Insofern hilft eine Wartezeit den Versicherern das Risiko
        besser kalkulieren zu können und dient letztlich der Beitragsstabilität.
        Die Versicherer können während der Wartezeit Rückstellungen für spätere
        Leistungen bilden. Da dies bei Tarifen ohne Wartezeit nicht möglich ist,
        sind die Beiträge dort auch zumeist etwas höher als bei Tarifen mit
        Wartezeit.
        <ShowContent />
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  import LayoutTable from "@/views/site/helper/LayoutTable";
  import ShowContent from "@/components/content/ShowContent";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Zahnzusatzversicherung ohne Wartezeit im Vergleich | sofortige Leistung",
      "Wo finde ich eine Zahnzusatzversicherung ohne Wartezeit, die sofort leistet"
    );
  });
</script>
