<template>
  <div style="min-height: 1000px">
    <v-row>
      <v-col cols="12"
        ><h2>WaizmannTabelle BudgetPlan Zahnarztservice</h2>
        Wir senden Ihren BudgetPlan direkt an Ihren Zahnarzt. Bitte geben Sie
        Ihre Daten ein.
        <v-alert type="success" v-if="success"
          ><h2 class="text-white">
            Wir werden Ihren BudgetPlan so schnell wie möglich an Ihre Zahnarzt
            senden
          </h2></v-alert
        >
        <div v-if="!success">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="zahnarzt.kuza_name"
                label="Name Ihres Zahnarztes"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="zahnarzt.kuza_strasse"
                label="Strasse Ihres Zahnarztes"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="zahnarzt.kuza_plz"
                label="PLZ Ihres Zahnarztes"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="zahnarzt.kuza_ort"
                label="Ort Ihres Zahnarztes"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider color="blue"></v-divider>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="zahnarzt.kuza_abs_id"
                label="Ihre 5-6 stellige Angebotsnummer"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="zahnarzt.kuza_ku_name"
                label="Ihr Nachname"
                outlined
              ></v-text-field>
              <v-btn @click="submitBudgetplan" class="nocaps"
                >jetzt BudgetPlan an Zahnarzt senden</v-btn
              >
            </v-col>
          </v-row>
        </div>

        <v-row>
          <v-col cols="12" md="12"> </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3"> </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { ref } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  import { useLoaderStore } from "@/stores/loaderStore";
  const loaderStore = useLoaderStore();

  //   const sendStarted = ref(false);
  const success = ref(false);
  const zahnarzt = ref({
    action: "budgetPlanZahnarzt",
    kuza_name: "",
    kuza_strasse: "",
    kuza_plz: "",
    kuza_ort: "",
    kuza_abs_id: "",
    kuza_ku_name: "",
  });

  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }

  async function submitBudgetplan() {
    setLoading(true);
    try {
      const resp = await BaseCalls.postMixed(zahnarzt.value); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.success) {
        success.value = true;
        console.log("insert");
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  }
</script>
