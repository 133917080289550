<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="9"
        ><h2>
          {{ versicherung.un_name }} Zahnzusatzversicherung kündigen |
          Checkliste Kündigung
        </h2>
        <em>
          Wann kann ich meine
          {{ versicherung.un_name }} Zusatzversicherung kündigen? Welche
          Kündigungsfristen muss ich bei der
          {{ versicherung.un_name }} beachten? Kann ich per E-Mail kündigen?
        </em>
        <br /><br />
        Hier finden alle Kündigungsfristen der
        {{ versicherung.un_name }} Zusatzversicherung. Sie erfahren ob Sie die
        {{ versicherung.un_name }} per E-Mail oder Fax kündigen können.
        Zusätzlich können Sie Kündigungsformular als PDF für die
        {{ versicherung.un_name }} ausdrucken.
        <v-divider :thickness="2" class="my-4"></v-divider>
        <h3>Senden Sie Ihre Kündigung an</h3>
        <span v-if="versicherung.un_fax">
          Kündigung Faxnummer:
          {{ versicherung.un_fax }} <br />
          <br />
        </span>
        <span v-if="versicherung.un_kuendigung_adresse">
          Schicken Sie die Kündigung an:<br />
          <span v-html="versicherung.un_kuendigung_adresse"></span>
        </span>
        <v-divider :thickness="2" class="my-4"></v-divider>
        <div v-if="kmMost == 0">
          <br />
          <h3>
            Kann ich meine {{ versicherung.un_name }} Versicherung innerhalb der
            nächsten 3-4 Monate kündigen?
          </h3>
          Die meisten Zusatzversicherungen der
          {{ versicherung.un_name }} können Sie mit einer Kündigungsfrist von 3
          Monaten zum Ende des Versicherungsjahres kündigen. Ein
          Versicherungsjahr beginnt mit dem Beginn Ihrer Versicherung. Das
          genaue Beginndatum finden Sie in Ihrer Police.
          <br />
        </div>

        <div v-if="kmMost == 3">
          <br />
          <h3>
            Ist eine tägliche Kündigung meiner
            {{ versicherung.un_name }} Zusatzversicherung möglich?
          </h3>
          Viele Tarife der
          {{ versicherung.un_name }} können Sie nach Ablauf der
          Mindestvertragslaufzeit täglich kündigen.
          <br />
        </div>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Kündigungsfrist für Tarif suchen"
          single-line
          hide-details
        ></v-text-field>
        <br />
        <v-data-table
          v-if="tarife && tarife.length > 0"
          :headers="headers"
          :items="tarife"
          :search="search"
        >
          <template v-slot:item="{ item }">
            <tr style="vertical-align: top">
              <td class="pa-2" style="max-width: 80px">
                <a :href="getTarifLink(item)"
                  >{{ item.un_name }} {{ item.tarif_title }}</a
                >
              </td>
              <td style="max-width: 60px">
                <span v-if="item.tarif_type == 0">Zahnzusatz</span>
                <span v-if="item.tarif_type == 1">Brille</span>
                <span v-if="item.tarif_type == 2">Heilpraktiker</span>
                <span v-if="item.tarif_type == 3">Krankenhaus</span>
              </td>
              <td style="max-width: 460px">
                <b>
                  <span v-if="item.tarif_int_kuendigung == 0"
                    >3 Monate zum Versicherungsjahr</span
                  >
                  <span v-if="item.tarif_int_kuendigung == 1"
                    >3 Monate zum Kalenderjahr</span
                  >
                  <span v-if="item.tarif_int_kuendigung == 2"
                    >1 Monat zum Versicherungsjahr</span
                  >
                  <span v-if="item.tarif_int_kuendigung == 3"
                    >zum Monatsende</span
                  >
                  <span v-if="item.tarif_int_kuendigung == 4"
                    >auf den Tag genau</span
                  ></b
                >
                <br />
                {{ item.tarif_int_kuendigung_details }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>

      <v-col cols="12" md="3" v-if="sharedDataStore.isDesktop()"
        ><MenueRechts
      /></v-col>
    </v-row>
    <div id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, watch, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  import BaseCalls from "@/services/BaseCalls";
  import { useRoute } from "vue-router";
  const route = useRoute();
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const versicherung = ref(false);
  const tarife = ref(false);
  const kmMost = ref(0);
  const appLoaded = ref(false);
  const search = ref("");

  const headers = ref([
    {
      title: "Tarif",
      align: "left",
      sortable: true,
      value: "tarif_title",
    },
    {
      title: "Art",
      align: "left",
      sortable: true,
      value: "tarif_type",
    },
    {
      title: "Kündigungsfrist",
      align: "left",
      sortable: true,
      value: "tarif_int_kuendigung",
    },
  ]);

  async function getVersicherung() {
    let select = {};
    select.action = "getVersicherungMitLink";
    select.name = route.params.name;
    try {
      const resp = await BaseCalls.postMixed(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.versicherung) {
        versicherung.value = resp.data.versicherung;
        tarife.value = resp.data.tarife;
        appLoaded.value = true;
        countKuend();
        proxy.$updateDocumentHead(
          versicherung.value.un_name +
            " kündigen | Kündigung E-Mail oder FAX| Download (PDF) Kündigungsformular ",
          versicherung.value.un_name +
            " kündigen.  Kündigung per E-Mail oder FAX. Download (PDF) Kündigungsformular. Anschrift Kündiung "
        );
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }
  function getTarifLink(item) {
    if (item.tarif_type == 0) {
      return "/zahnzusatzversicherung/id/" + item.tarif_id;
    }
    if (item.tarif_type == 1) {
      return "/brillenversicherung/id/" + item.tarif_id;
    }
    if (item.tarif_type == 2) {
      return "/heilpraktikerversicherung/id/" + item.tarif_id;
    }
    if (item.tarif_type == 3) {
      return "/krankenhauszusatzversicherung/id/" + item.tarif_id;
    }
  }
  function countKuend() {
    let modal = new Array(5).fill(0);

    tarife.value.forEach((item) => {
      modal[item.tarif_int_kuendigung] = modal[item.tarif_int_kuendigung] + 1;
    });

    let status = [];
    modal.forEach((val, index) => {
      status.push({ name: String(index), val: val });
    });

    status.sort((a, b) => b.val - a.val);

    if (status.length > 0) {
      console.log("thats it " + status[0].name);
      kmMost.value = status[0].name;
    }
  }

  watch(
    () => route.params,
    () => {
      // Die Funktion checkRouteChange wird aufgerufen, wenn sich die Route-Parameter ändern
      getVersicherung();
    },
    { deep: true, immediate: true }
  );
  onMounted(() => {});
</script>
