<template>
  <div>
    <h1>
      {{ tarif.un_name }}
      <span class="text-cyan">{{ tarif.tarif_title }}</span>
    </h1>
    <h3>
      Ausführliche Leistungsbeschreibung der {{ tarif.un_name }}
      {{ tarif.tarif_title }} krankenhauszusatzversicherung.
    </h3>

    <v-divider :thickness="2" class="my-4"></v-divider>
    <v-row>
      <v-col cols="2" v-if="!sharedDataStore.isMobile()"
        ><div class="cianground px-4 py-8" style="height: 120px">
          <h1 class="text-white">{{ tarif.kh_ww }} %</h1>
          <span class="text-white"> Ø-Erstattung</span>
        </div>
        <div class="pt-2">
          Tarif-Nr.: <b>{{ tarif.tarif_id }}</b>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <h3>Wichtige Eckdaten</h3>

        <v-row>
          <v-col cols="12" md="4">1/2-Bett-Zimmmer:</v-col>
          <v-col cols="12" md="8">
            <strong v-if="tarif.kh_bett == 1">Ja</strong>
            <strong v-if="tarif.kh_bett != 1">Nein</strong>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" md="4">Chefarztbehandlung:</v-col>
          <v-col cols="12" md="8">
            <strong v-if="tarif.kh_ca == 1">Ja</strong>
            <strong v-if="tarif.kh_ca != 1">Nein</strong>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" md="4">Wartezeit:</v-col>
          <v-col cols="12" md="8">
            {{ tarif.kh_wz }}
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="4"> </v-col>
    </v-row>
    <v-divider :thickness="2" class="my-4"></v-divider>
  </div>
</template>

<script setup>
  // import { useBerechnungStore } from "@/stores/berechnung";
  import { defineProps } from "vue";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();
  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    preise: {
      type: Array,
    },
  });

  console.log(props.tarif.tarif_id);
</script>
