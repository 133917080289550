<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10"
        ><h2>WaizmannTabelle Expertenberatung</h2>
        <v-row style="max-width: 940px">
          <v-col cols="12">
            <!-- Ersetzt v-layout durch v-row und v-flex durch v-col -->
            <v-row>
              <v-col cols="12" md="7">
                <p>
                  Sie haben 3 Möglichkeiten unsere Beratung zu nutzen: Per
                  E-Mail, über Telefon und am einfachsten mit unserem
                  Online-Chat (während den normalen Bürozeiten werktags von
                  10:00 bis 16:00 Uhr).
                </p>
              </v-col>
              <v-col cols="12" md="5">
                <b>Unsere Post-Anschrift:</b>
                <ul>
                  <li>WaizmannTabelle GmbH</li>
                  <li>Lohstraße 56</li>
                  <li>81543 München</li>
                </ul>
              </v-col>
            </v-row>

            <!-- Weitere v-layout Ersetzungen -->
            <v-row>
              <v-col cols="12" md="1">
                <v-icon style="font-size: 42px">mdi-email</v-icon>
              </v-col>
              <v-col cols="12" md="6">
                <span>Schicken Sie uns Ihre Frage einfach per E-Mail</span>
                <br />Senden Sie uns Mails mit
                <strong class="green--text">Anhängen</strong> bitte an<br />
                <a style="font-size: 24px" href="mailto:info@waizmanntabelle.de"
                  >info@waizmanntabelle.de</a
                ><br />
                Wir antworten in der Regel innerhalb weniger Stunden.
              </v-col>
              <v-col cols="12" md="5">
                <ul>
                  <li>
                    <router-link to="/zahnzusatzversicherung/faq"
                      >FAQ Erwachsene</router-link
                    >
                    |
                    <router-link to="/kinder/faq">FAQ Kinder</router-link>
                  </li>
                  <li>
                    <router-link to="/tarifsuche">Tarifsuche</router-link>
                  </li>
                  <li>
                    <router-link to="/erklaert">Zahnzusatz-Lexikon</router-link>
                  </li>
                  <li><router-link to="/beratung">Beratung</router-link></li>
                </ul>
              </v-col>
            </v-row>
            <v-divider :thickness="2" class="my-4"></v-divider>
            <v-row>
              <v-col md="1">
                <v-icon
                  v-if="berechnungStore.showPopChatButton"
                  style="font-size: 42px"
                  class="text-green"
                  >mdi-chat</v-icon
                >
                <v-icon
                  v-if="!berechnungStore.showPopChatButton"
                  style="font-size: 42px"
                  class="text-red"
                  >mdi-chat</v-icon
                >
              </v-col>
              <v-col md="11" xs="12">
                <span>Chatten Sie mit unseren "Zusatz"-Experten | </span>
                <b v-if="berechnungStore.showPopChatButton" class="text-green"
                  >Chat verfügbar</b
                >
                <b v-if="!berechnungStore.showPopChatButton" class="text-red"
                  >Chat derzeit offline</b
                >
                <br />Unser Chat ist an Werktagen zwischen 10-16 Uhr online.
                <span v-if="berechnungStore.showPopChatButton">
                  <a @click="berechnungStore.setShowPopChat"
                    >Klicken Sie hier um einen Chat zu starten...</a
                  ></span
                >
              </v-col>
            </v-row>
            <v-divider :thickness="2" class="my-4"></v-divider>
            <v-row>
              <v-col md="1">
                <v-icon style="font-size: 42px" class="text-green"
                  >mdi-phone</v-icon
                >
              </v-col>
              <v-col md="11" xs="12">
                <span
                  >Rückruf-Service | Sie wünschen eine telefonische
                  Beratung</span
                >
                <br />Nutzen Sie unseren Rückruf-Service an Werktagen zwischen
                10-16 Uhr.
                <a @click="showRueckRuf = !showRueckRuf"
                  >Fordern Sie jetzt eine kostenlose telefonische Beratung an</a
                >.
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts
      /></v-col>
    </v-row>
    <v-dialog width="670" v-model="showRueckRuf">
      <RueckRuf />
    </v-dialog>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import RueckRuf from "@/views/site/helper/RueckRuf";
  import { useSharedDataStore } from "@/stores/sharedData";
  import { useBerechnungStore } from "@/stores/berechnung";
  const berechnungStore = useBerechnungStore();
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  const showRueckRuf = ref(false);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Waizmann Expertenberatung",
      "Waizmann Expertenberatung"
    );
  });
</script>
