import { createRouter, createWebHistory } from "vue-router";
// import { isEqual } from "lodash";
import RechnerView from "../views/rechner/RechnerView.vue";
import AllerechnerView from "../views/rechner/AllerechnerView.vue";
import ErgebnisView from "../views/rechner/ErgebnisView.vue";
import AntragfragenView from "../views/rechner/AntragfragenView.vue";
import PersonalfragenView from "../views/rechner/PersonalfragenView.vue";
import AntragOkView from "../views/rechner/AntragOkView.vue";
//seo
import ForumIndex from "../views/site/ForumIndex.vue";
import HomeView from "../views/site/HomeView.vue";
import LexikonIndex from "../views/site/LexikonIndex.vue";
import LexikonKinderIndex from "../views/site/LexikonKinderIndex.vue";
import ErklaertIndex from "../views/site/ErklaertIndex.vue";
import ErklaertSuche from "../views/site/ErklaertSuche.vue";
import SofortKuendigen from "../views/site/SofortKuendigen.vue";
import TarifBeschreibung from "../views/site/TarifBeschreibung.vue";
import VergleichAlleZahn from "../views/site/zahn/VergleichAlle.vue";
import VergleichAllePlus from "../views/site/zahn/VergleichPlus.vue";
import VergleichAlleKind from "../views/site/zahn/VergleichKind.vue";
import IndexBeratung from "../views/site/IndexBeratung.vue";
import TarifsucheView from "../views/site/TarifsucheView.vue";
import FaqKinderIndex from "../views/site/FaqKinderIndex.vue";
import FaqErwachsenIndex from "../views/site/FaqErwachsenIndex.vue";
import IndexImplantatohnewartezeit from "../views/site/zahn/IndexImplantatohnewartezeit.vue";
import WaizmannWertInfo from "../views/site/zahn/WaizmannWertInfo.vue";
import PzrFlatrate from "../views/site/zahn/PzrFlatrate.vue";
import TestsiegerVergleich from "../views/site/zahn/TestsiegerVergleich.vue";
import WechselIndex from "../views/site/zahn/WechselIndex.vue";
import GesundheitsFragenVergleich from "../views/site/zahn/GesundheitsFragenVergleich.vue";
import ProContraIndex from "../views/site/zahn/ProContraIndex.vue";
import ImplantatVergleich from "../views/site/zahn/ImplantatVergleich.vue";
import KinderHeilpraktikerVergleich from "../views/site/heil/KinderHeilpraktikerVergleich.vue";
import FaqHeilpraktiker from "../views/site/heil/FaqHeilpraktiker.vue";
import WartezeitVergleich from "../views/site/zahn/WartezeitVergleich.vue";
import TestHeilpraktiker from "../views/site/heil/TestHeilpraktiker.vue";
import KinderBrillenVergleich from "../views/site/brille/KinderBrillenVergleich.vue";
import SeniorenBrillenVergleich from "../views/site/brille/SeniorenBrillenVergleich.vue";
import ArbeitsplatzBrille from "../views/site/brille/ArbeitsplatzBrille.vue";
import LasikBrillenVergleich from "../views/site/brille/LasikBrillenVergleich.vue";
import BrilleFaq from "../views/site/brille/BrilleFaq.vue";
import PrismenBrille from "../views/site/brille/PrismenBrille.vue";
import BrilleTest from "../views/site/brille/BrilleTest.vue";
import BrilleMarke from "../views/site/brille/BrilleMarke.vue";
import BrilleOhneWartezeit from "../views/site/brille/BrilleOhneWartezeit.vue";
import BrillenVergleich from "../views/site/brille/BrillenVergleich.vue";
import HeilpraktikerVergleich from "../views/site/heil/HeilpraktikerVergleich.vue";
import KrankenhausVergleich from "../views/site/krank/KrankenhausVergleich.vue";
import KrankenhausOhneGesundheitsfragen from "../views/site/krank/KrankenhausOhneGesundheitsfragen.vue";
import TestKrankenhaus from "../views/site/krank/TestKrankenhaus.vue";
import KinderKrankenhausVergleich from "../views/site/krank/KinderKrankenhausVergleich.vue";
import KrankenhausMitAltersrueckstellung from "../views/site/krank/KrankenhausMitAltersrueckstellung.vue";
import KrankenhausOhneAltersrueckstellung from "../views/site/krank/KrankenhausOhneAltersrueckstellung.vue";
import KrankenhausEinbettzimmer from "../views/site/krank/KrankenhausEinbettzimmer.vue";
import KrankenhausZweibettzimmer from "../views/site/krank/KrankenhausZweibettzimmer.vue";
import KrankenhausFAQ from "../views/site/krank/KrankenhausFAQ.vue";
import ImpressumIndex from "../views/site/ImpressumIndex.vue";
import DatenschutzIndex from "../views/site/DatenschutzIndex.vue";
import RatgeberIndex from "../views/site/heil/RatgeberIndex.vue";
import ClearmentIndex from "../views/site/ClearmentIndex.vue";
import BudgetPlan from "../views/site/BudgetPlan.vue";
import SignUp from "../views/rechner/SignUp.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/budgetplan",
    name: "budgetplan",
    component: BudgetPlan,
  },
  {
    path: "/signup/:token/:tarif_id",
    name: "signup",
    component: SignUp,
  },
  {
    path: "/zahnzusatzversicherung/was_ist_besser/:tarif_id1/:tarif_id2",
    name: "was_ist_besser",
    component: ProContraIndex,
  },
  {
    path: "/vergleich/ergebnis/:token",
    name: "ergebnis",
    component: ErgebnisView,
  },
  {
    path: "/angebot/kostenlos/:tarif_id/:token",
    name: "antragfragen",
    component: AntragfragenView,
  },
  {
    path: "/angebot/es_hat_geklappt/:tarif_id/:token",
    name: "antragfragenok",
    component: AntragOkView,
  },
  {
    path: "/angebot/kostenlos_fertig/:tarif_id/:token",
    name: "personalfragen",
    component: PersonalfragenView,
  },
  {
    path: "/vergleich/starten/:bertype?",
    name: "rechner",
    component: RechnerView,
  },
  {
    path: "/zusatzversicherung",
    name: "allerechner",
    component: AllerechnerView,
  },
  {
    path: "/tarifsuche",
    name: "tarifsuche",
    component: TarifsucheView,
  },
  // static views#
  {
    path: "/clearment",
    name: "clearmentindec",
    component: ClearmentIndex,
  },
  {
    path: "/heilpraktikerversicherung/:name",
    name: "hpbratgeber",
    component: RatgeberIndex,
  },
  {
    path: "/krankenhauszusatzversicherung_faq",
    name: "krankenhaus_faq", //x
    component: KrankenhausFAQ,
  },
  {
    path: "/impressum",
    name: "impressum", //
    component: ImpressumIndex,
  },
  {
    path: "/datenschutz",
    name: "datenschutz", //
    component: DatenschutzIndex,
  },
  {
    path: "/krankenhauszusatzversicherung_zweibettzimmer",
    name: "krankenhauszweibettzimmer",
    component: KrankenhausZweibettzimmer,
  },
  {
    path: "/krankenhauszusatzversicherung_einbettzimmer",
    name: "krankenhauseinbettzimmer",
    component: KrankenhausEinbettzimmer,
  },
  {
    path: "/krankenhauszusatzversicherung_ohne_altersrueckstellung",
    name: "krankenhausohnealtersrueckstellung",
    component: KrankenhausOhneAltersrueckstellung, //todo24
  },
  {
    path: "/krankenhauszusatzversicherung_mit_altersrueckstellung",
    name: "krankenhausmitaltersrueckstellung",
    component: KrankenhausMitAltersrueckstellung,
  },
  {
    path: "/kinder_krankenhauszusatzversicherung_vergleich",
    name: "kinderkrankenhausvergleich",
    component: KinderKrankenhausVergleich,
  },
  {
    path: "/krankenhauszusatzversicherung_test",
    name: "test_krankenhaus",
    component: TestKrankenhaus,
  },
  {
    path: "/krankenhauszusatzversicherung_ohne_gesundheitsfragen",
    name: "krankenhaus_ohne_gesundheitsfragen",
    component: KrankenhausOhneGesundheitsfragen,
  },
  {
    path: "/krankenhauszusatzversicherung_vergleich",
    name: "krankenhausvergleich",
    component: KrankenhausVergleich,
  },
  {
    path: "/heilpraktikerversicherung_vergleich",
    name: "heilpraktikervergleich",
    component: HeilpraktikerVergleich,
  },
  {
    path: "/brillenversicherung_vergleich",
    name: "brillenvergleich",
    component: BrillenVergleich,
  },
  {
    path: "/brillenversicherung_ohne_Wartezeit_mit_Sofortleistung",
    name: "brillenversicherung_ohne_Wartezeit_mit_Sofortleistung",
    component: BrilleOhneWartezeit,
  },
  {
    path: "/brillenversicherung_3_Schritte_zu_einer_guenstigen_Markenbrille",
    name: "brillenversicherung_3_Schritte_zu_einer_guenstigen_Markenbrille",
    component: BrilleMarke, //todo16
  },
  {
    path: "/brillenversicherung_test",
    name: "brillenversicherung_test",
    component: BrilleTest, //todo15
  },
  {
    path: "/brillenversicherung_prismenbrille",
    name: "brillenversicherung_prismenbrille",
    component: PrismenBrille,
  },
  {
    path: "/brillenversicherung_fragen_und_antworten_faq",
    name: "brillenversicherung_fragen_und_antworten_faq",
    component: BrilleFaq, //todo13
  },
  {
    path: "/lasik_brillenversicherung_vergleich",
    name: "lasik_brillenversicherung",
    component: LasikBrillenVergleich,
  },
  {
    path: "/brillenversicherung_arbeitsplatzbrille",
    name: "brillenversicherung_arbeitsplatzbrille",
    component: ArbeitsplatzBrille,
  },
  {
    path: "/senioren_brillenversicherung_vergleich",
    name: "senioren_brillenversicherung",
    component: SeniorenBrillenVergleich, //todo10
  },
  {
    path: "/kinder_brillenversicherung_vergleich",
    name: "kinderr_brillenversicherung",
    component: KinderBrillenVergleich,
  },
  {
    path: "/heilpraktikerversicherung_test",
    name: "test_heilpraktiker",
    component: TestHeilpraktiker,
  },
  {
    path: "/zahnzusatzversicherung_ohne_wartezeit",
    name: "wartezeit",
    component: WartezeitVergleich,
  },
  {
    path: "/heilpraktikerversicherung_faq_haeufig_gestellte_fragen",
    name: "faq_heilpraktiker",
    component: FaqHeilpraktiker,
  },
  {
    path: "/kinder_heilpraktikerversicherung_vergleich",
    name: "kinder_heilpraktikervergleich",
    component: KinderHeilpraktikerVergleich,
  },
  {
    path: "/zahnzusatzversicherung_ohne_gesundheitsfragen",
    name: "gesundheitsfragen",
    component: GesundheitsFragenVergleich,
  },
  {
    path: "/zahnzusatzversicherung_implantate",
    name: "implantatvergleich",
    component: ImplantatVergleich,
  },
  {
    path: "/wechsel",
    name: "wechsel",
    component: WechselIndex,
  },
  {
    path: "/pzr_flatrate",
    name: "PzrFlatrate",
    component: PzrFlatrate,
  },
  {
    path: "/zahnzusatzversicherung_testsieger",
    name: "testsieger",
    component: TestsiegerVergleich,
  },
  {
    path: "/waizmannwert",
    name: "waizmannwert",
    component: WaizmannWertInfo,
  },
  {
    path: "/zahnzusatzversicherung_implantat_sofort_ohne_wartezeit",
    name: "implantat_ohne_wartezeit",
    component: IndexImplantatohnewartezeit,
  },
  {
    path: "/zahnzusatzversicherung_vergleich",
    name: "zahnvergleichalle",
    component: VergleichAlleZahn,
  },
  {
    path: "/beratung",
    name: "beratung",
    component: IndexBeratung,
  },
  {
    path: "/hilfe/beratung",
    name: "hilfeberatung",
    component: IndexBeratung,
  },
  {
    path: "/kinder",
    name: "zahnvergleichallekinder",
    component: VergleichAlleKind,
  },
  {
    path: "/60plus",
    name: "zahnvergleichalleplus",
    component: VergleichAllePlus,
  },
  {
    path: "/zahnzusatzversicherung_forum/:tarif/:thread",
    name: "forum",
    component: ForumIndex,
  },
  {
    path: "/zahnzusatzversicherung/faq/:thema",
    name: "lexikon",
    component: LexikonIndex,
  },
  {
    path: "/kinder/faq/:thema",
    name: "lexikonkinder",
    component: LexikonKinderIndex,
  },
  {
    path: "/erklaert/:thema",
    name: "erklaertindex",
    component: ErklaertIndex,
  },
  {
    path: "/erklaert",
    name: "erklaertsuche",
    component: ErklaertSuche,
  },
  {
    path: "/zahnzusatzversicherung/faq",
    name: "erwachsenfaq",
    component: FaqErwachsenIndex,
  },
  {
    path: "/kinder/faq",
    name: "kinderfaq",
    component: FaqKinderIndex,
  },
  {
    path: "/sofort_kuendigen/:name",
    name: "kuendigen",
    component: SofortKuendigen,
  },
  {
    path: "/zahnzusatzversicherung/id/:id",
    name: "tbzahnn",
    component: TarifBeschreibung,
  },
  {
    path: "/brillenversicherung/id/:id",
    name: "tbbrille",
    component: TarifBeschreibung,
  },
  {
    path: "/heilpraktikerversicherung/id/:id",
    name: "tbheil",
    component: TarifBeschreibung,
  },
  {
    path: "/krankenhauszusatzversicherung/id/:id",
    name: "tbkrank",
    component: TarifBeschreibung,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
