<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <span v-if="sharedDataStore.isMobile()">
          <h1>Ihr Infopaket</h1>
          Fordern Sie jetzt kostenlos Ihr Antrag & Infopaket an.
        </span>
        <h1 v-if="!sharedDataStore.isMobile()">
          Jetzt Antrag & Infopaket anfordern |
          <span class="text-green">kostenlos</span>
        </h1>
        <span v-if="!sharedDataStore.isMobile()">
          <b class="text-green">{{ tarif.un_name }} {{ tarif.tarif_title }}</b>
          (mtl. <b>{{ tarif.preis_value }}€</b>). Empfehlung für
          {{ berechnung.show_ber_geschlecht }} |
          {{ berechnung.show_ber_geburtstag }} | Nicht richtig? Nicht richtig?
          <router-link to="/vergleich/starten">Jetzt ändern...</router-link>
        </span>
      </v-col>
    </v-row>
    <div v-if="!sharedDataStore.isMobile()">
      <v-divider :thickness="2" class="my-2"></v-divider>
      <v-row>
        <v-col
          ><v-icon class="text-green">mdi-thumb-up</v-icon>&nbsp; Ihr Infopaket
          enthält:</v-col
        >
        <v-col
          ><a @click="showInfopaket = !showInfopaket"
            >1. umfangreiche Tarifinfos</a
          ></v-col
        >
        <v-col
          ><a @click="showInfopaket = !showInfopaket"
            >2.Versicherungsdaten</a
          ></v-col
        >
        <v-col v-if="berechnung.ber_segment <= 1"
          ><a @click="showInfopaket = !showInfopaket"
            >3.Cleament Zugsangsdaten</a
          ></v-col
        >
      </v-row>
      <v-divider :thickness="2" class="my-2"></v-divider>
    </div>
    <v-dialog v-model="showInfopaket" width="888">
      <v-card class="pa-4">
        <InfoPaket :tarif="tarif" :berechnung="berechnung" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
  import { ref, defineProps } from "vue";
  import InfoPaket from "@/components/berechnung/info/InfoPaket";
  import { useSharedDataStore } from "@/stores/sharedData";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();
  const sharedDataStore = useSharedDataStore();
  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  const showInfopaket = ref(false);
  console.log(props.tarif);
</script>
