<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h1 class="subtitle-3">
          WaizmannTabelle |
          <span style="color: #ff8000">Arbeitsplatzbrille versichern</span>
        </h1>
        <br />
        <h3>
          <v-icon style="color: #ff8000">check</v-icon> Leisten
          Brillenversicherungen auch für Arbeitsplatzbrillen?<br />
          <v-icon style="color: #ff8000">check</v-icon> Wieviel erstattet die
          GKV für eine Arbeitsplatz-Brille?<br />
          <v-icon style="color: #ff8000">check</v-icon> Rechtliche Grundlagen
          der Arbeitsplatzbrille<br />
        </h3>
        <br />
        <h3>
          <span style="color: #ff8000">Experten-Rat:</span> Verlassen Sie sich
          nicht auf Ihren Arbeitsgeber. Sorgen Sie vor - Mit einer
          Brillenversicherung die für eine ansprechende Arbeitsplatzbrille
          leistet.
        </h3>
        <br />
        <a href="/brillenversicherung_vergleich">
          Alle Brillen-Versicherungen im Vergleich</a
        >
        <br />
        <br />
        <v-btn
          href="/vergleich/starten/brille"
          class="button_caps mb-4"
          style="background-color: #ff8000; color: #fff !important"
          >jetzt Brillenversicherungen online vergleichen</v-btn
        >
        <br />
        <br />
        <br />
        <div id="app">
          <div class="text--primary">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card :elevation="hover ? 24 : 6" class="mx-auto pa-6">
                  <h2>
                    <span style="color: #ff8000">Arbeitsplatzbrille - </span>Das
                    Wichtigste auf einen Blick
                  </h2>
                  <br />
                  <strong>1.</strong> Eine Arbeitsplatzbrille ist für das
                  Arbeiten am Bildschrim optimiert <br />
                  <strong>2.</strong> Eine Arbeitsplatzbrille kostet zwischen
                  200 € und 400 € <br />
                  <strong>3.</strong> Unter gewissen Vorraussetzungen musss sich
                  der Arbeitgeber an den Kosten einer Arbeitsplatzbrille
                  beteiligen <br />
                  <strong>4.</strong> Die GKV zahlt grundsätzlich nicht für eine
                  Arbeitsplatzbrille <br />
                  <strong>5.</strong> Eine Arbeitsplatzbrille lässt sich
                  arbeitgeber-unabhängig über eine Brillenversicherung
                  finanzieren<br />
                </v-card>
              </template>
            </v-hover>
          </div>
        </div>
        <br />
        <br />
        <h3>Was ist eine Arbeitsplatzbrille?</h3>
        Eine Arbeitsplatzbrille oder Bildschirmbrille ist eine spezielle
        Sehhilfe, die für die tägliche Arbeit am Arbeitsplatz gemacht ist.
        Sowohl bei der Arbeit am Computer wie auch bei der Arbeit an der
        Werkbank unterstützt die Arbeitsplatzbrille ihren Träger. Im Gegensatz
        zur Gleitsichtbrille fokussiert sich die Arbeitsplatzbrille auf kurze
        und mittlere Distanzen und gewährleistet so eine scharfe Sehfähigkeit
        zwischen 50 cm und 100 cm (je nach Anforderung). Damit wird die Arbeit
        am Bildschirm, die Tätigkeit in der Produktionszelle und auch das
        Gespräch mit Kollegen für ihre Augen weniger anstrengend. Zusätzliche
        Optionen wie die Entspiegelung oder der Blaulichtfilter, um das
        schädliche blaue HEV-Licht zu filtern, macht das Arbeiten mit einer
        Arbeitsplatzbrille noch angenehmer. Starke Ermüdungserscheinungen der
        Augen und Kopfschmerzen, nach einem langen Arbeitstag, gehören damit der
        Vergangenheit an.
        <br />
        <br />
        <div id="app">
          <div class="text--primary">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card :elevation="hover ? 24 : 6" class="mx-auto pa-2">
                  <span style="color: #ff8000">Info: </span>Eine
                  Arbeitsplatzbrille ist auf kurze und mittlere Distanzen
                  optimiert.
                </v-card>
              </template>
            </v-hover>
          </div>
        </div>
        <br />
        <h3>Was kostet eine Arbeitsplatzbrille?</h3>
        Wie bei anderen Brillen, können auch bei Arbeitsplatzbrillen die Kosten
        je nach Modell und Optiker stark variieren. Hauptkostentreiber bei
        Arbeitsplatzbrillen sind: Stärke und Qualität der Gläser, hochwertige
        Brillengestelle und zusätzliche Annehmlichkeiten wie Entspiegelung oder
        Blaulichtfilter. Einstiegsmodelle für Bildschirmbrillen sind bei
        Onlinehändler bereits ab ca. 100 € erhältlich. Möchte man ein besser
        Ausführung einer Arbeitsplatzbrille die spezifisch auf die eigenen
        Anforderung abgestimmt ist, muss man mit Kosten zwischen 200 € und 400 €
        rechnen. Bei der Finanzierung einer hochwertigeren Arbeitsplatzbrille
        kann eine Brillenversicherung unterstützen.
        <br />
        <a href="/vergleich/starten/brille">
          Jetzt die geeignete Brillenversicherung finden!</a
        >
        <br />
        <br />
        <h3>Wann ist eine Arbeitsplatzbrille notwendig?</h3>
        Ein langer Arbeitstag am Computer ist für unsere Augen sehr anstrengend.
        Da ist es normal, dass unsere Augen Abends erschöpft sind. Kommt es aber
        vermehrt zu einer Überbeanspruchung der Augen, die sich durch rote,
        brennende oder trockene Augen ausdrücken kann. Oder haben Sie nach einem
        langen Arbeitstag immer mit Kopfschmerzen und Nackenbeschwerden zu
        kämpfen? Dann macht es Sinn seine Sehfähigkeit von einem Optiker oder
        Augenarzt überprüfen zu lassen und über die Anschaffung einer Sehhilfe
        nachzudenken.
        <br />
        <br />
        <h3>Zahlt mein Arbeitgeber für eine Arbeitsplatzbrille?</h3>
        <h4>Welche gesetzlichen Grundlagen gibt es?</h4>
        <ul>
          <strong><em>1. Arbeitsschutzgesetz - ArbSchG</em></strong>
          <br />
          Nach
          <strong>§ 3</strong>
          Grundpflichten des Arbeitgebers Abs. (1) (Arbeitsschutzgesetz -
          ArbSchG) ist der Arbeitgeber dazu verpflichtet,
          <em
            >“...die erforderlichen Maßnahmen des Arbeitsschutzes unter
            Berücksichtigung der Umstände zu treffen, die Sicherheit und
            Gesundheit der Beschäftigten bei der Arbeit beeinflussen…”
          </em>
          Zudem lautet es im Abs. (3) weiter,
          <em
            >“...Kosten für Maßnahmen nach diesem Gesetz darf der Arbeitgeber
            nicht den Beschäftigten auferlegen.”</em
          >
        </ul>
        <ul>
          <strong
            ><em
              >2. Verordnung zur arbeitsmedizinischen Vorsorge - ArbMedVV</em
            ></strong
          >
          <br />
          Nach
          <strong>§ 5</strong>
          der Verordnung zur arbeitsmedizinischen Vorsorge (ArbMedVV) hat
          <em
            >"der Arbeitgeber...den Beschäftigten
            Angebotsvorsorge...anzubieten.”</em
          >
          Diese sogenannte Angebotsvorsorge beinhaltet bei Tätigkeiten an
          Bildschirmgeräten auch eine
          <em>“...angemessene Untersuchung der Augen und des Sehvermögens."</em>
          Des Weiteren lautet es im Anhang der ArbMedVV:
          <em
            >“Erweist sich auf Grund der Angebotsvorsorge eine augenärztliche
            Untersuchung als erforderlich, so ist diese zu ermöglichen… Den
            Beschäftigten sind im erforderlichen Umfang spezielle Sehhilfen für
            ihre Arbeit an Bildschirmgeräten zur Verfügung zu stellen, wenn
            Ergebnis der Angebotsvorsorge ist, dass spezielle Sehhilfen
            notwendig und normale Sehhilfen nicht geeignet sind;”</em
          >
        </ul>
        <br />
        <h4>
          Fazit: Der Arbeitgeber ist in vielen Fällen dazu verpflichtet einen
          Zuschuss für eine neue Arbeitsplatzbrille zu zahlen.
        </h4>
        In den meisten großen Unternehmen ist deshalb diese Beteiligung an den
        Kosten einer Bildschirmbrille meist sehr gut organisiert. Oft legt man
        nur ein entsprechendes augenärztliches Attest bei der Personalabteilung
        vor und beantragt die Beteiligung an den Kosten. In manchen Unternehmen
        existiert bereits ein Betriebsvereinbarung zu diesem Thema.
        <br />
        In kleineren Unternehmen ist der “Zuschuss zur Bildschirmbrille” meist
        nicht so klar geregelt. Oft ist man der “Erste” der eine
        Arbeitsplatzbrille benötigt und möchte sich gegenüber seinem Arbeitgeber
        nicht auf gesetzliche Ansprüche berufen. Aus diesen Gründen übernehmen
        viele Arbeitnehmer die Kosten einer Arbeitsplatzbrille selbst. <br />Für
        eine private Brillenversicherung spielt es keine Rolle, wenn sich der
        Arbeitgeber nicht an den Kosten einer Arbeitsplatzbrille beteiligt. Die
        Brillenversicherung erstattet tarifgemäß die Kosten einer
        Arbeitsplatzbrille.
        <br />
        <br />
        <div id="app">
          <div class="text--primary">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card :elevation="hover ? 24 : 6" class="mx-auto pa-2">
                  <span style="color: #ff8000">Vorsicht: </span>Der Arbeitgeber
                  übernimmt keine Kosten für teure Markengestelle!
                </v-card>
              </template>
            </v-hover>
          </div>
        </div>
        <br />
        <br />
        <h3>
          Was erstattet die gesetzliche Krankenkasse (GKV) für eine
          Arbeitsplatzbrille?
        </h3>
        Eine Arbeitsplatzbrille ist keine Leistung der gesetzlichen
        Krankenkasse.
        <br />
        <a
          href="https://www.waizmanntabelle.de/brillenversicherung_vergleich#Die_GKV"
        >
          Unter welchen Voraussetzungen die GKV für eine Brille leistet.</a
        >
        <br />
        <br />
        <br />
        <h3>
          Unterschiede zwischen einer Arbeitsplatzbrille und einer
          Gleitsichtbrille?
        </h3>
        <br />
        <v-table style="max-width: 700px" dense>
          <br />
          <thead>
            <tr>
              <th></th>
              <th>Arbeitsplatzbrille</th>
              <th>Gleitsichtbrille</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Optimierte Sichtbereiche</td>
              <td>Nahbereich und Zwischenbereich</td>
              <td>Nahbereich und Fernbereich</td>
            </tr>
            <br />
            <tr>
              <td>Einsatzbereich</td>
              <td>Längeres Arbeiten am Computer oder an der Werkbank</td>
              <td>
                Alltägliche Situationen, die unterschiedliche Anforderungen im
                Nah-, Zwischen- und Fernbereich mit sich bringen
              </td>
            </tr>
            <br />
            <tr>
              <td>Kosten</td>
              <td>ca. 100 € - 400 €</td>
              <td>ca. 200 € - 1200 €</td>
            </tr>
            <br />
            <tr>
              <td>Finanzierung</td>
              <td>Arbeitgeber Zuschuss prüfen</td>
              <td>-</td>
            </tr>
          </tbody>
        </v-table>
        <br />
        <br />
        <br />
        <br
      /></v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts typeInfo="brillen"
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Brillenversicherungen für Arbeitsplatzbrillen",
      "Brillenversicherungen übernehmen die Kosten einer Arbeitsplatzbrille. Online-Vergleich der besten Brillenversicherungen für Arbeitsplatzbrillen"
    );
  });
</script>
