<template>
  <div>
    <v-card elevation="2" class="pt-4">
      <v-row class="px-4" v-if="tableTyp == 'krankAlle'">
        <v-col cols="12" md="10">
          <h2>Die leistungsstärksten Krankenhaus-Zusatzversicherungen</h2>
          Die leistungsstärksten Krankenhaus-Zusatzversicherungen im Vergleich.
        </v-col>
        <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()">
          <v-icon left class="text-cyan" style="font-size: 78px"
            >mdi-play</v-icon
          ></v-col
        >
      </v-row>

      <v-row class="px-4" v-if="tableTyp == 'krankBett1'">
        <v-col cols="12" md="10">
          <h2>
            Krankenhaus-Zusatzversicherungen mit 1-Bettzimmer im Vergleich
          </h2>
          Krankenhaus-Zusatzversicherungen mit 1-Bettzimmer-Unterbringung im
          Vergleich.
        </v-col>
        <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()">
          <v-icon left class="text-cyan" style="font-size: 78px"
            >mdi-play</v-icon
          ></v-col
        >
      </v-row>
      <v-row class="px-4" v-if="tableTyp == 'krankBett2'">
        <v-col cols="12" md="10">
          <h2>
            Krankenhaus-Zusatzversicherungen mit 2-Bettzimmer im Vergleich
          </h2>
          Krankenhaus-Zusatzversicherungen mit 1-Bettzimmer-Unterbringung im
          Vergleich.
        </v-col>
        <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()">
          <v-icon left class="text-cyan" style="font-size: 78px"
            >mdi-play</v-icon
          ></v-col
        >
      </v-row>
      <v-row class="px-4" v-if="tableTyp == 'krankOhneAlter'">
        <v-col cols="12" md="10">
          <h2>Die Krankenhaus-Zusatzversicherungen ohne Altersrückstellung</h2>
          Krankenhaus-Zusatzversicherungen ohne Altersrückstellungen im
          Vergleich.
        </v-col>
        <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()">
          <v-icon left class="text-cyan" style="font-size: 78px"
            >mdi-play</v-icon
          ></v-col
        >
      </v-row>
      <v-row class="px-4" v-if="tableTyp == 'krankMitAlter'">
        <v-col cols="12" md="10">
          <h2>Die Krankenhaus-Zusatzversicherungen mit Altersrückstellung</h2>
          Krankenhaus-Zusatzversicherungen mit Altersrückstellungen im
          Vergleich.
        </v-col>
        <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()">
          <v-icon left class="text-cyan" style="font-size: 78px"
            >mdi-play</v-icon
          ></v-col
        >
      </v-row>
      <v-row class="px-4" v-if="tableTyp == 'krankKinder'">
        <v-col cols="12" md="10">
          <h2>Die besten "Kinder" Krankenhaus-Zusatzversicherungen</h2>
          Die leistungsstärksten Krankenhaus-Zusatzversicherungen für Kinder im
          Vergleich.
        </v-col>
        <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()">
          <v-icon left class="text-cyan" style="font-size: 78px"
            >mdi-play</v-icon
          ></v-col
        >
      </v-row>
      <v-row class="px-4" v-if="tableTyp == 'brilleAlle'">
        <v-col cols="12" md="10">
          <h2>Die leistungsstärksten Brillenversicherungen</h2>
          Die leistungsstärksten Brillenversicherungen im Vergleich.
        </v-col>
        <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()">
          <v-icon left class="text-orange" style="font-size: 78px"
            >mdi-play</v-icon
          ></v-col
        >
      </v-row>
      <v-row class="px-4" v-if="tableTyp == 'brilleLasik'">
        <v-col cols="12" md="10">
          <h2>LASIK Versicherungen im Vergleich & Test</h2>
          Die besten LASIK-Brillenversicherungen im Vergleich.
        </v-col>
        <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()">
          <v-icon left class="text-orange" style="font-size: 78px"
            >mdi-play</v-icon
          ></v-col
        >
      </v-row>
      <v-row class="px-4" v-if="tableTyp == 'brilleWarte'">
        <v-col cols="12" md="10">
          <h2>Wartezeit & Anfängliche Leistungsbegrenzugen im Vergleich</h2>
          Diese Brillenversicherungen haben die kürzesten Wartezeiten.
        </v-col>
        <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()">
          <v-icon left class="text-orange" style="font-size: 78px"
            >mdi-play</v-icon
          ></v-col
        >
      </v-row>
      <v-row class="px-4" v-if="tableTyp == 'brille60'">
        <v-col cols="12" md="10">
          <h2>Senioren Brillenversicherungen im Vergleich</h2>
          Die besten Brillen-Zusatzversicherungen für Senioren.
        </v-col>
        <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()">
          <v-icon left class="text-orange" style="font-size: 78px"
            >mdi-play</v-icon
          ></v-col
        >
      </v-row>
      <v-row class="px-4" v-if="tableTyp == 'brilleKinder'">
        <v-col cols="12" md="10">
          <h2>Brillenversicherungen Kinder im Vergleich & Test</h2>
          Diese Brillen-Zusatzversicherungen leisten am besten für Kinder.
        </v-col>
        <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()">
          <v-icon left class="text-orange" style="font-size: 78px"
            >mdi-play</v-icon
          ></v-col
        >
      </v-row>
      <v-row class="px-4" v-if="tableTyp == 'heilKinder'">
        <v-col cols="12" md="10">
          <h2>Kinder Heilpraktikerversicherungen</h2>
          Diese Heilpraktiker-Zusatzversicherungen leisten am besten für Kinder.
        </v-col>
        <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()">
          <v-icon left class="text-turk" style="font-size: 78px"
            >mdi-play</v-icon
          ></v-col
        >
      </v-row>
      <v-row class="px-4" v-if="tableTyp == 'heilAlle'">
        <v-col cols="12" md="10">
          <h2>Heilpraktiker-Zusatzversicherung Testsieger im Vergleich</h2>
          Die leistungsstärksten Heilpraktiker-Zusatzversicherungen im
          Vergleich.
        </v-col>
        <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()">
          <v-icon left class="text-turk" style="font-size: 78px"
            >mdi-play</v-icon
          ></v-col
        >
      </v-row>
      <v-row class="px-4" v-if="tableTyp == 'implantatVergleich'">
        <v-col cols="12" md="10">
          <h2>Zahnzusatzversicherungen für Implantate im Vergleich</h2>
          Diese Zahnzusatzversicherungen begrenzen die Leistung für Implantate
          weder auf einen Höchstbetrag noch auf eine maximale Anzahl.
        </v-col>
        <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()">
          <v-icon left class="text-blue" style="font-size: 78px"
            >mdi-play</v-icon
          ></v-col
        >
      </v-row>
      <v-row class="px-4" v-if="tableTyp == 'wartezeitVergleich'">
        <v-col cols="12" md="10">
          <h2>Zahnzusatzversicherungen ohne Wartezeiten im Vergleich</h2>
          Diese Zahnzusatzversicherungen bieten Leistungen ohne Wartezeit.
        </v-col>
        <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()">
          <v-icon left class="text-blue" style="font-size: 78px"
            >mdi-play</v-icon
          ></v-col
        >
      </v-row>
      <v-row class="px-4" v-if="tableTyp == 'startErwachsen'">
        <v-col cols="12" md="10">
          <h2>
            WaizmannTabelle Zahnzusatzversicherung |
            <span class="text-blue">Erwachsene</span>
          </h2>
          über 200 getestete Zahnzusatzversicherungen. Mathematisch objektives
          Bewertungsverfahren.
          <router-link to="/vergleich/starten"
            >Jetzt Vergleich starten</router-link
          >
        </v-col>
        <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()">
          <v-icon left class="text-blue" style="font-size: 78px"
            >mdi-play</v-icon
          ></v-col
        >
      </v-row>
      <v-row class="px-4" v-if="tableTyp == 'pzrFlat'">
        <v-col cols="12" md="10">
          <h2>
            WaizmannTabelle Zahnzusatzversicherung |
            <span class="text-pink">PZR-Flatrate</span>
          </h2>
          <ul class="my-2">
            <li>Alle <b>Zahnzusatzversicherungen mit PZR-Flatrate</b></li>
            <li>
              <b>100 % Erstattung</b> für alle professionellen Zahnreinigungen
            </li>
            <li>
              <b>PZR ohne Limit</b> | keine Begrenzung | volle Leistung | keine
              Wartezeit
            </li>
            <li>
              Abrechnung bis zur
              <b>Höchstsatz der Gebührenordnung für Zahnärzte (GOZ)</b>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()">
          <v-icon left class="text-pink" style="font-size: 78px"
            >mdi-play</v-icon
          ></v-col
        >
      </v-row>

      <v-row
        class="px-4"
        v-if="tableTyp == 'startKind' || tableTyp == 'startKindAlle'"
      >
        <v-col cols="12" md="10">
          <h2>
            WaizmannTabelle Zahnzusatzversicherung |
            <span class="yellowcolor">Kinder</span>
          </h2>
          Schwerpunkt Kieferorthopädie und Kinder-Zahnarztleistungen. Bewertung
          mittels objektiven KFO-Benchmarkverfahren (KIG 1-5).
          <router-link to="/vergleich/starten/kind"
            >Klicken Sie hier und vergleichen die besten
            Kinder-Zahnzusatzversicherungen.</router-link
          >
        </v-col>
        <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()">
          <v-icon left class="yellowcolor" style="font-size: 78px"
            >mdi-play</v-icon
          ></v-col
        >
      </v-row>

      <v-row
        class="px-4"
        v-if="tableTyp == 'startPlus' || tableTyp == 'startPlusAlle'"
      >
        <v-col cols="12" md="10">
          <h2>
            WaizmannTabelle Zahnzusatzversicherung |
            <span class="text-purple">60Plus</span>
          </h2>
          Auch über 60 Jahre noch beantragbar. Schwerpunkt Zahnersatz,
          Prothetik, Zahnerhalt.
        </v-col>
        <v-col cols="12" md="2" v-if="!sharedDataStore.isMobile()">
          <v-icon left class="text-purple" style="font-size: 78px"
            >mdi-play</v-icon
          ></v-col
        >
      </v-row>
      <v-row class="px-4">
        <v-col cols="12">
          <ErwachsenTableZahn
            :tarife="tarife"
            v-if="
              tarife &&
              tarife.length > 0 &&
              (tableTyp == 'startErwachsen' ||
                tableTyp == 'startErwachsenAlle' ||
                tableTyp == 'startErwachsenAlleFull')
            "
            :elementLine="tableTyp"
          />
          <KinderTableZahn
            v-if="
              tarife &&
              tarife.length > 0 &&
              (tableTyp == 'startKind' || tableTyp == 'startKindAlle')
            "
            :tarife="tarife"
            :elementLine="tableTyp"
          />
          <PlusTableZahn
            v-if="
              tarife &&
              tarife.length > 0 &&
              (tableTyp == 'startPlus' || tableTyp == 'startPlusAlle')
            "
            :tarife="tarife"
            :elementLine="tableTyp"
          />
          <PzrTableZahn
            v-if="tarife && tarife.length > 0 && tableTyp == 'pzrFlat'"
            :tarife="tarife"
            :elementLine="tableTyp"
            :alterPreis="19"
          />
          <ImplantatTableZahn
            v-if="
              tarife && tarife.length > 0 && tableTyp == 'implantatVergleich'
            "
            :tarife="tarife"
            :elementLine="tableTyp"
          />
          <WartezeitTableZahn
            v-if="
              tarife && tarife.length > 0 && tableTyp == 'wartezeitVergleich'
            "
            :tarife="tarife"
            :elementLine="tableTyp"
          />
          <KinderTableHeil
            v-if="tarife && tarife.length > 0 && tableTyp == 'heilKinder'"
            :tarife="tarife"
            alterPreis="9"
            :elementLine="tableTyp"
          />
          <KinderTableHeil
            v-if="tarife && tarife.length > 0 && tableTyp == 'heilAlle'"
            :tarife="tarife"
            alterPreis="19"
            :elementLine="tableTyp"
          />
          <KinderTableBrille
            v-if="tarife && tarife.length > 0 && tableTyp == 'brilleKinder'"
            :tarife="tarife"
            :elementLine="tableTyp"
          />
          <PlusTableBrille
            v-if="tarife && tarife.length > 0 && tableTyp == 'brille60'"
            :tarife="tarife"
            :elementLine="tableTyp"
          />
          <LasikTable
            v-if="tarife && tarife.length > 0 && tableTyp == 'brilleLasik'"
            :tarife="tarife"
            :elementLine="tableTyp"
          />
          <WarteTable
            v-if="tarife && tarife.length > 0 && tableTyp == 'brilleWarte'"
            :tarife="tarife"
            :elementLine="tableTyp"
          />
          <BrilleTable
            v-if="tarife && tarife.length > 0 && tableTyp == 'brilleAlle'"
            :tarife="tarife"
            :elementLine="tableTyp"
          />
          <KrankTable
            v-if="tarife && tarife.length > 0 && tableTyp == 'krankAlle'"
            :tarife="tarife"
            :elementLine="tableTyp"
            preisAlter="19"
          />
          <KrankTableKinder
            v-if="tarife && tarife.length > 0 && tableTyp == 'krankKinder'"
            :tarife="tarife"
            :elementLine="tableTyp"
            preisAlter="6"
          />
          <KrankTableMitAlter
            v-if="tarife && tarife.length > 0 && tableTyp == 'krankMitAlter'"
            :tarife="tarife"
            :elementLine="tableTyp"
            preisAlter="19"
          />
          <KrankTableOhneAlter
            v-if="tarife && tarife.length > 0 && tableTyp == 'krankOhneAlter'"
            :tarife="tarife"
            :elementLine="tableTyp"
            preisAlter="19"
          />
          <KrankTableBett
            v-if="
              tarife &&
              tarife.length > 0 &&
              (tableTyp == 'krankBett1' || tableTyp == 'krankBett2')
            "
            :tarife="tarife"
            :elementLine="tableTyp"
            preisAlter="19"
          />
        </v-col>
      </v-row>
    </v-card>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, defineProps, onMounted } from "vue";
  import { useSharedDataStore } from "@/stores/sharedData";
  import BaseCalls from "@/services/BaseCalls";
  import ErwachsenTableZahn from "@/views/site/tarif/zahn/ErwachsenTable";
  import KinderTableZahn from "@/views/site/tarif/zahn/KinderTable";
  import PlusTableZahn from "@/views/site/tarif/zahn/PlusTable";
  import PzrTableZahn from "@/views/site/tarif/zahn/PzrTableZahn";
  import ImplantatTableZahn from "@/views/site/tarif/zahn/ImplantatTableZahn";
  import WartezeitTableZahn from "@/views/site/tarif/zahn/WartezeitTableZahn";
  import KinderTableHeil from "@/views/site/tarif/heil/KinderTable";
  import KinderTableBrille from "@/views/site/tarif/brille/KinderTable";
  import PlusTableBrille from "@/views/site/tarif/brille/PlusTable";
  import LasikTable from "@/views/site/tarif/brille/LasikTable";
  import WarteTable from "@/views/site/tarif/brille/WarteTable";
  import BrilleTable from "@/views/site/tarif/brille/BrilleTable";
  import KrankTable from "@/views/site/tarif/krank/KrankTable";
  import KrankTableKinder from "@/views/site/tarif/krank/KrankTableKinder";
  import KrankTableMitAlter from "@/views/site/tarif/krank/KrankTableMitAlter.vue";
  import KrankTableOhneAlter from "@/views/site/tarif/krank/KrankTableOhneAlter.vue";
  import KrankTableBett from "@/views/site/tarif/krank/KrankTableBett.vue";
  const sharedDataStore = useSharedDataStore();
  const props = defineProps({
    tableTyp: {
      type: String,
    },
  });
  const appLoaded = ref(false);
  const tarife = ref(false);
  async function getTarife() {
    console.log("getTarife " + props.tableTyp);
    let select = {};
    if (props.tableTyp == "krankAlle") {
      select.action = "getStaticKrankTarife";
      select.select = props.tableTyp;
      select.addPreis = 21;
    }
    if (props.tableTyp == "krankBett1") {
      select.action = "getStaticKrankTarife";
      select.select = props.tableTyp;
      select.addPreis = 21;
    }
    if (props.tableTyp == "krankBett2") {
      select.action = "getStaticKrankTarife";
      select.select = props.tableTyp;
      select.addPreis = 21;
    }
    if (props.tableTyp == "krankOhneAlter") {
      select.action = "getStaticKrankTarife";
      select.select = props.tableTyp;
      select.addPreis = 21;
    }
    if (props.tableTyp == "krankMitAlter") {
      select.action = "getStaticKrankTarife";
      select.select = props.tableTyp;
      select.addPreis = 21;
    }
    if (props.tableTyp == "krankKinder") {
      select.action = "getStaticKrankTarife";
      select.select = props.tableTyp;
      select.addPreis = 6;
    }
    if (props.tableTyp == "startErwachsen") {
      select.action = "getStaticZahnTarife";
      select.select = props.tableTyp;
      select.addPreis = 19;
    }
    if (props.tableTyp == "startErwachsenAlle") {
      select.action = "getStaticZahnTarife";
      select.select = props.tableTyp;
      select.addPreis = 19;
    }
    if (props.tableTyp == "startErwachsenAlleFull") {
      select.action = "getStaticZahnTarife";
      select.select = props.tableTyp;
      select.addPreis = 19;
    }

    if (props.tableTyp == "startKind") {
      select.action = "getStaticZahnTarife";
      select.select = props.tableTyp;
      select.addPreis = 10;
    }
    if (props.tableTyp == "startKindAlle") {
      select.action = "getStaticZahnTarife";
      select.select = props.tableTyp;
      select.addPreis = 10;
    }
    if (props.tableTyp == "pzrFlat") {
      select.action = "getStaticZahnTarife";
      select.select = props.tableTyp;
      select.addPreis = 19;
    }
    if (props.tableTyp == "implantatVergleich") {
      select.action = "getStaticZahnTarife";
      select.select = "startErwachsen";
      select.addPreis = 19;
    }
    if (props.tableTyp == "wartezeitVergleich") {
      select.action = "getStaticZahnTarife";
      select.select = "wartezeitVergleich";
      select.addPreis = 19;
    }
    if (props.tableTyp == "heilKinder") {
      select.action = "getStaticHeilTarife";
      select.select = props.tableTyp;
      select.addPreis = 6;
    }
    if (props.tableTyp == "heilAlle") {
      select.action = "getStaticHeilTarife";
      select.select = props.tableTyp;
      select.addPreis = 19;
    }
    if (props.tableTyp == "brilleKinder") {
      select.action = "getStaticBrilleTarife";
      select.select = props.tableTyp;
      select.addPreis = 6;
    }
    if (props.tableTyp == "brille60") {
      select.action = "getStaticBrilleTarife";
      select.select = props.tableTyp;
      select.addPreis = 55;
    }
    if (props.tableTyp == "brilleLasik") {
      select.action = "getStaticBrilleTarife";
      select.select = props.tableTyp;
      select.addPreis = 55;
    }
    if (props.tableTyp == "brilleWarte") {
      select.action = "getStaticBrilleTarife";
      select.select = props.tableTyp;
      select.addPreis = 19;
    }
    if (props.tableTyp == "brilleAlle") {
      select.action = "getStaticBrilleTarife";
      select.select = props.tableTyp;
      select.addPreis = 19;
    }

    if (props.tableTyp == "startPlus") {
      select.action = "getStaticZahnTarife";
      select.select = props.tableTyp;
      select.addPreis = 60;
    }
    if (props.tableTyp == "startPlusAlle") {
      select.action = "getStaticZahnTarife";
      select.select = props.tableTyp;
      select.addPreis = 60;
    }

    try {
      const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.tarife) {
        tarife.value = resp.data.tarife;
        appLoaded.value = true;
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }

  console.log(props.tableType);

  onMounted(() => {
    getTarife();
  });
</script>
