<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2>Heilpraktiker-Zusatzversicherung Psychotherapie</h2>
        Nicht alle Heilpraktiker-Zusatzversicherungen leisten für
        Psychotherapie. Aber es gibt einzelne Tarife.<br />
        Bei Leistungen für Psychotherapie kommt es maßgeblich auf die
        Bedingungen bzw. das Tarifwerk der Heilpraktikerversicherung an. Einige
        Tarife leisten dabei im Rahmen der Position “19 Psychotherapie” im
        Gebührenverzeichnis für Heilpraktiker (GebüH). Medizinische
        Notwendigkeit vorausgesetzt.

        <br /><br />

        <v-card>
          <v-card-title>Unser Rat:</v-card-title>
          <v-card-text
            >Da gesetzlich Versicherte gegenüber der GKV einen Anspruch auf eine
            Psychotherapie haben, empfiehlt es sich, VOR Beginn einer Behandlung
            mit einem Heilpraktiker Tarif unbedingt eine schriftliche
            Kostenzusage der Heilpraktikerversicherung zu erhalten, also eine
            Leistungsfall-Prüfung zur Kostenübernahme vorab durchführen zu
            lassen.</v-card-text
          >
        </v-card>
        <br /><br />
        <v-btn outlined color="#088a85" href="/vergleich/starten/heilpraktiker"
          >jetzt Heilpraktiker Zusatzversicherung vergleichen</v-btn
        >
        <br /><br />

        <h3>Der Heilpraktiker Psychotherapie als Anlaufstelle</h3>
        Eine Heilpraktikerversicherung inkl. Psychotherapie-Leistungen bietet
        eine gute Option für Behandlungen wie z.B. Stress, Burnout sowie
        depressiven Verstimmungen. Speziell ausgebildete Heilpraktiker für
        Psychotherapie sind hierfür eine gute Anlaufstelle.
        <ul>
          <li>
            <a href="/heilpraktikerversicherung_vergleich"
              >alle Heilpraktikerversicherungen im Vergleich</a
            >
          </li>
          <li>
            <a href="/kinder_heilpraktikerversicherung_vergleich"
              >Kinder-Heilpraktikerversicherungen im Vergleich</a
            >
          </li>
        </ul>
        <br /><br />
        <strong>Wichtig:</strong> Bereits eingetretene Leistungsfälle können
        nicht mehr versichert werden. In den Gesundheitsfragen, welche der
        Antragsteller zu beantworten hat, können bis zu 10 Jahren rückwirkend
        psychotherapeutische Behandlungen bzw. Erkrankungen abgefragt werden.
        <br /><br />

        <h3>Heilpraktikerversicherung trotz Psychotherapie</h3>
        Befindet sich der Antragsteller aktuell in einer psychotherapeutischen
        Behandlung bzw. wurde eine angeraten, gibt es dafür keine Leistung mehr.
        Es werden auch diese Tarife wohl nicht mehr abschließbar sein, welche
        für Psychotherapie leisten würden. Oft beinhalten Gesundheitsfragen auch
        explizite Fragen nach Psychotherapie in den vergangenen, teils bis zu 10
        Jahren.
        <br /><br />

        <h3>Wartezeit Psychotherapie bei der Heilpraktikerversicherung</h3>
        Für die Leistung Psychotherapie gilt i.d.R. eine besondere Wartezeit von
        8 Monaten.
        <br /><br />
        <v-btn outlined color="#088a85" href="/vergleich/starten/heilpraktiker"
          >jetzt Heilpraktiker Zusatzversicherung vergleichen</v-btn
        >
        <br /><br />

        <h3>
          Tarif-Empfehlung: Folgende Heilpraktikerversicherungen leisten für
          Psychotherapie
        </h3>
        <ul>
          <li>
            <a href="/heilpraktikerversicherung/id/270"
              >Heilpraktiker-Zusatzversicherung Barmenia Mehr Gesundheit
              2.000</a
            >
          </li>
        </ul>
        <ul>
          <li>
            <a href="/heilpraktikerversicherung/id/269"
              >Heilpraktiker-Zusatzversicherung Barmenia Mehr Gesundheit
              1.000</a
            >
          </li>
        </ul>
        <ul>
          <li>
            <a href="/heilpraktikerversicherung/id/257"
              >Heilpraktiker-Zusatzversicherung Gothaer Medi Ambulant</a
            >
          </li>
        </ul>
        <br /><br />
        <v-btn outlined color="#088a85" href="/vergleich/starten/heilpraktiker"
          >Jetzt Berechnung starten</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script setup></script>
