<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h2 class="subtitle-3">
          WaizmannTabelle |
          <span style="color: #00bcd4"
            >Krankenhauszusatzversicherung ohne Gesundheitsfragen</span
          >
        </h2>

        <h3>
          Gibt es eine Krankenhauszusatzversicherung ohne Gesundheitsfragen?
          <b>Ja, aber mit Einschränkungen!</b>
        </h3>

        <br /><br />

        Eine Zusatzversicherung für den stationären Bereich mit vollem
        Leistungsspektrum ist nur mit Beantwortung einfacher oder normaler bis
        schwerer Gesundheitsfragen abschließbar. Es gibt allerdings einen
        Anbieter, der Krankenhaus-Zusatzversicherungen ohne Gesundheitsfragen
        anbietet. Hier müssen allerdings Einschränkungen beim Leistungsumfang
        hingenommen werden.

        <br /><br />

        <ul>
          <li>
            <a href="/krankenhauszusatzversicherung_vergleich"
              >Alle Krankenhaus-Zusatzversicherungen im Vergleich</a
            >
          </li>
        </ul>

        <h3>
          Krankenhauszusatzversicherungen mit Chefarztbehandlung ohne
          Gesundheitsfragen gibt es nicht – warum?
        </h3>

        Behandlungen gerade durch den Chefarzt bergen für den Versicherer ein
        besonderes Kostenrisiko. Um dieses Risiko, welches bei bereits schwer
        oder chronisch vorerkrankten Personen um ein vielfaches höher ist, zu
        verringern, werden bei Tarifen, welche die Chefarztbehandlung
        beinhalten, auf jeden Fall Gesundheitsfragen im Antrag gestellt.
        <br />
        <v-btn
          href="/vergleich/starten/krankenhaus"
          class="button_caps mb-4 text-white"
          style="background-color: #00bcd4"
          >Online-Vergleich für Krankenhaus-Zusatzversicherungen jetzt
          starten</v-btn
        >

        <h3>
          Gesundheitsfragen bei Krankenhaus-Zusatzversicherungen – was genau ist
          das, wie funktioniert der Abschluss, welche Probleme kann es dabei
          geben?
        </h3>

        <br />

        Die meisten privaten Krankenzusatzversicherer verlangen beim Abschluss
        einer Krankenhaus-Zusatzversicherung die Beantwortung von
        Gesundheitsfragen. Dazu werden im Antrag leichte oder auch normale bis
        schwere Gesundheitsfragen gestellt. Die Annahme des Antrags durch den
        Versicherer erfolgt dann einzig und allein anhand der Beantwortung
        dieser Gesundheitsfragen.

        <br /><br />

        Eine Nachprüfung der im Antrag gemachten Angaben findet im Rahmen der
        Risikoprüfung durch den Versicherer zur Antragsannahme nicht statt. Erst
        beim ersten Leistungsfall wird die sog. „nachgelagerte
        Gesundheitsprüfung“, auch „VVA-Prüfung“ (Prüfung auf Verletzung der
        vorvertraglichen Anzeigepflicht) genannt, durchgeführt. Dazu erhält der
        aktuelle Behandler der versicherten Person aber ggf. auch eventuelle
        Vorbehandler Arztrückfragebögen zum Beantworten. Diese Rückfragen sind
        über einen Zeitraum von bis zu 10 Jahren rückwirkend möglich. Im Zweifel
        kann der Versicherer sogar Einsicht in die Patientenakte verlangen.

        <br /><br />

        Durch diese Arztrückfragebögen bzw. im Einzelfall die Kopien der
        Patientenakten ist es den Krankenversicherern möglich zu prüfen, ob die
        Gesundheitsfragen im Antrag korrekt beantwortet wurden oder ob ggf.
        schon Versicherungsfälle vor Abschluss der Versicherung eingetreten
        waren.

        <br /><br />

        Wurden Gesundheitsfragen im Antrag – dabei ist es unerheblich, ob
        versehentlich oder wissentlich – falsch beantwortet, wird der
        Versicherer Rechtsmaßnahmen einleiten. Diese sind im Detail:

        <br /><br />

        <b>Leistungsausschluss</b><br />

        Dabei ist die nachträgliche Vereinbarung von Leistungsausschlüssen das
        „geringere Übel“. Der Versicherer leistet dann für stationäre
        Aufenthalte aufgrund bestimmter Erkrankungen nicht mehr. Alles andere
        ist und bleibt aber vollumfänglich tarifgemäß versichert.

        <br /><br />

        <b>Rücktritt vom Vertrag</b><br />

        Wesentlich schlimmer ist es, wenn der Vertrag, falls die
        Gesundheitsfragen korrekt beantwortet worden wären, nicht zustande
        gekommen wäre. In diesem Fall wird der Versicherer rückwirkend ab Beginn
        vom Versicherungsvertrag zurücktreten – es kommt also zum Verlust des
        Versicherungsschutzes.

        <br /><br />

        <b>Anfechtung des Vertrags</b><br />

        Der „Worst-Case“ tritt dann ein, wenn der Versicherer nachweisen kann,
        dass die Gesundheitsfragen wissentlich falsch beantwortet wurden. In
        diesem Fall kommt es zur rückwirkenden Anfechtung des
        Versicherungsvertrags. Der Unterschied zum Rücktritt liegt darin, dass
        eventuell bereits gewährte Versicherungsleistungen zusätzlich zum
        rückwirkenden Verlust des Versicherungsschutzes zurückgezahlt werden
        müssen.

        <br /><br />

        Daher ist es extrem wichtig, dass die Gesundheitsfragen im Antrag 100%ig
        korrekt beantwortet werden. Sind Sie sich bei der Beantwortung einer
        Gesundheitsfrage nicht sicher, fragen Sie lieber in der Arztpraxis nach
        der korrekten Antwort. Wichtig dabei ist, dass Sie die Fragen
        telefonisch abklären und sich nicht nochmals untersuchen lassen sollten.
        Wird bei dieser Untersuchung etwas festgestellt, wäre das nicht mehr
        versicherbar und kann sogar dazu führen, dass der Abschluss der
        Krankenhauszusatzversicherung nicht mehr möglich ist.

        <br /><br />

        Meist werden Gesundheitsfragen im Antrag nicht ganz allgemein, sondern
        nur über einen bestimmten Zeitraum rückwirkend gestellt. Sind eventuelle
        Vorerkrankungen bereits länger her, kann die Frage im Antrag mit „NEIN“
        beantwortet werden. Diese Zeiträume sind:

        <br /><br />

        <ul>
          <li>Fragen zu speziellen Krankheiten drei Jahre rückwirkend</li>
          <li>Fragen zu stationären Aufenthalten fünf Jahre rückwirkend</li>
          <li>
            Fragen zu psychotherapeutischen Behandlungen zehn Jahre rückwirkend
          </li>
        </ul>

        <br />

        Eventuell ist es somit möglich eine noch kurze Zeitspanne abzuwarten,
        bis die entsprechende Frage im Antrag mit „NEIN“ beantwortet werden
        kann.

        <br /><br />

        Zusätzlich können auch Fragen zu regelmäßig einzunehmenden Medikamenten,
        durchzuführenden Untersuchungen oder Behandlungen bzw. zu Behinderungen
        oder bleibenden Einschränkungen gestellt werden.

        <br /><br /><br />

        <h3>Tarife ohne Gesundheitsfragen</h3>

        <br />

        Krankenhaus-Zusatzversicherungen ohne Gesundheitsfragen gibt es nur von
        einem einzigen Anbieter. Aufgrund der Abschließbarkeit für „Jedermann“
        kommt es zu Einschränkungen hinsichtlich des Leistungsumfangs. So ist
        unter anderem die Chefarztbehandlung grundsätzlich vom
        Versicherungsschutz ausgeschlossen. Die Tarife leisten im Großen und
        Ganzen ausschließlich für die Unterbringung im Ein- oder Zweibettzimmer.
        Krankenhauszusatzversicherungen ohne Gesundheitsfragen sind:

        <br /><br />

        <ul>
          <li>
            <a href="/krankenhauszusatzversicherung/id/315"
              >Krankenhauszusatzversicherung DKV UZ1</a
            >
          </li>
          <li>
            <a href="/krankenhauszusatzversicherung/id/318"
              >Krankenhauszusatzversicherung DKV UZ2</a
            >
          </li>
        </ul>

        <br /><br />

        <h3>Tarife mit einfachen Gesundheitsfragen</h3>

        <br />

        Bei Krankenhauszusatzversicherungen mit einfachen Gesundheitsfragen
        werden im Antrag nur eine oder maximal zwei Gesundheitsfrage/n gestellt.
        Dabei ist eine Gesundheitsfrage relativ umfangreich und fragt das
        gesamte Spektrum des Körpers ab. Kann diese Frage aber mit „NEIN“
        beantwortet werden, ist eine Annahme des Antrags durch den
        Krankenzusatzversicherer garantiert. Krankenhaus-Zusatzversicherungen
        mit einfachen Gesundheitsfragen sind:

        <br /><br />

        <ul>
          <li>
            <a href="/krankenhauszusatzversicherung/id/286"
              >Krankenhauszusatzversicherung Barmenia Mehr Komfort 1-Bett K</a
            >
          </li>
          <li>
            <a href="/krankenhauszusatzversicherung/id/285"
              >Krankenhauszusatzversicherung Barmenia Mehr Komfort 1-Bett</a
            >
          </li>
          <li>
            <a href="/krankenhauszusatzversicherung/id/288"
              >Krankenhauszusatzversicherung Barmenia Mehr Komfort 2-Bett K</a
            >
          </li>
          <li>
            <a href="/krankenhauszusatzversicherung/id/287"
              >Krankenhauszusatzversicherung Barmenia Mehr Komfort 2-Bett</a
            >
          </li>
          <li>
            <a href="/krankenhauszusatzversicherung/id/345"
              >Krankenhauszusatzversicherung Nürnberger SG1</a
            >
          </li>
          <li>
            <a href="/krankenhauszusatzversicherung/id/347"
              >Krankenhauszusatzversicherung Nürnberger SG2</a
            >
          </li>
          <li>
            <a href="/krankenhauszusatzversicherung/id/346"
              >Krankenhauszusatzversicherung Nürnberger SG2R</a
            >
          </li>
        </ul>

        <br /><br />

        <h3>Tarife mit normalen bis schweren Gesundheitsfragen</h3>

        <br />

        Die meisten Tarife für stationäre Zusatzversicherungen haben normale bis
        schwere Gesundheitsfragen. Hier werden im Antrag bis zu zehn, teilweise
        noch mehr Gesundheitsfragen gestellt. Ein Problem bei der Annahme des
        Antrags kann hier jeweils die Frage nach dem sog. „Body-Mass-Index“
        sein. Entweder sind Größe und Gewicht im Antrag direkt anzugeben oder
        der Versicherer stellt die Formel zur Berechnung zur Verfügung, anhand
        derer der Antragsteller gleich selbst berechnet und einträgt. Da die
        privaten Krankenversicherer meist nicht offenlegen ab welchem bzw. bis
        zu welchem Body-Mass-Index eine Annahme des Antrags, ggf. noch mit
        Risikozuschlag, möglich ist, kann eine Annahme des Antrags nicht
        garantiert werden.

        <br /><br /><br />

        <v-card class="my-4 pa-4">
          <v-card-title>TIPP vom KV-Spezialisten: </v-card-title>
          <v-card-text
            >Sollten Sie bereits einen bestimmten Tarif ins Auge gefasst haben,
            dieser Ihnen aber aufgrund der beantworteten Gesundheitsfrage im
            Online-Rechner als nicht abschließbar angezeigt werden, stellen wir
            auch gerne eine Risikovorabanfrage an den jeweiligen Versicherer.
            <br />Während z. B. Erkrankungen des Herz-/Kreislaufsystems zu einer
            Ablehnung des Antrags führen, kann ein gut eingestellter Blutdruck
            meist sogar ohne Risikozuschlag versichert werden, obwohl in beiden
            Fällen die entsprechende Gesundheitsfrage mit „JA“ zu beantworten
            wäre. <br />Bitte kontaktieren Sie uns dann einfach kurz per Email
            unter info@waizmanntabelle.de. Gerne unterstützen wir Sie dann nach
            Möglichkeit beim Abschluss im gewünschten Tarif.
          </v-card-text>
        </v-card>

        <br /><br /><br />

        <v-btn
          href="/vergleich/starten/krankenhaus"
          class="button_caps mb-4"
          style="background-color: #00bcd4"
          >Online-Vergleich für Krankenhaus-Zusatzversicherungen jetzt
          starten</v-btn
        >
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts typeInfo="krank"
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Beste Krankenhauszusatzversicherung ohne Gesundheitsfragen",
      "Jetzt eine Krankenhauszusatzversicherung ohne Gesundheitsfrage finden | Nur ein Anbieter am Markt | Abschluss für jeden möglich"
    );
  });
</script>
