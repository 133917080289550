<template>
  <div>
    <v-table>
      <thead>
        <tr style="vertical-align: top">
          <th colspan="2" style="width: 80px" class="pinkground pa-2">
            <h2 class="text-white">Top {{ tarife.length }} | PZR-Flatrate</h2>
          </th>
          <th class="pa-2 greyground">
            <TooltipHelper toolkey="tarif_kwert_gesamt">
              <template v-slot:toolTipText>
                <a>Prophylaxe</a>
              </template>
            </TooltipHelper>
          </th>
          <th class="pa-2 greyground">
            <TooltipHelper toolkey="tarif_waizmannwert">
              <template v-slot:toolTipText>
                <a
                  >Ø-Erstattung
                  <br />
                  <span style="font-size: 10px">Realwert-Methode</span></a
                >
              </template>
            </TooltipHelper>
          </th>
          <th class="pa-2 greyground">
            <TooltipHelper toolkey="tarif_kwert_gesamt">
              <template v-slot:toolTipText>
                <a>Zahnersatz</a>
              </template>
            </TooltipHelper>
          </th>
          <th class="pa-2 greyground">
            <TooltipHelper toolkey="tarif_kwert_gesamt">
              <template v-slot:toolTipText>
                <a>Zahnerhalt</a>
              </template>
            </TooltipHelper>
          </th>

          <th class="pa-2 greyground">
            Preis<br />
            {{ alterPreis }} Jahre
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          style="vertical-align: top"
          v-for="tarif in tarife"
          :key="tarif.tarif_id"
        >
          <td>#{{ tarif.rank }}</td>
          <td>
            <strong
              ><a
                @click="
                  berechnungStore.setShowPopAppTarif(tarif, 'showTarifOptions')
                "
                >{{ tarif.versicherung }}</a
              >
            </strong>

            <br />
            {{ tarif.tarif_title }}
          </td>
          <td>
            <strong>{{ tarif.tarif_leistung_pzr }} %</strong>

            <b v-if="tarif.tarif_pzr_summe == 0" class="text-green">
              <br />
              ohne Begrenzung
            </b>
            <b v-if="tarif.tarif_pzr_summe > 0" class="text-green"
              ><br />bis {{ tarif.tarif_pzr_summe }} € pro Jahr</b
            >
          </td>
          <td>
            <b>{{ tarif.waizmannwert }}</b>
          </td>
          <td>
            <strong>{{ tarif.tarif_leistung_ze }} %</strong>
          </td>
          <td>
            <strong>{{ tarif.tarif_leistung_zb }} %</strong>
          </td>

          <td>{{ tarif.preis_value }}€</td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script setup>
  import TooltipHelper from "@/components/base/TooltipHelper.vue";
  import { defineProps } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  const berechnungStore = useBerechnungStore();
  const props = defineProps({
    elementLine: {
      type: String,
    },
    alterPreis: {
      type: String,
    },
    tarife: {
      type: Array,
    },
  });
  console.log(props.tarif);
</script>
<style scoped>
  td {
    padding-top: 8px !important;
  }
</style>
