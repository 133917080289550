<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card style="min-height: 800px; max-width: 888px">
          <v-card-title hidden-xs-only class="headline"
            >Vergleichen Sie 2 Tarife miteinander</v-card-title
          >
          <v-card-text>
            <v-alert
              v-if="isMobile"
              border="top"
              colored-border
              type="info"
              elevation="2"
              >Drehen Sie bitte Ihr Handy um diesen Vergleich vollständig nutzen
              zu können.</v-alert
            >

            <v-table>
              <thead>
                <tr>
                  <th style="font-size: 14px">Tarif</th>
                  <th style="width: 300px; font-size: 14px" class="blue--text">
                    {{ tarif1.un_name }}
                    {{ tarif1.tarif_title }}
                  </th>
                  <th style="width: 300px; font-size: 14px" class="blue--text">
                    {{ tarif2.un_name }}
                    {{ tarif2.tarif_title }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Ø-Erstattung</strong>
                    <br />
                    <span class="caption">WaizmannWert</span>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_waizmannwert }} %</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_waizmannwert">
                      <strong>{{ tarif2.tarif_waizmannwert }} %</strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Ø-Erstattung</strong>
                    <br />
                    <span class="caption m">Kieferorthopädie</span>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_kwert_gesamt }} %</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_kwert_gesamt">
                      <strong>{{ tarif2.tarif_kwert_gesamt }} %</strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong>Clearment</strong>
                    <br />
                    <span class="caption">abgesichert</span>
                  </td>
                  <td>
                    <strong
                      v-if="tarif1.tarif_clearment_id > 0"
                      class="green-text"
                    >
                      Ja
                      <sup>kostenlos</sup>
                    </strong>
                    <strong
                      v-if="tarif1.tarif_clearment_id == 0"
                      class="red-text"
                      >Nein</strong
                    >
                  </td>
                  <td>
                    <strong
                      v-if="tarif2.tarif_clearment_id > 0"
                      class="green-text"
                    >
                      Ja
                      <sup>kostenlos</sup>
                    </strong>
                    <strong
                      v-if="tarif2.tarif_clearment_id == 0"
                      class="red-text"
                      >Nein</strong
                    >
                  </td>
                </tr>
                <!-- Enden FZ -->

                <!-- Ende ANgebot -->
                <!-- Start KFO -->

                <!-- ende Kinder -->
                <tr>
                  <td colspan="3">&nbsp;</td>
                </tr>
                <!-- Start ertse Jahre -->
                <tr>
                  <td colspan="3">
                    <strong class="blue--text" style="font-size: 15px"
                      >Leistungsübersicht der ersten Versicherungsjahren</strong
                    >
                    | Zahnersatz, Zahnerhalt und PZR
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Ø-Erstattung</strong>
                    <br />
                    <span class="caption">im 1.-4. Jahr</span>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_wwert_4 }} %</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_wwert_4">
                      <strong>{{ tarif2.tarif_wwert_4 }} %</strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Ø-Erstattung</strong>
                    <br />
                    <span class="caption">im 5.-8. Jahr</span>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_wwert_8 }} %</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_wwert_8">
                      <strong>{{ tarif2.tarif_wwert_8 }} %</strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Max. Erstattung</strong>
                    <br />
                    <span class="caption">im 1.-2. Jahr</span>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_int_begrenzung_2 }}</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_int_begrenzung_2">
                      <strong>{{ tarif2.tarif_int_begrenzung_2 }}</strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Max. Erstattung</strong>
                    <br />
                    <span class="caption">im 3.-4. Jahr</span>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_int_begrenzung_4 }}</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_int_begrenzung_4">
                      <strong>{{ tarif2.tarif_int_begrenzung_4 }}</strong>
                    </span>
                  </td>
                </tr>

                <!-- ende erste Jahre erwacshsen -->

                <!--  wichtig Exkdaten Erwachsen -->
                <tr>
                  <td colspan="3">&nbsp;</td>
                </tr>
                <tr>
                  <td colspan="3">
                    <strong class="blue--text" style="font-size: 15px"
                      >Wichtige Leistungseckdaten</strong
                    >
                    | tarifliche Erstattung ohne Berücksichtigung von
                    Begrenzungen
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Zahnersatz</strong>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_leistung_ze }} %</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_id">
                      <strong>{{ tarif2.tarif_leistung_ze }} %</strong>
                    </span>
                  </td>
                </tr>
                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Zahnerhalt</strong>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_leistung_zb }} %</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_id">
                      <strong>{{ tarif2.tarif_leistung_zb }} %</strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Prophylaxe</strong>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_leistung_pzr }} %</strong>
                    &nbsp;|&nbsp;{{ tarif1.show_pzr_grenze }}
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_id">
                      <strong>{{ tarif2.tarif_leistung_pzr }} %</strong>
                      &nbsp;|&nbsp;{{ tarif2.show_pzr_grenze }}
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Hightech</strong>
                    <br />
                    <span class="caption">Zahnarzt-Leistungen</span>
                  </td>
                  <td>
                    <ul class="body-2">
                      <li v-for="item in tarif1.hightechLog" :key="item.id">
                        <span v-if="item.ja">{{ item.value }}</span>
                        <span
                          v-if="!item.ja"
                          class="red-text"
                          style="text-decoration: line-through"
                          >{{ item.value }}</span
                        >
                      </li>
                    </ul>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_id">
                      <ul class="body-2">
                        <li v-for="item in tarif2.hightechLog" :key="item.id">
                          <span v-if="item.ja">{{ item.value }}</span>
                          <span
                            v-if="!item.ja"
                            class="red-text"
                            style="text-decoration: line-through"
                            >{{ item.value }}</span
                          >
                        </li>
                      </ul>
                    </span>
                  </td>
                </tr>
                <!--  Ende Eckdaten Erwachsene -->
                <tr>
                  <td colspan="3">&nbsp;</td>
                </tr>

                <tr>
                  <td colspan="3">
                    <strong class="blue--text" style="font-size: 15px"
                      >Übersicht Kieferorthopädie-Leistungen &amp;
                      Kinder-Zahnarztleistungen</strong
                    >
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Ø-Erstattung</strong>
                    <br />
                    <span class="caption">KFO KIG 1-2</span>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_kwert_1 }} %</strong>
                    <span v-if="tarif1.tarif_leistung_kfo_med_not == 1">
                      <br />
                      <b class="green-text">KFO-Sonderklausel vorhanden</b>
                      <br />
                      Im Bereich Kieferorthopädie bei Kindern leistet dieser
                      Tarif auch unabhängig von der medizinischen Notwendigkeit
                      für kieferorthopädische Maßnahmen schon bei leichten
                      Fehlstellungen.
                    </span>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_kwert_1">
                      <strong>{{ tarif2.tarif_kwert_1 }} %</strong>
                      <span v-if="tarif1.tarif_leistung_kfo_med_not == 1">
                        <br />
                        <b class="green-text">KFO-Sonderklausel vorhanden</b>
                        <br />
                        Im Bereich Kieferorthopädie bei Kindern leistet dieser
                        Tarif auch unabhängig von der medizinischen
                        Notwendigkeit für kieferorthopädische Maßnahmen schon
                        bei leichten Fehlstellungen.
                      </span>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Ø-Erstattung</strong>
                    <br />
                    <span class="caption">KFO KIG 3-5</span>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_kwert_3 }} %</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_kwert_3">
                      <strong>{{ tarif2.tarif_kwert_3 }} %</strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">KFO Begrenzung</strong>
                    <br />
                    <span class="caption">KIG 3-5</span>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_leistung_kfo_beg_ogkv }}</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_leistung_kfo_beg_ogkv">
                      <strong>{{ tarif2.tarif_leistung_kfo_beg_ogkv }}</strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">KFO Begrenzung</strong>
                    <br />
                    <span class="caption">KIG 1-2</span>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_leistung_kfo_beg_mgkv }}</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_leistung_kfo_beg_mgkv">
                      <strong>{{ tarif2.tarif_leistung_kfo_beg_mgkv }}</strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Wartezeit</strong>
                    <br />
                    <span class="caption">für KFO-Leistungen</span>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_beschreibung_wz_kfo }}</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_beschreibung_wz_kfo">
                      <strong>{{ tarif2.tarif_beschreibung_wz_kfo }}</strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Leistungen</strong>
                    <br />
                    <span class="caption">Kinder-Zahnarzt</span>
                  </td>
                  <td>
                    <ul class="body-2">
                      <li v-for="item in tarif1.kinderLog" :key="item.id">
                        <span v-if="item.ja">{{ item.value }}</span>
                        <span
                          v-if="!item.ja"
                          class="red-text"
                          style="text-decoration: line-through"
                          >{{ item.value }}</span
                        >
                      </li>
                    </ul>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_kwert_3">
                      <ul class="body-2">
                        <li v-for="item in tarif2.kinderLog" :key="item.id">
                          <span v-if="item.ja">{{ item.value }}</span>
                          <span
                            v-if="!item.ja"
                            class="red-text"
                            style="text-decoration: line-through"
                            >{{ item.value }}</span
                          >
                        </li>
                      </ul>
                    </span>
                  </td>
                </tr>

                <!-- Zahnestaz Cluster -->

                <tr>
                  <td colspan="3">
                    <strong class="blue--text" style="font-size: 15px"
                      >Leistungen für Zahnersatz</strong
                    >
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Zahnersatz</strong>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_leistung_ze }} %</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_id">
                      <strong>{{ tarif2.tarif_leistung_ze }} %</strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong>Wartezeit</strong>
                    <br />
                    <span class="caption">für Zahnersatz</span>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_beschreibung_wz_ze }}</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_id">
                      <strong>{{ tarif2.tarif_beschreibung_wz_ze }}</strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Implantate</strong>
                    <br />
                    <span class="caption">Begr. Anzahl</span>
                    <br />
                    <span class="caption">Begr. Betrag</span>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_beschreibung_implantat }}</strong>
                    <br />
                    {{ tarif1.tarif_beschreibung_implantat_beg_anzahl }}
                    <br />
                    {{ tarif1.tarif_beschreibung_implantat_beg_betrag }}
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_id">
                      <strong>{{ tarif2.tarif_beschreibung_implantat }}</strong>
                      <br />
                      {{ tarif2.tarif_beschreibung_implantat_beg_anzahl }}
                      <br />
                      {{ tarif2.tarif_beschreibung_implantat_beg_betrag }}
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Inlays</strong>
                    <br />
                    <span class="caption">Begrenzungen</span>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_beschreibung_inlay }}</strong>
                    <br />
                    {{ tarif1.tarif_beschreibung_inlay_beg }}
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_id">
                      <strong>{{ tarif1.tarif_beschreibung_inlay }}</strong>
                      <br />
                      {{ tarif1.tarif_beschreibung_inlay_beg }}
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong>Verblendungen</strong>
                    <br />
                    <span class="caption">Begrenzungen</span>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_beschreibung_verblendung }}</strong>
                    <br />
                    {{ tarif1.tarif_beschreibung_verblendung_beg }}
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_id">
                      <strong>{{
                        tarif2.tarif_beschreibung_verblendung
                      }}</strong>
                      <br />
                      {{ tarif2.tarif_beschreibung_verblendung_beg }}
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong>Knochenaufbau</strong>
                  </td>
                  <td>
                    <strong>{{
                      tarif1.tarif_beschreibung_knochenaufbau
                    }}</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_id">
                      <strong>{{
                        tarif2.tarif_beschreibung_knochenaufbau
                      }}</strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong>Funktionsanalyse</strong>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_beschreibung_funktion_ze }}</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_id">
                      <strong>{{
                        tarif2.tarif_beschreibung_funktion_ze
                      }}</strong>
                    </span>
                  </td>
                </tr>

                <!-- Ende Zahnerstaz -->

                <!-- Cluster  Zahnerhalt -->
                <tr>
                  <td colspan="3">
                    <strong class="blue--text" style="font-size: 15px"
                      >Leistungen für zahnerhaltende Maßnahmen</strong
                    >
                  </td>
                </tr>
                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Zahnerhalt</strong>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_leistung_zb }} %</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_id">
                      <strong>{{ tarif2.tarif_leistung_zb }} %</strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong>Wartezeit</strong>
                    <br />
                    <span class="caption">für Zahnerhalt</span>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_beschreibung_wz_zb }}</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_id">
                      <strong>{{ tarif2.tarif_beschreibung_wz_zb }}</strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong>Kunststofffüllungen</strong>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_beschreibung_ksf }}</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_id">
                      <strong>{{ tarif2.tarif_beschreibung_ksf }}</strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong>Wurzelbehandlung</strong>
                    <br />
                    <span class="caption">wenn GKV leistet</span>
                    <br />
                    <span class="caption red-text">wenn GKV nicht leistet</span>
                    <br />
                  </td>
                  <td>
                    <strong>
                      {{ tarif1.tarif_beschreibung_wurzel_mgkv }}
                      <br />
                      {{ tarif1.tarif_beschreibung_wurzel_ogkv }}
                    </strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_id">
                      <strong>
                        {{ tarif2.tarif_beschreibung_wurzel_mgkv }}
                        <br />
                        {{ tarif2.tarif_beschreibung_wurzel_ogkv }}
                      </strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong>Parodontose-Leistungen</strong>
                    <br />
                    <span class="caption">wenn GKV leistet</span>
                    <br />
                    <span class="caption red-text">wenn GKV nicht leistet</span>
                    <br />
                  </td>
                  <td>
                    <strong>
                      {{ tarif1.tarif_beschreibung_paro_mgkv }}
                      <br />
                      {{ tarif1.tarif_beschreibung_paro_ogkv }}
                    </strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_id">
                      <strong>
                        {{ tarif2.tarif_beschreibung_paro_mgkv }}
                        <br />
                        {{ tarif2.tarif_beschreibung_paro_ogkv }}
                      </strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong>Funktionsanalyse</strong>
                    <br />
                    <span class="caption">für Zahnerhalt</span>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_beschreibung_funktion_zb }}</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_id">
                      <strong>{{
                        tarif2.tarif_beschreibung_funktion_zb
                      }}</strong>
                    </span>
                  </td>
                </tr>
                <!--  Ende Cluster Zahnerhalt -->
                <tr>
                  <td colspan="3">&nbsp;</td>
                </tr>
                <!-- PZR Cluser -->

                <tr>
                  <td colspan="3">
                    <strong class="blue--text" style="font-size: 15px"
                      >Leistungen für Prophylaxe</strong
                    >
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Prophylaxe</strong>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_leistung_pzr }} %</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_id">
                      <strong>{{ tarif2.tarif_leistung_pzr }} %</strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong>Wartezeit</strong>
                    <br />
                    <span class="caption">für Prophylaxe</span>
                  </td>
                  <td>
                    <strong>{{ tarif1.tarif_beschreibung_wz_pzr }}</strong>
                  </td>
                  <td>
                    <span v-if="tarif2.tarif_id">
                      <strong>{{ tarif2.tarif_beschreibung_wz_pzr }}</strong>
                    </span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Details</strong>
                  </td>
                  <td>{{ tarif1.tarif_leistung_pzr_details }}</td>
                  <td>
                    <span v-if="tarif2.tarif_id">{{
                      tarif2.tarif_leistung_pzr_details
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td colspan="3">&nbsp;</td>
                </tr>

                <!-- End PZR -->

                <!-- Vertrag -->
                <tr>
                  <td colspan="3">
                    <strong class="blue--text" style="font-size: 15px"
                      >Begrenzungen, Wartezeiten & Kündigungsfristen</strong
                    >
                  </td>
                </tr>
                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Begrenzungen</strong>
                  </td>
                  <td>
                    <span
                      v-if="tarif1.tarif_beschreibung_beg"
                      v-html="
                        tarif1.tarif_beschreibung_beg.replace(/\n/g, '<br />')
                      "
                    ></span>
                  </td>
                  <td>
                    <span
                      v-if="tarif2.tarif_beschreibung_beg"
                      v-html="
                        tarif2.tarif_beschreibung_beg.replace(/\n/g, '<br />')
                      "
                    ></span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Wartezeiten</strong>
                  </td>
                  <td>
                    <span
                      v-if="tarif1.tarif_modal_wartezeit"
                      v-html="
                        tarif1.tarif_modal_wartezeit.replace(/\n/g, '<br />')
                      "
                    ></span>
                  </td>
                  <td>
                    <span
                      v-if="tarif2.tarif_modal_wartezeit"
                      v-html="
                        tarif2.tarif_modal_wartezeit.replace(/\n/g, '<br />')
                      "
                    ></span>
                  </td>
                </tr>

                <tr style="vertical-align: top">
                  <td>
                    <strong class="pb-0 mb-0">Kündigungsfrist</strong>
                  </td>
                  <td>{{ tarif1.tarif_int_kuendigung_details }}</td>
                  <td>
                    <span v-if="tarif2.tarif_id">{{
                      tarif2.tarif_int_kuendigung_details
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-card-text>
        </v-card></v-col
      >
    </v-row>
  </div>
</template>

<script setup>
  import { ref, onMounted, getCurrentInstance } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  import { useRoute } from "vue-router";
  import { useLoaderStore } from "@/stores/loaderStore";
  const loaderStore = useLoaderStore();
  const route = useRoute();
  const { proxy } = getCurrentInstance();
  const tarif1 = ref(false);
  const tarif2 = ref(false);

  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }
  async function getTarif(id, was) {
    console.log("getTarif");
    let select = {};
    select.action = "getTarifComplete";
    select.tarif_id = id;
    setLoading(true);
    try {
      const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.tarif) {
        if (was == 1) {
          tarif1.value = resp.data.tarif;
        }
        if (was == 2) {
          tarif2.value = resp.data.tarif;
        }
        setLoading(false);
        // test.value = resp.data.test;
        // avb.value = resp.data.avb;
        // forum.value = resp.data.forum;
        // preise.value = resp.data.preise;
        // preisSpruenge.value = resp.data.preisSpruenge;
        // fragen.value = resp.data.fragen;
        // weitere.value = resp.data.weitere;
        // waizmannwert.value = resp.data.waizmannwert;
        // moreThreads.value = resp.data.moreThreads;

        setLoading(false);

        proxy.$updateDocumentHead(
          "welche Zahnzusatzversicherung ist besser",
          ""
        );
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }

  onMounted(() => {
    getTarif(route.params.tarif_id1, 1);
    getTarif(route.params.tarif_id2, 2);
  });
</script>
