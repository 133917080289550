// Importieren der Firebase-Initialisierungsfunktion und Firestore-Funktion
import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";

// Konfigurationsobjekt für Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBV_8TKEG29fBEuKxA5Qm4wG_8KKyy0YFQ",
  authDomain: "waizmannprochat.firebaseapp.com",
  databaseURL: "https://waizmannprochat.firebaseio.com",
  projectId: "waizmannprochat",
  storageBucket: "",
  messagingSenderId: "1",
};

// Initialisieren der Firebase App
const app = initializeApp(firebaseConfig);

// Erhalten der Firestore-Instanz
const db = getFirestore(app);
// console.log(db);

// Exportieren der Firestore-Collection
export const chatsCollection = collection(db, "chats");
