<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h2>
          <span style="color: #ff8000"
            >Mit einer Brillenversicherung in 3 Schritten zu einer günstigen
            Markenbrille</span
          >
        </h2>
        <br />
        <h3>
          <v-icon style="color: #ff8000">check</v-icon> Brillenversicherung ohe
          Wartezeit - Neue Markenbrille zum günstigen Preis<br />
          <v-icon style="color: #ff8000">check</v-icon> Alle 2 Jahre bis zu 400
          € Budget für Ihre neue Markenbrille<br />
          <v-icon style="color: #ff8000">check</v-icon> In 3 Schritten zur
          günstigen Markenbrille<br /><br />
        </h3>
        <br />
        <h2>So einfach gehts:</h2>
        <br />
        <h2>
          <span style="color: #ff8000">Schritt 1:</span> Brillenversicherung
          vergleichen & abschließen
          <span class="material-icons" style="color: #ff8000">class</span>
        </h2>
        Schließen Sie Ihre Brillenversicherung über die WaizmannTabelle ab und
        sichern Sie sich bis zu 400 € alle 2 Jahre für Ihre neue Markenbrille<br />
        <v-icon style="color: #ff8000">check</v-icon>Starten Sie jetzt unseren
        <a href="/vergleich/starten/brille">Online-Rechner</a> und finden Sie
        eine leistungsstarke Brillenversicherung.<br />
        <v-icon style="color: #ff8000">check</v-icon>Fordern Sie ein kostenloses
        und unverbindliches Angebot für den Tarif Ihrer Wahl an.<br />
        <v-icon style="color: #ff8000">check</v-icon>Senden Sie uns die
        unterschriebenen Unterlagen per Post oder Email zurück.<br />
        <v-icon style="color: #ff8000">check</v-icon>Wir senden diese an die
        Versicherung weiter und Sie erhalten die Versicherungspolice.<br /><br />
        <v-btn
          href="/vergleich/starten/brille"
          class="button_caps mb-4"
          style="background-color: #ff8000; color: #fff !important"
          >jetzt Brillenversicherungen online vergleichen</v-btn
        >
        <br />
        <br />
        <h2>
          <span style="color: #ff8000">Schritt 2:</span> Augen testen & Brille
          bestellen
          <span class="material-icons" style="color: #ff8000"
            >local_shipping</span
          >
        </h2>
        Lassen Sie Ihre Augen testen und bestellen Sie eine Markenbrille mit
        hochwertigen Gläsern bei dem Optiker Ihres Vertrauens<br />
        <v-icon style="color: #ff8000">check</v-icon>Besuchen Sie Ihren
        Augenarzt oder Optiker und machen Sie einen Sehtest.<br />
        <v-icon style="color: #ff8000">check</v-icon>Wählen Sie Ihre neue Brille
        aus und bestellen Sie.
        <br />
        <br />
        <h2>
          <span style="color: #ff8000">Schritt 3:</span> Brille abholen & Geld
          erhalten
          <span class="material-icons" style="color: #ff8000">euro_symbol</span>
        </h2>
        <v-icon style="color: #ff8000">check</v-icon>Holen Sie Ihre neue
        Marken-Brille ab.<br />
        <v-icon style="color: #ff8000">check</v-icon>Senden Sie die Rechnung an
        Ihre Brillenversicherung.<br />
        <v-icon style="color: #ff8000">check</v-icon>Innerhalb weniger Wochen
        erhalten Sie bis zu 400 € Erstattung für Ihre neue Brille und das alle 2
        Jahre.<br /><br />
        <br />
        <br />
        <h3>
          <span class="material-icons" style="color: #ff8000"
            >double_arrow</span
          >
          <a href="/vergleich/starten/brille">
            Vergleichen auch Sie JETZT die leistungstärksten Brillenversicherung
            ohne Wartezeit</a
          >
        </h3>
        <br />
        <a href="/brillenversicherung_vergleich">
          Alle Sehhilfe Versicherungen im Vergleich</a
        >
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts typeInfo="brillen"
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Brillenversicherung - 3 Schritte zu günstigen Markenbrille",
      "In 3 Schritten zu einer günstigen Markenbrille, mit einer leistungsstraken Brillenversicherung ohne Wartezeit"
    );
  });
</script>
