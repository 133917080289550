<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h3>
          <span class="text-blue">{{ tarif.tarif_waizmannwert }} %</span>
          WaizmannWert | Ø-Erstattung
        </h3>
        Die {{ tarif.un_name }} {{ tarif.tarif_title }} hat einen WaizmannWert
        Benchmark in Höhe von
        <strong>{{ tarif.tarif_waizmannwert }} %</strong>
        für den Leistungsbereich Zahnersatz, Zahnerhalt und Prophylaxe. In einem
        Beobachtungszeitraum von 8 Jahren (die ersten 8 Versicherungsjahre)
        würde die {{ tarif.un_name }} {{ tarif.tarif_title }} bei
        Behandlungskosten von 9.940,- € (=Eigenanteil nach GKV-Erstattung)
        <strong>{{ tarif.tarif_eigenanteil }} €</strong>
        erstatten.
      </v-col>
    </v-row>
    <v-divider
      :thickness="2"
      v-if="tarif.tarif_kwert_gesamt > 0"
      class="my-4"
    ></v-divider>
    <v-row v-if="tarif.tarif_kwert_gesamt > 0">
      <v-col cols="12">
        <h3>
          <span style="color: #fecf0c">{{ tarif.tarif_kwert_gesamt }} %</span>
          WaizmannWert | Ø-Erstattung Kieferorthopädie
        </h3>
        Für kieferorthopädische Leistungen bei Kindern leistet der Tarif
        durchschnittlich
        <strong>{{ tarif.tarif_kwert_gesamt }} %</strong>
        . Bei KFO-Behandlungen die in KIG 1-2 eingestuft sind leistet die
        {{ tarif.un_name }} {{ tarif.tarif_title }} durchschnittlich
        <strong>{{ tarif.tarif_kwert_1 }} %</strong>, bei KIG 3-5 Behandlungen
        <strong>{{ tarif.tarif_kwert_3 }} %</strong>.
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
