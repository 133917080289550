<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10"
        ><h2>
          Welche Bedeutung haben Gesundheitsfragen in der
          Zahnzusatzversicherung?
        </h2>
        <h3>
          Welche Zahnzusaztversicherungen stellen keine Gesundheitsfragen?
        </h3>
        <br />
        Zum Abschluss einer Zahnzusatzversicherung ist die Beantwortung von
        Gesundheitsfragen notwendig. Dadurch kann der Versicherer das objektive
        und subjektive Versicherungsrisiko anhand des Zahnzustands bei der
        Antragsbearbeitung einschätzen und über mögliche Risikozuschläge,
        Leistungsausschlüsse und die generelle Antragsannahme entscheiden.
        <h3>Was ist das besondere an Tarifen ohne Gesundheitsfragen?</h3>
        In den Anträgen für diese Zahnzusatzversicherungen stellt das
        Versicherungsunternehmen keine komplizierten Antragsfragen an den
        Versicherungsnehmer, die er vielleicht falsch beantworten könnte.
        <v-divider :thickness="2" class="my-6"></v-divider>
        <h3>
          Hat ein Tarif ohne Gesundheitsfragen Vorteil- oder Nachteile für den
          Versicherungsnehmer?
        </h3>

        Wie alles im Leben, gibt es auch hier zwei Seiten der Medaille.
        Beispielsweise ist die Auswahl an Tarifen ohne Gesundheitsfragen ist
        sehr gering. Ebenfalls sind fehlende Zähne sowie bereits zahnärztlich
        festgestellte Mängel und angeratene Maßnahmen nicht mitversichert. Doch
        nach unserer Meinung überwiegen die Vorteile für den
        Versicherungsnehmer.

        <v-divider :thickness="2" class="my-6"></v-divider>
        <v-table>
          <tr>
            <th>
              <h3>Vorteile</h3>
            </th>
            <th>
              <h3>Nachteile</h3>
            </th>
          </tr>
          <tr style="vertical-align: top">
            <td>
              <ul class="text-green" style="margin-top: 0px; padding-top: 0px">
                <li>schnelle & einfache Antragstellung</li>
                <li>keine komplizierten Gesundheitsfragen</li>
                <li>schlechte Zähne spielen keine Rolle</li>
                <li>Antragstellung trotz angeratener Maßnahme</li>
                <li>
                  keine Falschangaben im Antrag möglich - daher keine Sanktionen
                  im Leistungsfall
                </li>
                <li>
                  Antragsannahme auch bei vorheriger Ablehnung durch eine andere
                  Versicherung
                </li>
                <li>volles Leistungsspektrum ohne dauerhafte Ausschlüsse</li>
                <li>
                  umfänglicher Versicherungsschutz in den Bereichen Zahnersatz,
                  Zahnerhalt und Prophylaxe
                </li>
              </ul>
            </td>
            <td>
              <ul class="text-red" style="margin-top: 0px; padding-top: 0px">
                <li>geringe Auswahl an Zahnzusatztarifen</li>
                <li>
                  zahnärztlich festgestellte Mängel vor Abschluss sind nicht
                  versichert
                </li>
                <li>fehlende Zähne sind nicht mitversichert</li>
              </ul>
            </td>
          </tr>
        </v-table>

        <v-divider :thickness="2" class="my-6"></v-divider>
        <h3>Welche Gesundheitsfragen können gestellt werden?</h3>
        Die Schlüsselfragen lauten meist
        <ul>
          <li>Fehlen Zähne bzw. bestehen Lücken?</li>
          <li>Befinden Sie sich in einer laufenden Behandlung?</li>
          <li>Besteht eine Parodontose/Parodontitis Vorerkrankung?</li>
          <li>Tragen Sie herausnehmbaren Zahnersatz?</li>
        </ul>
        Entsprechend dem was der Versicherungsnehmer dort angibt, wird über die
        Annahme oder Ablehnung des Antrages und damit über die Gewährung des
        Versicherungsschutzes entschieden. Zusätzlich können beispielsweise
        fehlende Zähne einen Risikozuschlag zum regulären Beitrag verursachen
        oder angeratene Maßnahmen werden vom Versicherungsschutz ausgeschlossen.
        Werden diese Fragen falsch beantwortet, kann es im Leistungsfall zu
        Sanktionen kommen.
        <v-divider :thickness="2" class="my-6"></v-divider>

        <h3>
          Welche Sanktionen können bei Falschbeantwortung der Gesundheitsfragen
          auf einen zukommen?
        </h3>
        Im Leistungsfall wird die Versicherung mit Ihnen und Ihrer
        Zahnarztpraxis die sogenannte nachgelagerte Gesundheitsprüfung
        durchführen. Das bedeutet, es werden ganz konkrete Fragen, meist per
        Fragebogen an Sie und die Praxis gerichtet. <br />Im ersten Schritt
        prüft die Versicherung dann, ob die Fragen im Antrag durch Sie korrekt
        beantwortet wurden. Sollte anhand des Fragebogens, der Patientenakte
        oder anderen medizinischen Belegen (Röntgenbilder, Abdrücke usw.)
        festgestellt werden, dass im Antrag die Fragen nicht korrekt beantwortet
        wurden, kann der Versicherer zu folgenden Maßnahmen greifen:
        <ol>
          <li>Ablehnung des Leistungsantrages</li>
          <li>dauerhafter Leistungsausschluss</li>
          <li>Rücktritt vom Vertrag</li>
          <li>Vertragsanfechtung</li>
        </ol>
        <br />
        <b>1. Ablehnung des Leistungsantrages</b>
        <br />Der Versicherer kann einen eingereichten Leistungsantrag
        (Kostenvoranschlag, Heil- und Kostenplan oder Rechnung) ablehnen, wenn
        im Antrag eine dazugehörige Frage falsch beantwortet wurde. Lässt der
        Versicherungsnehmer diesen “Schaden” dann auf eigene Rechnung beheben,
        so kann der betreffende Zahn wieder in denVersicherungsschutz
        eingeschlossen werden, ohne dass ein dauerhafter Ausschluss im Vertrag
        hinterlegt wird.
        <br />
        <br />
        <b>2. dauerhafter Leistungsausschluss</b>
        Hat ein Vertrag beispielsweise Leistungen für den Bereich
        Kieferorthopädie enthalten und man verschweigt eine Fehlstellung der
        Zähne oder Kiefer, kann der Versicherer diesen Leistungsbereich aus dem
        Versicherungsschutz entfernen. Der restliche Versicherungsschutz für
        Zahnersatz, Zahnbehandlung und Prophylaxe bleibt dabei unberührt.
        <br />
        <br />
        <b>3. Rücktritt vom Vertrag</b>
        Wurde eine Antragsfrage falsch beantwortet, um beispielsweise trotz
        angeratener zurücktreten. Die gezahlten Beiträge bleiben bei der
        Versicherung.
        <br />
        <br />

        <b>4. Vertragsanfechtung</b>
        Hier geht der Versicherer von einer schwerwiegenden Falschangabe aus.
        Sollte man dem Versicherungsnehmer also gezielte Falschangaben zum
        Erschleichen des Versicherungsschutz und von Leistungen nachweisen
        können, wir der Versicherer den Vertrag anfechten. Damit werden zum
        einen die eingezahlten Beiträge einbehalten und bereits ausgezahlte
        Leistungen werden zurückgefordert. Je nach Schwere der Falschangaben
        kann dies zusätzlich rechtliche Folgen haben.
        <br />
        <br />
        <h3>Was spricht für Zahnzusatzversicherungen ohne Gesundheitsfragen</h3>
        <ul>
          <li>
            Die Anträge sind auf die wichtigsten Angaben des Antragstellers
            reduziert.
          </li>
          <li>
            In den Anträgen sind keine kompliziert formulierten Antragsfragen
            hinterlegt, welche evtl. falsch beantwortet werden könnten. Es
            findet keine Gesundheitsprüfung statt.
          </li>
          <li>
            Auch wenn die Zahnsubstanz schlecht ist, Parodontose/Parodontitis
            vorliegt oder eine Zahn- oder Kieferfehlstellung besteht, ist die
            Antragstellung möglich.
          </li>
          <li>
            Trotz angeratener und vom Zahnarzt empfohlener Maßnahmen ist der
            Abschluss eines Tarifes ohne Gesundheitsfragen möglich.
          </li>
          <li>
            Im Antrag können keine Falschangaben gemacht werden. Somit ist eine
            Obliegenheitsverletzung bzw. die Verletzung der vorvertraglichen
            Anzeigepflicht nicht möglich und kann später auch nicht zu einem
            Verlust des Versicherungsschutzes führen.
          </li>
          <li>
            Wenn Sie sich in der Situation befinden, dass Sie schon einmal von
            einer Zahnzusatzversicherung abgelehnt worden sind, spielt das für
            den Abschluß einer Versicherung ohne Gesundheitsfragen keine Rolle.
          </li>
          <li>
            Es gibt keine im Vertrag hinterlegten Leistungsausschlüsse für
            Vorschäden im Vertrag. Lediglich zahnärztlich festgestellte Mängel,
            angeratene oder laufende Behandlungen sind zunächst auf eigene
            Rechnung zu beheben, um dann für die betreffenden Zähne
            Versicherungsschutz zu erhalten.
          </li>
          <li>
            Es gibt Tarife ohne Gesundheitsfragen, welche in sämtlichen
            Leistungsbereichen ebenfalls auf Top-Niveau erstatten. Keine
            Gesundheitsprüfung bedeutet nicht gleich schlechtere Leistungen.
          </li>
        </ul>

        <h3>Was spricht gegen Tarife ohne Gesundheitsfragen</h3>
        <ul>
          <li>
            Das Angebot von Zahnzusatzversicherungen ohne Gesundheitsfragen ist
            nicht so umfangreich. Dennoch gibt es hier einige Top-Tarife auf dem
            Markt.
          </li>
          <li>
            Zahnärztlich bereits festgestellte Mängel, angeratene und laufende
            Maßnahmen an den Zähnen sind grundsätzlich vom Versicherungsschutz
            ausgeschlossen, bis diese Mängel auf eigene Kosten behoben werden.
          </li>
          <li>
            Bei Versicherungen ohne Gesundheitsfragen können fehlende Zähne
            nicht mitversichert werden. Bei einigen Versicherungen mit
            Gesundheitsfragen können durch Leisten eines Risikozuschlages
            zusätzlich zum Monatsbeitrag, fehlende Zähne mitversichert werden.
          </li>
        </ul>
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Zahnzusatzversicherungen ohne Gesundheitsfragen im Vergleich",
      "Bei unbekanntem oder schlechtem Zahnstatus die richtige Zahnzusatzversicherung finden. Mit dem WaizmannTabelle Vergleich für Tarife ohne Gesundheitsfragen findet man schnell den besten Zahnzusatztarif."
    );
  });
</script>
