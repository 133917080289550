<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="10">
        <h2>Datenschutzerklärung der WaizmannTabelle</h2>
        <v-row>
          <v-col cols="12">
            <p>
              Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
              Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
              vertraulich und entsprechend der gesetzlichen
              Datenschutzvorschriften sowie dieser Datenschutzerklärung.
            </p>
            <p>
              Die Nutzung unserer Webseite ist in der Regel ohne Angabe
              personenbezogener Daten möglich. Soweit auf unseren Seiten
              personenbezogene Daten (beispielsweise Name, Anschrift oder
              E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich,
              stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
              ausdrückliche Zustimmung nicht an Dritte weitergegeben.
            </p>
            <p>
              Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
              bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
              kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
              Dritte ist nicht möglich.
            </p>
            <br />
            <p>
              <strong>Cookies</strong>
            </p>
            <p>
              Die Internetseiten verwenden teilweise so genannte Cookies.
              Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten
              keine Viren. Cookies dienen dazu, unser Angebot
              nutzerfreundlicher, effektiver und sicherer zu machen. Cookies
              sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und
              die Ihr Browser speichert.
            </p>
            <p>
              Die meisten der von uns verwendeten Cookies sind so genannte
              „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch
              gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert,
              bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren
              Browser beim nächsten Besuch wiederzuerkennen.
            </p>
            <p>
              Sie können Ihren Browser so einstellen, dass Sie über das Setzen
              von Cookies informiert werden und Cookies nur im Einzelfall
              erlauben, die Annahme von Cookies für bestimmte Fälle oder
              generell ausschließen sowie das automatische Löschen der Cookies
              beim Schließen des Browser aktivieren. Bei der Deaktivierung von
              Cookies kann die Funktionalität dieser Website eingeschränkt sein.
            </p>
            <br />
            <p>
              <strong>Server-Log-Files</strong>
            </p>
            <p>
              Der Provider der Seiten erhebt und speichert automatisch
              Informationen in so genannten Server-Log Files, die Ihr Browser
              automatisch an uns übermittelt. Dies sind:
            </p>
            <ul>
              <li>Browsertyp/ Browserversion</li>
              <li>verwendetes Betriebssystem</li>
              <li>Referrer URL</li>
              <li>Hostname des zugreifenden Rechners</li>
              <li>Uhrzeit der Serveranfrage</li>
            </ul>
            <p>
              <br />Diese Daten sind nicht bestimmten Personen zuordenbar. Eine
              Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
              vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu
              prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige
              Nutzung bekannt werden.
            </p>
            <br />
            <p>
              <strong
                >Datenschutzerklärung für die Nutzung von Google
                Analytics</strong
              >
            </p>
            <p>
              Diese Website nutzt Funktionen des Webanalysedienstes Google
              Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway
              Mountain View, CA 94043, USA.
            </p>
            <p>
              Google Analytics verwendet sog. "Cookies". Das sind Textdateien,
              die auf Ihrem Computer gespeichert werden und die eine Analyse der
              Benutzung der Website durch Sie ermöglichen. Die durch den Cookie
              erzeugten Informationen über Ihre Benutzung dieser Website werden
              in der Regel an einen Server von Google in den USA übertragen und
              dort gespeichert.
            </p>
            <p>
              Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite
              wird Ihre IP-Adresse von Google jedoch innerhalb von
              Mitgliedstaaten der Europäischen Union oder in anderen
              Vertragsstaaten des Abkommens über den Europäischen
              Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die
              volle IP-Adresse an einen Server von Google in den USA übertragen
              und dort gekürzt. Im Auftrag des Betreibers dieser Website wird
              Google diese Informationen benutzen, um Ihre Nutzung der Website
              auszuwerten, um Reports über die Websiteaktivitäten
              zusammenzustellen und um weitere mit der Websitenutzung und der
              Internetnutzung verbundene Dienstleistungen gegenüber dem
              Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics
              von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen
              Daten von Google zusammengeführt.
            </p>
            <p>
              Sie können die Speicherung der Cookies durch eine entsprechende
              Einstellung Ihrer Browser-Software verhindern; wir weisen Sie
              jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
              sämtliche Funktionen dieser Website vollumfänglich werden nutzen
              können. Sie können darüber hinaus die Erfassung der durch das
              Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten
              (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser
              Daten durch Google verhindern, indem sie das unter dem folgenden
              Link verfügbare Browser-Plugin herunterladen und installieren:
              <a href="http://tools.google.com/dlpage/gaoptout?hl=de"
                >http://tools.google.com/dlpage/gaoptout?hl=de</a
              >
            </p>
            <p>
              Sie können die Erfassung durch Google Analytics verhindern, indem
              Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie
              gesetzt, das das Erfassung Ihrer Daten bei zukünftigen Besuchen
              dieser Website verhindert:
              <a href="javascript:gaOptout()">Google Analytics deaktivieren</a>
            </p>
            <br />
            <p>
              <strong
                >Datenschutzerklärung für die Nutzung von Facebook-Plugins
                (Like-Button)</strong
              >
            </p>
            <p>
              Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook,
              Anbieter Facebook Inc., 1 Hacker Way, Menlo Park, California
              94025, USA, integriert. Die Facebook-Plugins erkennen Sie an dem
              Facebook-Logo oder dem "Like-Button" ("Gefällt mir") auf unserer
              Seite. Eine übersicht über die Facebook-Plugins finden Sie hier:
              <a href="http://developers.facebook.com/docs/plugins/"
                >http://developers.facebook.com/docs/plugins/</a
              >.
            </p>
            <p>
              Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte
              Verbindung zwischen Ihrem Browser und dem Facebook-Server
              hergestellt. Facebook erhält dadurch die Information, dass Sie mit
              Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook
              "Like-Button" anklicken während Sie in Ihrem Facebook-Account
              eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem
              Facebook-Profil verlinken. Dadurch kann Facebook den Besuch
              unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf
              hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt
              der übermittelten Daten sowie deren Nutzung durch Facebook
              erhalten. Weitere Informationen hierzu finden Sie in der
              Datenschutzerklärung von Facebook unter
              <a href="http://de-de.facebook.com/policy.php"
                >http://de-de.facebook.com/policy.php</a
              >.
            </p>
            <p>
              Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten
              Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte
              aus Ihrem Facebook-Benutzerkonto aus.
            </p>
            <br />
            <p>
              <strong>Auskunft, Löschung, Sperrung</strong>
            </p>
            <p>
              Sie haben jederzeit das Recht auf unentgeltliche Auskunft über
              Ihre gespeicherten personenbezogenen Daten, deren Herkunft und
              Empfänger und den Zweck der Datenverarbeitung sowie ein Recht auf
              Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu
              weiteren Fragen zum Thema personenbezogene Daten können Sie sich
              jederzeit unter der im Impressum angegebenen Adresse an uns
              wenden.
            </p>
            <br />
            <p>
              <strong>Widerspruch Werbe-Mails</strong>
            </p>
            <p>
              Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
              Kontaktdaten zur übersendung von nicht ausdrücklich angeforderter
              Werbung und Informationsmaterialien wird hiermit widersprochen.
              Die Betreiber der Seiten behalten sich ausdrücklich rechtliche
              Schritte im Falle der unverlangten Zusendung von
              Werbeinformationen, etwa durch Spam-E-Mails, vor.
            </p>
            <br />
            <p>
              <em>
                Quellverweis:
                <a
                  href="https://www.e-recht24.de/artikel/datenschutz/10066-abmahnung-datenschutzerklaerung-auf-webseiten.html"
                  >eRecht24</a
                >
              </em>
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="2" v-if="sharedDataStore.showMenueRechts()"
        ><MenueRechts
      /></v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import MenueRechts from "@/views/site/helper/MenueRechts";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const appLoaded = ref(true);
  //  import BaseCalls from  "@/services/BaseCalls";

  //   async function getBerechnungen() {
  //     let berSelect = {};
  //     berSelect.action = "getAllUserBerechnungen";
  //     console.log("getAllUserBerechnungen");
  //     try {
  //       const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
  //       if (resp.data && resp.data.berechnungen) {
  //         berechnungen.value = resp.data.berechnungen;
  //       }
  //     } catch (err) {
  //       console.log("error berechnung");
  //     }
  //   }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "WaizmannTabelle Datenschutzerklärung",
      "WaizmannTabelle Datenschutzerklärung"
    );
  });
</script>
