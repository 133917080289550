<template>
  <v-container>
    <div v-if="content && content.length">
      <div v-for="(item, index) in content" :key="index">
        <div v-html="parseContent(item.content_text)"></div>
      </div>
    </div>
  </v-container>
</template>

<script setup>
  import { ref, onMounted } from "vue";
  import BaseCalls from "@/services/BaseCalls";

  const currentPath = ref("");
  const content = ref(false);
  const parseContent = (text) => {
    const regex =
      /\[link:(?<linkPath>.*?)\](?<linkText>.*?)\[\/link\]|\[red\](?<redText>.*?)\[\/red\]|\[green\](?<greenText>.*?)\[\/green\]|\[bold\](?<boldText>.*?)\[\/bold\]|\[br\]|\[h1\](?<h1Text>.*?)\[\/h1\]|\[h2\](?<h2Text>.*?)\[\/h2\]|\[h3\](?<h3Text>.*?)\[\/h3\]|\[ul\](?<ulContent>.*?)\[\/ul\]|\[ol\](?<olContent>.*?)\[\/ol\]|\[li\](?<liContent>.*?)\[\/li\]/gs;

    let htmlText = "";
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(text)) !== null) {
      // Füge den Text vor dem Match hinzu
      htmlText += text.slice(lastIndex, match.index);

      // Verarbeite [link:path]linktext[/link]
      if (match.groups.linkPath) {
        const innerHtml = parseContent(match.groups.linkText); // Rekursion für den inneren Text
        htmlText += `<a href="${match.groups.linkPath}">${innerHtml}</a>`;
      }
      // Verarbeite [red]text[/red]
      else if (match.groups.redText) {
        htmlText += `<span class="text-red">${match.groups.redText}</span>`;
      }
      // Verarbeite [green]text[/green]
      else if (match.groups.greenText) {
        htmlText += `<span class="text-green">${match.groups.greenText}</span>`;
      }
      // Verarbeite [bold]text[/bold]
      else if (match.groups.boldText) {
        htmlText += `<strong>${match.groups.boldText}</strong>`;
      }
      // Verarbeite [br]
      else if (match[0] === "[br]") {
        htmlText += `<br />`;
      }
      // Verarbeite [h1]überschrift[/h1]
      else if (match.groups.h1Text) {
        htmlText += `<h1>${match.groups.h1Text}</h1>`;
      }
      // Verarbeite [h2]überschrift[/h2]
      else if (match.groups.h2Text) {
        htmlText += `<h2>${match.groups.h2Text}</h2>`;
      }
      // Verarbeite [h3]überschrift[/h3]
      else if (match.groups.h3Text) {
        htmlText += `<h3>${match.groups.h3Text}</h3>`;
      }
      // Verarbeite [ul] ... [/ul]
      else if (match.groups.ulContent) {
        const innerUlHtml = parseContent(match.groups.ulContent); // Rekursion für den inneren Text
        htmlText += `<ul>${innerUlHtml}</ul>`;
      }
      // Verarbeite [ol] ... [/ol]
      else if (match.groups.olContent) {
        const innerOlHtml = parseContent(match.groups.olContent); // Rekursion für den inneren Text
        htmlText += `<ol>${innerOlHtml}</ol>`;
      }
      // Verarbeite [li] ... [/li]
      else if (match.groups.liContent) {
        const innerLiHtml = parseContent(match.groups.liContent); // Rekursion für den inneren Text
        htmlText += `<li>${innerLiHtml}</li>`;
      }

      lastIndex = regex.lastIndex;
    }

    // Füge den Rest des Textes hinzu, falls vorhanden
    htmlText += text.slice(lastIndex);
    htmlText = htmlText.replace(/\n/g, "<br>");
    return htmlText;
  };

  async function getContent() {
    let cotentSelect = {};
    cotentSelect.action = "getContent";
    cotentSelect.url = currentPath.value;

    try {
      const resp = await BaseCalls.postMixed(cotentSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.content) {
        content.value = resp.data.content;
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }
  onMounted(() => {
    currentPath.value = window.location.pathname.substring(1);
    getContent();
  });
</script>
