import axios from "axios";

var baseURL = "https://www.waizmanntabelle.de";

if (
  window.location.hostname == "localhost" ||
  window.location.hostname == "lean.waizmanntabelle.de"
) {
  baseURL = "https://lean.waizmanntabelle.de";
}

if (window.location.hostname == "/www.waizmanntabelle.de") {
  baseURL = "https:///www.waizmanntabelle.de";
}

const http = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: {
    // Authorization: "sdZdfcaeqwsc9jsd189asddsa219275asmygvlwdsjasd9a", // Achte auf das Leerzeichen nach 'Bearer'
    "Content-Type": "application/json",
  },
});

export default http;
