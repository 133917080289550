<template>
  <div>
    <!-- Eckdaten -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Eckdaten</strong>
        <br />
        <span class="caption"></span>
      </v-col>
      <v-col cols="12" md="9">
        <div>
          Zahnersatz: <strong>{{ tarif.tarif_leistung_ze }}%</strong>
        </div>
        <div>
          Zahnerhalt: <strong>{{ tarif.tarif_leistung_zb }}%</strong>
        </div>
        <div>
          Prophylaxe:
          <strong
            >{{ tarif.tarif_leistung_pzr }}%
            <span
              v-if="tarif.tarif_leistung_pzr_ohne_begrenzung == 1"
              class="text-green"
            >
              | PZR-Flatrate (<router-link to="/pzr_flatrate"
                >was ist das?</router-link
              >)</span
            ></strong
          >
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Wartezeiten -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Wartezeiten</strong>
      </v-col>
      <v-col cols="12" md="9">
        <div>
          Zahnersatz: <strong>{{ tarif.tarif_beschreibung_wz_ze }}</strong>
        </div>
        <div>
          Zahnerhalt: <strong>{{ tarif.tarif_beschreibung_wz_zb }}</strong>
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <div v-if="Object.keys(budgets).length > 0">
      <v-divider :thickness="1" class="my-2"></v-divider>
      <v-row class="mb-2">
        <v-col cols="12" md="3">
          <strong
            >Anfängliche<br />
            Abrechnungsbudgets</strong
          ><br />
          Bei Abschluss zum {{ budgetbeginn }} stehen Ihnen folgende Budgets zur
          Verfügung:
        </v-col>
        <v-col cols="12" md="9">
          <v-row>
            <v-col v-for="bereich in bereiche" :key="bereich.value" cols="4">
              <b>{{ bereich.name }}-Budget</b>
            </v-col>
          </v-row>

          <!-- Datenreihen -->
          <v-row v-for="(budget, index) in budgets" :key="index">
            <v-col v-for="bereich in bereiche" :key="bereich.value" cols="4">
              <div v-if="budget[bereich.value] && budget[bereich.value].value">
                <span>{{ budget[bereich.value].value.datum }}:</span>
                <b :style="{ color: budget[bereich.value].value.color }">
                  {{ budget[bereich.value].value.sum }}
                </b>
              </div>
              <div v-else>
                <span>&nbsp;</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider :thickness="1" class="my-2"></v-divider>
    </div>

    <!-- Hightech-Leistungen -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Hightech-Leistungen</strong>
        <br />
        <span class="caption"></span>
      </v-col>
      <v-col cols="12" md="9">
        <ul>
          <li v-for="item in tarif.highspezial" :key="item.id">
            <span v-if="item.ja">{{ item.value }}</span>
            <span
              v-else
              class="text-red"
              style="text-decoration: line-through"
              >{{ item.value }}</span
            >
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Versicherung Lücken</strong>
        <br />bzw. fehlende Zähne
      </v-col>
      <v-col cols="12" md="9">
        <span v-if="tarif.tarif_annahme_zahn_mitvers == 1">
          <strong class="green--text"
            >Fehlende Zähne können mitversichert werden.</strong
          >
          <span
            v-if="
              tarif.tarif_zuschlag_prozent == 0 &&
              tarif.tarif_zuschlag_pro_zahn == 0 &&
              tarif.tarif_zuschlag_staffel == ''
            "
            >Die Mitversicherung ist kostenlos (Achtung: maximale Anzahl
            fehlender Zähne darf nicht überschritten werden).</span
          >
          <span v-if="tarif.tarif_zuschlag_prozent > 0"
            >Für die Mitversicherung fehlender Zähne wird ein prozentualler
            Zuschlag berechnet. (Achtung: maximale Anzahl fehlender Zähne darf
            nicht überschritten werden).</span
          >
          <span v-if="tarif.tarif_zuschlag_pro_zahn > 0"
            >Für die Mitversicherung fehlender Zähne wird ein Zuschlag pro
            fehlenden Zahn berechnet. (Achtung: maximale Anzahl fehlender Zähne
            darf nicht überschritten werden).</span
          >
          <span v-if="tarif.tarif_zuschlag_staffel != ''"
            >Die Mitversicherung fehlender Zähne ist kostenlos. Allerdings
            verlängern sich die anfänglichen Begrenzungen.</span
          >
        </span>
        <span v-if="tarif.tarif_annahme_zahn_mitvers == 0">
          <strong class="red--text"
            >Fehlende Zähne können nicht mitversichert werden.</strong
          >
        </span>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Wartezeiten -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Altersrückstellungen</strong>
      </v-col>
      <v-col cols="12" md="9">
        <span v-if="tarif.tarif_altersrueckstellung == 0">
          <b> Beiträge sind mit Altersrückstellungen kalkuliert. </b> Die
          Beiträge bleiben dadurch stabil.
        </span>
        <span v-if="tarif.tarif_altersrueckstellung == 1">
          <b> Beiträge sind ohne Altersrückstellungen kalkuliert. </b> Die
          Beiträge werden regelmäßig angepasst. Vorteil: Sie können den Tarif
          ohne Verlust von Rückstellungen wechseln.
        </span>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong style="font-size: 18px" class="text-blue">Zahnersatz</strong>
      </v-col>
      <v-col cols="12" md="9">
        <strong style="font-size: 18px" class="text-blue"
          >{{ tarif.tarif_leistung_ze }} %</strong
        >
        <br />
        <v-btn
          v-if="tarif.antraglink"
          :href="tarif.antraglink"
          depressed
          class="text-blue button_caps"
        >
          Antrag & Infopaket anfordern
        </v-btn>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Wartezeit -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Wartezeit</strong>
        <br />
        <span class="caption">für Zahnersatz</span>
      </v-col>
      <v-col cols="12" md="9">
        <strong>{{ tarif.tarif_beschreibung_wz_ze }}</strong>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Inlays -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Inlays</strong>
        <br />
        <span class="caption">Begrenzungen</span>
      </v-col>
      <v-col cols="12" md="9">
        <strong>{{ tarif.tarif_beschreibung_inlay }}</strong>
        <br />
        {{ tarif.tarif_beschreibung_inlay_beg }}
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Implantate -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Implantate</strong>
        <br />
        <span class="caption">Begrenzungen Anzahl</span>
        <br />
        <span class="caption">Begrenzungen Betrag</span>
      </v-col>
      <v-col cols="12" md="9">
        <strong>{{ tarif.tarif_beschreibung_implantat }}</strong>
        <br />
        {{ tarif.tarif_beschreibung_implantat_beg_anzahl }}
        <br />
        {{ tarif.tarif_beschreibung_implantat_beg_betrag }}
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Knochenaufbau -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Knochenaufbau</strong>
      </v-col>
      <v-col cols="12" md="9">
        <strong>{{ tarif.tarif_beschreibung_knochenaufbau }}</strong>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Funktionsanalyse -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Funktionsanalyse</strong>
      </v-col>
      <v-col cols="12" md="9">
        <strong>{{ tarif.tarif_beschreibung_funktion_ze }}</strong>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Verblendungen -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Verblendungen</strong>
        <br />
        <span class="caption">Begrenzungen</span>
      </v-col>
      <v-col cols="12" md="9">
        <strong>{{ tarif.tarif_beschreibung_verblendung }}</strong>
        <br />
        {{ tarif.tarif_beschreibung_verblendung_beg }}
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Prophylaxe -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong style="font-size: 18px" class="text-blue">Prophylaxe</strong>
        <br />
        <span class="caption">Leistung</span>
      </v-col>
      <v-col cols="12" md="9">
        <strong style="font-size: 18px" class="text-blue"
          >{{ tarif.tarif_leistung_pzr }} %</strong
        >
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Bleaching</strong>
      </v-col>
      <v-col cols="12" md="9">
        <b v-if="tarif.tarif_beschreibung_bleaching">{{
          tarif.tarif_beschreibung_bleaching
        }}</b>
        <b v-if="!tarif.tarif_beschreibung_bleaching">keine Leistung</b>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Wartezeit für Prophylaxe -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Wartezeit</strong>
        <br />
        <span class="caption">für Prophylaxe</span>
      </v-col>
      <v-col cols="12" md="9">
        <strong>{{ tarif.tarif_beschreibung_wz_pzr }}</strong>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Details -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Details</strong><br />
        <span class="caption">Prophylaxe-Leistung</span>
      </v-col>
      <v-col cols="12" md="9">
        {{ tarif.tarif_leistung_pzr_details }}
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Zahnerhalt -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong style="font-size: 18px" class="text-blue">Zahnerhalt</strong>
      </v-col>
      <v-col cols="12" md="9">
        <strong style="font-size: 18px" class="text-blue"
          >{{ tarif.tarif_leistung_zb }} %</strong
        ><br />
        <v-btn
          v-if="tarif.antraglink"
          :href="tarif.antraglink"
          depressed
          class="text-blue button_caps"
          >Antrag & Infopaket anfordern</v-btn
        >
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Wartezeit für Zahnerhalt -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Wartezeit</strong><br />
        <span class="caption">für Zahnerhalt</span>
      </v-col>
      <v-col cols="12" md="9">
        <strong>{{ tarif.tarif_beschreibung_wz_zb }}</strong>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Kunststofffüllungen -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Kunststofffüllungen</strong>
      </v-col>
      <v-col cols="12" md="9">
        <strong>{{ tarif.tarif_beschreibung_ksf }}</strong>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Funktionsanalyse für Zahnerhalt -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Funktionsanalyse</strong><br />
        <span class="caption">für Zahnerhalt</span>
      </v-col>
      <v-col cols="12" md="9">
        <strong>{{ tarif.tarif_beschreibung_funktion_zb }}</strong>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Wurzelbehandlung -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Wurzelbehandlung</strong>
      </v-col>
      <v-col cols="12" md="9">
        <strong>{{ tarif.tarif_beschreibung_wurzel_ogkv }}</strong> | wenn GKV
        leistet nicht
        <br />
        <strong>{{ tarif.tarif_beschreibung_wurzel_mgkv }}</strong> |
        <span class="red--text">wenn GKV leistet</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Parodontose-Leistungen -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Parodontose-Leistungen</strong>
      </v-col>
      <v-col cols="12" md="9">
        <strong>{{ tarif.tarif_beschreibung_paro_ogkv }}</strong> | wenn GKV
        nicht leistet
        <br />
        <strong>{{ tarif.tarif_beschreibung_paro_mgkv }}</strong> |
        <span class="red--text">wenn GKV leistet</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Hightech-Leistungen -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong style="font-size: 18px" class="text-blue"
          >Hightech-Leistungen</strong
        ><br />
        <span class="caption">Anzahl</span>
      </v-col>
      <v-col cols="12" md="9">
        <strong style="font-size: 18px" class="text-blue"
          >{{ tarif.tarif_count_high }} von 12</strong
        >
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Laserbehandlung -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Laserbehandlung</strong>
      </v-col>
      <v-col cols="12" md="9">
        {{ tarif.tarif_leistung_trend_laser }}
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <!-- OP-Mikroskop -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>OP-Mikroskop</strong>
      </v-col>
      <v-col cols="12" md="9">
        {{ tarif.tarif_leistung_trend_opmikro }}
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- CEREC-Behandlung -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>CEREC-Behandlung</strong>
      </v-col>
      <v-col cols="12" md="9">
        {{ tarif.tarif_leistung_trend_cerec }}
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Digitale Volumentomographie -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Digitale Volumentomographie</strong>
      </v-col>
      <v-col cols="12" md="9">
        {{ tarif.tarif_leistung_trend_dvt }}
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Bakterien- / DNA-Test -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Bakterien- / DNA-Test</strong>
      </v-col>
      <v-col cols="12" md="9">
        {{ tarif.tarif_leistung_trend_dna }}
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Vollnarkose -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Vollnarkose</strong>
      </v-col>
      <v-col cols="12" md="9">
        {{ tarif.tarif_leistung_trend_narkose }}
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Akupunktur -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Akupunktur</strong>
      </v-col>
      <v-col cols="12" md="9">
        {{ tarif.tarif_leistung_trend_aku }}
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>PACT - Photoaktivierte Chemotherapie</strong>
      </v-col>
      <v-col cols="12" md="9">
        {{ tarif.tarif_leistung_trend_pact }}
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- DROS-Schiene -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>DROS-Schiene</strong>
      </v-col>
      <v-col cols="12" md="9">
        {{ tarif.tarif_leistung_trend_dros }}
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Aufbissschiene -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Aufbissschiene</strong>
      </v-col>
      <v-col cols="12" md="9">
        {{ tarif.tarif_leistung_trend_aufbiss }}
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- VECTOR-Technologie -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>VECTOR-Technologie</strong>
      </v-col>
      <v-col cols="12" md="9">
        {{ tarif.tarif_leistung_trend_vector }}
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Invisalign -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Invisalign</strong>
      </v-col>
      <v-col cols="12" md="9">
        {{ tarif.tarif_leistung_trend_invisa }}
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <span v-if="tarif.tarif_kwert_gesamt > 0">
      <v-row class="mt-6">
        <v-col cols="12" md="3">
          <strong style="font-size: 18px" class="text-blue"
            >KFO Ø-Erstattung</strong
          >
          <br />
          <span class="caption">(Kieferorthopädie)</span>
        </v-col>
        <v-col cols="12" md="9">
          <strong style="font-size: 18px"
            >{{ tarif.tarif_kwert_gesamt }} %</strong
          >
          <br />
          <span class="caption">
            KIG 1-2: <strong>{{ tarif.tarif_kwert_1 }} %</strong> | KIG 3-5:
            <strong>{{ tarif.tarif_kwert_3 }} %</strong>
          </span>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- KFO Ø-Begrenzung -->
      <v-row class="mt-6">
        <v-col cols="12" md="3">
          <strong>KFO Ø-Begrenzung</strong>
          <br />
          <span class="caption"></span>
        </v-col>
        <v-col cols="12" md="9">
          <strong>{{ tarif.tarif_leistung_kfo_beg_ogkv }} | KIG 1-2</strong>
          <br />
          <strong>{{ tarif.tarif_leistung_kfo_beg_mgkv }} | KIG 3-5</strong>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- KFO Wartezeit -->
      <v-row class="mt-6">
        <v-col cols="12" md="3">
          <strong>KFO Wartezeit</strong>
          <br />
          <span class="caption"></span>
        </v-col>
        <v-col cols="12" md="9">
          <strong>{{ tarif.tarif_beschreibung_wz_kfo }}</strong>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Kinder-Zahnarztleistungen -->
      <v-row class="mt-6">
        <v-col cols="12" md="3">
          <strong>Kinder-Zahnarztleistungen</strong>
          <br />
          <span class="caption"></span>
        </v-col>
        <v-col cols="12" md="9">
          <ul>
            <li v-for="item in tarif.kindspezial" :key="item.id">
              <span v-if="item.ja">{{ item.value }}</span>
              <span
                v-else
                class="text-red"
                style="text-decoration: line-through"
                >{{ item.value }}</span
              >
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <!-- Fissurenversiegelung -->
      <v-row class="mt-6">
        <v-col cols="12" md="3">
          <strong>Fissurenversiegelung</strong>
        </v-col>
        <v-col cols="12" md="9">
          {{ tarif.tarif_leistung_speziell_fis }}
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Kinder-Prophylaxe -->
      <v-row class="mt-6">
        <v-col cols="12" md="3">
          <strong>Kinder-Prophylaxe</strong>
        </v-col>
        <v-col cols="12" md="9">
          {{ tarif.tarif_leistung_speziell_pzr_k }}
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Funktionstechnische Analyse KFO -->
      <v-row class="mt-6">
        <v-col cols="12" md="3">
          <strong>Funktionstechnische Analyse KFO</strong>
        </v-col>
        <v-col cols="12" md="9">
          {{ tarif.tarif_leistung_speziell_k_kfofta }}
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Kunststoff-Brackets -->
      <v-row class="mt-6">
        <v-col cols="12" md="3">
          <strong>Kunststoff-Brackets</strong>
        </v-col>
        <v-col cols="12" md="9">
          {{ tarif.tarif_leistung_speziell_k_kunstbrack }}
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Mini-Brackets -->
      <v-row class="mt-6">
        <v-col cols="12" md="3">
          <strong>Mini-Brackets</strong>
        </v-col>
        <v-col cols="12" md="9">
          {{ tarif.tarif_leistung_speziell_k_minibrack }}
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <!-- Farblosbogen -->
      <v-row class="mt-6">
        <v-col cols="12" md="3">
          <strong>Farblosbogen</strong>
        </v-col>
        <v-col cols="12" md="9">
          {{ tarif.tarif_leistung_speziell_k_farblbogen }}
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Lingual-Technik -->
      <v-row class="mt-6">
        <v-col cols="12" md="3">
          <strong>Lingual-Technik</strong>
        </v-col>
        <v-col cols="12" md="9">
          {{ tarif.tarif_leistung_speziell_k_lingua }}
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Retainer -->
      <v-row class="mt-6">
        <v-col cols="12" md="3">
          <strong>Retainer</strong>
        </v-col>
        <v-col cols="12" md="9">
          {{ tarif.tarif_leistung_speziell_k_retainer }}
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Invisalign -->
      <v-row class="mt-6">
        <v-col cols="12" md="3">
          <strong>Invisalign</strong>
        </v-col>
        <v-col cols="12" md="9">
          {{ tarif.tarif_leistung_speziell_k_invisa }}
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </span>
    <!-- Vertragsmodalitäten -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong style="font-size: 18px" class="text-blue"
          >Vertragsmodalitäten</strong
        ><br />
        <span class="caption">Leistungsbegrenzungen</span>
      </v-col>
      <v-col cols="12" md="9">
        <span
          v-if="tarif"
          v-html="tarif.tarif_beschreibung_beg.replace(/\n/g, '<br />')"
        ></span>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Wartezeit -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Wartezeit</strong>
      </v-col>
      <v-col cols="12" md="9">
        {{ tarif.tarif_modal_wartezeit }}
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- Kündigungsfrist -->
    <v-row class="mt-6">
      <v-col cols="12" md="3">
        <strong>Kündigungsfrist</strong>
      </v-col>
      <v-col cols="12" md="9">
        {{ tarif.tarif_int_kuendigung_details }}<br />
        <a :href="`/sofort_kuendigen/${tarif.un_url}`"
          >{{ tarif.un_name }} Zahnzusatzversicherung kündigen - Checkliste
          Kündigung</a
        >
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    bereiche: {
      type: Object,
      default: () => ({}),
    },
    budgets: {
      type: Object,
      default: () => ({}),
    },
    budgetbeginn: {
      type: String,
    },
  });
  console.log(props.tarif);
</script>
